import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

export const miniStyle = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		minWidth: '100px',
		height: '10px',
		marginTop: '2px',
		paddingLeft: '11px',
		paddingTop: '8px',
		paddingBottom: '9px',
		fontWeight: 100,
		borderStyle: 'solid',
		borderColor: '#808080',
		fontSize: '0.9rem',
		borderWidth: 0,
		borderRadius: 12,
		color: '#809c87',
		justifyContent: 'flex-start'
	},
	icon: {
		color: '#809c87',
		right: 12,
		position: 'absolute',
		userSelect: 'none',
		pointerEvents: 'none'
	},
	paper: {
		borderRadius: 12
	},
	list: {
		paddingTop: 0,
		paddingBottom: 0,
		'& li': {
			fontWeight: 200,
			paddingTop: '12px',
			paddingBottom: '12px'
		},
		'& li:hover': {
			backgroundColor: '#4CAEF6'
		},
		'& li.Mui-selected:hover': {
			backgroundColor: '#4CAEF6'
		}
	}
}));
