import { MqttClient } from 'mqtt';

export function subscribeMQTT (
	topic: any,
	client: MqttClient,
	handleUpdateMQ: any
) {
	client.subscribe(topic, function (err, granted) {
		if (granted!==undefined) {
			granted.map((grantedMessage) => {
				if (grantedMessage.topic) {
					console.log(
						`Subscribed to topic: ${grantedMessage.topic} with clientID: ${client.options.clientId}`
					);
				}
				return null;
			});
			client.on('message', function (topic, message, packet) {
				if (!message.toString().includes('type')) {
					handleUpdateMQ(`${message.toString()}`, topic);
				}
			});
		} else {
			console.log(err);
		}
	});
}

interface IsendMQTTCommand {
    timestamp: string;
    payload: {
        SPEED?: number;
        ANGLE?: number;
        SRC?: string;
        CUSTOMCOMMAND?: string;
    };
}

export function sendMQTTCommand (command: IsendMQTTCommand, client: MqttClient, topic: string) {
	client.publish(topic, JSON.stringify(command));
}

//Offer and Candidate functions
export function setOffer (offer: any, connection: RTCPeerConnection) {
	connection.setRemoteDescription(new RTCSessionDescription(offer));
	console.log('Recieved The Offer.');
}

export function setCandidate (candidate: any, connection: RTCPeerConnection) {
	connection.addIceCandidate(new RTCIceCandidate(candidate));
	console.log('Recieved The Candidate.');
}
