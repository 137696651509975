import * as React from 'react';
import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStatusBarStyles = makeStyles((theme: Theme) =>
	createStyles({
		auto: { height: '15px', width: '15px', borderRadius: '10px', backgroundColor: 'blue' },
		manual: { height: '15px', width: '15px', borderRadius: '10px', backgroundColor: 'green' }
	})
);

interface IManualIndicator {
   status: string;
}

export const ManualIndicator: React.FC<IManualIndicator>= ({ status }) => {
	const classes = useStatusBarStyles();

	return (
		<>
			{status === 'AUTO'?<div className={classes.auto}/>: <div className={classes.manual}/>}
		</>
	);
};