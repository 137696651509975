import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import '../../style/App.css';

const DefaultRoute: React.FunctionComponent = ({ ...rest }: any) => {
	const User = React.useContext(UserContext);

	const routeComponent = () =>
		User.isAuthenticated ? (
			<Redirect to = '/robots'/>
		) : (
			<Redirect to = '/login'/>
		);
	return <Route {...rest} render={routeComponent}/>;
};

export default DefaultRoute;
