import axios from 'axios';
import * as config from '../API/config';

let URL_BASE = config.DEV;
if (process.env.REACT_APP_ENV === 'LOCAL') {
	URL_BASE = config.LOCALHOST;
}

export async function login (email: string, password: string) {
	try {
		const response = await axios.post(URL_BASE + 'login', {
			email: email,
			password: password
		}, { withCredentials: true });

		if (response.status === 200) {
			// Assuming the response contains a user ID or other data you need
			return {
				isAuthorized: true,
				data: response.data
			};
		} else {
			return {
				isAuthorized: false
			};
		}
	} catch (e) {
		console.log(e);
		return {
			isAuthorized: false
		};
	}
}

export const logout = (setAuth: (status: boolean)=>{status: boolean}) => {
	document.cookie = 'session_cookie= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
	setAuth(false);
	window.location.reload();
};

export function forgotPassword (email: string, validEmail: any, invalidEmail: any) {
	axios.post(URL_BASE + 'resetpassword', {
		email: email
	}).then((response) => {
		if (response.status === 200) validEmail();
	}).catch((error) => {
		invalidEmail();
	});
}

export function changePassword (token: string, userId: number, newPassword: string | undefined, validChange: any, invalidChange: any) {
	axios.post(URL_BASE + 'resetpassword/' + token + '/' + userId, {
		newPassword: newPassword
	}).then((response) => {
		if (response.status === 200) validChange();
	}).catch((error) => {
		invalidChange();
	});
}