import React from 'react';
import { Button, Card, Grid, Modal, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import fifadrones from '../../static/fifa-drones.png';
import fifaqp from '../../static/fifa-qp.png';
import generalStyles from '../../style/styles';


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: { height: '50%' },
		modalContent: { height: '93%' },
		modalDetails: { margin: 0, height: '90%',
			'> button': { height: '100%' }
		},
		locationSetOption: { border: '1px solid #e9e9e9',  borderRadius: '10px' }
	})
);

interface standardWaypointsModal {
    standardWaypointsModal: boolean;
    setStandardWaypointsModal: (modalState: boolean) => void;
    insertFootballStandardPoints: (name: 'fifa-drones' | 'fifa-qp') => void;
}


export const StandardWaypointsModal: React.FC<standardWaypointsModal> = ({ standardWaypointsModal, setStandardWaypointsModal, insertFootballStandardPoints }) => {
	const importedclasses = generalStyles();
	const classes = useStyles();

	const handleClickDrone = () => {
		insertFootballStandardPoints('fifa-drones');
		setStandardWaypointsModal(false);
	};

	const handleClickQualityProgramme = () => {
		insertFootballStandardPoints('fifa-qp');
		setStandardWaypointsModal(false);
	};

	return (
		<Modal open={standardWaypointsModal} onClose={() => setStandardWaypointsModal(false)}>
			<Card className={importedclasses.modal + ' ' + classes.modal}>
				<div className={classes.modalContent}>
					<Typography variant={'subtitle1'}>Pick a standard location set</Typography>
					<div className={importedclasses.modalDetails + ' ' + classes.modalDetails}>
						<Grid container>
							<Grid item xs={6} className={classes.locationSetOption}>
								<Button onClick={handleClickQualityProgramme}>
									<Grid container>
										<Grid item xs={12}>
											<img style={{ maxWidth: '100%'}} src={fifaqp}/>
										</Grid>
										<Grid item xs={12}>
											<Typography>Total points: 19</Typography>
										</Grid>
									</Grid>
								</Button>
							</Grid>
							<Grid item xs={6} className={classes.locationSetOption}>
								<Button onClick={handleClickDrone}>
									<Grid container>
										<Grid item xs={12}>
											<img style={{ maxWidth: '100%'}} src={fifadrones}/>
										</Grid>
										<Grid item xs={12}>
											<Typography>Total points: 80</Typography>
										</Grid>
									</Grid>
								</Button>
							</Grid>
						</Grid>
					</div>
					<div>
						<Button variant={'outlined'} className={importedclasses.modalCancel} onClick={() => setStandardWaypointsModal(false)}>Cancel</Button>
					</div>
				</div>
			</Card>
		</Modal>
	);
};