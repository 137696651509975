import * as React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { Divider, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Robot } from '../../helpers/robot/robot';
import image from '../../static/rb1.png';

interface RCListProps {
    jobRobots: Set<Robot>;
	selectedRobot: Robot | undefined;
	setSelectedRobot: (robot: Robot | undefined) => void;
    selectedWP: number;
}

export const listStyles = makeStyles((theme: Theme) =>
	createStyles({
		scrollable: { maxHeight: '300px', overflow: 'auto' },
		div: { background: 'white', borderRadius: '15px', overflow: 'auto' }
	})
);

export const RobotClickableList: React.FC<RCListProps> = ({ jobRobots, selectedRobot, setSelectedRobot, selectedWP }: RCListProps) => {
	const classes = listStyles();
	const listOfJobSelectedRobots = Array.from(jobRobots);

	const createItems = () => {
		return listOfJobSelectedRobots.map((robot: Robot) =>
			<ListItem selected={ checkSelected(robot) } button onClick={() => {
				setSelectedRobot(robot);
			}} key={robot.serialId}>
				<ListItemAvatar>
					<Avatar src={image}></Avatar>
				</ListItemAvatar>
				<ListItemText primary={robot.name} secondary={`${robot.model}/${robot.serialId}`} />
				{ checkSelected(robot) ?
					<CheckIcon style={ { color: '#4CAEF6' } }/>
					:
					<></>
				}
			</ListItem>
		);
	};

	const checkSelected = (robot: Robot) => {
		if (listOfJobSelectedRobots && selectedRobot !== undefined) {
			return robot.id === selectedRobot.id;
		} else {
			return false;
		}
	};

	return (
		<div className={classes.div}>
			<h3>Robots</h3>
			<Divider />
			{
				selectedWP > 0 ?
					<List dense={true} className={classes.scrollable}> {createItems()} </List>
					:
					<Typography>No waypoint selected</Typography>
			}
		</div>
	);
};

