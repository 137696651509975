import axios from 'axios';
import * as config from '../API/config';
import { UserArea } from '../user/user';

let URL_BASE = config.DEV;
if (process.env.REACT_APP_ENV === 'LOCAL') {
	URL_BASE = config.LOCALHOST;
}

export async function xytolatlnginarea (x: number, y: number, area: UserArea) {
	let ans: any;
	if (area.sortedVertices) {
		try {
			const sortedVertices = JSON.parse(area.sortedVertices);
			await axios.get(URL_BASE + 'latlngfromxy', { withCredentials: true, params: {
				x: x,
				y: y,
				p1lng: sortedVertices[1][0],
				p1lat: sortedVertices[1][1],
				p2lng: sortedVertices[2][0],
				p2lat: sortedVertices[2][1],
				p3lng: sortedVertices[3][0],
				p3lat: sortedVertices[3][1],
				p4lng: sortedVertices[4][0],
				p4lat: sortedVertices[4][1],
				sizex: area.pitchSize ? area.pitchSize[0] : 110,
				sizey: area.pitchSize ? area.pitchSize[1] : 80
			}}).then((response) => {
				if (response.status === 200) {
					ans = response.data;
					return ans;
				}
			});
		} catch (e) {
			console.log(e);
		}
		return ans;
	} else {
		return ans;
	}
}