import * as React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import turf from '@turf/area';
import { Feature } from 'geojson';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { JobCard } from '../components/jobs/JobCard';
import { JobMap } from '../components/jobs/JobMap';
import { Paginate } from '../components/Pagination/Paginate';
import UserContext from '../context/UserContext';
import * as Job from '../helpers/job/job';
import * as Sensor from '../helpers/sensors/sensors';
import { miniStyle } from '../style/miniStyle';
import generalStyles from '../style/styles';

const useStyles = makeStyles((theme) =>
	createStyles({
		rootDiv: { height: '100%', width: '100%', margin: '0px', padding: '0px', overflowY: 'hidden', overflowX: 'hidden', borderTop: '2px solid #e0e0e0' },
		pageTitle: { display: 'flex', height: '40px', marginLeft: '20px', marginTop: '20px' },
		selectFilter: { display: 'flex', height: '90px', alignItems: 'center', marginLeft: '20px', marginRight: '20px', justifyContent: 'space-between' },
		jobMap: { display: 'inline-block', height: '100%', minWidth: '50%' },
		pagination: { height: '60px', paddingTop: '10px', borderTop: '1px solid #bdbdbd', display: 'flex', justifyContent: 'center' },
		flex: { display: 'flex', justifyContent: 'flex-start' },
		title: { display: 'flex', justifyContent: 'space-between' },
		mainContent: { display: 'flex', width: '100%', height: 'calc(100% - 150px)' },
		gridRoot: { display: 'inline-block', height: '100%', minWidth: '50%' },
		gridContainer: { height: 'calc(100% - 60px)', width: '100%', borderTop: '1px solid #bdbdbd' },
		mapGridItem: { height: '100%', minWidth: '100%', direction: 'rtl', overflowY: 'scroll' },
		filterLabel: { marginLeft: 0, fontSize: '1.2em', color: 'gray', '&.Mui-focused': { color: 'gray' }, transform: 'translate(0px, -9px) scale(0.75)' },
		button: { width: 200, left: '2%' },
		margin: { margin: '1%' },
		extendedIcon: { marginRight: theme.spacing(1) },
		none: { textDecoration: 'none' },
		cards: { width: '60%', marginLeft: '20%' },
		formcontrol: { marginLeft: theme.spacing(1) },
		placeholderText: { width: '60%', marginLeft: '20%', fontStyle: 'italic', fontWeight: 'lighter', fontSize: '2rem', color: 'rgba(0,0,0,0.4)' },
		selectDropdown: { color: 'green', backgroundColor: 'blue' },
		newJobButton: { color: '#ffffff', background: 'rgb(47, 91, 181)', alignItems: 'center', whiteSpace: 'nowrap', transition: 'color 0.5s', width: '10rem', height: '30px', borderRadius: '20px', textTransform: 'none', fontSize: '1.2em', '&:hover': { backgroundColor: '#545AA7' } }
	})
);

interface geoData {
    type: string;
	coordinates: number[];
}

interface waypointGeoData {
    id: string;
    type: string;
    properties: {type: string};
    geometry: { coordinates: [number, number]; type: string };
}

export const Jobs: React.FC = () => {
	const User = React.useContext(UserContext);
	const classes = useStyles();
	const importedClasses = generalStyles();
	const minimalSelectClasses = miniStyle();
	const [jobList, setJobList] = React.useState<Job.Job[]>([]);
	const [jobState, setJobState] = React.useState<string>('All');
	const [measurementsGeoData, setMeasurementsGeoData] = React.useState<geoData[]>([]);
	const [waypointGeoData, setWaypointGeoData] = React.useState<waypointGeoData[]>([]);
	const [selectedJobId, setSelectedJobId] = React.useState<number>(0);
	const [activeIndex, setActiveIndex] = React.useState<number>(0);
	const [lastJobId, setLastJobId] = React.useState<number|null>(null);
	const [numberOfPages, setNumberOfPages] = React.useState<number>(0);
	const [page, setPage] = React.useState<number>(0);
	const [pageJobs, setPageJobs] = React.useState<Job.Job[]>([]);

	const jobstateChangeHandler = (e: any) => {
		setJobState(e.target.value);
	};

	const menuProps: any = {
		classes: {
			paper: minimalSelectClasses.paper,
			list: minimalSelectClasses.list
		},
		anchorOrigin: {
			vertical: 'bottom',
			horizontal: 'left'
		},
		transformOrigin: {
			vertical: 'top',
			horizontal: 'left'
		},
		getContentAnchorEl: null
	};

	const iconComponent = (props: any) => {
		return (
			// eslint-disable-next-line react/prop-types
			<ExpandMoreIcon className={props.className + ' ' + minimalSelectClasses.icon}/>
		);
	};

	React.useEffect(() => {
		Job.getJobs(User.id).then((res) => {
			let tempJobList: Job.Job[] = [];
			let filteredJobs: Job.Job[];
			tempJobList = [...res];
			if (jobState.toLowerCase() === 'all') {
				filteredJobs = tempJobList;
				setJobList(filteredJobs);
			} else {
				filteredJobs = tempJobList.filter((job:any) => job.state.toLowerCase() === jobState.toLowerCase());
			}
			setJobList(filteredJobs);
			//For pagination
			const numberOfJobs = filteredJobs.length;
			const totalPageNumber = numberOfJobs / 10;
			const actualTotalPage = Math.round(totalPageNumber);
			setNumberOfPages(actualTotalPage);
			setPage(1);
		});
	}, [jobState]);

	const listOfJobsPerPage = () => {
		const numberOfJobsPerPage = 10;
		const lastIndex = (numberOfJobsPerPage * page);
		const firstIndex = (lastIndex - numberOfJobsPerPage);
		setPageJobs(jobList.slice(firstIndex, lastIndex));
	};

	React.useEffect(() => {
		listOfJobsPerPage();
	}, [jobList]);

	React.useEffect(() => {
		listOfJobsPerPage();
	}, [page]);

	React.useEffect(() => {
		if (pageJobs.length > 0) {
			const tempArr: any[] = [];
			const tempWaypointArr: any[] = [];
			Sensor.getGeoDataByJobId(pageJobs[0].id.toString()).then((res) => {
				res.forEach((data) => {
					const { point } = data;
					if (point) {
						tempArr.push(point);
					}
				});
				setMeasurementsGeoData(tempArr);
			});
			setLastJobId(pageJobs[pageJobs.length - 1].id);
			setActiveIndex(0);
			Job.getJobById(pageJobs[0].id).then((res) => {
				setSelectedJobId(pageJobs[0].id);
				tempWaypointArr.push(...res.geoInfo);
				setWaypointGeoData(tempWaypointArr);
			});
		}
	}, [pageJobs]);


	const getTitle = (job: Job.Job) => {
		const date = moment(job.scheduledTime);
		const isToday: boolean = date.isSame(moment(), 'day');
		let title = '';
		isToday ? title = `Today at ${date.format('HH:mm A')}` : title = `${date.format('MMMM D')} at ${date.format('HH:mm a')}`;
		return title;
	};

	const getDaysLeft = (job: Job.Job) => {
		const jobDate = moment(job.scheduledTime);
		const currentDate = moment();
		const daysLeftDifference: number = (jobDate.diff(currentDate, 'days'));
		let left = '';
		daysLeftDifference > 0 ? left = `${daysLeftDifference} days left` : left = '';
		return left;
	};

	const getRobots = (job: Job.Job) => {
		const robots = new Set<number>(JSON.parse(job.configuration).robotsIDs);
		if (robots.size === 1) {
			return '1 Robot';
		} else {
			return robots.size + ' Robots';
		}
	};
	const getArea = (job: Job.Job) => {
		let area = 0;
		JSON.parse(job.configuration).geoInfo.forEach((feature: Feature) => {
			if (feature.geometry.type === 'Polygon') area = area + Math.round(turf(feature));
		});
		return area + ' M2';
	};

	const getWaypoints = (job: Job.Job) => {
		let waypoints = 0;
		JSON.parse(job.configuration).geoInfo.forEach((feature: Feature) => {
			if (feature.geometry.type === 'Point') {
				waypoints++;
			}
		});
		return waypoints === 1 ? '1 Waypoint' : waypoints + ' Waypoints';
	};

	return (
		<div className={classes.rootDiv}>
			<div className={classes.pageTitle}>
				<Typography variant='h4'>Jobs</Typography>
			</div>
			<div className={classes.selectFilter}>
				<FormControl>
					<InputLabel className={classes.filterLabel} id="job-state">STATE</InputLabel>
					<Select className={importedClasses.select} classes={{select: minimalSelectClasses.root}} labelId="job-state" onChange={(e) => {
						jobstateChangeHandler(e); setPage(1);
					}} value={jobState} IconComponent={iconComponent} MenuProps={menuProps}>
						<MenuItem value="All">All</MenuItem>
						<MenuItem value="Pending">Pending</MenuItem>
						<MenuItem value="In progress">In progress</MenuItem>
						<MenuItem value="Finished">Finished</MenuItem>
						<MenuItem value="Cancelled">Cancelled</MenuItem>
						<MenuItem value="Aborted">Aborted</MenuItem>
					</Select>
				</FormControl>
				<Link to={'/newjob'} key='job' className={classes.none}>
					<Button className={classes.newJobButton} variant="text">+ New Job</Button>
				</Link>
			</div>

			{
				pageJobs.length > 0 ?
					<div className={classes.mainContent}>
						<div className={classes.gridRoot}>
							<Grid container className={classes.gridContainer}>
								<Grid item xs={12} md={6} lg={6} className={classes.mapGridItem}>
									{pageJobs.map((job, i) => (
										<JobCard jobTime={moment(job.scheduledTime)} title={getTitle(job)} name={job.jobName} state={job.state} id={job.id!.toString()} key={job.id} daysLeft={getDaysLeft(job)} robots={getRobots(job)} selectedJobId={selectedJobId} index={i}
											area={getArea(job)} lastJobId={lastJobId} activeIndex={activeIndex} waypoints={getWaypoints(job)} configuration={job.configuration} setGeoData={setMeasurementsGeoData} setWaypointGeoData={setWaypointGeoData} setSelectedJobId={setSelectedJobId} setActiveIndex={setActiveIndex}/>
									))}
								</Grid>
							</Grid>
							<div className={classes.pagination}>
								<Paginate numberOfPages={numberOfPages} setPage={setPage} page={page} />
							</div>
						</div>
						<div className={classes.jobMap}>
							<JobMap measurementsLocations={measurementsGeoData} jobWaypoints={waypointGeoData} />
						</div>
					</div> :
					<div>
						<span>No jobs available</span>
					</div>
			}
		</div>
	);
};
