import * as React from 'react';
import { Divider, Typography } from '@mui/material';
import style from '../../style/styles';

interface Message {
    timestamp: number;
    read: boolean;
    message: string;
    topic: string;
    full: boolean;
}

interface IProps {
    messages: Message[];
}

export const Sensors: React.FC<IProps> = ({ messages }) => {

	const classes = style();
	const { message, topic } = messages[0];

	const [sensors, setSensors] = React.useState({
		right: {
			tacho: 0,
			speed: 0
		},
		left: {
			tacho: 0,
			speed: 0
		}
	});

	function displaySensors (message: string, topic: string) {
		if (topic.includes('sensor')) {
			const messageJSON = JSON.parse(message);
			if (topic === '/AABBCCDDEEFFGG1/sensor/speed/motor_right') {
				setSensors({
					...sensors,
					right: {
						tacho: parseInt(messageJSON.TACHOMETER),
						speed: parseInt(messageJSON.SPEED)
					}
				});
			} else if (topic === '/AABBCCDDEEFFGG1/sensor/speed/motor_left') {
				setSensors({
					...sensors,
					left: {
						tacho: parseInt(messageJSON.TACHOMETER),
						speed: parseInt(messageJSON.SPEED)
					}
				});
			}
		}
	}

	React.useEffect(() => {
		displaySensors(message, topic);
		// eslint-disable-next-line
    }, [messages]);

	return (
		<div className={classes.rowSensors}>
			<div className={classes.columnSensors}>
				<Typography variant='subtitle1'>LEFT</Typography>
				<div className={classes.rowSensors}>
					<Typography variant='subtitle2'>Tachometer:</Typography>

					<div className={classes.dataSensors}>
						<Typography variant='h3'> {sensors.left.tacho} </Typography>
						<Typography variant='subtitle2'>RPM</Typography>
					</div>

					<Typography variant='subtitle2'>Speed:</Typography>

					<div className={classes.dataSensors}>
						<Typography variant='h3'> {sensors.left.speed} </Typography>
						<Typography variant='subtitle2'>%</Typography>
					</div>
				</div>
			</div>

			<Divider orientation='vertical' />

			<div className={classes.columnSensors}>
				<Typography variant='subtitle1'>RIGHT</Typography>

				<div className={classes.rowSensors}>
					<Typography variant='subtitle2'>Tachometer:</Typography>

					<div className={classes.dataSensors}>
						<Typography variant='h3'> {sensors.right.tacho} </Typography>
						<Typography variant='subtitle2'>RPM</Typography>
					</div>

					<Typography variant='subtitle2'>Speed:</Typography>

					<div className={classes.dataSensors}>
						<Typography variant='h3'> {sensors.right.speed} </Typography>
						<Typography variant='subtitle2'>%</Typography>
					</div>
				</div>
			</div>
		</div>
	);
};
