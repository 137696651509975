import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Header } from '../../components/site/Header';
import { Sidebar } from '../../components/site/Sidebar';
import UserContext from '../../context/UserContext';
import '../../style/App.css';

// eslint-disable-next-line
const ProtectedRoute = ({ component, ...rest }: any) => {
	const User = React.useContext(UserContext);

	const routeComponent = (props: any) =>
		User.isAuthenticated ? (
			<Route {...rest}>
				<Header/>
				<div className='LowerRow'>
					<Sidebar/>
					<div className='Content'>
						{React.createElement(component, props)}
					</div>
				</div>
			</Route>
		) : (
			<Redirect to='/login'/>
		);
	return <Route {...rest} render={routeComponent} path='/robots'/>;
};

export default ProtectedRoute;