import React from 'react';
import { Grid, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import { Sensor } from '../../helpers/sensors/sensors';

const useStyles = makeStyles(() =>
	createStyles({
		summaryCard: { width: '100%', marginTop: '0px', marginLeft: '0px', paddingLeft: '0px', paddingTop: '0px' },
		itemTitle: { display: 'flex', paddingLeft: '15px',
			'& .MuiTypography-root': { fontSize: '0.8em', color: '#6c6c6c' } },
		itemValue: { display: 'flex',
			'& .MuiTypography-root': { color: '#000000', fontWeight: 500, textAlign: 'left' } }
	})
);

export const SummaryContent = ({sensorData, from, to, areaName}: any) => {

	const classes = useStyles();

	const numberOfJobs = () => {
		let numberOfJobs = 0;
		const numberOfDays = moment(to).diff(moment(from), 'days') + 1;
		const uniqueJobs = new Set();
		sensorData.forEach((data: Sensor) => {
			uniqueJobs.add(data.job);
		});
		// Instead of checking for undefined value on each iteration, just substract one to the size
		if (uniqueJobs.has(undefined)) {
			numberOfJobs = uniqueJobs.size - 1;
		} else {
			numberOfJobs = uniqueJobs.size;
		}
		return numberOfJobs + (numberOfJobs === 1 ? ' job along ' : ' jobs along ')
			+ numberOfDays + (numberOfDays === 1 ? ' day' : ' days');
	};

	const measurements = () => {
		const measurements = new Set();
		sensorData.forEach((job: Sensor) => {
			if (job.type !== 'payload') {
				measurements.add(job.type);
			}
		});
		let text = '';
		const measurementTypes = Array.from(measurements).sort();
		for ( let i = 0; i < measurementTypes.length; i++) {
			if ( i === 0 ) {
				text += measurementTypes[0];
			} else {
				if ( i < measurementTypes.length-1 ) {
					text += ', ';
				} else if ( i === measurementTypes.length-1 ) {
					text += ' and ';
				}
				text += measurementTypes[i];
			}
		}
		return text.charAt(0).toUpperCase() + text.slice(1);
	};

	const numberOfPoints = () => {
		let numberOfPoints = 0;
		sensorData.forEach((job: Sensor) => {
			if (job.type !== 'payload') {
				numberOfPoints++;
			}
		});
		if (numberOfPoints === 1) {
			return '1 point';
		}
		return `${numberOfPoints} points`;
	};

	return (
		<div>
			<Grid container spacing={1} className={classes.summaryCard}>
				<Grid item xs={5} className={classes.itemTitle}>
					<Typography variant='body1'>Start date: </Typography>
				</Grid>
				<Grid item xs={7} className={classes.itemValue}>
					<Typography variant='subtitle2'>
						{moment(from).format('MMMM Do')}
					</Typography>
				</Grid>
				<Grid item xs={5} className={classes.itemTitle}>
					<Typography variant='body1'>End date: </Typography>
				</Grid>
				<Grid item xs={7} className={classes.itemValue}>
					<Typography variant='subtitle2'>
						{moment(to).format('MMMM Do')}
					</Typography>
				</Grid>
				<Grid item xs={5} className={classes.itemTitle}>
					<Typography variant='body1'>Area: </Typography>
				</Grid>
				<Grid item xs={7} className={classes.itemValue}>
					<Typography variant='subtitle2'>
						{areaName}
					</Typography>
				</Grid>
				<Grid item xs={5} className={classes.itemTitle}>
					<Typography variant='body1'># of jobs: </Typography>
				</Grid>
				<Grid item xs={7} className={classes.itemValue}>
					<Typography variant='subtitle2'>
						{numberOfJobs()}
					</Typography>
				</Grid>
				<Grid item xs={5} className={classes.itemTitle}>
					<Typography variant='body1'>Measurements: </Typography>
				</Grid>
				<Grid item xs={7} className={classes.itemValue}>
					<Typography variant='subtitle2'>
						{measurements()}
					</Typography>
				</Grid>
				<Grid item xs={5} className={classes.itemTitle}>
					<Typography variant='body1'># of measurements: </Typography>
				</Grid>
				<Grid item xs={7} className={classes.itemValue}>
					<Typography variant='subtitle2'>
						{numberOfPoints()}
					</Typography>
				</Grid>


			</Grid>
		</div>
	);
};
