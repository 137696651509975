import React from 'react';
import EventIcon from '@mui/icons-material/Event';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import { Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import * as robot from '../../helpers/robot/robot';
import image from '../../static/rb1.png';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		listItem: { justifyContent: 'flex-end' },
		chip: { backgroundColor: '#e0e0e0' }
	})
);

interface jobProps {
    positioning: string;
    currentStep: number;
    selectedRobots: Set<robot.Robot>;
    robots: robot.Robot[];
    waypointsAmmount: number;
    areasTotalSqMeters: number;
    selectedTasks: Set<string>;
    selectedDate: Date;
}

export const JobStatus: React.FC<jobProps> = ({ positioning, currentStep, selectedRobots, robots, waypointsAmmount, areasTotalSqMeters, selectedTasks, selectedDate }: jobProps) => {
	const classes = useStyles();

	const getRobotLabel = () => {
		if (robots!.length === 1) {
			return robots![0].name;
		}
		if (selectedRobots.size === 1) {
			return Array.from(selectedRobots)[0].name;
		}
		return selectedRobots.size + ' Robots';
	};

	const dateTimeDisplay = (dat: Date) => {
		const displayLabel: any = moment(dat);
		return displayLabel.format('YYYY-MM-DD, hh:mma');
	};

	const getTasksChip = () => {
		let tasksLabel = '';
		Array.from(selectedTasks).forEach((task: string) => {
			if (tasksLabel === '') {
				tasksLabel = task;
			} else {
				tasksLabel = tasksLabel + ', ' + task;
			}
		});
		return <ListItem className={classes.listItem} key={'taskChip'}>
			<Chip key={'taskChip'} style={{ backgroundColor: '#e0e0e0', display: 'flex', flexWrap: 'wrap', height: '20%', maxWidth: 'fitContent' }} label={<Typography style={{ whiteSpace: 'normal', fontSize: '0.8125rem' }}>{'Tasks: ' + tasksLabel}</Typography>}/>
		</ListItem>;
	};

	function generateItems (step: number) {
		const chips = [<ListItem className={classes.listItem} key={'robotChip'} ><Chip className={classes.chip} key={'robotChip'} label={getRobotLabel()} avatar={<Avatar src={image}/>}/></ListItem>,
			<ListItem className={classes.listItem} key={'areaChip'}><Chip key={'areaChip'} className={classes.chip} label={(Math.round((areasTotalSqMeters + Number.EPSILON) * 100) / 100) + ' M2'} avatar={<SquareFootIcon/>} /></ListItem>,
			<ListItem className={classes.listItem} key={'geoChip'}><Chip key={'geoChip'} className={classes.chip} label={waypointsAmmount + ' Waypoints'} avatar={<GpsFixedIcon/>} /></ListItem>,
			getTasksChip(),
			<ListItem className={classes.listItem} key={'dateChip'}>
				<Chip className={classes.chip} variant= "filled"key={'selectedDate'} label={dateTimeDisplay(selectedDate)} avatar={<EventIcon/>}/>
			</ListItem>];

		return chips.filter((elem, step) => {
			return step < currentStep;
		});
	}

	return (
		<div className={positioning}>
			<List style={{ maxWidth: '220px' }}>
				{generateItems(currentStep)}
			</List>
		</div>
	);
};
