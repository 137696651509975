import React from 'react';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { Badge, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		badge: { borderRadius: '10px', color: 'white', padding: '0 12px 0 12px', textAlign: 'center' },
		online: { backgroundColor: 'green', borderColor: 'green', border: '1px', borderStyle: 'solid' },
		offline: { backgroundColor: 'white', color: 'red', borderColor: 'red', border: '1px', borderStyle: 'solid' },
		icon: { }
	})
);

interface indicatorInterface {
	status: boolean;
}

export const Indicator: React.FC<indicatorInterface> = ({ status }) => {
	const classes = useStyles();
	return (
		<Badge className={ classes.badge + ' ' + (status ? classes.online : classes.offline) } >
			{ !status ?
				<NotInterestedIcon style={{ height: '13px', margin: '1px -3px 0 -8px' }} />
				:
				<></>
			}
			<Typography style={{ fontSize: '0.7rem' }}>{status ? 'online' : 'offline'}</Typography>
		</Badge>
	);
};
