import { useState, useEffect } from 'react';
import { UserModel } from './UserContext';

const useUserHook = (): UserModel => {
	const [isAuthenticated, setAuth] = useState(
		localStorage.getItem('isAuthenticated') === 'true'
	);
	const [id, setId] = useState(
		parseInt(localStorage.getItem('userId') || '0', 10)
	);

	// Explicitly state that userType can be a string or null
	const [userType, setUserType] = useState<string | null>(
		localStorage.getItem('userType') || 'default'
	);

	useEffect(() => {
		localStorage.setItem('isAuthenticated', String(isAuthenticated));
		localStorage.setItem('userId', String(id));
		localStorage.setItem('userType', userType || '');
	}, [isAuthenticated, id, userType]);

	return {
		isAuthenticated,
		setAuth,
		id,
		setId,
		userType,
		setUserType
	};
};

export default useUserHook;
