import * as React from 'react';
import { useState, useEffect } from 'react';
import { SelectChangeEvent, TextFieldProps, TextField } from '@mui/material';
import { Button, Select, MenuItem } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import { Marker, InteractiveMap, ViewState, Popup } from 'react-map-gl';
import { getMapToken } from '../../helpers/map/map';
import { Robot, getRobots } from '../../helpers/robot/robot';
import { Sensor, getSensors } from '../../helpers/sensors/sensors';
import generalStyles from '../../style/styles';



interface Viewport {
	latitude: number;
	longitude: number;
	zoom: number;
	bearing?: number;
	pitch?: number;
	width?: number | string;
	height?: number | string;
}

interface RobotSensorDataMapProps {
	onMarkerClick: (id: string) => void;
}

export const RobotSensorDataMap: React.FC<RobotSensorDataMapProps> = ({ onMarkerClick }) => {
	const importedclasses = generalStyles();

	const [robots, setRobots] = useState<Robot[]>([]);
	const [selectedRobot, setSelectedRobot] = useState<Robot | null>(null);
	const [sensorData, setSensorData] = useState<Sensor[]>([]);
	const [selectedDateRange, setSelectedDateRange] = useState<Date[]>([new Date(), new Date()]);
	const [viewport, setViewport] = useState<Viewport>({ latitude: 37.7577, longitude: -122.4376, zoom: 16 });
	const [selectedMarker, setSelectedMarker] = useState<Sensor | null>(null);

	useEffect(() => {
		const fetchRobots = async () => {
			const robots = await getRobots();
			setRobots(robots);
			setSelectedRobot(robots[0] || null);
		};

		fetchRobots();
	}, []);

	const handleRobotChange = (event: SelectChangeEvent) => {
		const robotId = Number(event.target.value);
		const robot = robots.find((robot) => robot.id === robotId) || null;
		setSelectedRobot(robot);
	};

	const fetchSensorData = async () => {
		if (!selectedRobot) return;

		const [startDate, endDate] = selectedDateRange;

		// Set startDate to the beginning of the day and endDate to the end of the day
		const formattedStartDate = format(startDate, 'yyyy-MM-dd') + 'T00:00:00';
		const formattedEndDate = format(endDate, 'yyyy-MM-dd') + 'T23:59:59';

		let sensorData = await getSensors(
			selectedRobot.serialId,
			formattedStartDate,
			formattedEndDate
		);

		// filter sensorData to only include moisture type
		sensorData = sensorData.filter((sensor) => sensor.type === 'moisture');

		setSensorData(sensorData);

		if (sensorData.length > 0 && sensorData[0].point.coordinates.length >= 2) {
			setViewport({
				...viewport,
				longitude: sensorData[0].point.coordinates[0],
				latitude: sensorData[0].point.coordinates[1]
			});
		}
	};

	const handleMarkerClick = (sensor: Sensor) => {
		setSelectedMarker(sensor);
		onMarkerClick(sensor.id.toString());
	};

	return (
		<div>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<Select value={selectedRobot?.id.toString() || ''} onChange={handleRobotChange}>
					{robots.map((robot) => (
						<MenuItem key={robot.id} value={robot.id}>
							{robot.name}
						</MenuItem>
					))}
				</Select>
				<DesktopDatePicker
					label="Start date"
					value={selectedDateRange[0]}
					onChange={(newValue: Date | null) => setSelectedDateRange([newValue || new Date(), selectedDateRange[1]])}
				/>
				<DesktopDatePicker
					label="End date"
					value={selectedDateRange[1]}
					onChange={(newValue: Date | null) => setSelectedDateRange([selectedDateRange[0], newValue || new Date()])}
				/>
				<Button onClick={fetchSensorData}>Fetch Data</Button>
			</LocalizationProvider>
			<div className={importedclasses.separator} />
			<InteractiveMap
				{...viewport}
				width="100%"
				height="400px"
				mapStyle="mapbox://styles/mapbox/satellite-v9"
				mapboxApiAccessToken={getMapToken()}
				onViewportChange={(nextViewport: Viewport) => setViewport(nextViewport)}
			>
				{sensorData.map((data, index) => (
					<Marker
						key={index}
						latitude={data.point.coordinates[1]}
						longitude={data.point.coordinates[0]}
					>
						<div onClick={() => handleMarkerClick(data)}>⚫</div>
					</Marker>
				))}
				{selectedMarker && (
					<Popup
						latitude={selectedMarker.point.coordinates[1]}
						longitude={selectedMarker.point.coordinates[0]}
						closeOnClick={false}
						onClose={() => setSelectedMarker(null)}
					>
						<span>ID: {selectedMarker.id}</span>
					</Popup>
				)}
			</InteractiveMap>
		</div>
	);
};
