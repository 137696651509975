import * as React from 'react';
import { TextField } from '@mui/material';
import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

interface JobNameProps {
    positioning: string;
    showOnStep: number;
    currentStep: number;
    jobNameText: string;
    setJobNameText: (jobName: string)=> void;
}

export const nameInputStyles = makeStyles((theme: Theme) =>
	createStyles({
		titleInput: { width: '100%' }
	})
);

export const JobName: React.FC<JobNameProps> = ({ positioning, showOnStep, currentStep, jobNameText, setJobNameText }: JobNameProps) => {
	const classes = nameInputStyles();

	return (
		<>
			{  currentStep === showOnStep ?
				<div className={positioning}>
					<TextField className={classes.titleInput} value={jobNameText} size='small' fullWidth={true} id='job-title' label='Job title'
						variant='outlined' onChange={(e) => setJobNameText(e.target.value)}/>
				</div>
				:
				<></>
			}
		</>
	);
};