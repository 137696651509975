import * as React from 'react';
import { Typography } from '@mui/material';
import style from '../../style/styles';

interface IProps {
    connection: RTCPeerConnection;
    connectTimestamp: number;
}

export const VideoStats: React.FC<IProps> = ({ connection, connectTimestamp }) => {
	const classes = style();

	const [session, setSession] = React.useState(0);
	const [fps, setFps] = React.useState(0);
	const [mbps, setMbps] = React.useState(0);
	const [dataTot, setDataTot] = React.useState(0);

	function getVideoStats () {
		connection.getStats().then((stats) => {
			stats.forEach((report) => {
				if (report.type === 'inbound-rtp') {
					const tempMbps = +(
						(report.bytesReceived * 8) /
                        (1024 * 1024) /
                        session
					).toFixed(2);
					setMbps(tempMbps);
					const tempDataTot = +(
						report.bytesReceived /
                        (1024 * 1024)
					).toFixed(2);
					setDataTot(tempDataTot);
				}
				if (report.type === 'track') {
					const tempFps = +(report.framesReceived / session).toFixed(2);
					setFps(tempFps);
				}
			});
		});
	}

	React.useEffect(() => {
		const interval = setInterval(() => {
			const session = Date.now() - connectTimestamp;
			setSession(Math.floor(session/1000));
			getVideoStats();
		}, 2000);
		return () => clearInterval(interval);
		// eslint-disable-next-line
    }, [session]);

	return (
		<div className={classes.statsVideo}>
			<Typography variant='body1' className={classes.statVideo + ' ' + classes.enanoBackground}> Session: {session} s{' '} </Typography>
			<Typography variant='body1' className={classes.statVideo + ' ' + classes.enanoBackground}> Frame rate: {fps} fr/s </Typography>
			<Typography variant='body1' className={classes.statVideo + ' ' + classes.enanoBackground}> Bit rate: {mbps} Mbit/s{' '} </Typography>
			<Typography variant='body1' className={classes.statVideo + ' ' + classes.enanoBackground}> Total data: {dataTot} MB{' '} </Typography>
		</div>
	);
};
