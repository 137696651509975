import axios from 'axios';
import * as config from '../API/config';

let URL_BASE = config.DEV;
if (process.env.REACT_APP_ENV === 'LOCAL') {
	URL_BASE = config.LOCALHOST;
}

export interface User {
    id?: number;
    email?: string;
    location?: UserLocation;
}

interface UserLocation {
    lat: number;
    lng: number;
    zoomAt?: number;
    bearing?: number;
}

export interface UserArea {
    name: string;
    area: {
		coordinates: [][number];
		type: string;
	};
    defaultShow: boolean;
	sortedVertices?: string;
	photo?: string;
	pitchSize?: number[];
	zoomAt?: number;
	bearing?: number;
	kind?: string;
}

export async function getUserByEmail (email: string | null) {
	let users: User[] = [];
	let loggedInUser: User = {};
	try {
		return await axios.get(URL_BASE + 'users', { withCredentials: true })
			.then((response) => {
				if (response.status === 200) {
					users = [...response.data];
				}
				return users.forEach((user) => {
					if (user.email === email) {
						loggedInUser = user;
					}
				});
			})
			.then(() => {
				return loggedInUser;
			});
	} catch {
		return loggedInUser;
	}
}

export async function getAllUsers () {
	let users: User[] = [];
	try {
		return await axios.get(URL_BASE + 'users', { withCredentials: true })
			.then((response) => {
				if (response.status === 200) {
					users = [...response.data];
				}
				return users;
			});
	} catch {
		return [];
	}
}

export async function getUserByToken () {
	try {
		return axios.get(URL_BASE + 'getuser', { withCredentials: true })
			.then((response) => {
				return response.data;
			});
	} catch {
		console.log('Could not find user');
	}
}

export async function getUserAreas () {
	try {
		return axios.get(URL_BASE + 'users/definedAreas', { withCredentials: true })
			.then((response) => {
				return response.data;
			});
	} catch {
		console.log('Error retrieving user areas');
	}
}

export async function postUserAreaWithSensorDataIds (name: string, vertices: string, kind?: string, userId?: string) {
	try {
		const bodyParams = {
			'name': name,
			'vertices': JSON.parse('[' + vertices + ']'),
			...(kind !== undefined && {'kind': kind}),
			...(userId !== undefined && {'userId': userId})
		};
		return axios.post(URL_BASE + 'users/definedAreas', bodyParams, { withCredentials: true })
			.then((response) => {
				return response.data;
			});
	} catch {
		console.log('Error creating user area');
		throw new Error('Error creating user area');
	}
}
