import * as React from 'react';
import { FC, useState, useContext, useEffect } from 'react';
import { Typography } from '@mui/material';
import { NewUserArea } from '../components/backoffice/NewUserArea';
import { RobotSensorDataMap } from '../components/backoffice/RobotSensorDataMap';
import UserContext from '../context/UserContext';
import generalStyles from '../style/styles';




const Backoffice: FC = () => {
	const [selectedSensors, setSelectedSensors] = useState<string[]>([]);
	const importedclasses = generalStyles();
	const contextUser = useContext(UserContext);

	useEffect(() => {
		console.log('User context: '+contextUser.id);
	}, [contextUser]);

	const handleMarkerClick = (id: string) => {
		setSelectedSensors((prev) => [...prev, id]);
	};

	const handleClearSensors = () => {
		setSelectedSensors([]);
	};

	return (
		<div>
			<div className={importedclasses.separatorTitle} />
			<Typography variant="h6">Create new user area</Typography>
			<div className={importedclasses.separatorTitle} />
			<NewUserArea selectedSensors={selectedSensors} onClear={handleClearSensors} />
			<div className={importedclasses.separatorTitle} />
			<Typography variant="h6">Robot Sensor Data Map</Typography>
			<div className={importedclasses.separatorTitle} />
			<RobotSensorDataMap onMarkerClick={handleMarkerClick} />
		</div>
	);
};

export { Backoffice };