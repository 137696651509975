import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

interface paginate {
  numberOfPages: number;
  setPage: (value: number) => void;
  page: number;
}

export const Paginate = ({ numberOfPages, setPage, page }: paginate) => {

	const handlePageChange = (e: React.ChangeEvent<unknown>, value: number) => {
		setPage(value);
	};

	return (
		<div>
			{numberOfPages < 2 ? <></> :
				<Stack>
					<Pagination count={numberOfPages}  shape="rounded" color="primary" onChange={handlePageChange} page={page}/>
				</Stack>}
		</div>
	);
};