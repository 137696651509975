import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import { getQueryVariable } from '../../helpers/main';
import { getDataForIntervalInUserArea, Sensor } from '../../helpers/sensors/sensors';
import { SummaryContent } from './SummaryContent';

const useStyles = makeStyles(() =>
	createStyles({
		resultsGrid: {width: 'calc(100% - 20px)', minWidth: '400px', margin: '10px', minHeight: '300px' },
		cardHeader: { color: '#585858', backgroundColor: '#d3d3d3', height: '30px', display: 'flex', alignItems: 'center', paddingLeft: '10px', fontSize: '0.8em' },
		cardTitle: { color: '#4c4c4c', fontSize: '0.9em',  fontWeight: '400' },
		smallCard: { padding: '0px', border: '1px solid #d3d3d3'},
		summaryCard: { minWidth: '400px' }
	})
);

export const ReportResult = () => {
	const [reportData, setReportData] = useState<Sensor[]>([]);
	const history = useHistory();
	const classes = useStyles();
	const areaName = getQueryVariable('areaName');
	const startDate =  getQueryVariable('startDate');
	const endDate = getQueryVariable('endDate');

	useEffect(() => {
		if ( areaName && startDate && endDate ) {
			getDataForIntervalInUserArea(areaName, startDate, endDate).then((res) => {
				if (res.length === 0 ) {
					history.push({ pathname: '/area-reports',
						search: '?areaName=' + areaName + '&startDate=' + startDate + '&endDate=' + endDate,
						state: { noResults: true }
					});
				} else if (res.length > 0) {
					setReportData(res);
				}
			});
		} else {
			history.push({ pathname: '/area-reports'});
		}
	}, []);

	return (<>
		{ reportData.length > 0 && <div>
			<Grid container spacing={0} className={classes.resultsGrid}>
				<Grid item xs={4} className={classes.smallCard + ' ' + classes.summaryCard}>
					<div className={classes.cardHeader}>
						<Typography className={classes.cardTitle} variant="subtitle1" >Summary</Typography>
					</div>
					<SummaryContent sensorData={reportData} from={startDate} to={endDate} areaName={areaName} />
				</Grid>
				<Grid item xs={4} className={classes.smallCard}>
					<div className={classes.cardHeader}>
						<Typography className={classes.cardTitle} variant="subtitle1" >Area</Typography>
					</div>
				</Grid>
				<Grid item xs={4} className={classes.smallCard}>
					<div className={classes.cardHeader}>
						<Typography className={classes.cardTitle} variant="subtitle1" >Weather</Typography>
					</div>
				</Grid>
			</Grid>
			<Grid container spacing={0} className={classes.resultsGrid}>
				<Grid item xs={4} className={classes.smallCard + ' ' + classes.summaryCard}>
				</Grid>
			</Grid>
		</div>
		}
	</>
	);
};
