import * as React from 'react';
import { Box, Typography } from '@mui/material';
import Moment from 'moment';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import importedStyles from '../../style/styles';

interface ICompactionPlot {
  message?: any;
}

export const CompactionPlot: React.FC<ICompactionPlot> = ({message}) => {
	const importedClasses = importedStyles();
	const [data, setData] = React.useState([{}]);
	const [filteredData, setFilteredData] = React.useState< string | null >(null);

	function plotData (message: any) {
		try {
			if (message.topic.includes('sensor/penetration_live/ASAESC')) {
				const ts: string = message.message.timestamp.toString();
				const timestamp = Moment.unix(Number(ts.substr(0,ts.indexOf('.'))));
				const temp = [...data,
					{
						'time': timestamp.format('HH:mm:ss'),
						'Compaction': (message.message.payload.value).toFixed(2)
					}
				];
				if (data.length>100) {
					temp.shift();
				}
				setData(temp);
			}
		} catch (err) {
			console.log('compaction err'+err);
		}
	}

	const filteredValue = (message: any) => {
		try {
			if (message.topic.includes('sensor/emcl/ML3')) {
				const messageValue = message.message.payload.value.load;
				setFilteredData(messageValue.toPrecision(4));
			}
		} catch (err) {
			console.log(err);
		}
	};

	React.useEffect(() => {
		plotData(message);
		if (data.length > 0) {
			filteredValue(message);
		}
	}, [message]);

	return (
		<Box className={importedClasses.chart}>
			{(data.length <= 1)?
				'WAITING FOR COMPACTION DATA':
				<>
					{(filteredData !== null) ?
						<Typography variant='subtitle2' style={{ position: 'absolute', marginTop: '0px', right: '2em', top: '0px' }}>Last measured value: {filteredData}Kg</Typography> : <></>}
					<div style={{ height: 'calc(100% - 15px)', marginTop: '15px', width: '100%'}}>
						<ResponsiveContainer width='100%' height='100%'>
							<LineChart data={data}  margin={{ top: 5, right: 30, left: 20, bottom: 5 }} >
								<XAxis dataKey="time" tick={{fontSize: '10'}} label={{ value: 'Timestamp', position: 'insideBottom', offset: 0}}/>
								<YAxis label={{ value: 'Compaction Kg', angle: -90, position: 'insideBottomLeft' , offset: 10}} domain={[0, 50]}/>
								<CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
								<Line type="monotone" dataKey="Compaction" stroke="darkblue" isAnimationActive={false}/>
								{/* <Legend verticalAlign="top" height={30} iconSize={0}/> */}
							</LineChart>
						</ResponsiveContainer>
					</div>
				</>}
		</Box>
	);
};