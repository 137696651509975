import React from 'react';
import { Button, Card, Modal, TextField, Typography } from '@mui/material';
import generalStyles from '../../style/styles';

interface randomPointsModal {
    randomPointsModal: boolean;
    setRandomPointsModal: (modalState: boolean) => void;
    createRandomPoints: (pointsCount: number) => void;
}


export const RandomPointsModal: React.FC<randomPointsModal> = ({ randomPointsModal, setRandomPointsModal, createRandomPoints }) => {
	const importedclasses = generalStyles();

	const [pointsCount, setPointsCount] = React.useState<number>(0);

	const handleConfirm = () => {
		createRandomPoints(+pointsCount);
		setRandomPointsModal(false);
	};

	return (
		<Modal open={randomPointsModal} onClose={() => setRandomPointsModal(false)}>
			<Card className={importedclasses.modal}>
				<div>
					<Typography variant={'subtitle1'}>How many points do you want to create?</Typography>
					<div className={importedclasses.modalDetails}>
						<Typography variant='subtitle2'># of points:</Typography> <TextField value={pointsCount} variant="standard" onChange={(e) => setPointsCount(+e.target.value)}/>
					</div>
					<div>
						<Button variant={'outlined'} className={importedclasses.modalCancel} onClick={() => setRandomPointsModal(false)}>Cancel</Button>
						<Button variant={'outlined'} className={importedclasses.modalConfirm} color="primary" onClick={handleConfirm}>Create</Button>
					</div>
				</div>
			</Card>
		</Modal>
	);
};