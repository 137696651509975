export const connectURL = 'wss://mqtt.e-nano.io:443';

export function connectOptions () {
	const options = {
		port: 443,
		clientId: `enano-client-${Date.now()}`,
		username: 'rayercat',
		password: 'blauWis5670101'
	};
	return options;
}

///stream details
export const config = { iceServers: [{ urls: 'stun:3.8.20.111:3478' }] };

//AABBCCDDEEFFGG1
export function createMessageConfig (robotID: string) {
	const movement = '/' + robotID + '/command/movement';
	const location = '/' + robotID + '/calculation/position/GPS_position_PK';
	const leftSpeed = '/' + robotID + '/sensor/speed/motor_left';
	const rightSpeed = '/' + robotID + '/sensor/speed/motor_right';
	const currentTask = '/' + robotID + '/status/robot/managerStatus';
	const heartbeat = '/' + robotID + '/status/heartbeat/alive';
	const speed = '/'+ robotID + '/sensor/speeds/kinematics';
	const measurement = '/' + robotID + '/measurement/moisture/MoistureSensor';
	const jobSchedulerStatus = '/' + robotID + '/status/job_scheduler/next';
	const battery1 = '/' + robotID + '/sensor/battery/1';
	const battery2 = '/' + robotID + '/sensor/battery/2';
	const battery3 = '/' + robotID + '/sensor/battery/3';
	const ML3= '/' + robotID + '/sensor/emcl/ML3';
	const ML3Live= '/' + robotID + '/sensor/emcl_live/ML3';
	const NDVI= '/' + robotID + '/sensor/ndvi/skye';
	const NDVILive= '/' + robotID + '/sensor/ndvi_live/skye';
	const hardness= '/' + robotID + '/sensor/hardness/clegg';
	const hardnessLive= '/' + robotID + '/sensor/hardness_live/clegg';
	const penetration= '/' + robotID + '/sensor/penetration/ASAESC';
	const penetrationLive= '/' + robotID + '/sensor/penetration_live/ASAESC';
	const triad= '/' + robotID + '/sensor/multispectral/AS7265x';
	const triadLive= '/' + robotID + '/sensor/multispectral_live/AS7265x';
	const compass= '/' + robotID + '/sensor/compass/bearing';
	const controller=  '/' + robotID + '/status/controller/manual';


	const topicsObj = {
		[movement]: { qos: 0 },
		[location]: { qos: 0 },
		[leftSpeed]: { qos: 0 },
		[rightSpeed]: { qos: 0 },
		[currentTask]: { qos: 0 },
		[heartbeat]: { qos: 0 },
		[speed]: { qos: 0 },
		[measurement]: { qos: 0 },
		[jobSchedulerStatus]: { qos: 0 },
		[battery1]: { qos: 0 },
		[battery2]: { qos: 0 },
		[battery3]: { qos: 0 },
		[ML3]: { qos: 0 },
		[ML3Live]: { qos: 0 },
		[NDVI]: { qos: 0},
		[NDVILive]: {qos: 0},
		[hardness]: { qos: 0},
		[hardnessLive]: {qos: 0},
		[penetration]: { qos: 0},
		[penetrationLive]: {qos: 0},
		[triad]: { qos: 0},
		[triadLive]: {qos: 0},
		[compass]: {qos: 0},
		[controller]: {qos: 0}
	};

	return topicsObj;
}

export function createHeartbeatConfig (heartbeatTopics: string[]) {
	const topicArr: any = [];
	heartbeatTopics.forEach((serialId) => {
		const topic = `/${serialId}/status/heartbeat/alive`;
		topicArr.push(topic);
	});
	return topicArr;
}

export function createRTCConfig (robotID: string, streamID: string) {
	const webRTC = '/' + robotID + '/' + streamID + '/webrtc';
	const topicsObj = {
		[webRTC]: { qos: 2 }
	};
	return topicsObj;
}
