import * as React from 'react';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import { Divider } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { MqttClient } from 'mqtt';
import { connectClient, getHeartbeats, heartbeat, updateHeartbeatMessage, updateHeartbeatTime } from '../../helpers/robot/heartbeat';
import * as robot from '../../helpers/robot/robot';
import image from '../../static/rb1.png';

interface RSListProps {
    currentStep: number;
    robotList: robot.Robot[] | undefined;
    selectedRobots: Set<robot.Robot>;
    positioning: string;
    showOnStep: number;
    setSelectedRobot: any;
    robotLocations: any;
    setRobotLocations: (status: any)=> void;
    setMapCenter: (mapCenter: any)=> void;
}

//defaults for heartbeat
const heartbeatsInit : heartbeat[] = [];
let heartbeatClient: MqttClient;

export const listStyles = makeStyles((theme: Theme) =>
	createStyles({
		scrollable: { maxHeight: '300px', overflow: 'auto' },
		dotOffline: { height: '20px', width: '20px', backgroundColor: '#bbb', borderRadius: '50%', marginRight: '10px' },
		dotOnline: { height: '20px', width: '20px', backgroundColor: 'green', borderRadius: '50%', marginRight: '10px' }
	})
);


export const RobotSelectionList: React.FC<RSListProps> = ({ currentStep, robotList, selectedRobots, positioning, showOnStep, setSelectedRobot, robotLocations, setRobotLocations, setMapCenter }: RSListProps) => {
	const classes = listStyles();
	const robotSelection = new Set<robot.Robot>(selectedRobots);

	// Using a forceUpdate because Set Object does not update the state. It only does the first time.
	const [, forceUpdate] = React.useReducer((x) => x + 1, 0);

	//heartbeat state
	const [heartbeats, setHeartbeats] = React.useState(heartbeatsInit);
	const [message, setMessage] = React.useState({});
	const [client, setClient] = React.useState(heartbeatClient);
	const [seconds, setSeconds] = React.useState(0);

	//update heartbeats
	React.useEffect(() => {
		const heartbeatsTemp = getHeartbeats(robotList);
		if (heartbeatsTemp.length > 0) {
			const topics = heartbeatsTemp.map((heartbeat: heartbeat) => {
				return heartbeat.serialId;
			});
			connectClient(topics, setMessage).then((client) => {
				setClient(client);
			});
			setHeartbeats(heartbeatsTemp);
		}
	}, [robotList]);

	React.useEffect(() => {
		updateHeartbeatMessage(heartbeats, message, setHeartbeats);
	}, [message]);

	React.useEffect(() => {
		setTimeout(() => {
			setSeconds(seconds + 1);
			updateHeartbeatTime(heartbeats, message, setHeartbeats);
		}, 1000);
	}, [seconds]);

	React.useEffect(() => {
		heartbeats.forEach((heartbeat) => {
			const currentRobotLocation = robotLocations.find((robotLocation:any) => robotLocation.serialId === heartbeat.serialId) || { online: false };
			///see if online status changed

			if (heartbeat?.isOnline !== currentRobotLocation?.online) {
				///from offline to online
				if (heartbeat?.isOnline === true) {
					currentRobotLocation.serialId = heartbeat.serialId;
					currentRobotLocation.online = true;
					currentRobotLocation.location = heartbeat.location;
					///last online timestamp
					const filteredRobotLocations = robotLocations.filter((robotLocationToKeep:any) => robotLocationToKeep.serialId !== currentRobotLocation.serialId);
					setRobotLocations([currentRobotLocation, ...filteredRobotLocations]);
				} else {
					///online to offline
					if (heartbeat.serialId === currentRobotLocation.serialId) {
						currentRobotLocation.online = false;
						const filteredRobotLocations = robotLocations.filter((robotLocationToKeep:any) => robotLocationToKeep.serialId !== currentRobotLocation.serialId);
						setRobotLocations([currentRobotLocation, ...filteredRobotLocations]);
					}

				}
			} else {
				if (heartbeat?.isOnline === true) {
					currentRobotLocation.location = heartbeat.location;
					const filteredRobotLocations = robotLocations.filter((robotLocationToKeep:any) => robotLocationToKeep.serialId !== currentRobotLocation.serialId);
					setRobotLocations([currentRobotLocation, ...filteredRobotLocations]);
				}
			}


			///online status hasn't changed - do nothing
		});
	}, [seconds]);



	function saveRobot (event: any) {
		const robot = robotList?.filter((each: any) => {
			if (each.id === Number(event.target.id)) return each;
		})[0]; //It always will be just one robot

		if (event.target.checked) {
			robotSelection.add(robot!);
			forceUpdate();
		} else {
			robotSelection.delete(robot!);
			forceUpdate();
		}

		setSelectedRobot(robotSelection);
	}

	const onCenter = (id: string) => {
		const centerLocation = robotLocations.find((robotLocation: any) => robotLocation.serialId === id);
		setMapCenter(centerLocation?.location);
	};

	function generateItems () {
		if (robotList) {
			return robotList.map((robot, i) => {
				const status = heartbeats.find((heartbeat) => heartbeat.serialId === robot.serialId);
				return (
					<ListItem id={robot.toString()} key={i}>
						<ListItemAvatar>
							<Avatar src={image}></Avatar>
						</ListItemAvatar>
						<span className={status?.isOnline? classes.dotOnline: classes.dotOffline}/>
						<ListItemText primary={robot.name} secondary={`${robot.model}/${robot.serialId}`} />
						{status?.isOnline?
							<IconButton
								style={{ padding: 'start' }}
								onClick={() => onCenter(robot.serialId)}
								size="large">
								<CenterFocusStrongIcon/>
							</IconButton>:<></>
						}
						<ListItemSecondaryAction>
							<IconButton edge="end" aria-label="check" size="large">
								<Checkbox color="error" id={robot.id.toString()} checked={selectedRobots.has(robot)} onChange={saveRobot}/>
							</IconButton>
						</ListItemSecondaryAction>
					</ListItem>
				);
			});
		}
	}

	return (
		<>
			{ currentStep === showOnStep ?
				<div className={positioning}>
					<h3>Robots</h3>
					<Divider />
					<List dense={true} className={classes.scrollable}> {generateItems()} </List>
				</div>
				: <></>
			}
		</>
	);
};
