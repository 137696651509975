import { createContext } from 'react';

export interface UserModel {
	isAuthenticated: boolean;
	setAuth: (val: boolean) => void;
	id: number;
	setId: (val: number) => void;
	// This is a patch while we wait for the new database to be in place
	userType: string | null;
	setUserType: (type: string | null) => void;
}

const defaultUserModel: UserModel = {
	isAuthenticated: false,
	setAuth: (val: boolean) => {},
	id: 0,
	setId: (val: number) => {},
	// This is a patch while we wait for the new database to be in place
	userType: null,
	setUserType: (type: string | null) => {}
};

export default createContext<UserModel>(defaultUserModel);