import * as React from 'react';
import { Button, FormControl, TextField } from '@mui/material';
import { MqttClient } from 'mqtt';
import { sendMQTTCommand } from '../../helpers/mqtt';
import style from '../../style/styles';

interface ICustomCommand {
    client: MqttClient;
    online: boolean;
    user: string;
    setHasFocus: (flag: boolean) => void;
}

export const CustomCommand: React.FC<ICustomCommand> = ({ user, client, online, setHasFocus }) => {
	const classes = style();

	//state for controlMessage
	const [customMessage, setCustomMessage] = React.useState({
		timestamp: new Date(Date.now()).toISOString(),
		payload: {
			USERID: user,
			SRC: 'WEB',
			CUSTOMCOMMAND: ''
		}
	});

	function handleChange (userCommand: string) {
		setHasFocus(true);
		setCustomMessage({
			timestamp: new Date(Date.now()).toISOString(),
			payload: {
				USERID: user,
				SRC: 'WEB',
				CUSTOMCOMMAND: userCommand
			}
		});
	}

	function handleSubmit (event: React.SyntheticEvent) {
		event.preventDefault();
		sendMQTTCommand(customMessage, client, '/AABBCCDDEEFFGG1/command/movement');
		setHasFocus(false);
	}

	return (
		<form onSubmit={handleSubmit} noValidate>
			<FormControl>
				<TextField variant='outlined' label='Custom command here!' value={customMessage.payload.CUSTOMCOMMAND} className={classes.inputCustomCommand} onChange={(e) => handleChange(e.currentTarget.value)}/>
			</FormControl>
			<br />
			<Button type='submit' variant='outlined' className={classes.buttonCustomCommand + ' ' + classes.enanoBackground} disabled={!online} > SEND </Button>
		</form>
	);
};
