import React from 'react';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import ReactTimeAgo from 'react-time-ago';

TimeAgo.addDefaultLocale(en);

// eslint-disable-next-line react/prop-types
export default function GetTimeAgo ({ date }) {
	return <ReactTimeAgo date={date} locale="en-GB" />;
}