import * as React from 'react';
import BatteryStdIcon from '@mui/icons-material/BatteryStd';
import SpeedIcon from '@mui/icons-material/Speed';
import { Card, Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ManualIndicator } from './ManualIndicator';
import { Online } from './Online';


export const useStatusBarStyles = makeStyles((theme: Theme) =>
	createStyles({
		statusBar: { position: 'fixed', zIndex: 100, width: '600px', display: 'flex', flexDirection: 'row', borderRadius: '30px', justifyContent: 'space-around', background: '#232D4C', color: 'white', marginTop: '-5px' },
		statusBarData: { display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', margin: '10px' },
		center: { display: 'flex', flexDirection: 'row', justifyContent: 'space-around' },
		estop: { display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#FF6161', width: '100%', height: '100%' },
		estopText: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
		estopOffline: { display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#FF6161', width: '100%', height: '100%', opacity: '50%' }
	})
);

interface IStatusBar {
    message: any;
    client: any;
    robotSerialId: string;
}

interface batteryLevel {
    battery1: number;
    battery2: number;
    battery3: number;
}

export const StatusBar: React.FC<IStatusBar>= ({ message, client, robotSerialId }) => {
	const classes = useStatusBarStyles();

	const [online, setOnline] = React.useState(false);
	const [onlineTimestamp, setOnlineTimestamp] = React.useState(0);
	const [seconds, setSeconds] = React.useState(0);
	const [speed, setSpeed] = React.useState('');
	const [batteryLevel, setBatteryLevel] = React.useState<batteryLevel>({ battery1: -1, battery2: -1, battery3: -1 });
	const [minBattery, setMinBattery] = React.useState<null|number>(null);
	const [controllerStatus, setControllerStatus] = React.useState<string>('AUTO');

	function updateOnline () {
		setOnline(true);
		setOnlineTimestamp(Date.now());
	}

	function everySecond () {
		if (onlineTimestamp>0) {
			const difference = Date.now() - onlineTimestamp;
			if (difference>10000) {
				setOnline(false);
				setOnlineTimestamp(0);
			}
		}
	}

	function handleEStop () {
		try {
			const newCommand = `{
            "timestamp": "${new Date(Date.now()).toISOString()}",
            "payload": {
                "USERID": "${localStorage.user}",
                "SPEED": 0,
                "ANGLE": 0,
                "BRAKE": 1,
                "SRC":"WEB"
            }
        }`;
			client.publish(`/${robotSerialId}/command/movement`, newCommand);
		} catch (e) {
			console.log(e);
		}
	}

	React.useEffect(() => {
		try {
			if (message.topic.includes('alive')) {
				updateOnline();
			} else if (message.topic.includes('kinematics')) {
				setSpeed(message.message.payload.value.x.toFixed(1));
			} else if (message.topic.includes('battery/1')) {
				const batteryId1 = message.message.payload.value;
				setBatteryLevel({ ...batteryLevel, battery1: batteryId1 });
			} else if (message.topic.includes('battery/2')) {
				const batteryId2 = message.message.payload.value;
				setBatteryLevel({ ...batteryLevel, battery2: batteryId2 });
			} else if (message.topic.includes('battery/3')) {
				const batteryId3 = message.message.payload.value;
				setBatteryLevel({ ...batteryLevel, battery3: batteryId3 });
			} else if (message.topic.includes('controller')) {
				setControllerStatus(message.message.payload.value.toString());
			}
		} catch (e) {
			// console.log(e)
		}
	}, [message]);

	React.useEffect(() => {
		if (Object.values(batteryLevel).includes(-1)) {
			// do nothing
		} else {
			const { battery1, battery2, battery3 } = batteryLevel;
			const minBatteryLevel = Math.round(Math.min(battery1, battery2, battery3));
			setMinBattery(minBatteryLevel);
		}
	}, [batteryLevel]);

	React.useEffect(() => {
		setTimeout(() => {
			everySecond();
			setSeconds(seconds + 1);
		}, 1000);
	}, [seconds]);

	return (
		<div className={classes.center}>
			<Card className={classes.statusBar}>
				<Grid container>
					<Grid item xs={6} className={classes.center}>
						<div className={classes.statusBarData}>
							<Online online={online}/>
						</div>
						<div className={classes.statusBarData}>
							<SpeedIcon/>
							<Typography variant='subtitle1'> {!online?' -- ':` ${speed} `}m/s</Typography>
						</div>
						<div className={classes.statusBarData}>
							<BatteryStdIcon/>
							<Typography variant='subtitle1'> {!online || minBattery === null?' -- ':`${minBattery}`}%</Typography>
						</div>
					</Grid>
					<Grid item xs= {6} className={classes.center}>
						{/* {online?
							<div className='estopHover'>
								<div className={classes.estop} onClick={handleEStop}>
									<Typography className={classes.estopText}>EMERGENCY STOP</Typography>
								</div>
							</div>:
							<div className={classes.estopOffline}>
								<Typography className={classes.estopText}>EMERGENCY STOP</Typography>
							</div>
						} */}
						<div className={classes.statusBarData}>
							<Typography className={classes.estopText}>Controller: {controllerStatus}</Typography>
						</div>
						<div className={classes.statusBarData}>
							<ManualIndicator status={controllerStatus} />
						</div>
					</Grid>
				</Grid>
			</Card>
		</div>
	);
};