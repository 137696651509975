import * as React from 'react';
import { Box } from '@mui/material';
import Moment from 'moment';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import importedStyles from '../../style/styles';

interface ISpeedPlot {
  message?: any;
}

export const SpeedPlot: React.FC<ISpeedPlot> = ({ message }) => {
	const importedClasses = importedStyles();
	const [data, setData] = React.useState([{}]);

	function plotData (message: any) {
		try {
			if (message.topic.includes('kinematics')) {
				const ts: string = message.message.timestamp.toString();
				const timestamp = Moment.unix(Number(ts.substr(0, ts.indexOf('.'))));
				const temp = [...data,
					{
						'time': timestamp.format('HH:mm:ss'),
						'Speed': parseFloat(message.message.payload.value.x.toFixed(2))
					}
				];
				if (data.length>100) {
					temp.shift();
				}
				setData(temp);
			}
		} catch (err) {
			// console.log(err)
		}
	}

	React.useEffect(() => {
		plotData(message);
		// eslint-disable-next-line
    }, [message]);

	return (
		<Box className={importedClasses.chart}>
			{(data.length <= 1)?
				'WAITING FOR SPEED DATA':
				<>
					<ResponsiveContainer width='100%' height='100%'>
						<LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }} >
							<XAxis dataKey="time" tick={{ fontSize: '10' }} label={{ value: 'Timestamp', position: 'insideBottom', offset: 0 }}/>
							<YAxis label={{ value: 'Speed (m/s)', angle: -90, position: 'insideBottomLeft', offset: 10 }} domain={[0, 3]}/>
							<CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
							<Line type="monotone" dataKey="Speed" stroke="#C96338" isAnimationActive={false}/>
							{/* <Legend verticalAlign="top" height={30} iconSize={0}/> */}
						</LineChart>
					</ResponsiveContainer>
				</>}
		</Box>
	);
};