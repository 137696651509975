import * as React from 'react';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { IconButton, Switch, Typography } from '@mui/material';
import ReactPlayer from 'react-player';
import style from '../../style/styles';
import { Loading } from '../site/Loading';

interface IProps {
    videoLoading: boolean;
    connection: RTCPeerConnection;
    streams: MediaStream[];
    hideStats: boolean;
    setHideStats: (hideStats: boolean) => void;
}

export const Stream: React.FC<IProps> = ({ videoLoading, connection, streams, hideStats, setHideStats }) => {
	const classes = style();
	const [paused, setPaused] = React.useState(false);

	const handlePause = () => {
		const tracks = streams[1].getTracks();
		setPaused(tracks[0].enabled);
		tracks[0].enabled = paused;
	};

	const handleStats = () => {
		setHideStats(!hideStats);
	};

	return (
		<div className={classes.videoStream}>
			{!videoLoading ? (
				<>
					<ReactPlayer playing={true} controls={false} url={streams[1]} width='78%' height='78%' />
					<div className={classes.rowStream}>

						<div className={classes.rowStream}>
							<Typography variant='subtitle2'> {!paused ? 'Pause Stream' : 'Resume Stream'} </Typography>
							<IconButton color='primary' size='medium' onClick={handlePause}> {!paused?<PauseCircleOutlineIcon/>:<PlayCircleOutlineIcon/>} </IconButton>
						</div>

						<div className={classes.rowStream}>
							<Typography variant='subtitle2'> {!hideStats ? 'Show video stats' : 'Hide video stats'}{' '} </Typography>
							<Switch checked={hideStats} onChange={handleStats} color='primary' />
						</div>
					</div>
				</>
			) : (
				<>
					<Loading />
					<Typography variant='body2'> {!(streams.length>1) ? 'WAITING FOR ROBOT CONNECTION...' : 'VERIFYING VIDEO STREAM...'} </Typography>
				</>
			)}
		</div>
	);
};
