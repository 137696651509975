import React from 'react';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import { Button, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import moment, { Moment } from 'moment';
import * as Job from '../../helpers/job/job';
import * as Sensor from '../../helpers/sensors/sensors';
import { geoData, waypointGeoData } from '../../helpers/sensors/sensors';
import image from '../../static/rb1.png';

import { JobAbortModal } from './JobAbortModal';
import { JobCancelModal } from './JobCancelModal';
import { JobCopyModal } from './JobCopyModal';


interface cardProps {
	title: string;
	name: string;
	robots: string;
	area: string;
	waypoints: string;
	state: string;
	id: string;
	daysLeft: string;
	configuration: string;
	setGeoData: (n: geoData[]) => void;
	setWaypointGeoData: (n: waypointGeoData[]) => void;
	selectedJobId: number;
	setSelectedJobId: (n: number) => void;
	index: number;
	activeIndex: number;
	setActiveIndex: (n: number) => void;
	jobTime: Moment;
	lastJobId: number | null;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		rootContent: { paddingBottom: '3%', direction: 'ltr', flexGrow: 2 },
		details: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' },
		selectedCardDetails: { display: 'flex', paddingTop: '0px', marginLeft: '20px', backgroundColor: '#F0F0F0' },
		unselectedCardDetails: { display: 'flex', paddingTop: '0px', marginLeft: '20px' },
		cardDetailChip: { margin: '4px', backgroundColor: 'inherit', fontSize: '18px' },
		titleDiv: { display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' },
		titleStateDiv: { display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginTop: '2%', marginLeft: '20px' },
		modalContainer: { display: 'flex', justifyContent: 'center', alignItems: 'center', width: '200px' },
		abortButtonContainer: { display: 'flex', justifyContent: 'flex-end', marginLeft: '50px' },
		descriptionContainer: { marginLeft: '20px', paddingLeft: 0, textAlign: 'left' },
		abortButton: { marginTop: '15px', borderRadius: '10%' },
		jobActionButton: { backgroundColor: '#4CAEF6', transition: 'color 0.5s', color: '#FFFFFF', borderRadius: '20px', '&:hover': { backgroundColor: '#545AA7' } },
		activeCard: { borderLeft: '10px solid', borderRadius: '10px 0 0 10px', marginRight: '0px', width: '0', borderColor: '#4CAEF6' },
		inActiveCard: { borderLeft: '10px solid', borderRadius: '10px 0 0 10px', marginRight: '0px', width: '0', borderColor: 'white' },
		selectedRoot: { display: 'flex', flexDirection: 'row-reverse', width: '100%', backgroundColor: '#F0F0F0' },
		unSelectedRoot: { display: 'flex', flexDirection: 'row-reverse', width: '100%' },
		cloneAndCancelButton: { display: 'flex', justifyContent: 'center', marginTop: '1rem', textAlign: 'center', gap: '15%' },
		selectedCard: { display: 'flex', justifyContent: 'center' },
		unselectedCard: { display: 'flex', justifyContent: 'center', margin: '0 20px 0 20px', borderTop: '1px solid #DDDDDD' },
		selectedLastCardBorder: { justifyContent: 'center', width: '79%', textAlign: 'center', marginRight: '13.2%' },
		unSelectedLastCardBorder: { justifyContent: 'center', width: '79%', textAlign: 'center', marginRight: '13.2%', borderTop: '1px solid #DDDDDD' },
		selectedCardDescription: { marginTop: '1px', paddingTop: '1px', width: '6%', borderBottom: '1px solid #4CAEF6' },
		unSelectedCardDescription: { marginTop: '1px', paddingTop: '1px', width: '6%', borderBottom: '1px solid #DDDDDD' },
		description: { paddingBottom: '1px', marginBottom: '1px', marginTop: '2px' },
		jobState: { marginRight: '20px' }
	})
);

export const JobCard: React.FC<cardProps> = ({ title, name, area, waypoints, robots, id, state, daysLeft, configuration, setGeoData, setWaypointGeoData, selectedJobId, setSelectedJobId, lastJobId, index, setActiveIndex, activeIndex, jobTime }) => {
	const classes = useStyles();
	const [jobCopyModal, setJobCopyModal] = React.useState<boolean>(false);
	const [jobCancelModal, setJobCancelModal] = React.useState<boolean>(false);
	const [jobAbortModal, setJobAbortModal] = React.useState<boolean>(false);

	const getStateChip = (state: string) => {
		if (state.toUpperCase() === 'PENDING') {
			return <Chip label={state.toUpperCase()} />;
		} else if (state.toUpperCase() === 'FINISHED') {
			return <Chip label={state.toUpperCase()} style={{ backgroundColor: '#46be86' }}/>;
		} else {
			return <Chip label={state.toUpperCase()} style={{ backgroundColor: '#4ca4ce' }} />;
		}
	};

	const handleDropDownToggle = () => {
		const tempArr: any[] = [];
		const tempWaypointArr :any[] = [];
		Sensor.getGeoDataByJobId(id).then((res) => {
			res.forEach((data) => {
				const { point } = data;
				if (point) {
					tempArr.push(point);
				}
			});
			setGeoData(tempArr);
		});
		Job.getJobById(parseInt(id)).then((res) => {
			tempWaypointArr.push(...res.geoInfo);
			setWaypointGeoData(tempWaypointArr);
		});
	};

	const jobCardOnclickHandler = () => {
		handleDropDownToggle();
		setSelectedJobId(parseInt(id));
		setActiveIndex(index);
	};

	return (
		<><div className={`${selectedJobId === parseInt(id) ? classes.selectedRoot : classes.unSelectedRoot}`}>
			<div className={classes.rootContent} onClick={jobCardOnclickHandler}>
				<div className={`${selectedJobId === parseInt(id) || activeIndex + 1 === index ? classes.selectedCard : classes.unselectedCard}`}></div>
				<div className={classes.titleStateDiv}>
					<div className={classes.titleDiv}>
						<Typography component='h6' variant='h6' align='left' >
							<span style={{ fontWeight: 100 }}>{jobTime.isSame(moment(), 'day')? 'Today': jobTime.format('MMMM ') }</span><span>{jobTime.isSame(moment(), 'day')? '': jobTime.format('D')}</span><span style={{ fontWeight: 100 }}> at </span><span>{jobTime.format('H:mma')}</span>
						</Typography>
						{
							daysLeft ?
								<Typography variant='subtitle1' align='left' style={{ marginLeft: '10px', fontStyle: 'italic' }} >{' (' + daysLeft + ')'}</Typography>
								: <></>
						}
					</div>
					<div className={classes.jobState}>
						{getStateChip(state)}
					</div>
				</div>
				<div className={classes.descriptionContainer}>
					<h2 className={classes.description}>{ name ? name : ('Job ' + id) }</h2>
					<div className={`${selectedJobId === parseInt(id) ? classes.selectedCardDescription: classes.unSelectedCardDescription}`}></div>
				</div>
				<div className={`${selectedJobId === parseInt(id) ? classes.selectedCardDetails : classes.unselectedCardDetails}`}>
					<Chip style={{ paddingLeft: 0, marginLeft: 0 }} className={classes.cardDetailChip} key={'IdNumber'} label={id} avatar={<Avatar style={{ paddingLeft: 0, marginLeft: 0 }}>#ID</Avatar>}/>
					<Chip style={{ width: '20%' }} className={classes.cardDetailChip} key={'robotChip'} label={robots} avatar={<Avatar src={image}/>}/>
					<Chip style={{ width: '22%' }} className={classes.cardDetailChip} key={'areaChip'} label={area} avatar={<SquareFootIcon/>}/>
					<Chip style={{ width: '22%' }} className={classes.cardDetailChip} key={'geoChip'} label={waypoints} avatar={<GpsFixedIcon/>} />
				</div>
				{selectedJobId === parseInt(id)?
					<div className={classes.cloneAndCancelButton}>
						<Button className={classes.jobActionButton} variant="contained" onClick = {() => setJobCopyModal(true)}>CLONE</Button>
						{state.toLowerCase() === 'pending' ?
							<Button className={classes.jobActionButton} variant="contained" onClick={() => setJobCancelModal(true)}>CANCEL</Button>
							: <></>}
					</div> : <></>}
				<div className={classes.abortButtonContainer}>
					{state.toLowerCase() === 'in progress' ?
						<Button className={classes.abortButton} variant={'outlined'} color='secondary' onClick={() => {
							setJobAbortModal(true);
						}}>Abort</Button>
						: null}
				</div>
			</div>
		</div>
		{lastJobId !== null && parseInt(id) === lastJobId ?
			<div className={`${selectedJobId === parseInt(id) ? classes.selectedLastCardBorder : classes.unSelectedLastCardBorder}`}></div> : <></> }
		<JobCopyModal jobCopyModal={jobCopyModal} setJobCopyModal={setJobCopyModal} configuration={configuration} id={id}/>
		<JobCancelModal jobCancelModal={jobCancelModal} setJobCancelModal={setJobCancelModal} id={id} title={title}/>
		<JobAbortModal jobAbortModal={jobAbortModal} setJobAbortModal={setJobAbortModal} id={id}/>

		</>
	);
};
