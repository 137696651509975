import React from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Polygon } from 'geojson';
import moment from 'moment';
import { AreaBoxPlot } from '../components/areaReports/AreaBoxPlot';
import { AreaSelector } from '../components/areaReports/AreaSelector';
import { AverageBox } from '../components/areaReports/AverageBox';
import { ExtendedFilters } from '../components/areaReports/ExtendedFilters';
import { Heatmap, HeatmapSettings } from '../components/areaReports/Heatmap';
import { HeatmapSettingsModal } from '../components/areaReports/HeatmapSettingsModal';
import { ReportDateSelector } from '../components/areaReports/ReportDateSelector';
import { UserAreaMap } from '../components/areaReports/UserAreaMap';
import { ZoneSummary } from '../components/areaReports/ZoneSummary';
import { getQueryVariable } from '../helpers/main';
import * as sensors from '../helpers/sensors/sensors';
import { getDataPerDate } from '../helpers/sensors/sensors';
import { getUserAreas, UserArea } from '../helpers/user/user';

const useStyles = makeStyles(() =>
	createStyles({
		resultsGrid: { width: 'calc(100% - 20px)', minWidth: '400px', margin: '10px', minHeight: '300px' },
		cardHeader: { color: '#585858', backgroundColor: '#d3d3d3', height: '30px', display: 'flex', alignItems: 'center', paddingLeft: '10px', fontSize: '0.9em', textTransform: 'capitalize',
			'& .MuiGrid-root': { alignItems: 'center' }
		},
		cardHeaderTitle: { textAlign: 'left' },
		cardTitle: { color: '#4c4c4c', fontSize: '0.9em',  fontWeight: '400' },
		smallCard: { padding: '0px', border: '1px solid #d3d3d3'},
		summaryCard: { minWidth: '400px' },
		titleContainer: { display: 'inline-block', minWidth: '260px', maxWidth: '260px', textAlign: 'left', paddingLeft: '14px', verticalAlign: 'top', paddingTop: '7px',
			'& h2': { fontWeight: '500' } },
		dateSelectorContainer: { display: 'inline-block', minWidth: 'calc(100% - 274px)', maxWidth: 'calc(100% - 274px)',
			'& .MuiTab-root': { minWidth: '200px' },
			'& .MuiButtonBase-root': { fontSize: '1em', fontWeight: '300' },
			'& .Mui-selected': { fontWeight: '400', color: '#2F5BB5' },
			'& .MuiTabs-indicator': { backgroundColor: '#2F5BB5', height: '3px' }
		},
		averagesContainer: { minHeight: '110px',
			'& .MuiCard-root': { display: 'inline-block' }
		},
		hide: { display: 'none' }
	})
);

enum Unit {
	moisture = '%',
	temperature = 'C',
	compaction = '',
	ec = 'mS/m',
	ndvi = '',
	alive = '%',
	hardness = 'gravs'
}

export interface Zone {
	points: {
		lat: number;
		lng: number;
	}[];
	geometry: Polygon;
	name: string;
}

export const AreaReports: React.FC = () => {
	const classes = useStyles();
	const [userAreas, setUserAreas] = React.useState<UserArea[]>([]);
	const [selectedUserArea, setSelectedUserArea] = React.useState<UserArea | undefined>(undefined);
	const urlAreaName = getQueryVariable('areaName');
	const [loadingDates, setLoadingDates] = React.useState<boolean>(false);
	const [loadingBoxPlot, setLoadingBoxPlot] = React.useState<boolean>(false);
	const [selectedDate, setSelectedDate] = React.useState<Date>();
	const [availableDates, setAvailableDates] = React.useState<sensors.SensorTypes[]>([]);
	const [sensorData, setSensorData] = React.useState<sensors.Sensor[]>([]);
	const [unfilteredDaySensorData, setUnfilteredDaySensorData] = React.useState<sensors.Sensor[]>([]);
	const [recentSensorData, setRecentSensorData] = React.useState<sensors.Sensor[]>([]);
	const [zoneSensorData, setZoneSensorData] = React.useState<sensors.Sensor[]>([]);
	const [selectedMeasurementType, setSelectedMeasurementType] = React.useState<string>('moisture');
	const [selectedSensorType, setSelectedSensorType] = React.useState<string>('moisture');
	const [selectedZone, setSelectedZone] = React.useState<Zone>();
	const [availableJobs, setAvailableJobs] = React.useState<number[]>([]);
	const [selectedJob, setSelectedJob] = React.useState<number>(0);
	const [heatmapSettings, setHeatmapSettings] = React.useState<HeatmapSettings>();

	React.useEffect(() => {
		const areaList: UserArea[] =[];
		getUserAreas().then((areas: UserArea[]) => {
			areas.forEach((area: UserArea) => {
				areaList.push(area);
			} );
			setUserAreas(areaList);
			const areaFromUrl = areaList.filter((area: UserArea) => {
				return area.name === urlAreaName;
			});
			if (urlAreaName && areaFromUrl.length > 0 ) {
				setSelectedUserArea(areaFromUrl[0]);
			} else {
				setSelectedUserArea(areaList[0]);
			}
		});
	}, []);

	React.useEffect(() => {
		if (selectedUserArea) {
			setAvailableDates([]);
			setSelectedDate(undefined);
			setSensorData([]);
			setUnfilteredDaySensorData([]);
			setLoadingDates(true);
			setRecentSensorData([]);
			setSelectedZone(undefined);
			setSelectedJob(0);
			sensors.getSensorsTypePerDateForArea(selectedUserArea.name).then((availableDates: sensors.SensorTypes[]) => {
				setLoadingDates(false);
				if (availableDates.length > 0) {
					setAvailableDates(availableDates);
					setSelectedDate(availableDates[0].date);
				}
			});
		}
	}, [selectedUserArea]);

	React.useEffect(() => {
		if (selectedUserArea && selectedDate !== undefined ) {
			sensors.getDataForDateInUserArea(selectedUserArea.name, moment(selectedDate, 'DD/MM/YY').format('YYYY-MM-DD')).then((availableSensorData: sensors.Sensor[]) => {
				setSensorData(availableSensorData);
				setUnfilteredDaySensorData(availableSensorData);
				const jobs = availableSensorData.map((sensorData: sensors.Sensor) => {
					return sensorData.job;
				}).filter((item) => {
					return item !== undefined;
				}).filter((item, pos, self) => {
					return self.indexOf(item) == pos;
				});
				setSelectedJob(0);
				setAvailableJobs(jobs);
			});
		}
	}, [selectedDate]);

	React.useEffect(() => {
		if (selectedUserArea && selectedMeasurementType && selectedDate) {
			setLoadingBoxPlot(true);
			sensors.getDataForIntervalInUserArea(selectedUserArea.name, moment(selectedDate, 'DD/MM/YY').subtract('7', 'days').format('YYYY-MM-DD'), moment(selectedDate, 'DD/MM/YY').format('YYYY-MM-DD')).then((availableSensorData: sensors.Sensor[]) => {
				if (selectedJob !== 0 ) {
					availableSensorData = availableSensorData.filter((sensorValue) => {
						// Filter only the values for the actual date.
						if (moment(sensorValue.timestamp).format('YYYYMMDD') === moment(selectedDate, 'DD/MM/YY').format('YYYYMMDD')) {
							return sensorValue.job === selectedJob;
						}
						return true;
					});
				}
				setRecentSensorData(availableSensorData);
				setLoadingBoxPlot(false);
			});
		}
	}, [selectedMeasurementType, selectedDate, selectedJob]);

	React.useEffect(() => {
		if (selectedDate && selectedZone !== undefined && selectedZone.points !== undefined && selectedZone.points[0] !== undefined && selectedZone.points[1] !== undefined) {
			getDataPerDate(moment(selectedDate, 'DD/MM/YY').format('MM-DD-YY'), selectedZone.geometry)
				.then((zoneSensorValues: sensors.Sensor[]) => {
					let output = zoneSensorValues;
					if (selectedJob !== 0 ) {
						output = zoneSensorValues.filter((sensorValue) => {
							return sensorValue.job === selectedJob;
						});
					}
					setZoneSensorData(output);
				});
		}
	}, [selectedZone, selectedDate, selectedJob]);

	React.useEffect(() => {
		setHeatmapSettings(undefined);
	}, [selectedSensorType]);


	React.useEffect(() => {
		if (selectedJob === 0) {
			setSensorData(unfilteredDaySensorData);
		} else {
			const filteredSensorData = sensorData.filter((sensor: sensors.Sensor) => {
				return sensor.job === selectedJob;
			});
			setSensorData(filteredSensorData);
		}
	}, [selectedJob]);

	const multispectralToNdvi = (payload: any) => {
		const value = JSON.parse(payload).value;
		if (parseFloat(value.wl_dwn_645) !== 0.0 && parseFloat(value.wl_dwn_810) !== 0.0) {
			const red = parseFloat(value.wl_up_645) / parseFloat(value.wl_dwn_645) ;
			const nir = parseFloat(value.wl_up_810) / parseFloat(value.wl_dwn_810) ;
			const ndvi = (nir-red)/(nir+red);
			return ndvi;
		}
		return 0;
	};

	const photosToAlive = (payload: any) => {
		const value = JSON.parse(payload).value;
		if (parseFloat(value.alive)) {
			return parseFloat(value.alive);
		}
		return 0;
	};

	const photosToDead = (payload: any) => {
		const value = JSON.parse(payload).value;
		if (parseFloat(value.dead)) {
			return parseFloat(value.dead);
		}
		return 0;
	};

	const thermalToCanopyTemp = (payload: any) => {
		const value = JSON.parse(payload).value;
		if (parseFloat(value.avg)) {
			return parseFloat(value.avg);
		}
		return 0;
	};

	return (
		<Box style={{ backgroundColor: '#f9f9f9', width: '100%', minHeight: '100%', borderTop: '4px solid #efefef', overflow: 'auto' }}>
			<Grid container spacing={2} alignItems='flex-start' justifyContent='flex-start'>
				<Grid item xs={12} md={12} lg={12}>
					<div className={classes.titleContainer}>
						<AreaSelector userAreas={userAreas} selectedUserArea={selectedUserArea} setSelectedUserArea={setSelectedUserArea} />
					</div>
					<div className={classes.dateSelectorContainer}>
						<ReportDateSelector typesPerDate={availableDates} selectedDate={selectedDate} setSelectedDate={setSelectedDate} loadingDates={loadingDates}/>
						<ExtendedFilters availableJobs={availableJobs} selectedJob={selectedJob} setSelectedJob={setSelectedJob} />
					</div>
				</Grid>
				<Grid item xs={12} md={12} lg={12} className={classes.averagesContainer}>
					<Grid container justifyContent='space-around'>
						<Grid item xs={1} md={1} lg={1}>
							<AverageBox measurementType='moisture' sensorType='moisture' unit={Unit['moisture']} sensorData={sensorData}
								isSelected={selectedMeasurementType === 'moisture'} setSelectedSensorType={setSelectedSensorType} setSelectedMeasurementType={setSelectedMeasurementType}
								outputFormatter={ (data: string) => {
									return (parseFloat(data)*100).toString();
								}}/>
						</Grid>
						<Grid item xs={1} md={1} lg={1}>
							<AverageBox measurementType='temperature' sensorType='temperature' label='Soil Temperature' unit={Unit['temperature']} sensorData={sensorData}
								isSelected={selectedMeasurementType === 'temperature'} setSelectedSensorType={setSelectedSensorType} setSelectedMeasurementType={setSelectedMeasurementType}/>
						</Grid>
						<Grid item xs={1} md={1} lg={1}>
							<AverageBox measurementType='canopytemperature' sensorType='thermal' label='Canopy Temperature' unit={Unit['temperature']} sensorData={sensorData}
								isSelected={selectedMeasurementType === 'canopytemperature'} setSelectedSensorType={setSelectedSensorType} setSelectedMeasurementType={setSelectedMeasurementType}
								getValueFromPayload={thermalToCanopyTemp}/>
						</Grid>
						<Grid item xs={1} md={1} lg={1}>
							<AverageBox measurementType='compaction' sensorType='load' label='Compaction' unit={Unit['compaction']} sensorData={sensorData}
								isSelected={selectedMeasurementType === 'compaction'} setSelectedSensorType={setSelectedSensorType} setSelectedMeasurementType={setSelectedMeasurementType}/>
						</Grid>
						<Grid item xs={1} md={1} lg={1}>
							<AverageBox measurementType='hardness' sensorType='hardness' label='Hardness' unit={Unit['hardness']} sensorData={sensorData}
								isSelected={selectedMeasurementType === 'hardness'} setSelectedSensorType={setSelectedSensorType} setSelectedMeasurementType={setSelectedMeasurementType}/>
						</Grid>
						<Grid item xs={1} md={1} lg={1}>
							<AverageBox measurementType='electricalconductivity' sensorType='electricalconductivity' label='EC' unit={Unit['ec']} sensorData={sensorData}
								isSelected={selectedMeasurementType === 'electricalconductivity'} setSelectedSensorType={setSelectedSensorType} setSelectedMeasurementType={setSelectedMeasurementType}/>
						</Grid>
						<Grid item xs={1} md={1} lg={1}>
							<AverageBox measurementType='multispectral' sensorType='multispectral' label='NDVI' unit={Unit['ndvi']} sensorData={sensorData}
								isSelected={selectedMeasurementType === 'multispectral'} setSelectedSensorType={setSelectedSensorType} setSelectedMeasurementType={setSelectedMeasurementType}
								getValueFromPayload={multispectralToNdvi}/>
						</Grid>
						<Grid item xs={1} md={1} lg={1}>
							<AverageBox measurementType='alive' sensorType='photos' label='Coverage' unit={Unit['alive']} sensorData={sensorData}
								isSelected={selectedMeasurementType === 'alive'} setSelectedSensorType={setSelectedSensorType} setSelectedMeasurementType={setSelectedMeasurementType}
								getValueFromPayload={photosToAlive}/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid container rowSpacing={2} className={classes.resultsGrid}>
				<Grid item container xs={12}>
					<Grid item xs={4}>
						<div className={classes.cardHeader}>
							<Grid container>
								<Grid item xs={11} className={classes.cardHeaderTitle}>
									<Typography className={classes.cardTitle} variant="subtitle1" >{selectedMeasurementType + ' heatmap [' + (Unit[selectedMeasurementType as keyof typeof Unit] || '-') + ']'} </Typography>
								</Grid>
								<Grid item xs={1}>
									<HeatmapSettingsModal userArea={selectedUserArea} heatmapSettings={heatmapSettings} setHeatmapSettings={setHeatmapSettings} configDisabled={ selectedDate === undefined || selectedSensorType === undefined || selectedUserArea === undefined }/>
								</Grid>
							</Grid>
						</div>
						<div className={classes.smallCard}>
							<Heatmap mapDate={selectedDate} area={selectedUserArea} measurementType={selectedSensorType} selectedJob={selectedJob} setSelectedZone={setSelectedZone} selectedZone={selectedZone} heatmapSettings={heatmapSettings}/>
						</div>
					</Grid>
					<Grid item xs={4}>
						<div className={classes.cardHeader}>
							<Typography className={classes.cardTitle} variant="subtitle1" > {selectedMeasurementType + ' last 7 days [' + (Unit[selectedMeasurementType as keyof typeof Unit] || '-') + ']'}  </Typography>
						</div>
						<div className={classes.smallCard}>
							<div className={ loadingBoxPlot ? classes.hide : '' }>
								<AreaBoxPlot data={recentSensorData} sensorType={selectedSensorType} selectedDate={selectedDate}
									getValueFromPayload={ selectedMeasurementType === 'multispectral' ?
										multispectralToNdvi
										:
										selectedMeasurementType === 'canopytemperature' ?
											thermalToCanopyTemp
											:
											selectedMeasurementType === 'alive' ?
												photosToAlive
												:
												undefined
									} />
							</div>
							{ loadingBoxPlot ?
								<Grid container sx={{ height: '300px', alignItems: 'center', justifyContent: 'center' }}>
									<CircularProgress />
								</Grid>
								:
								<></>
							}
						</div>
					</Grid>
					<Grid item xs={4}>
						<div className={classes.cardHeader}>
							<Typography className={classes.cardTitle} variant="subtitle1" > Area map </Typography>
						</div>
						<div className={classes.smallCard}>
							<UserAreaMap userArea={selectedUserArea} sensorData={sensorData} />
						</div>
					</Grid>
				</Grid>
			</Grid>
			<Grid container rowSpacing={2} className={classes.resultsGrid}>
				<Grid item container xs={12}>
					<Grid item xs={4}>
						<div className={classes.cardHeader}>
							<Typography className={classes.cardTitle} variant="subtitle1" > Zone summary { selectedZone ? ' - ' + selectedZone.name : '' }</Typography>
						</div>
						<div className={classes.smallCard}>
							<ZoneSummary sensorData={zoneSensorData} zoneName={selectedZone ? selectedZone.name : '' }/>
						</div>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};
