import * as React from 'react';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import moment from 'moment';
import * as sensors from '../../helpers/sensors/sensors';

interface ReportDateSelector {
	typesPerDate: sensors.SensorTypes[];
	selectedDate?: Date;
	setSelectedDate: (selectedDate: Date)=> void;
	loadingDates: boolean;
}

export const ReportDateSelector: React.FC<ReportDateSelector> = ({ typesPerDate, selectedDate, setSelectedDate, loadingDates }: ReportDateSelector) => {

	const handleChange = (event: React.ChangeEvent<unknown>, newValue: Date) => {
		setSelectedDate(newValue);
		return ;
	};

	return (
		<>
			{ typesPerDate !== undefined && typesPerDate.length > 0 && selectedDate !== undefined ?
				<Tabs value={selectedDate} onChange={handleChange} variant="scrollable" scrollButtons aria-label='basic tabs example'>
					{
						typesPerDate.map((dateopt: sensors.SensorTypes, index: number) => {
							return <Tab key={index} label={moment(dateopt.date, 'DD/MM/YY').format('MMM Do - dddd')} value={dateopt.date}/>;
						})
					};
				</Tabs>
				:
				loadingDates ?
					<LinearProgress sx={{ width: '90%' }} />
					:
					<Alert severity="warning" sx={{ width: '90%' }}>No data for area</Alert>
			}
		</>
	);
};