import * as React from 'react';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Button, Typography, Table, TableBody, TableCell, TableHead, TableRow, Box, Select, MenuItem, FormControl, InputLabel} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import { createStyles, makeStyles } from '@mui/styles';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import MUIDataTable, { FilterType, Responsive, SelectableRows } from 'mui-datatables';
import { useParams } from 'react-router-dom';
import UserContext from '../context/UserContext';
import { buildFileUrl } from '../helpers/main';
import * as Device from '../helpers/robot/robot';
import * as Sensors from '../helpers/sensors/sensors';
import { miniStyle } from '../style/miniStyle';
import generalStyles from '../style/styles';


const useStyles = makeStyles((theme) =>
	createStyles({
		rootDiv: { height: '100%', width: '100%', margin: '0px', padding: '0px', overflowY: 'hidden', overflowX: 'hidden', borderTop: '2px solid #e0e0e0' },
		pageTitle: { display: 'flex', height: '40px', marginLeft: '20px', marginTop: '20px' },
		selectFilter: { display: 'flex', height: '90px', alignItems: 'center', marginLeft: '20px', marginRight: '20px', justifyContent: 'space-between' },
		jobMap: { display: 'inline-block', height: '100%', minWidth: '50%' },
		pagination: { height: '60px', paddingTop: '10px', borderTop: '1px solid #bdbdbd', display: 'flex', justifyContent: 'center' },
		flex: { display: 'flex', justifyContent: 'flex-start' },
		title: { display: 'flex', justifyContent: 'space-between' },
		mainContent: { display: 'flex', width: '100%', height: 'calc(100% - 150px)' },
		gridRoot: { display: 'inline-block', height: '100%', minWidth: '50%' },
		gridContainer: { height: 'calc(100% - 60px)', width: '100%', borderTop: '1px solid #bdbdbd' },
		mapGridItem: { height: '100%', minWidth: '100%', direction: 'rtl', overflowY: 'scroll' },
		filterLabel: { marginLeft: 0, fontSize: '1.2em', color: 'gray', '&.Mui-focused': { color: 'gray' }, transform: 'translate(0px, -9px) scale(0.75)' },
		button: { width: 200, left: '2%' },
		margin: { margin: '1%' },
		extendedIcon: { marginRight: theme.spacing(1) },
		none: { textDecoration: 'none' },
		cards: { width: '60%', marginLeft: '20%' },
		formcontrol: { marginLeft: theme.spacing(1) },
		placeholderText: { width: '60%', marginLeft: '20%', fontStyle: 'italic', fontWeight: 'lighter', fontSize: '2rem', color: 'rgba(0,0,0,0.4)' },
		selectDropdown: { color: 'green', backgroundColor: 'blue' },
		newJobButton: { color: '#ffffff', background: 'rgb(47, 91, 181)', alignItems: 'center', whiteSpace: 'nowrap', transition: 'color 0.5s', width: '10rem', height: '30px', borderRadius: '20px', textTransform: 'none', fontSize: '1.2em', '&:hover': { backgroundColor: '#545AA7' } },
		selectedRow: { backgroundColor: theme.palette.grey[300]},
		tableHeaderCell: { boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)' },
		tableRowRoot: {'&tableRowSelected': {backgroudColor: 'gray'}},
		tableRowSelected: { backgroundColor: 'gray' },
		areaMapCard: { width: '100%', height: '300px', marginTop: '0px', marginLeft: '0px', paddingLeft: '0px', paddingTop: '0px', minHeight: '200px', alignItems: 'center', justifyContent: 'center' },
		mapContainer: { position: 'relative', width: '100%', height: '100%' },
		waypointIcon: { height: '20px', width: '20px', color: 'rgb(0,191,255)', fill: 'rgb(0,191,255)', transform: 'translate(-10px, -10px)', zIndex: 11 },
		modalHeader: { height: '45px', width: '100%', backgroundColor: 'rgba(110,110,110,0.5)', borderRadius: '10px 10px 0 0' },
		modalHeaderContent: { display: 'inline-block', width: 'calc(100% - 130px)', paddingLeft: '65px', paddingTop: '15px', height: 'calc(100% - 15px)', verticalAlign: 'middle' },
		modalHeaderClose: { display: 'inline-block', width: '65px', height: '100%', verticalAlign: 'top', paddingTop: '5px' },
		popUp: { zIndex: 12, maxWidth: 'auto', padding: '0px', margin: '0px', textAlign: 'center' },
		popupitems: { margin: '0px', padding: '0px', fontSize: '0.6rem' },
		waypointMarker: { cursor: 'pointer', zIndex: 11 },
		modal: { position: 'absolute', height: '90%', width: '90%', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'rgba(219,219,219,0.9)', borderRadius: '10px', textAlign: 'center',
			'& img': { maxWidth: '100%', maxHeight: '100%', height: 'calc(100% - 50px)' }
		}
	})
);

interface paramsURL {
	serialId: string;
}

interface SensorData {
    dataId: number | null;
    ImageId: string;
    rgb_crop: string;
    ir_crop: string;
    red_crop: string;
    mix: string;
    coverage: number | null;
    stressed: number | null;
    ndvi: number | null;
    score: number | null;
    date: string;
    time: string;
}

export const ImageAnalysis: React.FC  = () => {
	// User information
	const User = React.useContext(UserContext);

	//Styles
	const classes = useStyles();
	const importedClasses = generalStyles();
	const minimalSelectClasses = miniStyle();
	const menuProps: any = {
		classes: {
			paper: minimalSelectClasses.paper,
			list: minimalSelectClasses.list
		},
		anchorOrigin: {
			vertical: 'bottom',
			horizontal: 'left'
		},
		transformOrigin: {
			vertical: 'top',
			horizontal: 'left'
		}
	};

	// General Data
	const today = new Date().toISOString().split('T')[0];
	const yesterday = new Date();
	yesterday.setDate(yesterday.getDate() - 1);

	// E-Nano Back End Data
	const [deviceList, setDeviceList] = React.useState<Device.Robot[]>([]);
	const paramsURL: paramsURL = useParams();

	// Sensor data
	const [sensorData,setSensorData] = React.useState<Sensors.Sensor[]>([]);
	const [sensorDataList, setSensorDataList] = React.useState<{
		dataId: number | null;
		ImageId: string;
		rgb_crop: string;
		ir_crop: string;
		red_crop: string;
		mix: string;
		coverage: number | null;
		stressed: number | null;
		ndvi: number | null;
		score: number | null;
		date: string;
		time: string;
	}[]>([]);
	const [selectedSensorData, setSelectedSensorData] = React.useState<{
		dataId: number | null;
		ImageId: string;
		rgb_crop: string;
		ir_crop: string;
		red_crop: string;
		mix: string;
		coverage: number | null;
		stressed: number | null;
		ndvi: number | null;
		score: number | null;
		date: string;
		time: string;
	}>();

	const [open, setOpen] = React.useState(false);
	const [imgUrl, setImgUrl] = React.useState<string>('');

	const [selectedDevice, setSelectedDevice] = React.useState('');
	const [fromDate, setFromDate] = React.useState('');
	const [toDate, setToDate] = React.useState('');
	const [analysisTitle, setAnalysisTitle] = React.useState('');
	const [loading, setLoading] = React.useState(false); // Initialize as true if needed

	const handleOpen = (imgUrl: string) => {
		setOpen(true);
		setImgUrl(imgUrl);
	};
	const handleClose = () => setOpen(false);

	const iconComponent = (props: any) => {
		return (
			// eslint-disable-next-line react/prop-types
			<ExpandMoreIcon className={props.className + ' ' + minimalSelectClasses.icon}/>
		);
	};

	React.useEffect(() => {
		Device.getRobots().then((res) => {
			let tempDeviceList: Device.Robot[] = [];
			let filteredDevices: Device.Robot[];
			tempDeviceList = [...res];
			//TODO: Delete this section if no filter
			if (User.id === 7) {
				console.log(User.id);
				filteredDevices = tempDeviceList;
			} else {
				filteredDevices = tempDeviceList;
			}
			setDeviceList(filteredDevices);
		});

		setFromDate(yesterday.toISOString().slice(0, 10));
		setToDate(today);
	},[]);

	React.useEffect(() => {
		if (deviceList !== null && paramsURL.serialId !== null) {
			const foundDevice = deviceList.find((device) => device.serialId === paramsURL.serialId);
			if (foundDevice) {
				setSelectedDevice(foundDevice.id.toString());
			}
		}
	},[paramsURL, deviceList]);

	React.useEffect(() => {
		async function fetchRobotData () {
			console.log(selectedDevice);
			const unfilteredData = await Sensors.getDataBetweenDates(fromDate,toDate,selectedDevice);
			setSensorData(unfilteredData);
			setLoading(false);
		}

		if (selectedDevice && toDate && fromDate !== undefined ) {
			fetchRobotData();
			console.log('Data Fetched');
			setLoading(true);
			console.log('To and From date defined');
		}
	}, [selectedDevice, fromDate, toDate]);

	React.useEffect(() => {
		processSensorData(sensorData);
	}, [sensorData]);

	React.useEffect(() => {
		if (sensorDataList) {
			setSelectedSensorData(sensorDataList[0]);
		} else {
			setSelectedSensorData({
				dataId: null,
				ImageId: '',
				rgb_crop: '',
				ir_crop: '',
				red_crop: '',
				mix: '',
				coverage: null,
				stressed: null,
				ndvi: null,
				score: null,
				date: '',
				time: ''
			});
		}
	}, [sensorDataList]);

	React.useEffect(() => {
		if (selectedSensorData) {
			setAnalysisTitle(`Measurement ${selectedSensorData.ImageId}`);
		  } else {
			// Handle the case when selectedSensorData is undefined
			// You can set a default title or take appropriate action here
			setAnalysisTitle('No Measurement Selected');
		  }
	}, [selectedSensorData]);

	const processSensorData = (sensorData: Sensors.Sensor[]) => {
		if (sensorData && sensorData.length > 0) {
			// Filter sensorData with type "photos"
			const photoSensorData = sensorData.filter((data) => data.type === 'photos');

			// Create a new array with the desired columns
			const processedSensorDataList = photoSensorData.map((data) => {
			  const payload = JSON.parse(data.payload);
			  //const timestamp = new Date(data.timestamp);
			  console.log(payload.value.rgb_crop);

			  // Using the date from the name of the rgb_crop file and not timestamp!
			  const rgbCrop = payload.value.rgb_crop;
			  const dateFromFileName = rgbCrop.substring(35, 45); // Characters 36 to 45
			  const timeFromFileName = rgbCrop.substring(46, 54); // Characters 47 to 54

			  // Format date and time accordingly
			  const formattedDate = dateFromFileName.replace(/-/g, '/');
			  const formattedTime = timeFromFileName.replace(/-/g, ':').substring(0, 5);

			  return {
					dataId: data.id,
					ImageId: payload.value.ImageID,
					rgb_crop: payload.value.rgb_crop,
					ir_crop: payload.value.ir_crop,
					red_crop: payload.value.red_crop,
					mix: payload.value.mix,
					coverage: payload.value.coverage,
					stressed: payload.value.stressed,
					ndvi: payload.value.ndvi,
					score: payload.value.score,
					// date: timestamp.toISOString().split('T')[0], // Using Timestamp
					// time: timestamp.toISOString().split('T')[1].substring(0, 5) // Using Timestamp
					date: formattedDate, // Use extracted date
					time: formattedTime // Use extracted time
			  };
			}).filter((data) => data.ImageId && data.ImageId.toLowerCase() !== 'undefined');

			setSensorDataList(processedSensorDataList);
		  } else {
			setSensorDataList([]);
		  }
	};

	const onLoadingComplete = () => {
		console.log('Loading complete.');
		console.log(sensorData);
		console.log(sensorDataList);
		console.log(selectedSensorData);
	  };

	React.  useEffect(() => {
		if (!loading) {
		  onLoadingComplete();
		}
	}, [loading]);

	const handleFromDateSelection = (fromDateSelected: React.SetStateAction<string>) => {
		setFromDate(fromDateSelected);
	};

	const handleToDateSelection = (toDateSelected: React.SetStateAction<string>) => {
		setToDate(toDateSelected);
	};

	// Sorting & Filtering Table
	const muiTableColumns = [
		{name: 'ImageId', label: 'Measurement', options: { filter: true, sort: true}},
		{name: 'date', label: 'Date', options: { filter: true, sort: true}},
		{name: 'time', label: 'Time', options: { filter: true, sort: true}},
		{name: 'coverage', label: 'Coverage', options: { filter: true, sort: true, display: false, customBodyRender: (value: any) => {
			return `${value}%`;
		  }}},
		{name: 'stressed', label: 'Stress', options: { filter: true, sort: true, display: false, customBodyRender: (value: any) => {
			return `${value}%`;
		  }}},
		{name: 'ndvi', label: 'NDVI', options: { filter: true, sort: true, display: false, customBodyRender: (value: any) => {
			return value != null ? value.toFixed(2) : 'N/A';
		  }}},
		{name: 'score', label: 'Score', options: { filter: true, sort: true, display: false}}
	   ];

	const [selectedRow, setSelectedRow] = React.useState(null);

	const muiTableOptions = {
		filter: true,
		filterType: 'dropdown' as FilterType,
		onRowClick: (rowData: any, rowMeta: any) => {
			setSelectedSensorData(sensorDataList[rowMeta.dataIndex]);

			const dataIndex = rowMeta.dataIndex;
			handleRowSelectionChange(dataIndex);
		  },
		responsive: 'standard' as Responsive,
		rowHover: true,
		rowsPerPageOptions: [5, 10],
		rowsPerPage: 10,
		selectableRows: 'multiple' as SelectableRows,
		setRowProps: (row: any, dataIndex: any) => {
			const isSelected = dataIndex === selectedRow;
			return {
			  style: {
					cursor: 'pointer',
					backgroundColor: isSelected ? '#01baef' : 'inherit'
			  }
			};
		  }
	  };

	  const handleRowSelectionChange = (currentRowsSelected: any) => {
		setSelectedRow(currentRowsSelected);
	  };

	  const [updatedScore, setUpdatedScore] = React.useState<number | null>(null);
	  const [isUpdatingScore, setIsUpdatingScore] = React.useState(false);


	  const handleScoreUpdate = async (selectedSensorData:SensorData) => {
		console.log('handleScoreUpdate called');
		setIsUpdatingScore(true);// Start loading
		console.log('handleScoreUpdate called');
		//const imageUrl = buildFileUrl('getfileurl/'+selectedSensorData.rgb_crop);
		//  THIS IS HARDCODED PLEASE REMEMBER TO COMMENT THE LINE BELOW IT AND UNCOMMENT THE ONE ON TOP.
		const imageUrl = 'https://dev.e-nano.io/api/getfileurl/'+selectedSensorData.rgb_crop;
		console.log('Requesting new score for image URL:', imageUrl);
		console.log('All Cookies:', document.cookie);

		// Extract session_cookie from the document.cookie
		const cookies = document.cookie.split('; ');
		let sessionCookie = null;
		for (const cookie of cookies) {
			const [name, value] = cookie.split('=');
			if (name === 'session_cookie') {
				//sessionCookie = value; THIS IS HARDCODED PLEASE REMEMBER TO COMMENT THE LINE BELOW IT AND UNCOMMENT THIS ONE.
				sessionCookie = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEsImVtYWlsIjoiYWRtaW5AZS1uYW5vLmlvIiwiaWF0IjoxNjk3NzIyMTQzLCJleHAiOjE3MDgwOTAxNDN9.s0cNbL6IVdwo9yMs-cXNKCccdUaWZ2R_NLNAzVRaPnw';
				break;
			}
		}

		const requestBody = {
			url: imageUrl,
			'session-cookie': sessionCookie // Include session-cookie in the request body
		};

		try {
			const response = await fetch('https://5wa6g91vj0.execute-api.eu-west-2.amazonaws.com/production/classifyTurf', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(requestBody) // Send the request body with session-cookie
			});

			console.log('Sending request to API:', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(requestBody)
			});
			console.log('Response received:', response);

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}

			const data = await response.json();
			console.log('Data from the response:', data);

			// Find the index + 1 of the maximum value in the predictions array
			const predictions = data.predictions[0];
			const maxIndex = predictions.indexOf(Math.max(...predictions));
			const maxIndexPlusOne = maxIndex + 1;
			console.log('Index + 1 of Maximum Value:', maxIndexPlusOne);
			data.newScore = maxIndexPlusOne;
			setUpdatedScore(data.newScore); // Update the updatedScore state
			console.log('Updated score set to:', data.newScore);
		} catch (error) {
			console.error('Error updating score:', error);
			console.log('Sending request to API:', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(requestBody)
			});
		} finally {
			setIsUpdatingScore(false); // End loading regardless of success or error
		}
	};

	return (
		<div className="RootDiv" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
			<div className={classes.pageTitle}>
				<Typography variant='h4'>Image Analysis</Typography>
			</div>
			<Grid container spacing={2} style={{ marginTop: '20px', paddingLeft: '20px', paddingRight: '20px', height: '100%' }}>
				{/* Left Column */}
				<Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
					<Grid container spacing={2}>
						{/* Device Filter */}
						<Grid item xs={4}>
							<FormControl fullWidth>
								<InputLabel className={classes.filterLabel} id="job-state">Device Selection</InputLabel>
								<Select
									className={importedClasses.select}
									classes={{select: minimalSelectClasses.root}}
									labelId="device-selection"
									onChange={(e) => setSelectedDevice(e.target.value)}
									value={selectedDevice}
									IconComponent={iconComponent}
									MenuProps={menuProps}
								>
									{deviceList.map((device, index) => (
										<MenuItem key={device.id} value={device.id}>
											{device.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>

						{/* Date Filter */}
						{selectedDevice && (
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<Grid item xs={8}>
									{/* <InputLabel className={style.filterLabel} id="job-state">Date Range Selection</InputLabel> */}
									<Grid container spacing={2}>
										<Grid item xs={6}>
											<DesktopDatePicker
												className={importedClasses.select}
												label="From"
												value={new Date(fromDate)}
												maxDate={new Date(toDate)}
												onChange={(newValue: Date | null) => handleFromDateSelection(newValue ? newValue.toDateString() : new Date().toDateString() )}
											/>
										</Grid>
										<Grid item xs={6}>
											<DesktopDatePicker
												className={importedClasses.select}
												label="To"
												value={new Date(toDate)}
												minDate={new Date(fromDate)}
												maxDate={new Date(today)}
												onChange={(newValue: Date | null) => handleToDateSelection(newValue ? newValue.toDateString() : new Date().toDateString() )}
											/>
											{/* <TextField
											className={importedClasses.select}
											label="To"
											type="date"
											fullWidth
											InputLabelProps={{
												shrink: true
											}}
											onChange={(e) => handleToDateSelection(e.target.value)}
											// onChange={(e) => setToDate(e.target.value)}
											value={toDate}
											inputProps={{ min: fromDate ? fromDate : undefined,
												max: today }}
										/> */}
										</Grid>
									</Grid>
								</Grid>
							</LocalizationProvider>
						)}
					</Grid>
					<div style={{ margin: '5px', width: '100%' }}>
						<MUIDataTable
							title="Measurement Data"
							data={sensorDataList.map((data) => [
								`Measurement ${data.ImageId}`,
								data.date,
								data.time,
								data.coverage,
								data.stressed,
								data.ndvi,
								data.score
							])}
							columns={muiTableColumns}
							options={muiTableOptions}
						/>
					</div>
				</Grid>

				{/* Right Column */}
				<Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
					<Box border={1} borderColor="grey.300" borderRadius={4} p={3} display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
						{selectedSensorData ? (
							<>
								<Grid container spacing={3} style={{ paddingLeft: '5px', paddingRight: '5px', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
									<Grid item xs={6} sm={6} md={6} lg={6} xl={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
										<div style={{ textAlign: 'center', paddingBottom: '0px' }}>
											<Typography variant="h6">{analysisTitle}</Typography>
										</div>
									</Grid>
									<Grid item xs={8} sm={8} md={8} lg={8} xl={8} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
										{/* Images */}
										<Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
											{['rgb_crop', 'ir_crop', 'red_crop', 'mix'].map((propertyName, index) => (
												<Grid item xs={5} sm={5} md={5} lg={5} xl={5} key={index}>
													{propertyName === 'rgb_crop' && (
														<img
															src={buildFileUrl('getfileurl/'+selectedSensorData.rgb_crop)}
															alt={'RGB'}
															style={{ width: '100%', cursor: 'pointer' }}
															onClick={() => handleOpen(buildFileUrl('getfileurl/'+selectedSensorData.rgb_crop))}
														/>
													)}
													{propertyName === 'ir_crop' && (
														<img
															src={buildFileUrl('getfileurl/'+selectedSensorData.ir_crop)}
															alt={'Infrared'}
															style={{ width: '100%', cursor: 'pointer' }}
															onClick={() => handleOpen(buildFileUrl('getfileurl/'+selectedSensorData.ir_crop))}
														/>
													)}
													{propertyName === 'red_crop' && (
														<img
															src={buildFileUrl('getfileurl/'+selectedSensorData.red_crop)}
															alt={'Red'}
															style={{ width: '100%', cursor: 'pointer' }}
															onClick={() => handleOpen(buildFileUrl('getfileurl/'+selectedSensorData.red_crop))}
														/>
													)}
													{propertyName === 'mix' && (
														<img
															src={buildFileUrl('getfileurl/'+selectedSensorData.mix)}
															alt={'NDVI'}
															style={{ width: '100%', cursor: 'pointer' }}
															onClick={() => handleOpen(buildFileUrl('getfileurl/'+selectedSensorData.mix))}
														/>
													)}
												</Grid>
											))}
										</Grid>
									</Grid>
									<Grid item xs={8} sm={8} md={8} lg={8} xl={8} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
										{/* Table */}
										<Table style={{ width: '100%' }}>
											<TableHead>
												<TableRow>
													<TableCell>Measurement Name</TableCell>
													<TableCell>Value</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{[
													{ name: 'Coverage', value: selectedSensorData.coverage },
													{ name: 'Stressed', value: selectedSensorData.stressed },
													{ name: 'NDVI', value: selectedSensorData.ndvi },
													{ name: 'Score', value: selectedSensorData.score }
												].map((result, index) => (
													<TableRow key={index} style={{ padding: '1px', height: '10px' }}>
														<TableCell>{result.name}</TableCell>
														<TableCell>
															{result.name === 'Score'
																? (
																	<div style={{ display: 'flex', alignItems: 'center' }}>
																		<span>
																			{updatedScore !== null
																				? updatedScore
																				: (result.value != null
																					? Math.round(Math.max(0, Math.min(10, result.value)))
																					: 'N/A')}
																		</span>
																		{isUpdatingScore
																			? <CircularProgress size={24} style={{ marginLeft: '10px' }} />
																			: <Button
																				onClick={() => handleScoreUpdate(selectedSensorData)}
																				size="small"
																				variant="outlined"
																				style={{ marginLeft: '10px' }}
																			>
																				Update Score
																			</Button>
																		}
																	</div>
																)
																: (
																	result.name === 'Coverage' || result.name === 'Stressed'
																		? (result.value != null ? `${result.value}%` : 'N/A')
																		: (result.value != null ? result.value.toFixed(2) : 'N/A') // Assuming NDVI is a floating point number
																)
															}
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</Grid>
								</Grid>
							</>
						) : (
							<Typography variant="h6">Please Select an Item to Display</Typography>
						)}
					</Box>
				</Grid>
			</Grid>
			<Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Box className={classes.modal} >
					<div className={classes.modalHeader}>
						<div className={classes.modalHeaderContent}>
							<strong>Photo analysis / </strong> Coverage: {selectedSensorData?.coverage}%
						</div>
						<div className={classes.modalHeaderClose}>
							<Button onClick={handleClose}><CancelOutlinedIcon/></Button>
						</div>
					</div>
					<img src={imgUrl} alt='Photo unavailable'/>
					{/* <div style={{ maxWidth: '90%', maxHeight: '90%'}}>
						<img src={imgUrl} alt="Image" style={{ width: '100%', height: 'auto', objectFit: 'contain', imageRendering: 'pixelated'}}/>
					</div> */}
				</Box>
			</Modal>
		</div>
	);
};