import * as React from 'react';
import { Apps, Flare } from '@mui/icons-material';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import CloseIcon from '@mui/icons-material/Close';
import PencilIcon from '@mui/icons-material/Create';
import CropFreeIcon from '@mui/icons-material/CropFree';
import DeleteIcon from '@mui/icons-material/Delete';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import MoreOutlinedIcon from '@mui/icons-material/MoreOutlined';
import SearchIcon from '@mui/icons-material/Search';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import ShareIcon from '@mui/icons-material/Share';
import StadiumIcon from '@mui/icons-material/Stadium';
import UndoIcon from '@mui/icons-material/Undo';
import { Button, Card, Divider, List, ListItem, ListItemText, TextField } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Feature } from 'geojson';
import { getPlaces } from '../../helpers/map/map';
// @ts-ignore
import circleCursor from '../../resources/images/circleCursor.cur';
import mouseIcon from '../../resources/images/mouseIcon.png';
// @ts-ignore
import pencilCursor from '../../resources/images/pencilCursor.cur';


interface NotchProps {
    undo?: any;
    randomPoints?: any;
    pointsGrid?: any;
	footballStandardPoints?: any;
	pointsOverLines?: any;
    deleteAll: any;
    deselectAll: any;
    setCurrentStep: any;
    selectAll: any;
    currentStep: number;
    lastStep: number;
    PATH_DEFINITION: number;
    WAYPOINTS_SELECTION: number;
    ROBOT_SELECTION: number;
    TASK_SELECTION: number;
    SCHEDULING: number;
    MAP_SELECTION: number;
    TOKEN: string;
    setCenter: any;
    mapboxDraw: any;
    mapRenderer?: mapboxgl.Map;
    setMapCursor: (value: string) => void;
    setIsDrawWaypointMode: (value: boolean) => void;
    multipleWaypointClick?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	setCoordinateInsertCard?: (value: boolean) => void;
}

interface SearchResult {
	id: string;
	type: string;
	place_array: [];
	relevance: number;
	bbox: number[];
	center: number[];
	matching_place_name: string;
	matching_text: string;
	place_name: string;
	place_type: string[];
	text: string;
	context: any[];
	geometry: {
		type:string;
		coordinates: number[];
	};
	properties: {
		wikidata: string;
		short_code: string;
	};
}

export const useStatusBarStyles = makeStyles((theme: Theme) =>
	createStyles({
		center: { width: '42%', minWidth: '468px', alignContent: 'center', position: 'absolute', opacity: '80%', left: '29%', zIndex: 2, marginTop: '2%' },
		notch: { borderRadius: '15px', justifyContent: 'space-around', color: '#232D4C', background: 'white', padding: '10px' },
		icons: { paddingLeft: '5%', paddingRight: '5%' },
		searchBar: { display: 'flex', flexDirection: 'row', justifyContent: 'center' },
		divider: { marginTop: '10px' },
		closeButton: { justifyContent: 'flex-end', padding: '0px', margin: '0px', fontWeight: 'lighter', color: 'rgba(0,0,0,0.5)' },
		iconClicked: { backgroundColor: '#dcdcdc', color: 'black', '&:hover': { backgroundColor: '#D3D3D3' } }
	})
);

export const DrawingNotch = ({ undo, randomPoints, pointsGrid, footballStandardPoints, pointsOverLines, currentStep, lastStep, deleteAll, setCurrentStep, MAP_SELECTION, TOKEN,
	PATH_DEFINITION, WAYPOINTS_SELECTION, ROBOT_SELECTION, TASK_SELECTION, SCHEDULING, selectAll, deselectAll, setCenter, mapboxDraw, mapRenderer, setMapCursor,
	setIsDrawWaypointMode, multipleWaypointClick, setCoordinateInsertCard }: NotchProps) => {

	const classes = useStatusBarStyles();
	const [disabledButton, setDisabledButton] = React.useState('none');
	const [confirmModalOpened, setConfirmModalOpened] = React.useState(false);
	const [searchText, setSearchText] = React.useState('');
	const [searchResultPlaces, setSearchResultPlaces] = React.useState<Feature[]>([]);
	const [placeIndex, setPlaceIndex] = React.useState<number|null>(null);
	const [showResultsCloseButton, setShowResultsCloseButton] = React.useState<boolean>(false);
	const [buttonState, setButtonState] = React.useState<any>({ mouse: true, drawPolygon: false, drawLine: false, insertByCoordinates: false });
	const [enableEscKeyEvent] = React.useState<boolean>(false);
	const textFieldRef: any = React.useRef();

	const handleClickDelete = () => {
		setConfirmModalOpened(true);
		setMapCursor('default');
	};

	const handleCloseDeleteModal = () => {
		setConfirmModalOpened(false);
	};

	const handleConfirmDelete = () => {
		setCurrentStep(ROBOT_SELECTION);
		deleteAll();
		handleCloseDeleteModal();
	};

	const confirmSearch = () => {
		getPlaces(searchText, TOKEN).then((resp) => {
			setSearchResultPlaces(resp);
		});
		setPlaceIndex(null);
	};

	const centerMapTo = (place: Feature) => {
		setCenter(place);
		setSearchResultPlaces([]);
	};

	const isSelected = (item: SearchResult) => {
		if (placeIndex === null) {
			return false;
		} else if (searchResultPlaces[placeIndex].id === item.id) {
			return true;
		} else {
			return false;
		}
	};

	const closeIconSequence = () => {
		setSearchResultPlaces([]);
		setSearchText('');
		textFieldRef.current.value = '';
		setShowResultsCloseButton(false);
	};

	const listKeyboardKeysHandler = (e: any) => {
		if (e.key === 'ArrowDown' && placeIndex === null) {
			setPlaceIndex(0);
		} else if (e.key === 'ArrowDown' && placeIndex !== null) {
			if (placeIndex < searchResultPlaces.length-1) {
				setPlaceIndex(placeIndex +1);
			}
		} else if (e.key === 'ArrowUp' && placeIndex !== null) {
			if ((placeIndex > 0 && placeIndex <= searchResultPlaces.length -1)) {
				setPlaceIndex(placeIndex-1);
			} else if (placeIndex === 0) {
				textFieldRef.current.focus();
				setPlaceIndex(null);
			}
		} else if (e.key === 'Escape') {
			closeIconSequence();
		}
	};

	const textFieldKeyHandler = (e: any) => {
		if (e.key === 'Enter') {
			confirmSearch();
		} else if (e.key === 'ArrowDown' && searchResultPlaces.length > 0) {
			setPlaceIndex(0);
		} else if (e.key === 'Escape') {
			closeIconSequence();
		}
	};

	const clickAwayHandler = () => {
		if (textFieldRef.current.value.length > 0) {
			setSearchResultPlaces([]);
		}
	};

	function showCloseButton () {
		if (showResultsCloseButton) {
			return <InputAdornment position='end' >
				<Button style={{ minWidth: 'auto' }} aria-label='clear' className={classes.closeButton}
					onClick={() => {
						closeIconSequence();
						setShowResultsCloseButton(false);
					}}>
					<CloseIcon/>
				</Button>
			</InputAdornment>;
		} else {
			return <></>;
		}
	}

	function textChangeHandler (e:any) {
		setSearchText(e.target.value);
		if (e.target.value.length > 0) {
			setShowResultsCloseButton(true);
		} else {
			setShowResultsCloseButton(false);
		}
	}

	// Step 2 - Path/Area definition - PATH_DEFINITION
	const selectionCursorHandler = () => {
		setMapCursor('default');
		mapboxDraw.changeMode('simple_select');
		setButtonState({ mouse: true, drawPolygon: false });
	};

	const drawLineModeButtonHandler = () => {
		setMapCursor(`url(${pencilCursor}) 5 28, ${'auto'}`);
		mapboxDraw.changeMode('draw_line_string');
		setButtonState({ mouse: false, drawPolygon: false, drawLine: true });
		setDisabledButton('path');
	};

	const drawPolygonModeButtonHandler = () => {
		setMapCursor(`url(${pencilCursor}) 5 28, ${'auto'}`);
		mapboxDraw.changeMode('draw_polygon');
		setButtonState({ mouse: false, drawPolygon: true, drawLine: false });
		setDisabledButton('path');
	};

	// End Step 2

	// Step 3 - Create waypoints inside area - WAYPOINTS_SELECTION
	const waypointSelectionMouseCursorHandler = () => {
		setMapCursor('default');
		mapboxDraw.changeMode('simple_select');
		setButtonState({ mouse: true, clickInsert: false, coordinateInsert: false });
		setIsDrawWaypointMode(false);
		if (setCoordinateInsertCard) {
			setCoordinateInsertCard(false);
		}
	};

	const drawPointModeButtonHandler = () => {
		setMapCursor(`url(${circleCursor}) 15 15, ${'auto'}`);
		mapboxDraw.changeMode('draw_point');
		setButtonState({ mouse: false, clickInsert: true, coordinateInsert: false });
		setIsDrawWaypointMode(true);
		if (setCoordinateInsertCard) {
			setCoordinateInsertCard(false);
		}
	};

	// End Step 3

	const dbClickHandler = () => {
		if (mapboxDraw.getMode() === 'simple_select') {
			if (currentStep === PATH_DEFINITION) {
				setButtonState({ mouse: true, drawPolygon: false, drawLine: false });
				setMapCursor('default');
			}
		}
	};

	const insertByCoordinatesModeHandler = () => {
		if (setCoordinateInsertCard) {
			setCoordinateInsertCard(true);
			setButtonState({ mouse: false, clickInsert: false, coordinateInsert: true });
		}
	};


	React.useEffect(() => {
		if (mapRenderer !== undefined && multipleWaypointClick !== undefined) {
			if (currentStep === PATH_DEFINITION) {
				mapRenderer.off('dblclick', dbClickHandler);
				mapRenderer.on('dblclick', dbClickHandler);
			} else if (currentStep === WAYPOINTS_SELECTION ) {
				mapRenderer.off('dblclick', dbClickHandler);
				if (buttonState.clickInsert) {
					mapRenderer.off('click', multipleWaypointClick);
					mapRenderer.on('click', multipleWaypointClick);
				} else {
					mapRenderer.off('click', multipleWaypointClick);
				}
			} else if (currentStep === TASK_SELECTION) {
				mapRenderer.off('click', multipleWaypointClick);
			}
		}
		if (buttonState.clickInsert) {
			setMapCursor(`url(${circleCursor}) 15 15, ${'auto'}`);
		}
	}, [buttonState]);

	React.useEffect(() => {
		if (mapRenderer !== undefined && multipleWaypointClick !== undefined) {
			if (currentStep === PATH_DEFINITION) {
				selectionCursorHandler();
				mapRenderer.off('click', multipleWaypointClick);
			} else if (currentStep === WAYPOINTS_SELECTION) {
				drawPointModeButtonHandler();
				if (buttonState.clickInsert) {
					mapRenderer.off('click', multipleWaypointClick);
					mapRenderer.on('click', multipleWaypointClick);
				} else {
					mapRenderer.off('click', multipleWaypointClick);
				}
			} else if (currentStep === TASK_SELECTION) {
				setIsDrawWaypointMode(false);
				mapRenderer.off('click', multipleWaypointClick);
			}
		}
	}, [currentStep]);

	React.useEffect(() => {
		window.addEventListener('keydown', (e) => {
			if (e.code === 'Escape') {
				if (mapRenderer !== undefined && mapboxDraw.getMode() === 'draw_polygon') {
					mapboxDraw.changeMode('draw_polygon');
				}
			}
		});
	}, [enableEscKeyEvent]);

	return (
		<div className={classes.center}>
			{
				!(new Set([lastStep, SCHEDULING]).has(currentStep))?
					<ClickAwayListener onClickAway={() => {
						if (textFieldRef.current) {
							clickAwayHandler();
						}
					}}>
						<Card className={classes.notch}>
							{
								(() => {
									switch (currentStep) {
									case MAP_SELECTION:
										return (
											<div className={classes.searchBar}>
												<TextField inputRef={textFieldRef} size='small' fullWidth={true} id='outlined-basic' label='Search place' variant='outlined'
													onChange={textChangeHandler} autoComplete='off' onKeyDown={textFieldKeyHandler} InputProps={{ endAdornment: (showCloseButton()) }}
												/>
												<Button style={{color: 'rgba(0, 0 , 0, 0.87)'}} onClick={() => {
													confirmSearch();
												}}>
													<SearchIcon className={classes.icons} />
												</Button>
											</div>
										);
									case PATH_DEFINITION:
										return (
											<>
												<Button className={`${buttonState.mouse ? classes.iconClicked : ''}`} onClick={selectionCursorHandler}><img style={{ color: 'white' }} src={mouseIcon} alt="Mouse Icon" className={classes.icons}/></Button>
												<Button className={`${buttonState.drawPolygon ? classes.iconClicked: ''}`} onClick={drawPolygonModeButtonHandler} disabled={disabledButton === 'area' ? true : false}><PencilIcon className={classes.icons} /></Button>
												<Button className={`${buttonState.drawLine ? classes.iconClicked: ''}`} onClick={drawLineModeButtonHandler} disabled={disabledButton === 'line' ? true : false}><ShareIcon className={classes.icons} /></Button>
												<Button onClick={() => {
													setDisabledButton('none'); undo();
												}} ><UndoIcon className={classes.icons} /></Button>
												<Button onClick={handleClickDelete}><DeleteIcon className={classes.icons} /></Button>
											</>
										);
									case WAYPOINTS_SELECTION:
										return (
											<>
												<Button className={`${buttonState.mouse ? classes.iconClicked: ''}`} onClick={waypointSelectionMouseCursorHandler}><img style={{ color: 'white' }} src={mouseIcon} alt="Mouse Icon" className={classes.icons}/></Button>
												<Button className={`${buttonState.clickInsert ? classes.iconClicked: ''}`} onClick={drawPointModeButtonHandler}><GpsFixedIcon className={classes.icons} /></Button>
												<Button className={`${buttonState.coordinateInsert ? classes.iconClicked : ''}`} onClick={insertByCoordinatesModeHandler}><AddLocationIcon className={classes.icons} /></Button>
												<Button onClick={randomPoints}><Flare className={classes.icons} /></Button>
												<Button onClick={pointsGrid}><Apps className={classes.icons} /></Button>
												<Button onClick={footballStandardPoints}><StadiumIcon className={classes.icons} /></Button>
												<Button onClick={pointsOverLines}><MoreOutlinedIcon className={classes.icons} /></Button>
												<Button onClick={undo}><UndoIcon className={classes.icons} /></Button>
												<Button onClick={handleClickDelete}><DeleteIcon className={classes.icons} /></Button>
											</>
										);
									case TASK_SELECTION:
										return (
											<>
												<Button onClick={() => selectAll()}><SelectAllIcon className={classes.icons} /></Button>
												<Button onClick={() => deselectAll()}><CropFreeIcon className={classes.icons} /></Button>
											</>
										);
									}
								})()
							}
							<Dialog open={confirmModalOpened} onClose={handleCloseDeleteModal} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
								<DialogTitle id="alert-dialog-title">{'Do you want to delete all in the map?'}</DialogTitle>
								<DialogContent>
									<DialogContentText id="alert-dialog-description">This action is not reversible and will delete waypoints, paths and areas.</DialogContentText>
								</DialogContent>
								<DialogActions>
									<Button onClick={handleCloseDeleteModal} color="primary">Cancel</Button>
									<Button onClick={handleConfirmDelete} color="primary" autoFocus>Delete All</Button>
								</DialogActions>
							</Dialog>
							{
								searchResultPlaces.length > 0 ? (
									<>
										<Divider className={classes.divider}></Divider>
										<List component='nav' aria-label='places' onKeyDown={listKeyboardKeysHandler}>
											{
												searchResultPlaces.map((place: any) => {
													return (
														<ListItem button key={place.id} onClick={() => {
															centerMapTo(place); textFieldRef.current.value = place.place_name;
														}} selected={isSelected(place)} autoFocus={isSelected(place)} >
															<ListItemText inset primary={place.place_name} style={{ paddingLeft: '0px' }} />
														</ListItem>
													);
												})
											}
										</List>
									</>
								) : <></>
							}
						</Card>
					</ClickAwayListener>
					:
					<></>
			}
		</div>
	);
};
