import * as React from 'react';
import SpeedIcon from '@mui/icons-material/Speed';
import { Container, Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStorageStyles = makeStyles((theme: Theme) =>
	createStyles({
		mainData: { display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' },
		units: { display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' },
		icon: { display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' },
		sensors: { padding: '2px', alignItems: 'flex-start' },
		title: { display: 'flex', justifyContent: 'flex-start' },
		container: { height: '180px', display: 'flex', alignItems: 'flex-start', marginTop: '20px' }
	})
);
interface ISensors{
    message: any;
}

export const Sensors: React.FC<ISensors> = ({ message }) => {
	const classes = useStorageStyles();
	const [motors, setMotors]= React.useState({
		right: {
			tacho: 0
		},
		left: {
			tacho: 0
		}
	});

	function displaySensors (message: any) {
		try {
			if (message.topic) {
				if (message.topic.includes('motor')) {
					if (message.topic.includes('right')) {
						setMotors({
							...motors,
							right: {
								tacho: message.message.payload.value
							}
						});
					} else if (message.topic.includes('left')) {
						setMotors({
							...motors,
							left: {
								tacho: message.message.payload.value
							}
						});
					}
				}
			}
		} catch (error) {
			//  console.log(error);
		}
	}

	React.useEffect(() => {
		displaySensors(message);
		// eslint-disable-next-line
    }, [message]);

	return (
		<div className={classes.sensors}>
			<Container maxWidth='xl'>
				{(Object.keys(message).length === 0)?
					'WAITING FOR SENSOR DATA':
					<>
						<Grid container spacing={1} className={classes.container}>
							<Grid item xs={12} className={classes.title}>
								<Typography variant = 'subtitle1'>Left</Typography>
							</Grid>
							<Grid item xs={2}/>
							<Grid item xs={2} className={classes.icon}>
								<SpeedIcon fontSize='small'/>
							</Grid>
							<Grid item xs={4} className={classes.mainData}>
								<Typography variant='subtitle1'>{motors.left.tacho.toFixed(2)}</Typography>
							</Grid>
							<Grid item xs={2} className={classes.units}>
								<Typography variant='body2'>rpm</Typography>
							</Grid>
							<Grid item xs={12} className={classes.title}>
								<Typography variant = 'subtitle1'>Right</Typography>
							</Grid>
							<Grid item xs={2} />
							<Grid item xs={2} className={classes.icon}>
								<SpeedIcon fontSize='small'/>
							</Grid>
							<Grid item xs={4} className={classes.mainData}>
								<Typography variant='subtitle1'>{motors.right.tacho.toFixed(2)}</Typography>
							</Grid>
							<Grid item xs={2} className={classes.units}>
								<Typography variant='body2'>rpm</Typography>
							</Grid>
						</Grid>
					</>}
			</Container>
		</div>
	);
};
