import * as React from 'react';
import { Box, Typography } from '@mui/material';
import Moment from 'moment';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import importedStyles from '../../style/styles';

interface IHardnessPlot {
  message?: any;
}

export const HardnessPlot: React.FC<IHardnessPlot> = ({message}) => {
	const importedClasses = importedStyles();
	const [data, setData] = React.useState([{}]);
	const [filteredData, setFilteredData] = React.useState< string | null >(null);

	function plotData (message: any) {
		try {
			if (message.topic.includes('sensor/hardness_live/clegg') && data !== null) {
				const ts: string = message.message.timestamp.toString();
				const timestamp = Moment.unix(Number(ts.substr(0,ts.indexOf('.'))));
				const temp = [...data,
					{
						'time': timestamp.format('HH:mm:ss'),
						'Hardness': (message.message.payload.value).toFixed(2)
					}
				];
				if (data.length>100) {
					temp.shift();
				}
				setData(temp);
			}
		} catch (err) {
			console.log(err);
		}
	}

	const filteredValue = (message: any) => {
		try {
			if (message.topic.includes('sensor/hardness/clegg')) {
				const messageValue = message.message.payload.value;
				setFilteredData(messageValue.toPrecision(4));
			}
		} catch (err) {
			console.log(err);
		}
	};

	React.useEffect(() => {
		filteredValue(message);
		if (data === null ) {
			try {
				if (message.topic.includes('sensor/hardness_live/clegg')) {
					const ts: string = message.message.timestamp.toString();
					const timestamp = Moment.unix(Number(ts.substr(0,ts.indexOf('.'))));
					const temp = [
						{
							'time': timestamp.format('HH:mm:ss'),
							'Hardness': (message.message.payload.value).toFixed(2)
						}
					];
					setData(temp);
				}
			} catch (e) {
				console.log(e);
			}
		} else {
		  plotData(message);
		}
		// eslint-disable-next-line
    }, [message]);

	return (
		<Box className={importedClasses.chart}>
			{(data.length <= 1)?
				'WAITING FOR HARDNESS DATA':
				<>
					{(filteredData !== null) ?
						<Typography variant='subtitle2' style={{ position: 'absolute', marginTop: '0px', right: '2em', top: '0px' }}>Last measured value: {filteredData}gravs</Typography> : <></>}
					<div style={{ height: 'calc(100% - 15px)', marginTop: '15px', width: '100%'}}>
						<ResponsiveContainer width='100%' height='100%'>
							<LineChart data={data}  margin={{ top: 5, right: 30, left: 20, bottom: 5 }} >
								<XAxis dataKey="time" tick={{fontSize: '10'}} label={{ value: 'Timestamp', position: 'insideBottom', offset: 0}}/>
								<YAxis label={{ value: 'Hardness gravs', angle: -90, position: 'insideBottomLeft' , offset: 10}} domain={[0, 100]} ticks={[0, 20, 30, 50, 100]}/>
								<CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
								<Line type="monotone" dataKey="Hardness" stroke="darkblue" isAnimationActive={false}/>
								{/* <Legend verticalAlign="top" height={30} iconSize={0}/> */}
							</LineChart>
						</ResponsiveContainer>
					</div>
				</>}
		</Box>
	);
};