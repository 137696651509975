import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
// import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import { Button } from '../../components/site/buttons/Button';
import * as robots from '../../helpers/robot/robot';
import { Indicator } from './Indicator';

interface heartbeat {
    serialId: string;
    timer: number;
    isOnline: boolean;
}
interface cardProps {
	robot: robots.Robot;
	heartbeats: heartbeat[];
	dash: string;
}

const useStyles = makeStyles((theme) =>
	createStyles({
		root: { display: 'flex', borderRadius: '10px', padding: '10px', width: '450px' },
		details: { display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' },
		content: { flex: '1 0 auto', display: 'block' },
		cover: { minWidth: '180px', maxHeight: '130px', borderRadius: '10px' },
		controls: { display: 'flex', alignItems: 'center', width: '70%', justifyContent: 'space-between' }
	})
);

export const LightboxCard: React.FC<cardProps> = ({ robot, heartbeats, dash }) => {
	const classes = useStyles();

	function getStatus (robot: robots.Robot, heartbeats: heartbeat[]) {
		return heartbeats.find((heartbeat) => heartbeat.serialId === robot.serialId)?.isOnline || false;
	}

	return (
		<Card className={classes.root}>
			<CardMedia className={classes.cover} image={robot.imageURL} title={robot.name}/>
			<div className={classes.details}>
				<CardContent className={classes.content}>
					<Typography component='h5' variant='h5'>
						{robot.name}
					</Typography>
					<Indicator status={getStatus(robot, heartbeats)} />
				</CardContent>
				<div className={classes.content}>
					{
						<Link to={{ pathname: dash, state: { robot: robot } }} style={{ textDecoration: 'none' }} key='liveview'>
							<Button text='Analysis View' type='standard' size='small'/>
						</Link>
						// !getStatus(robot, heartbeats) ?
						// 	<Button disabled={true} text='Analysis View' type='standard' size='small'/>
						// 	:
						// 	<>
						// 		<Link to={{ pathname: dash, state: { robot: robot } }} style={{ textDecoration: 'none' }} key='liveview'>
						// 			<Button text='Analysis View' type='standard' size='small'/>
						// 		</Link>
						// 	</>
					}
				</div>
			</div>
		</Card>
	);
};
