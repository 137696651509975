import * as React from 'react';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Button, Card } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { CSSProperties } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import * as robot from '../../helpers/robot/robot';
import * as EnanoButton from '../site/buttons/Button';

interface StepProps {
    currentStep: number;
    lastStep: number;
    minStep: number;
    setStep: any;
    positioning: string;
    persistData: any;
    startingStep: number;
	features: Set<string>;
    selectedRobots: Set<robot.Robot>;
    selectedTasks: Set<string>;
	selectedAreas: Set<string>;
    areasTotalSqMeters: number;
	pathsTotalMeters: number;
    waypointsAmount: number;
    selectedDate: Date;
    ROBOT_SELECTION: number;
    PATH_DEFINITION: number;
    WAYPOINTS_SELECTION: number;
    TASK_SELECTION: number;
    SCHEDULING: number;
    selectAllPoints: () => void;
    disablePolygon: () => void;
    setMapCursor: (value: string) => void;
}

export const useStatusBarStyles = makeStyles((theme: Theme) =>
	createStyles({
		stepper: { display: 'flex', borderRadius: '15px', alignItems: 'center', color: '#232D4C', background: 'white', paddingTop: '10px', paddingBottom: '10px' },
		icons: { paddingLeft: '2%', paddingRight: '2%' },
		buttonL: { left: 0 },
		buttonR: { right: 0, color: 'rgba(0, 0, 0, 0.87)' },
		text: { width: '60%' },
		fixedButtonSpace: { width: '20%' }
	})
);

const getStepText = (step: number) => {
	switch (step) {
	case 0:
		return '1. Select one or more Robots';
	case 1:
		return '2. Select working Area';
	case 2:
		return '3. Select waypoints inside the area';
	case 3:
		return '4. Task Selection';
	case 4:
		return '5. Job scheduling';
	default:
		return '';
	}
};

export const Stepper: React.FC<StepProps> = ({ currentStep, setStep, lastStep, minStep, positioning, persistData, startingStep, features, SCHEDULING,
	selectedRobots, areasTotalSqMeters, pathsTotalMeters, waypointsAmount, selectedDate, selectedTasks, selectedAreas, ROBOT_SELECTION, PATH_DEFINITION, WAYPOINTS_SELECTION, TASK_SELECTION, selectAllPoints, disablePolygon, setMapCursor }: StepProps) => {
	const classes = useStatusBarStyles();
	const [fontBack, setFontWeightBack] = React.useState<'normal' | 'bold'>('normal');
	const [fontNext, setFontWeightNext] = React.useState<'normal' | 'bold'>('normal');


	const buttonStyleBack: CSSProperties = {
		backgroundColor: 'transparent',
		fontWeight: fontBack
	};

	const buttonStyleNext: CSSProperties = {
		backgroundColor: 'transparent',
		fontWeight: fontNext
	};

	React.useEffect(() => {
		disableNext();
	}, [currentStep, selectedRobots, areasTotalSqMeters, pathsTotalMeters, waypointsAmount, selectedTasks, selectedDate, selectedAreas, features]);

	const setStepperStep = (step: number) => {
		if (step > 0) {
			//If Possitive, move next.
			if (currentStep < lastStep) {
				setStep(currentStep + 1);
			}
		} else {
			if (currentStep > minStep) {
				setStep(currentStep - 1);
			}
		}
		if (currentStep === PATH_DEFINITION) {
			//disable the polygoon and points from moving and makes draw_point the default mode for step 1(PATH_DEFINITION)
			disablePolygon();
		} else if (currentStep === WAYPOINTS_SELECTION) {
			selectAllPoints();
			setMapCursor('default');
		}
	};

	const disableNext = () => {
		if (currentStep === ROBOT_SELECTION) {
			return !(selectedRobots.size > 0);
		} else if (currentStep === PATH_DEFINITION) {
			return !(areasTotalSqMeters > 0 || pathsTotalMeters > 0);
		} else if (currentStep === WAYPOINTS_SELECTION) {
			return !(waypointsAmount > 0);
		} else if (currentStep === TASK_SELECTION) {
			return !(selectedTasks.size > 0);
		} else if (currentStep === SCHEDULING) {
			return selectedDate.getTime() < new Date().getTime();
		} else {
			return true;
		}
	};

	const backButtonHandler = () => {
		setStepperStep(-1);
		if (currentStep === WAYPOINTS_SELECTION) {
			disablePolygon();
		}
	};

	return (
		<div className={positioning}>
			<Card className={classes.stepper}>
				{ currentStep !== lastStep ?
					<>
						{
							currentStep !== startingStep ?
								<div className={classes.fixedButtonSpace}>
									<Button style={buttonStyleBack} className={classes.buttonL} onClick={backButtonHandler}
										onMouseLeave={() => setFontWeightBack('normal')} onMouseEnter={() => setFontWeightBack('bold')}>
										<NavigateBeforeIcon className={classes.icons}/>
                                            Back
									</Button>

								</div>
								:
								<div className={classes.fixedButtonSpace}>
									<Button style={buttonStyleBack} className={classes.buttonL} disabled={true}/>
								</div>
						}
						<div className={classes.text}>{getStepText(currentStep)}</div>
						<div className={classes.fixedButtonSpace}>
							<Button style={buttonStyleNext} className={classes.buttonR} disabled={disableNext()}
								onMouseLeave={() => setFontWeightNext('normal')} onMouseEnter={() => setFontWeightNext('bold')} onClick={() => {
									setStepperStep(1);
								}}>
                                        Next
								<NavigateNextIcon className={classes.icons}/>
							</Button>
						</div>
					</>
					:
					<>
						<div className={classes.fixedButtonSpace}>
							<Button style={buttonStyleBack} onClick={() => {
								setStepperStep(-1);
							}} onMouseLeave={() => setFontWeightBack('normal')} onMouseEnter={() => setFontWeightBack('bold')}
							><NavigateBeforeIcon className={classes.icons} />
                                    Back
							</Button>
						</div>
						<div className={classes.text}>{getStepText(currentStep)}</div>
						<div className={classes.fixedButtonSpace}>
							<EnanoButton.Button text='Save' type='confirm' size='small' onClick={persistData}/>
						</div>
					</>
				}
			</Card>
		</div>
	);
};