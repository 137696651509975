import React, { useEffect } from 'react';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import CheckIcon from '@mui/icons-material/Check';
import { Fab, Slider, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import mapboxgl, { GeoJSONSourceRaw } from 'mapbox-gl';
import Moment from 'moment';
import { DrawingNotch } from '../components/newjob/DrawingNotch';
import { getCenter, getMapCanvas, getMapToken } from '../helpers/map/map';

// Ignoring mapbox-gl-draw typos until they expose @types oficially for typescript.
// @ts-ignore
import * as sensors from '../helpers/sensors/sensors';

interface Mark {
	value: number;
	label: string;
}

const useStyles = makeStyles((theme: Theme) => {
	return createStyles({
		box: { width: 'calc(100vw - 70px)' },
		slider: { width: 'calc(100vw - 70px)' },
		map: { height: 'calc(60vh - 70px)', outline: 'none', borderBottomRightRadius: '15px', borderBottomLeftRadius: '15px' },
		root: { width: '80%', marginLeft: '10%' },
		button: { marginRight: theme.spacing(1) },
		canvasOutline: { outline: 'none' },
		margins: { left: '15px', right: '15px', width: '85vw', justifyContent: 'center', top: '50px' },
		sensors: { width: '14%', position: 'absolute', opacity: '80%', right: '2%', marginTop: '2%', zIndex: 2 },
		sensorButton: { background: '#4CAEF6', color: 'white', alignItems: 'center', margin: theme.spacing(1), width: '100%' }
	});
}
);

let mapRenderer: mapboxgl.Map;
const draw = new MapboxDraw({ displayControlsDefault: false, userProperties: true });
const TOKEN = getMapToken() ;

export const ReportsHeatmap: React.FC = () => {
	const classes = useStyles();
	const [place, setPlace] = React.useState<any>();

	const [dateType, setDateType] = React.useState(new Map<Date, string[]>());
	const [sliderDate, setSliderDate] = React.useState<Date>();
	const [marks, setMarks] = React.useState<Mark[]>([]);
	const [valuesToDate, setValuesToDate] = React.useState<Map<any, Date>>();

	//On true disable the buttons.
	const [ndvi, setNdvi] = React.useState<boolean>(true);
	const [moisture, setMoisture] = React.useState<boolean>(true);
	const [hardness, setHardness] = React.useState<boolean>(true);
	const [slider, setSlider] = React.useState<boolean>(true);

	const [selectedSensor, setSelectedSensor] = React.useState<'ndvi' | 'moisture' | 'hardness' | undefined>();

	//Steps constants
	const MAP_SELECTION = 0;
	const NONE = -1;

	//Initial useEffect, only rendered once.
	useEffect(() => {

		mapboxgl.accessToken = TOKEN;
		mapRenderer = new mapboxgl.Map({
			container: 'map',
			style: 'mapbox://styles/mapbox/satellite-v9',
			center: getCenter(),
			zoom: 4, // Initialized in 4, and then animate to 17.
			maxZoom: 550,
			minZoom: 4
		});

		mapRenderer.addControl(draw);

		mapRenderer.zoomTo(17, { duration: 2000 });

		mapRenderer.addControl(
			new mapboxgl.GeolocateControl({
				positionOptions: {
					enableHighAccuracy: true
				},
				trackUserLocation: true
			})
		);

		//If the map changes the view then request for dates and sensors types
		mapRenderer.on('moveend', () => {
			sensors.getSensorsTypePerDate(getMapCanvas(mapRenderer)).then((ans: sensors.SensorTypes[]) => {
				const mapDateType = new Map<Date, string[]>();
				ans.forEach((each: sensors.SensorTypes) => {
					mapDateType.set(each.date, each.types);
				});
				setDateType(mapDateType);

				if (mapDateType.size === 0) {
					setSlider(true);
				} else {
					setSlider(false);
				}
			});
		});

		//This is only for dev purposes, deleting watermars/trademarks/links from mapbox
		document.getElementsByClassName('mapboxgl-control-container')[0].remove();
	}, []);

	useEffect(() => {
		if (place) {
			if (['poi', 'address', 'postcode', 'neighborhood'].includes(place.place_type[0])) {
				mapRenderer.flyTo({ center: place.center, zoom: 17 });
			} else {
				mapRenderer.fitBounds(place.bbox);
			}
		}
	}, [place]);

	useEffect(() => {
		toMarks();
	}, [dateType]);

	useEffect(() => {
		updateSensorButtons();
	}, [sliderDate]);

	const updateSensorButtons = () => {
		clean();
		if (sliderDate) {
			dateType.get(sliderDate)?.forEach((type: string) => {
				if (type === 'ndvi') {
					setNdvi(false);
				} else if (type === 'hardness') {
					setHardness(false);
				} else if (type === 'moisture') {
					setMoisture(false);
				}
			});
		}
	};

	const clean = () => {
		setNdvi(true);
		setHardness(true);
		setMoisture(true);
		setSelectedSensor(undefined);
	};

	const removeSourceAndLayer = () => {
		try {
			//Order is important, first remove layer. Then source.
			if (mapRenderer.getLayer('sensor-heat')) {
				mapRenderer.removeLayer('sensor-heat');
				if (mapRenderer.getSource('sensor')) {
					mapRenderer.removeSource('sensor');
				}
			}
		} catch (ex) {
			//Do Nothing
		}
	};

	const toMarks = () => {
		const marks: Mark[] = [];
		let lastDate = new Date();
		const map: Map<number, Date> = new Map();

		let order = 90;
		dateType.forEach((value: string[], key: Date) => {
			if (order !== 90) {
				if (new Date(lastDate).getMonth() > new Date(key).getMonth()) order = order - 12; //8 Arbitrary separation between dates
			}
			marks.push({
				value: order,
				label: Moment(key, 'DD/MM/YY').format('DD-MM-YY')
			});

			map.set(order, key);
			lastDate = new Date(key);
			order = order - 8; //8 Arbitrary separation between dates
		});

		setValuesToDate(map);
		setSliderDate(map.get(90));
		setMarks(marks);
	};

	const onSelectedSensor = (type: 'ndvi' | 'moisture' | 'hardness') => {
		removeSourceAndLayer();
		setSelectedSensor(type);
		const MMDDYYOnly = Moment(sliderDate, 'DD/MM/YY').format('MM-DD-YY');

		sensors.getDataPerTypeAndDate(MMDDYYOnly, type, getMapCanvas(mapRenderer)).then((ans: any) => {
			const measurements: any[] = [];

			ans.forEach((point: any) => {
				measurements.push({
					type: 'Feature',
					properties: { value: parseFloat(JSON.parse(point.payload).value) },
					geometry: {
						type: 'Point',
						coordinates: point.point.coordinates
					}
				});
			});

			const data: GeoJSONSourceRaw = {
				type: 'geojson',
				data: {
					'type': 'FeatureCollection',
					'features': measurements
				}
			};

			mapRenderer.addSource('sensor', data);

			mapRenderer.addLayer({
				id: 'sensor-heat',
				source: 'sensor',
				type: 'heatmap',
				paint: {
					'heatmap-radius': 30,
					'heatmap-color': [
						'interpolate',
						['linear'],
						['heatmap-density'],
						0,
						'hsla(0, 97%, 51%, 0)',
						0.1,
						'hsl(51, 73%, 57%)',
						0.18,
						'hsl(115, 100%, 50%)',
						0.23,
						'hsl(115, 100%, 25%)',
						0.3,
						'hsl(115, 100%, 10%)',
						1,
						'hsl(187, 100%, 50%)'
					],
					'heatmap-weight': {
						property: 'value',
						type: 'categorical',
						stops: [
							[0, 10],
							[10, 18],
							[18, 21],
							[21, 23],
							[23, 30],
							[30, 100]
						]
					},
					'heatmap-intensity': [
						'interpolate',
						['linear'],
						['zoom'],
						0,
						0.1,
						10,
						0.8,
						22,
						2
					]
				}
			});
		});
	};

	return (
		<>
			<DrawingNotch currentStep={MAP_SELECTION} lastStep={MAP_SELECTION + 1} PATH_DEFINITION={NONE} WAYPOINTS_SELECTION={NONE}
				ROBOT_SELECTION={NONE} TASK_SELECTION={NONE} SCHEDULING={NONE} MAP_SELECTION={MAP_SELECTION}
				deleteAll={() => {}} setCurrentStep={MAP_SELECTION} selectAll={() => {}} deselectAll={() => {}} TOKEN={TOKEN} setCenter={setPlace} setIsDrawWaypointMode={(value: boolean) => {}}
				setMapCursor={(value: string) => {}} mapboxDraw={() => {}}/>

			<div className={classes.sensors}>
				<Fab variant="extended" disabled={ndvi} className={classes.sensorButton} onClick={ (event) => {
					onSelectedSensor('ndvi');
				}}>NDVI { selectedSensor === 'ndvi' ? <CheckIcon style={{ marginLeft: '10px' }}/> : <></>}</Fab>
				<Fab variant="extended" disabled={moisture} className={classes.sensorButton} onClick={ (event) => {
					onSelectedSensor('moisture');
				}}>Moisture { selectedSensor === 'moisture' ? <CheckIcon style={{ marginLeft: '10px' }}/> : <></>}</Fab>
				<Fab variant="extended" disabled={hardness} className={classes.sensorButton} onClick={ (event) => {
					onSelectedSensor('hardness');
				}}>Hardness { selectedSensor === 'hardness' ? <CheckIcon style={{ marginLeft: '10px' }}/> : <></>}</Fab>
			</div>

			<div className={classes.box}>
				<div id='map' className={classes.map}/>
			</div>

			<Typography style={{ left: 0, paddingTop: '20px', fontWeight: 600 }}>Dates with available data</Typography>
			{ (dateType.size <= 0) ?
				<Typography style={{ left: 0, paddingTop: '20px' }}>No data available for this area.</Typography>
				:
				<div className={classes.slider}>
					<Slider className={classes.margins} disabled={slider} track={false} defaultValue={90} step={null} marks={marks} onChange={(ev, val) => {
						removeSourceAndLayer(); setSliderDate(valuesToDate!.get(val));
					}} />
				</div>
			}
		</>
	);
};
