import { MqttClient } from 'mqtt';

export async function getStream (connection: RTCPeerConnection, client: MqttClient, streams: any, setStreams: any, setVideoLoading: any, robotID: string, streamID: string) {
	console.log('get Stream');
	let status = 'none';
	let finalStream : MediaStream;
	client.on('message', function (topic, message) {
		const mac = topic.split('/')[1];
		const stream = topic.split('/')[2];
		if (!message.toString().includes('start') && mac === robotID && stream===streamID) {
			try {
				const json = JSON.parse(message.toString());
				if (status === 'none' && json.data) {
					if (json.type === 'offer' && json.origin === 'robot') {
						setOffer(json.data, connection);
						status = 'candidate';
					}
				} else if (status === 'candidate') {
					if (json.type === 'candidate' && json.origin === 'robot') {
						setCandidate(json.data, connection);
					} else if (json.type === 'finish_candidates') {
						connection.createAnswer().then((answer) => {
							connection.setLocalDescription(answer);
							status = 'answer';
							const ans: any = {};
							ans.origin = 'webUser';
							ans.type = 'answer';
							ans.data = answer;
							client.publish(topic, JSON.stringify(ans), { qos: 2 });
							console.log('Answer sent.');
							setVideoLoading(false);
						});
						streams[parseInt(streamID)] = finalStream;
						const newStreamArr = [...streams];
						setStreams(newStreamArr);
					}
				}
			} catch (error) {
				console.log(`Failed to log message: ${error}`);
			}
		}
	});

	connection.onicecandidate = (event) => {
		if (event.candidate) {
			const ans: any = {};
			ans.origin = 'webUser';
			ans.type = 'candidate';
			ans.data = event.candidate;
			client.publish('/'+ robotID +'/'+streamID + '/webrtc', JSON.stringify(ans), { qos: 2 });
		}
	};

	connection.ontrack = (event) => {
		finalStream = event.streams[0];
	};
}

export function setOffer (offer: any, connection: RTCPeerConnection) {
	connection.setRemoteDescription(new RTCSessionDescription(offer));
	console.log('Recieved WebRTC offer.');
}

export function setCandidate (candidate: any, connection: RTCPeerConnection) {
	connection.addIceCandidate(new RTCIceCandidate(candidate));
	console.log('Recieved WebRTC candidate.');
}
