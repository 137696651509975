import * as React from 'react';
import { Divider , Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Theme } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import * as robot from '../../helpers/robot/robot';

interface TSListProps {
    robot: robot.Robot;
    setSelectedTasks: any;
    selectedTasks: Set<string>;
    selectedLabels: Set<string>;
    selectedWP: number;
}

interface allTasksList {
    [Odometer: string]: boolean;
    Accelerometer: boolean;
    Gyroscope: boolean;
    'Motor Right Speed': boolean;
    'Motor Left Speed': boolean;
    Speed: boolean;
    Moisture: boolean;
    NDVI: boolean;
    Hardness: boolean;
    Penetration: boolean;
    Temperature: boolean;
    Load: boolean;
    Wait: boolean;
    Compaction: boolean;
}
// Remove penetration once it is removed from everywhere else


export const listStyles = makeStyles((theme: Theme) =>
	createStyles({
		scrollable: { maxHeight: '300px', overflow: 'auto' },
		div: { background: 'white', borderRadius: '15px', overflow: 'auto', marginTop: '3%' },
		tasks: { paddingLeft: '10%' },
		selected: {
			backgroundColor: 'green'
		},
		notSelected: {
			backgroundColor: 'red'
		},
		toggleBase: {
			'&$checked': {
			  color: 'green', // color of the slider when checked
			  '& + $track': {
					toggleBase: {
						'&$checked': {
					  color: 'green', // color of the slider when checked
					  '& + $track': {
								backgroundColor: 'green' // background color of the track when checked
					  }
						},
						'&$disabled': {
					  color: 'red', // color of the slider when not checked
					  '& + $track': {
								backgroundColor: 'red' // background color of the track when not checked
					  }
						}
				  },
				  checked: {},
				  disabled: {},
				  track: {},	backgroundColor: 'green' // background color of the track when checked
			  }
			},
			'&$disabled': {
			  color: 'red', // color of the slider when not checked
			  '& + $track': {
					backgroundColor: 'red' // background color of the track when not checked
			  }
			}
		  },
		  checked: {},
		  disabled: {},
		  track: {}
	})
);


export const TaskSelectionList: React.FC<TSListProps> = ({ robot, setSelectedTasks, selectedTasks, selectedWP, selectedLabels }: TSListProps) => {
	const classes = listStyles();
	const allDefaultTasks = { Odometer: false, Accelerometer: false, Gyroscope: false, 'Motor Right Speed': false, 'Motor Left Speed': false, Speed: false,
		Moisture: false, NDVI: false, Hardness: false, Penetration: false, Temperature: false, Load: false, Wait: false, Compaction: false,
		'Surface temperature': false, 'Photo analysis': false, Spectrum: false};
	const [itemCheck, setItemCheck] = React.useState<allTasksList>(allDefaultTasks);
	const [initialTasks, setInitialTasks] = React.useState(new Set<string>());

	React.useEffect(() => {
		if (robot !== undefined && selectedWP > 0) {
			// Initialize the buffer with the current selected tasks
			setInitialTasks(new Set(selectedTasks));

			// Clear the selectedTasks as the switches are initially off
			setSelectedTasks(new Set());

			// Reset itemCheck state to all switches off
			setItemCheck(allDefaultTasks);
		}
	}, [robot, selectedWP]); // Depends on robot and selectedWP

	React.useEffect(() => {
		console.log('Selected Tasks:', Array.from(selectedTasks));
	}, [selectedTasks]);

	React.useEffect(() => {
		console.log('Selected Labels:', Array.from(selectedLabels));
	}, [selectedLabels]);


	const toggleTask = (taskId: string, key: string) => {
		const newSelectedTasks = new Set(selectedTasks);
		const isChecked = !itemCheck[key];

		if (isChecked) {
			newSelectedTasks.add(taskId); // Add task if the switch is turned on
			console.log('key: '+key);
			selectedLabels.add(key); // Add label if the switch is turned on
		} else {
			newSelectedTasks.delete(taskId); // Remove task if the switch is turned off
			selectedLabels.delete(key); // Remove label if the switch is turned off
		}

		setSelectedTasks(newSelectedTasks); // Update the selectedTasks
	};


	const handleCheckChange = (e: any, key: string) => {
		const taskId = JSON.parse(robot.tasks)[key];
		setItemCheck((prevItemCheck) => ({ ...prevItemCheck, [key]: !prevItemCheck[key] }));
		toggleTask(taskId, key);
	};

	return (
		<Grid>
			<div className={classes.div}>
				<h3>Tasks</h3>
				<Divider />
				{
					robot !== undefined && selectedWP > 0 && itemCheck ?
						<List dense={true} className={classes.scrollable}>
							{
								Object.keys(JSON.parse(robot.tasks)).map((key: string) => {
									const isSelected = itemCheck[key];
									return <ListItem key={key}>
										<FormControlLabel
											control={<Switch
												color="default"
												classes={{ switchBase: classes.toggleBase, checked: classes.checked, disabled: classes.disabled, track: classes.track }}
												name={key}
												checked={itemCheck[key]}
												onChange={(e) => handleCheckChange(e, key)} size="small"/>} label={key}/>
									</ListItem>;
								})
							}
						</List>
						:
						<Typography>No robot selected</Typography>
				}
			</div>
		</Grid>
	);
};
