import { GeoJSONObject } from '@turf/turf';
import axios from 'axios';
import { RobotsWaypointTasks } from '../../components/liveview/Map';
import * as config from '../API/config';
import { GeoInfo } from '../map/map';
import { Robot } from '../robot/robot';

let URL_BASE = config.DEV;
if (process.env.REACT_APP_ENV === 'LOCAL') {
	URL_BASE = config.LOCALHOST;
}

export interface Job {
	id: number;
	scheduledTime: Date;
	geoInfo: GeoJSONObject[];
	robots: Robot[];
	state: string;
	completed?: boolean;
	configuration: string;
	jobName: string;
}

interface updateState {
	state: string;
}

interface JobByIdConfiguration {
	scheduledDate: string;
	geoInfo: GeoInfo[];
	robots: RobotsWaypointTasks[];
	state: string;
	robotsIDs: number[];
}

export async function saveJob (id: number, job: string) {
	return await axios.post(URL_BASE + 'jobs/' + id, job, { withCredentials: true });
}

export async function getJobs (userId: number) {
	let jobs: Job[] = [];
	try {
		await axios.get(URL_BASE + 'jobs/' + userId, { withCredentials: true })
			.then((response) => {
				if (response.status === 200) jobs = [...response.data];
			});
	} catch (e) {
		//See what to do
	}
	return jobs;
}

export async function getJobById (jobId: number) {
	let currentJob: JobByIdConfiguration = {
		scheduledDate: '',
		geoInfo: [],
		robots: [],
		state: '',
		robotsIDs: []
	};
	try {
		await axios.get(URL_BASE + 'job/' + jobId, { withCredentials: true })
			.then((response) => {
				if (response.status === 200) {
					const jobInfo: JobByIdConfiguration = JSON.parse(response.data.configuration);
					currentJob = jobInfo;
				}
			});
	} catch (e) {
		console.log(e);
	}
	return currentJob;

}

export async function updateJobState (jobId: number, updateState: updateState) {
	try {
		await axios.patch(URL_BASE + 'jobs/' + jobId + '/updateState', updateState, { withCredentials: true })
			.then((response) => {
				if (response.status === 204) {
					console.log('Successfully cancelled');
				}
			});
	} catch (e) {
		console.log(e);
	}

}