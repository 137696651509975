/* builtin libraries */
/* External libraries */
/* Components */
/* Parents */
/* silblings*/
import * as React from 'react';
import { ThemeProvider, StyledEngineProvider, Theme, createTheme } from '@mui/material/styles';
import { DefaultTheme } from '@mui/styles';
import axios from 'axios';
import dotenv from 'dotenv';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ReportResult } from './components/areaReports/ReportResult';
import AdminRoute from './components/routes/AdminRoute';
import DefaultRoute from './components/routes/DefaultRoute';
import LoginRoute from './components/routes/LoginRoute';
import ProtectedRoute from './components/routes/ProtectedRoute';
import UserContext from './context/UserContext';
import useUserContext from './context/UserHook';
import { isUserAdmin, isUserDefault, isUserLightbox } from './context/UserUtil';
import * as config from './helpers/API/config';
import './style/App.css';
import { getUserByToken } from './helpers/user/user';
import { AreaReports } from './views/AreaReports';
import { Backoffice } from './views/Backoffice';
import { GeoAnalytics } from './views/GeoAnalytics';
import { ImageAnalysis } from './views/ImageAnalysis';
import { Jobs } from './views/Jobs';
import { Liveview } from './views/Liveview';
import { ForgotPassword } from './views/Login/ForgotPassword';
import { Login } from './views/Login/Login';
import { ResetPassword } from './views/Login/ResetPassword';
import { ManualControl } from './views/ManualControl';
import { NewJob } from './views/NewJob';
import { ReportsHeatmap } from './views/ReportsHeatmap';
import { Robots } from './views/Robots';
import { Sandbox } from './views/Sandbox';


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const App: React.FunctionComponent = () => {
	const theme: DefaultTheme = createTheme({
		palette: {
		  primary: {
				main: 'rgba(0, 0,0,0.87)',
				contrastText: '#fff'
			}
		}
	});
	// initialize configuration
	dotenv.config();

	const User = useUserContext();

	const [isLoading, setIsLoading] = React.useState(true);

	async function isAuthenticated () {
		if (getCookie('session_cookie') !== null) {
			await axios
				.get(
					process.env.REACT_APP_ENV === 'LOCAL'
						? config.LOCALHOST + 'isAuthenticated'
						: config.DEV + 'isAuthenticated',
					{ withCredentials: true }
				)
				.then((response) => {
					if (response.status === 200) {
						return getUserByToken();
					} else {
						User.setAuth(false);
						setIsLoading(false);
					}
				})
				.then((response) => {
					User.setId(response.id);
					User.setAuth(true);
					if (User.id) {
						console.log(User.id);

						// Temporary Fix for User Type
						if (isUserAdmin(User.id.toString())) {
							User.setUserType('admin');
						}

						if (isUserLightbox(User.id.toString())) {
							User.setUserType('lightbox');
						}

						if (isUserDefault(User.id.toString())) {
							User.setUserType('default');
						}
					}
					setIsLoading(false);
				})
				.catch(() => {
					User.setAuth(false);
					setIsLoading(false);
				});
		} else {
			User.setAuth(false);
			setIsLoading(false);
		}
	}

	React.useEffect(() => {
		setIsLoading(true);
		isAuthenticated();
	}, []);

	// https://stackoverflow.com/questions/5968196/how-do-i-check-if-a-cookie-exists
	const getCookie = (name: string) => {
		const dc = document.cookie;
		const prefix = name + '=';
		let begin = dc.indexOf('; ' + prefix);
		let end = -1;
		if (begin === -1) {
			begin = dc.indexOf(prefix);
			if (begin !== 0) return null;
		} else {
			begin += 2;
			end = document.cookie.indexOf(';', begin);
			if (end === -1) {
				end = dc.length;
			}
		}
		// because unescape has been deprecated, replaced with decodeURI
		// return unescape(dc.substring(begin + prefix.length, end));
		return decodeURI(dc.substring(begin + prefix.length, end));
	};

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<SnackbarProvider maxSnack={3}>
					<div className='App'>
						<UserContext.Provider value = {User}>
							{isLoading?<></>:
								<Router>
									<Switch>
										<LoginRoute exact path = '/login' component={Login}/>
										<LoginRoute exact path = '/forgotpassword' component={ForgotPassword}/>
										<LoginRoute exact path = '/resetpassword/:token/:userId' component={ResetPassword}/>

										<ProtectedRoute exact path = '/liveview/:serialId?' component={Liveview}/>
										<ProtectedRoute exact path = '/control/:serialId?' component={ManualControl}/>
										<ProtectedRoute exact path = '/sandbox' component={Sandbox}/>
										<ProtectedRoute exact path = '/newjob' component={NewJob}/>
										<ProtectedRoute exact path = '/area-reports' component={AreaReports}/>
										<ProtectedRoute exact path = '/geo-analytics' component={GeoAnalytics}/>
										<ProtectedRoute exact path = '/reports-heatmap' component={ReportsHeatmap}/>
										<ProtectedRoute exact path = '/robots' component={Robots}/>
										<ProtectedRoute exact path = '/jobs' component={Jobs}/>
										<ProtectedRoute exact path = '/area-reports/' component={ReportResult} />
										<ProtectedRoute exact path = '/area-reports/results' component={ReportResult} />
										<ProtectedRoute exact path = '/backoffice' component={Backoffice} />

										<ProtectedRoute exact path = '/image-analysis' component={ImageAnalysis} />
										<ProtectedRoute exact path = '/image-analysis/:serialId?' component={ImageAnalysis}/>
										<DefaultRoute/>
									</Switch>
								</Router>
							}
						</UserContext.Provider>
					</div>
				</SnackbarProvider>
			</ThemeProvider>
		</StyledEngineProvider>

	);
};

export default App;
