import * as React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import { Fab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

interface buttonProps {
    text: string;
    type: 'standard' | 'confirm';
    size?: 'medium' | 'small';
    onClick?: any;
    disabled?: boolean;
}

export const Button: React.FC<buttonProps> = ( { text, type, onClick, disabled, size } ) => {
	const useStyles = makeStyles((theme) =>
		createStyles({
			standard: {color: textColor, background: '#4CAEF6', alignItems: 'center', whiteSpace: 'nowrap', transition: 'color 0.5s', height: '30px'},
			confirm: {color: textColor, background: '#00A86B', alignItems: 'center', whiteSpace: 'nowrap', transition: 'color 0.5s'}
		})
	);

	const [textColor, setTextColor] = React.useState('#ffffff');
	const style = useStyles();

	return (
		<Fab variant='extended' size={size ? size : 'medium'} aria-label='add' className={type === 'standard' ? style.standard : style.confirm} disabled={disabled ? disabled : false}
			onMouseEnter={() => setTextColor('#6f6f6f')} onMouseLeave={() => setTextColor('#ffffff')} onClick={onClick ? onClick : null}>
			{
				type === 'confirm' ?
					<SaveIcon/>
					: <></>
			}
			{text}
		</Fab>
	);
};