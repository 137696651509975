import * as React from 'react';
import { FC, useState, useEffect, useContext } from 'react';
import { Button, Grid, TextField, Typography, Select, MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import UserContext from '../../context/UserContext';
import { getAllUsers, postUserAreaWithSensorDataIds, User } from '../../helpers/user/user';
import generalStyles from '../../style/styles';

interface NewUserAreaProps {
	selectedSensors: string[];
	onClear: () => void;
}

export const NewUserArea: FC<NewUserAreaProps> = ({ selectedSensors, onClear }) => {
	const importedclasses = generalStyles();
	const contextUser = useContext(UserContext);
	const { enqueueSnackbar } = useSnackbar();

	const [areaName, setAreaName] = useState<string>('');
	const [areaPoints, setAreaPoints] = useState<string>(selectedSensors.join(','));
	const [areaKind, setAreaKind] = useState<string | undefined>('football'); // Default value is 'football'
	const [selectedUser, setSelectedUser] = useState<User | undefined>();
	const [availableUsers, setAvailableUsers] = useState<User[]>([]);

	useEffect(() => {
		setUserList();
	}, []);

	useEffect(() => {
		if (contextUser && contextUser.id !== 1) {
			setSelectedUser(contextUser);
		}
		setUserList();
	}, [contextUser]);

	useEffect(() => {
		setAreaPoints(selectedSensors.join(','));
	}, [selectedSensors]);

	const handleSave = () => {
		if (areaName && areaName !== '' && areaPoints && areaPoints !== '') {
			if (selectedUser && selectedUser.id) {
				postUserAreaWithSensorDataIds(areaName, areaPoints, areaKind, selectedUser.id?.toString())
					.then(() => {
						enqueueSnackbar('New user defined area has been saved.', { variant: 'success', autoHideDuration: 5000 });
						cleanInputs();
					})
					.catch((reason) => {
						enqueueSnackbar(
							'Oops, something went wrong. Area could not be saved. Reason: ' + reason,
							{ variant: 'error', autoHideDuration: 10000 }
						);
					});
			} else {
				enqueueSnackbar('No user selected. Please select a user and try again.', {variant: 'error',autoHideDuration: 10000});
			}
		}
	};

	const handleCancel = () => {
		cleanInputs();
	};

	const handleClear = () => {
		setAreaPoints('');
		onClear();
	};

	const cleanInputs = () => {
		setAreaName('');
		setAreaPoints('');
		setAreaKind(undefined);
		//setUserList();
	};

	const setUserList = () => {
		getAllUsers().then((users: User[]) => {
			console.log('user id: '+ contextUser.id);
			if (contextUser.id === 1) {
				setAvailableUsers(users);
				setSelectedUser(users.find((user: User) => user.id === contextUser.id) || undefined);
			} else {
				setAvailableUsers([contextUser]);
				setSelectedUser(contextUser);
				console.log('else: '+ contextUser.id);
			}
		});
	};

	return (
		<>
			<Typography>
				Choose name for the user defined areas and insert comma separated sensor data ids for the vertices of the area
			</Typography>
			<div className={importedclasses.separator} />
			<Grid container spacing={5}>
				<Grid item xs={3}>
					<TextField
						id="areaName"
						label="Area name"
						variant="outlined"
						margin="none"
						fullWidth
						InputLabelProps={{ shrink: true }}
						value={areaName}
						onChange={(event) => setAreaName(event.target.value)}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						id="pointsIds"
						label="Points ids"
						variant="outlined"
						margin="none"
						fullWidth
						InputLabelProps={{ shrink: true }}
						value={areaPoints}
						InputProps={{ readOnly: true }}
					/>
				</Grid>
				<Grid item xs={3}>
					<Select
						id="areaKind"
						value={areaKind || ''}
						onChange={(event) => setAreaKind(event.target.value as string)}
						label="Kind (optional)"
						variant="outlined"
						margin="none"
						fullWidth
					>
						<MenuItem value="football">Football</MenuItem>
						<MenuItem value="other">Other</MenuItem>
					</Select>
				</Grid>
				{contextUser.id === 1 && (
					<Grid item xs={3}>
						<Select
							value={selectedUser && selectedUser.id ? selectedUser.id.toString() : ''}
							onChange={(event) => {
								const userId = Number(event.target.value);
								const user = availableUsers.find((user) => user.id === userId);
								setSelectedUser(user || undefined);
							}}
							label="User"
							fullWidth
						>
							{availableUsers.map((user) => (
								<MenuItem key={user.id} value={user.id}>
									{user.email}
								</MenuItem>
							))}
						</Select>
					</Grid>
				)}
			</Grid>
			<div className={importedclasses.separator} />
			<Grid container justifyContent="center" spacing={2}>
				<Grid item>
					<Button variant="contained" onClick={handleSave}>
						Save
					</Button>
				</Grid>
				<Grid item>
					<Button variant="contained" onClick={handleClear}>
						Clear
					</Button>
				</Grid>
			</Grid>

		</>
	);
};
