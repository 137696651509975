import * as React from 'react';
import { useContext, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { MqttClient } from 'mqtt';
import { LightboxCard } from '../components/robots/lightboxCard';
import { RobotCard } from '../components/robots/robotCard';
import UserContext from '../context/UserContext';

// import { connectMQTT } from '../helpers/connect/connect';
// import { connectURL, connectOptions, createHeartbeatConfig } from '../helpers/connect/connectConfig';
import { connectClient, getHeartbeats, heartbeat, updateHeartbeatMessage, updateHeartbeatTime } from '../helpers/robot/heartbeat';
import * as robots from '../helpers/robot/robot';

const useStyles = makeStyles(() =>
	createStyles({
		title: { display: 'flex', alignItem: 'left' }
	})
);

const heartbeatsInit : heartbeat[] = [];
const temp: robots.Robot[] = [];
let heartbeatClient: MqttClient;

export const Robots: React.FC = () => {
	const style = useStyles();
	const [robotList, setRobotList] = React.useState(temp);
	const [heartbeats, setHeartbeats] = React.useState(heartbeatsInit);
	const [message, setMessage] = React.useState({});
	const [, setClient] = React.useState(heartbeatClient);
	const [seconds, setSeconds] = React.useState(0);
	const contextUser = useContext(UserContext);

	useEffect(() => {
		console.log('User context: '+contextUser.id);
	}, [contextUser]);


	React.useEffect(() => {
		const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
		const userId = parseInt(localStorage.getItem('userId') || '0', 10);
		const userType = localStorage.getItem('userType') || 'default';

		// Update User context
		contextUser.setAuth(isAuthenticated);
		contextUser.setId(userId);
		contextUser.setUserType(userType);

		console.log('Loaded User context:', contextUser);
		robots.getRobots().then((list) => {
			setRobotList(list);
		});
	}, []);

	React.useEffect(() => {
		const heartbeatsTemp = getHeartbeats(robotList);
		if (heartbeatsTemp.length >0) {
			const topics = heartbeatsTemp.map((heartbeat: heartbeat) => {
				return heartbeat.serialId;
			});
			connectClient(topics, setMessage).then((client) => {
				setClient(client);
			});
			setHeartbeats(heartbeatsTemp);
		}
	}, [robotList]);

	React.useEffect(() => {
		updateHeartbeatMessage(heartbeats, message, setHeartbeats);
	}, [message]);

	React.useEffect(() => {
		setTimeout(() => {
			setSeconds(seconds + 1);
			updateHeartbeatTime(heartbeats, message, setHeartbeats);
		}, 1000);
	}, [seconds]);

	return (
		<div style={{ margin: '1%' }}>
			<Typography component='h5' variant='h5' className={style.title}> Robots </Typography>
			<Grid container spacing={2}>
				{robotList.map((robot) => {
					return (
						<Grid item key={robot.serialId}>
							{robot.model === 'lightboxPro' ? (
								<LightboxCard robot={robot} dash={`/image-analysis/${robot.serialId}`} heartbeats={heartbeats} />
							) : (
								<RobotCard robot={robot} dash={`/liveview/${robot.serialId}`} heartbeats={heartbeats} />
							)}
						</Grid>
					);
				})}
			</Grid>
		</div>
	);
};
