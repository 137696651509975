import * as React from 'react';
import { ChangeEvent, useEffect } from 'react';
import { Checkbox, Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Feature, GeoJsonProperties, Geometry } from 'geojson';
import { UserArea, getUserAreas } from '../../helpers/user/user';



interface AreasListProps {
    currentStep: number;
    positioning: string;
    showOnStep: number;
    mapAreas: () => Feature<Geometry, GeoJsonProperties>[];
    deleteArea: (areaName: string) => void;
    drawArea: (userArea: UserArea) => void;
}

export const listStyles = makeStyles((theme: Theme) =>
	createStyles({
		scrollable: { maxHeight: '300px',  overflow: 'auto' },
		areaListItem: { minHeight: '50px' }
	})
);

export const AreasList: React.FC<AreasListProps> = ( { currentStep, positioning, showOnStep, mapAreas, deleteArea, drawArea }: AreasListProps ) => {
	const classes = listStyles();

	const [userAreas, setUserAreas] = React.useState<UserArea[]>([]);

	// Using a forceUpdate because Set Object does not update the state. It only does the first time.
	const [, forceUpdate] = React.useReducer((x) => x + 1, 0);

	function handleAreaToggle ( e: ChangeEvent<HTMLInputElement> ) {
		if ( e.target.checked ) {
			userAreas.forEach( (userArea) => {
				if ( userArea.name === e.target.id ) {
					drawArea(userArea);
				}
			});
		} else {
			deleteArea(e.target.id);
		}
		forceUpdate();
	}

	function doesAreaExist ( areaName: string ): boolean {
		let exists = false;
		mapAreas().forEach((area: Feature<Geometry, GeoJsonProperties>) => {
			if ( area?.properties?.areaName === areaName ) {
				exists = true;
			}
		});
		return exists;
	}

	function generateItems () {
		return userAreas.sort((a: UserArea, b: UserArea) => {
			const nameA = a.name.toLowerCase();
			const nameB = b.name.toLowerCase();
			if (nameA < nameB) {
				return -1;
			} else if ( nameA > nameB) {
				return 1;
			}
			return 0;
		}).map((userArea, i) => {
			return (
				<ListItem id={userArea.name} key={i} className={classes.areaListItem}>
					<ListItemText primary={userArea.name} />
					<ListItemSecondaryAction>
						<IconButton edge="end" aria-label="check" size="large">
							<Checkbox color="error" id={userArea.name} value={doesAreaExist(userArea.name)} checked={doesAreaExist(userArea.name)} onChange={handleAreaToggle}/>
						</IconButton>
					</ListItemSecondaryAction>
				</ListItem>
			);
		});
	}

	useEffect(() => {
		getUserAreas().then((areas: UserArea[]) => {
			setUserAreas(areas);
		});
	}, []);

	return (
		<>
			{   currentStep === showOnStep ?
				userAreas.length > 0 ?
					<div className={positioning}>
						<h3>Preset areas</h3>
						<Divider />
						<List dense={true} className={classes.scrollable}> { generateItems() } </List>
					</div>
					: <></>
				:<></>
			}
		</>
	);
};
