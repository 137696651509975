import React from 'react';
import { Typography, TextField, TextFieldProps } from '@mui/material';
import Card from '@mui/material/Card';
import Modal from '@mui/material/Modal';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

import { Button } from '../../components/site/buttons/Button';
import UserContext from '../../context/UserContext';
import * as Job from '../../helpers/job/job';
import importedStyles from '../../style/styles';


interface jobCopyModalProps {
	jobCopyModal: boolean;
	setJobCopyModal: (modalState: boolean) => void;
  configuration: string;
  id: string;
}

export const JobCopyModal: React.FC<jobCopyModalProps> = ({ jobCopyModal, setJobCopyModal, configuration, id }) => {
	const importedClasses = importedStyles();
	const User = React.useContext(UserContext);
	const [date, setDate] = React.useState<moment.MomentInput>();

	const handleClose = () => {
		setJobCopyModal(false);
	};

	const getGeoInfo = () => {
		if (JSON.parse(configuration).geoInfo.length) {
			return JSON.parse(configuration).geoInfo.length + ' waypoints';
		} else {
			return 'no waypoints';
		}
	};

	const getRobotInfo = () => {
		if (JSON.parse(configuration).robotsIDs.length) {
			return JSON.parse(configuration).robotsIDs.length + ' robots';
		} else {
			return 'no robots';
		}
	};

	const handleSave = () => {
		const jobObject: any = { ...JSON.parse(configuration) };
		if (date) {
			const saveDate = moment(date);
			saveDate.set('second', 0);
			jobObject.scheduledDate = saveDate;
			jobObject.state = 'pending';

			Job.saveJob(User.id, jobObject).then((res) => {
				handleClose();
				window.location.reload();
			});
		}
	};

	return (
		<div>
			<Modal
				open={jobCopyModal}
				onClose={handleClose}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<Card className={importedClasses.modal}>
					<div className={importedClasses.row}>
						<Typography variant='h5'>Copy of job #{id}</Typography>
						<div className={importedClasses.details}>
							<Typography variant='h6'>Configuration</Typography>
							<Typography variant='subtitle2'>Geo Info: {getGeoInfo()}</Typography>
							<Typography variant='subtitle2'>Robots: {getRobotInfo()}</Typography>
							<Typography variant='subtitle2'>Current user ID: {User.id}</Typography>
						</div>
						<div className = {importedClasses.details}>
							<Typography variant = 'h6'>Pick new date/time</Typography>
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<DateTimePicker
									value={date ? moment(date) : null}
									onChange={(newDate) => newDate ? setDate(newDate) : null}
								/>
							</LocalizationProvider>
						</div>
						<div className={importedClasses.center}>
							<Button text={'Save job'} type={'standard'} onClick={handleSave}/>
						</div>
					</div>
				</Card>
			</Modal>
		</div>
	);
};