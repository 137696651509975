import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import TodayIcon from '@mui/icons-material/Today';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import { Chip } from '@mui/material';
import Fab from '@mui/material/Fab';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import moment from 'moment';

interface ScheduleSidebarProps {
    positioning: string;
    currentDate: Date;
    saveDate: any;
    currentStep: number;
    showOnStep: number;
    chipstyle: any;
}

interface selected {
    manualStart: boolean;
    startWhenReady: boolean;
}

export const useStatusBarStyles = makeStyles((theme: Theme) =>
	createStyles({
		icons: { paddingLeft: '2%', paddingRight: '2%' },
		schedule: { background: '#4CAEF6', color: 'white', alignItems: 'center', margin: theme.spacing(1), width: '100%' },
		extendedIcon: { marginRight: theme.spacing(1) },
		checkIcon: { marginLeft: '2.8rem' },
		colorCheck: { backgroundColor: 'rgb(0,76,153)' },
		chip: { backgroundColor: '#e0e0e0' }
	})
);

export const ScheduleSidebar: React.FC<ScheduleSidebarProps> = ({ positioning, currentDate, saveDate, currentStep, showOnStep, chipstyle }: ScheduleSidebarProps) => {
	const classes = useStatusBarStyles();
	const [open, setOpen] = React.useState(false);
	const [buttonstyle] = React.useState<any>(null);
	const [checkSelected, setCheckSelected] = React.useState<selected>({ manualStart: false, startWhenReady: false });

	const save = (date: moment.Moment | null) => {
		saveDate(date?.toDate());  // converts Moment.js object back to JavaScript Date object
	  };

	const datetimeFormat = (date: Date) => {
		const dateTimeFormat: any = moment(date);
		return dateTimeFormat.format('dddd DD MMMM, hh:mma');
	};

	return (
		<>
			{ currentStep === showOnStep ?
				<div className={positioning}>
					<Fab variant="extended" aria-label="add" className={`${classes.schedule} ${checkSelected.manualStart ? classes.colorCheck : null}`} onClick={() => setCheckSelected({ ...checkSelected, manualStart: !checkSelected.manualStart })}><TouchAppIcon className={classes.extendedIcon} />Manual Start{checkSelected.manualStart ? <CheckIcon className={classes.checkIcon}/> : <></>}</Fab>
					<Fab variant="extended" aria-label="add" className={`${classes.schedule} ${checkSelected.startWhenReady ? classes.colorCheck : null}`} onClick={() => setCheckSelected({ ...checkSelected, startWhenReady: !checkSelected.startWhenReady })}><PlayArrowIcon className={classes.extendedIcon} />Start When Ready{checkSelected.startWhenReady ? <CheckIcon className={classes.checkIcon}/> : <></>}</Fab>
					<Fab variant="extended" style={buttonstyle} aria-label="add" onClick={() => {
						setOpen((isOpen) => !isOpen);
					}} className={`${classes.schedule} ${currentDate ? classes.colorCheck : null}`}><TodayIcon className={classes.extendedIcon} />Schedule <CheckIcon className={classes.checkIcon}/></Fab>
					<Chip className={classes.chip} label={datetimeFormat(currentDate)} />


					{/* DateTimePicker is only visible in the last step. The date preview is not visible, only the modal. */}
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<MobileDateTimePicker
							disablePast={true}
							open={open}
							value={moment(currentDate)}
							onChange={save}
							minutesStep={1}
							onClose={() => setOpen(false)}
						/>
					</LocalizationProvider>
				</div>

				:
				<></>
			}
		</>
	);
};