import * as React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Chip, Card, List, ListItem, ListItemButton, ListItemText, Modal, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import generalStyles from '../../style/styles';


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		areaTitle: { textTransform: 'uppercase', display: 'inline' },
		modal: { overflowY: 'scroll', maxHeight: '80%' }
	})
);

interface ExtendedFilters {
	availableJobs: number[];
	selectedJob?: number;
	setSelectedJob: (selectedJob: number)=> void;
}

export const ExtendedFilters: React.FC<ExtendedFilters> = ({ availableJobs, selectedJob, setSelectedJob }: ExtendedFilters) => {

	const classes = useStyles();
	const importedclasses = generalStyles();
	const [showExtendedFilterModal, setShowExtendedFilterModal] = React.useState<boolean>(false);

	const handleRemoveFilter = () => {
		setSelectedJob(0);
	};

	return (
		<>
			{ availableJobs.length > 1 ?
				<>
					<div style={{ textAlign: 'right', marginRight: '17px' }}>
						{ selectedJob ?
							<>
								<Chip icon={<CancelIcon />}label={'Job ' + selectedJob} onClick={handleRemoveFilter}></Chip>
								<Chip icon={<FilterAltIcon />} label={availableJobs.length + ' jobs this day'} variant='outlined' clickable={true} onClick={() => setShowExtendedFilterModal(true) }/>
							</>
							:
							<Chip icon={<FilterAltOutlinedIcon />} label={availableJobs.length + ' jobs this day'} variant='outlined' clickable={true} onClick={() => setShowExtendedFilterModal(true) }/>
						}
					</div>
					<Modal open={showExtendedFilterModal} onClose={() => setShowExtendedFilterModal(false)}>
						<Card className={importedclasses.modal + ' ' + classes.modal}>
							<Typography className={classes.areaTitle} variant="subtitle1">Select an area</Typography>
							<List>
								{
									availableJobs.sort((a, b) => {
										return a - b;
									}).map((jobId: number, index: number) => {
										return (<ListItem key={index} disablePadding>
											<ListItemButton onClick={() => {
												setSelectedJob(jobId);
												setShowExtendedFilterModal(false);
											}}>
												<ListItemText primary={'Job ' + jobId} />
											</ListItemButton>
										</ListItem>);
									})
								}
							</List>
						</Card>
					</Modal>
				</>
				:
				<></>
			}
		</>
	);
};