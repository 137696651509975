import React from 'react';
import { Button, Card, Modal, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import * as Job from '../../helpers/job/job';
import importedStyles from '../../style/styles';

interface jobAbortModal {
    jobAbortModal: boolean;
    setJobAbortModal: (modalstate:boolean)=> void;
    id: string;
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(1)
		}
	}
}));

export const JobAbortModal: React.FC<jobAbortModal> = ({ jobAbortModal, setJobAbortModal, id }) => {
	const importedclasses = importedStyles();
	const classes = useStyles();
	const handleAbort = () => {
		Job.updateJobState(parseInt(id), { state: 'aborted' }).then((res) => {
			setJobAbortModal(false);
			window.location.reload();
		});
	};
	return (
		<Modal open={jobAbortModal} onClose={() => setJobAbortModal(false)}>
			<Card className={importedclasses.modal}>
				<div>
					<Typography variant={'subtitle1'} >Are you sure you want to abort the job?</Typography>
					<div className={importedclasses.details}>
						<Typography variant='subtitle2'>Job Id: {id}</Typography>
					</div>
					<div className={classes.root}>
						<Button variant={'outlined'} onClick={() => setJobAbortModal(false)}>No, keep running</Button>
						<Button variant={'outlined'} color="secondary" onClick={handleAbort}>Yes, Abort</Button>
					</div>
				</div>
			</Card>
		</Modal>
	);
};
