import * as React from 'react';
import { Box, Card, Container, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import mqtt, { MqttClient } from 'mqtt';
import { Connect } from '../components/sandbox/Connect';
import { ControlLog } from '../components/sandbox/ControlLog';
import { CustomCommand } from '../components/sandbox/CustomCommand';
import { JoystickControl } from '../components/sandbox/JoystickControl';
import { Sensors } from '../components/sandbox/Sensors';
import { Stream } from '../components/sandbox/Stream';
import { VideoStats } from '../components/sandbox/VideoStats';
import { config } from '../helpers/connect/connectConfig';
import { sendMQTTCommand, subscribeMQTT } from '../helpers/mqtt';
import { getStream } from '../helpers/video';

//MUI styling
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		dashboard: { width: '100%' },
		dashComponents: { display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignContent: 'flex-start', padding: '0.25rem' },
		connectCard: { display: 'flex', flexDirection: 'column', margin: '0.25rem', height: '120px', width: '16rem' },
		sensorCard: { display: 'flex', flexDirection: 'column', width: '60rem', height: '120px', margin: '0.25rem' },
		customCard: { width: '16rem', height: '200px', margin: '0.25rem', display: 'flex', alignItems: 'center', justifyContent: 'center' },
		controlCard: { display: 'flex', flexDirection: 'column', width: '46rem', height: '250px', margin: '0.25rem', alignItems: 'center' },
		videoCard: { display: 'flex', flexDirection: 'column', width: '30rem', height: '250px', margin: '0.25rem', alignItems: 'center', justifyContent: 'center' },
		messageCard: { flexDirection: 'row', width: '60rem', height: '200px', margin: '0.25rem' },
		joystick: { width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' },
		statsCard: { width: '14rem', height: '200px', margin: '0.25rem', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }
	})
);

//Establish MQTT connection
const connectURL = 'wss://mqtt.e-nano.io:443';
const connectOptions = {
	port: 443,
	clientId: `enano-client-${Date.now()}`,
	username: 'rayercat',
	password: 'blauWis5670101'
};
const client = mqtt.connect(connectURL, connectOptions);

//Establish WebRTC conection

const configuration = {
	iceServers: [{ urls: 'stun:3.8.20.111:3478' }]
};
const rtcConnection = new RTCPeerConnection(configuration);

//define MQ structure

export const Sandbox: React.FC = () => {
	const classes = useStyles();

	//get user from local storage and user throughout app
	const [user, setUser] = React.useState(localStorage.user);
	React.useEffect(() => {
		setUser(localStorage.user);
	}, []);

	//STATE
	const [online, setOnline] = React.useState(false);
	const [hasFocus, setHasFocus] = React.useState(false);
	const [messageQueue, setMessageQueue] = React.useState([
		{
			timestamp: Date.now(),
			read: true,
			message: 'Hi from e-nano',
			topic: '/robot1/command/movement',
			full: false
		}
	]);
	const [streams, setStreams] = React.useState([new MediaStream()]);
	const [connection, setConnection] = React.useState(rtcConnection);
	const [videoLoading, setVideoLoading] = React.useState(true);
	const [hideStats, setHideStats] = React.useState(false);
	const [connectTimestamp, setConnectTimestamp] = React.useState(
		new Date().getTime()
	);
	const [movementMessage, setMovementMessage] = React.useState({
		timestamp: new Date(Date.now()).toISOString(),
		payload: {
			USERID: user,
			SPEED: 0,
			ANGLE: 0,
			BRAKE: 0,
			SRC: 'WEB'
		}
	});
	const [toggleRemoteControl, setToggleRemoteControl] = React.useState(true);

	//HANDLE MQTT EVENTS
	//Handle connection and subscription

	const topicsObj = {
		'/robot1/command/movement': { qos: 0 },
		'/robot1/command/tool': { qos: 0 },
		'/robot1/config': { qos: 0 },
		'/robot1/sensor/speed/motor_right': { qos: 0 },
		'/robot1/sensor/speed/motor_left': { qos: 0 },
		'/robot1/sensor/imu/T265': { qos: 0 },
		'/robot1/sensor/odometry/T265': { qos: 0 },
		'/robot1/1/webrtc': { qos: 0 }
	};

	function handleConnect () {
		// e.preventDefault();
		client.on('connected', function () {
			console.log('MQTT disconnected');
		});
		console.log('MQTT Connected');
		setOnline(true);
		//provide object in this form:

		subscribeMQTT(topicsObj, client, handleUpdateMQ);
		setConnection(rtcConnection);
		setConnectTimestamp(Date.now());
	}

	function connectStream () {
		client.publish('/robot1/1/webrtc', JSON.stringify({ start: 'start' }), {
			qos: 2
		});
		const connection = new RTCPeerConnection(config);
		getStream(connection, client, streams, setStreams, setVideoLoading, 'robot1', '1');
	}

	//handle disconnect
	function handleDisconnect (client: MqttClient) {
		client.end();
		setStreams([new MediaStream()]);
		setConnection(new RTCPeerConnection());
		setVideoLoading(true);
		setTimeout(function () {
			if (client.disconnected) {
				console.log('MQTT Disconnected');
				setOnline(false);
			}
		}, 500);
	}

	function handleReconnect () {
		handleDisconnect(client);
		setTimeout(function () {
			if (client.disconnected) {
				console.log('MQTT Reconnecting');
				client.reconnect();
				setOnline(true);
			}
		}, 2000);
	}

	//Handle message
	function handleUpdateMQ (message: string, topic: string) {
		let read = true;
		if (topic.includes('/movement') || topic.includes('/customcommand')) {
			read = false;
		}
		try {
			const messageJSON = JSON.parse(message);

			const tempMessage = {
				timestamp: messageJSON.timestamp,
				read,
				message: JSON.stringify(messageJSON.payload),
				topic: topic,
				full: false
			};
			messageQueue.unshift(tempMessage);
			if (messageQueue.length > 10) {
				messageQueue.pop();
			}
			const tempMQ = [...messageQueue];
			setMessageQueue(tempMQ);
		} catch (err) {
			console.log(err);
		}
	}

	//handle remote control toggle
	function handleToggleRemoteControl (e: any) {
		setToggleRemoteControl(!toggleRemoteControl);
		client.publish('/robot1/command/enabled', `{"remoteControl":"${toggleRemoteControl}"}`);
	}

	React.useEffect(() => {
		sendMQTTCommand(movementMessage, client, '/robot1/command/movement');
		// eslint-disable-next-line
	}, [movementMessage]);

	React.useEffect(() => {
		connectStream();
		// eslint-disable-next-line
	}, []);

	return (
		<Container maxWidth='xl'>
			<Box className={classes.dashComponents}>
				<Card className={classes.connectCard}>
					<Connect online={online} handleConnect={handleConnect} handleReconnect={handleReconnect}/>{' '}
				</Card>
				<Card className={classes.sensorCard}>
					<Sensors messages={messageQueue} />
				</Card>
				<Card className={classes.controlCard}>
					<Box className={classes.joystick}>
						<JoystickControl
							movementMessage={movementMessage}
							setMovementMessage={setMovementMessage}
							online={online}	hasFocus={hasFocus}
							toggleRemoteControl={toggleRemoteControl}
							handleToggleRemoteControl={handleToggleRemoteControl}/>
					</Box>
				</Card>
				<Card className={classes.videoCard}>
					<Stream videoLoading={videoLoading} connection={connection} streams={streams} hideStats={hideStats} setHideStats={setHideStats}/>
				</Card>
				<Card className={classes.messageCard}>
					<ControlLog messageQueue={messageQueue} setMessageQueue={setMessageQueue}/>
				</Card>
				<Card className={classes.customCard}>
					<CustomCommand client={client} online={online} user={user} setHasFocus={setHasFocus}/>
				</Card>
				{hideStats ? (
					<Card className={classes.statsCard}>
						<VideoStats connection={connection} connectTimestamp={connectTimestamp}/>
					</Card>
				) : null}
			</Box>
		</Container>
	);
};
