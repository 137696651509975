import React, { useEffect } from 'react';
import Card from '@mui/material/Card';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import * as sensors from '../../helpers/sensors/sensors';


interface AverageBox {
	measurementType: string;
    sensorType: string;
    sensorData: sensors.Sensor[];
	isSelected: boolean;
	isClickable?: boolean;
	unit: string;
	label?: string;
	setSelectedMeasurementType: (newType: string) => void;
	setSelectedSensorType: (newType: string) => void;
	outputFormatter?: (datainput: string) => string;
	getValueFromPayload?: (inputJson: any) => number;
}
const useStyles = makeStyles(() =>
	createStyles({
		selectedCard: { border: '1px #2F5BB5 solid', borderRadius: '12px' },
		error: { fontSize: '0.6em', fontStyle: 'italic', fontWeight: 200, color: '#6f6f6f', textTransform: 'uppercase' }
	})
);
export const AverageBox: React.FC<AverageBox> = ({ measurementType, sensorType, sensorData, isSelected, isClickable=true, unit, label='', setSelectedMeasurementType, setSelectedSensorType, outputFormatter, getValueFromPayload }: AverageBox) => {
	const classes = useStyles();
	const [average, setAverage] = React.useState<number>(0.0);

	useEffect(() => {
		if (sensorData && sensorData.length > 0 ) {
			const filteredData = sensorData.filter((sensorValue) => {
				return sensorValue.type === sensorType;
			});
			const sum = filteredData.reduce((prev, current) => {
				if (getValueFromPayload) {
					return prev + getValueFromPayload(current.payload);
				} else {
					return prev + JSON.parse(current.payload).value;
				}
			},0);
			setAverage(sum / filteredData.length);
		} else {
			setAverage(0.0);
		}
	}, [sensorData]);

	return (
		<Card className={ isSelected ? classes.selectedCard : ''} sx={{ minWidth: 110, minHeight: 55, padding: '8px', border: 'none', backgroundColor: 'transparent' }} variant="outlined"
			onClick={ () => {
				if (isClickable && !isNaN(average) && average !== undefined) {
					setSelectedMeasurementType(measurementType);
					setSelectedSensorType(sensorType);
				}
			}} >
			<div style={{ fontSize: '1.6em', letterSpacing: '1px' }}>
				{	isNaN(average) || average === undefined ?
					<div>{'N/A'}</div>
					:
					outputFormatter !== undefined ?
						<> {parseFloat(outputFormatter(average.toString())).toFixed(2)}{ unit }</>
						:
						<>{average.toFixed(2)}{ unit }</>
				}
			</div>
			<div style={{ fontSize: '0.9em', fontWeight: '300', lineHeight: '16px', color: '#6f6f6f', textTransform: 'capitalize' }}>
				{ label !== '' ?
					label
					:
					sensorType
				}</div>
		</Card>
	);
};
