import React from 'react';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Button } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { Robot } from '../../helpers/robot/robot';
import { RobotClickableList } from './RobotClickableList';
import { TaskSelectionList } from './TaskSelectionList';

interface TSidebarProps {
    showOnStep: number;
    currentStep: number;
    positioning: string;
    jobRobots: Set<Robot>;
    draw: MapboxDraw;
    robotJobs: any[];
    robotIDs: number[];
    areas: any;
    points: any;
    selectedTasksLabels: Set<string>;
    deselectAll: any;
}

export const useStatusBarStyles = makeStyles((theme: Theme) =>
	createStyles({
		confirm: {  },
		tasks: { width: '18%', position: 'absolute', opacity: '80%', right: '2%', marginTop: '2%', background: 'white' },
		buttonDisabled: { backgroundColor: '#6f6f6f', color: '#808080', alignItems: 'center', whiteSpace: 'nowrap', width: '100%', marginTop: '4%', transition: 'color 0.5s backgroundColor 0.3s' },
		buttonEnabled: { background: '#4CAEF6', color: '#ffffff', alignItems: 'center', whiteSpace: 'nowrap', width: '100%', marginTop: '4%', transition: 'color 0.5s backgroundColor 0.3s' }
	})
);

export const TaskSidebar: React.FC<TSidebarProps> = ({ jobRobots, currentStep, showOnStep, draw, positioning,
	robotJobs, robotIDs, points, selectedTasksLabels, deselectAll }: TSidebarProps) => {
	const classes = useStatusBarStyles();
	const [selectedTasks, setSelectedTasks] = React.useState(new Set<string>());
	const [selectedLabels, setSelectedLabels] = React.useState(new Set<string>());
	const [selectedRobot, setSelectedRobot] = React.useState<Robot | undefined>(undefined);
	const { enqueueSnackbar } = useSnackbar();

	React.useEffect(() => {
		if (jobRobots.size === 1) {
			setSelectedRobot(jobRobots.values().next().value);
		} else {
			setSelectedRobot(undefined);
		}
	},[jobRobots, points]);

	React.useEffect(() => {
		if (points === 0) {
			setSelectedRobot(undefined);
		}
	}, [points]);

	// if (draw && draw.getSelectedIds().length !== 0) {

	const confirm = () => {
		const robotObj: any = {};
		const taskObj: any = {};

		draw.getSelectedIds().forEach((element: any) => {
			taskObj[element] = Array.from(selectedTasks);
		});

		robotObj[selectedRobot!.id] = taskObj;
		robotJobs.push(robotObj);
		robotIDs.push(selectedRobot!.id);

		//Adding labels for chips
		selectedLabels.forEach((label: string) => {
			selectedTasksLabels.add(label);
		});

		enqueueSnackbar(selectedLabels.size + (selectedLabels.size>1?' tasks ':' task ')+ 'saved for ' + selectedRobot!.name, { variant: 'info', autoHideDuration: 3000 } );
		//Show all robots again and clean structures for next
		clean();
		deselectAll();
	};

	const clean = () => {
		setSelectedRobot(undefined);
		setSelectedTasks(new Set<string>());
		setSelectedLabels(new Set<string>());
	};

	const isDisabled = (): boolean => {
		return points === 0 || selectedRobot === undefined || selectedTasks.size === 0;
	};

	return (
		<>
			{
				currentStep === showOnStep ?
					<div className={positioning}>
						<RobotClickableList jobRobots={jobRobots} selectedWP={points} selectedRobot={selectedRobot} setSelectedRobot={setSelectedRobot} />
						<TaskSelectionList robot={selectedRobot!} setSelectedTasks={setSelectedTasks} selectedTasks={selectedTasks} selectedWP={points} selectedLabels={selectedLabels}/>
						{/* Displaying selected tasks */}
						<Button className={ isDisabled() ? classes.buttonDisabled : classes.buttonEnabled } onClick={confirm} disabled={isDisabled()}>Confirm</Button>
					</div>
					:
					<></>
			}
		</>
	);
};
