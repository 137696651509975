import * as React from 'react';
import ExploreIcon from '@mui/icons-material/Explore';
import { Container, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useSensorStyles = makeStyles((theme: Theme) =>
	createStyles({
		bearing: { padding: '10px', height: '180px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }
	})
);

interface IBearing {
    message: any;
}

export const Bearing: React.FC<IBearing> = ({ message }) => {
	const classes = useSensorStyles();
	const [bearing, setBearing] = React.useState(0.0);

	React.useEffect(() => {
		try {
			if (message.topic.includes('bearing')) {
				setBearing(message.message.payload.value);
			}
		} catch (err) {
			// console.log(err)
		}
	}, [message]);

	return (
		<div className={classes.bearing}>
			<Container maxWidth='xl'>
				{!(message.message)?
					'WAITING FOR COMPASS DATA':
					<>
						<Typography variant='subtitle2'>
							<ExploreIcon fontSize='large'/>
						</Typography>
						<Typography variant='body1'>
							{bearing.toFixed(2)}{' '}°
						</Typography>
					</>
				}
			</Container>
		</div>
	);
};
