import React from 'react';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Button, Card, Grid, IconButton, Modal, TextField, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { UserArea } from '../../helpers/user/user';
import generalStyles from '../../style/styles';
import { HeatmapSettings } from './Heatmap';


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		areaTitle: { textTransform: 'uppercase', display: 'inline' }
	})
);

export interface HeatmapSettingsModal {
	userArea?: UserArea;
	heatmapSettings?: HeatmapSettings;
	setHeatmapSettings?: (heatmapSettings: HeatmapSettings) => void;
	configDisabled: boolean;
}

export const HeatmapSettingsModal: React.FC<HeatmapSettingsModal> = ({ userArea, heatmapSettings, setHeatmapSettings, configDisabled }: HeatmapSettingsModal) => {

	const classes = useStyles();
	const importedclasses = generalStyles();
	const [heatmapSettingsModal, setHeatmapSettingsModal] = React.useState<boolean>(false);
	const [newSquareSize, setNewSquareSize] = React.useState<number>();
	const [newMinRange, setNewMinRange] = React.useState<number>();
	const [newMaxRange, setNewMaxRange] = React.useState<number>();
	const [newColorLevels, setNewColorLevels] = React.useState<number>();
	const [newMinCubicRange, setNewMinCubicRange] = React.useState<number>();
	const [newMaxCubicRange, setNewMaxCubicRange] = React.useState<number>();

	React.useEffect(() => {
		if (heatmapSettings === undefined) {
			setNewSquareSize(undefined);
			setNewMinRange(undefined);
			setNewMaxRange(undefined);
			setNewColorLevels(undefined);
			setNewMinCubicRange(undefined);
			setNewMaxCubicRange(undefined);
		}
	}, [heatmapSettings]);

	const handleSave = () => {
		if ( setHeatmapSettings !== undefined ) {
			const newHeatmapSettings: HeatmapSettings = { squareSize: newSquareSize, minRange: newMinRange, maxRange: newMaxRange, colorLevels: newColorLevels, minCubicRange: newMinCubicRange, maxCubicRange: newMaxCubicRange };
			setHeatmapSettings(newHeatmapSettings);
			setHeatmapSettingsModal(false);
		}
	};

	const handleClose = () => {
		setNewSquareSize(heatmapSettings?.squareSize);
		setNewMinRange(heatmapSettings?.minRange);
		setNewMaxRange(heatmapSettings?.maxRange);
		setNewColorLevels(heatmapSettings?.colorLevels);
		setNewMinCubicRange(heatmapSettings?.minCubicRange);
		setNewMaxCubicRange(heatmapSettings?.maxCubicRange);
		setHeatmapSettingsModal(false);
	};

	return (
		<>
			<IconButton aria-label='settings' size='small' disabled={configDisabled} onClick={() => setHeatmapSettingsModal(true)}><SettingsOutlinedIcon fontSize='inherit'/></IconButton>
			<Modal open={heatmapSettingsModal} onClose={handleClose}>
				<Card className={importedclasses.modal}>
					<Typography className={classes.areaTitle} variant="h6">Heat map settings</Typography>
					<Grid container>
						<Grid item xs={4}>
							<Typography>Pitch size</Typography>
						</Grid>
						<Grid item xs={8}>
							<TextField
								label={'Pitch size'}
								variant='standard'
								placeholder={'' + ( userArea?.pitchSize ? userArea.pitchSize : '(default: 110 by 80)' ) }
								margin={'normal'}
								disabled
								InputLabelProps={{ shrink: true }}
							/>
						</Grid>
						<Grid item xs={4}>
							<Typography>Grid square size</Typography>
						</Grid>
						<Grid item xs={8}>
							<TextField
								label={'Square size (meters)'}
								variant='standard'
								placeholder={'Number...'}
								margin={'normal'}
								InputLabelProps={{ shrink: true }}
								value={newSquareSize || ''}
								onChange={(event) => {
									setNewSquareSize(parseInt(event.target.value));
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<Typography>Color Range (min/max)</Typography>
						</Grid>
						<Grid item xs={4}>
							<TextField
								label={'Min'}
								variant='standard'
								placeholder={'Number...'}
								margin={'normal'}
								InputLabelProps={{ shrink: true }}
								InputProps={{ inputMode: 'numeric' }}
								value={newMinRange || ''}
								onChange={(event) => {
									setNewMinRange(parseInt(event.target.value));
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								label={'Max'}
								variant='standard'
								placeholder={'Number...'}
								margin={'normal'}
								InputLabelProps={{ shrink: true }}
								value={newMaxRange || ''}
								onChange={(event) => {
									setNewMaxRange(parseInt(event.target.value));
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<Typography>Color levels</Typography>
						</Grid>
						<Grid item xs={8}>
							<TextField
								label={'Levels'}
								variant='standard'
								placeholder={'Number...'}
								margin={'normal'}
								InputLabelProps={{ shrink: true }}
								value={newColorLevels || ''}
								onChange={(event) => {
									setNewColorLevels(parseInt(event.target.value));
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<Typography>Cubic Range (min/max)</Typography>
						</Grid>
						<Grid item xs={4}>
							<TextField
								label={'Min'}
								variant='standard'
								placeholder={'Number...'}
								margin={'normal'}
								InputLabelProps={{ shrink: true }}
								value={newMinCubicRange || ''}
								onChange={(event) => {
									setNewMinCubicRange(parseInt(event.target.value));
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								label={'Max'}
								variant='standard'
								placeholder={'Number...'}
								margin={'normal'}
								InputLabelProps={{ shrink: true }}
								value={newMaxCubicRange || ''}
								onChange={(event) => {
									setNewMaxCubicRange(parseInt(event.target.value));
								}}
							/>
						</Grid>
						<Grid container justifyContent='center' item xs={12}>
							<Button variant={'outlined'} className={importedclasses.modalCancel} onClick={handleClose}>Cancel</Button>
							<Button variant={'outlined'} className={importedclasses.modalConfirm} onClick={handleSave}>Save</Button>
						</Grid>
					</Grid>
				</Card>
			</Modal>
		</>
	);
};