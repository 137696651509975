import React from 'react';
import FindReplaceOutlinedIcon from '@mui/icons-material/FindReplaceOutlined';
import { Card, IconButton, List, ListItem, ListItemButton, ListItemText, Modal, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { UserArea } from '../../helpers/user/user';
import generalStyles from '../../style/styles';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		areaTitle: { textTransform: 'uppercase', display: 'inline' },
		modal: { overflowY: 'scroll', maxHeight: '80%' }
	})
);
interface ReportAreaSelector {
	userAreas: UserArea[];
	selectedUserArea: UserArea | undefined;
	setSelectedUserArea: (selectedUserArea: UserArea)=> void;
}

export const AreaSelector = ({ userAreas, selectedUserArea, setSelectedUserArea }: ReportAreaSelector) => {

	const classes = useStyles();
	const importedclasses = generalStyles();
	const [changeAreaModal, setChangeAreaModal] = React.useState<boolean>(false);
	React.useEffect(() => {

	}, []);

	return (
		<>
			{ userAreas.length > 0 ?
				<>
					<Typography className={classes.areaTitle} variant="h4">{selectedUserArea?.name}</Typography>
					<IconButton sx={{verticalAlign: 'bottom'}} onClick={() => setChangeAreaModal(true)}><FindReplaceOutlinedIcon/></IconButton>
					<Modal  open={changeAreaModal} onClose={() => setChangeAreaModal(false)}>
						<Card className={importedclasses.modal + ' ' + classes.modal}>
							<Typography className={classes.areaTitle} variant="subtitle1">Select an area</Typography>
							<List>
								{
									userAreas.sort((a, b) => {
										if (a.name > b.name) {
											return 1;
										} else if (a.name < b.name) {
											return -1;
										}
										return 0;
									}).map((area: UserArea, index: number) => {
										return (<ListItem key={index} disablePadding>
											<ListItemButton onClick={() => {
												setSelectedUserArea(area);
												setChangeAreaModal(false);
											}}>
												<ListItemText primary={area.name} />
											</ListItemButton>
										</ListItem>);
									})
								}
							</List>
						</Card>
					</Modal>
				</>
				:
				<Typography variant="body2" color="error">No areas available. Please contact support.</Typography>
			}
		</>
	);
};