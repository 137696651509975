import React, { useEffect, useRef } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import * as config from '../../helpers/API/config';
import { xytolatlnginarea } from '../../helpers/location/locationMapping';
import { UserArea } from '../../helpers/user/user';
import pitchMarkings from '../../static/pitch-markings.png';
import { Zone } from '../../views/AreaReports';

let URL_BASE = config.DEV;
if (process.env.REACT_APP_ENV === 'LOCAL') {
	URL_BASE = config.LOCALHOST;
}

export interface HeatmapSettings {
	squareSize?: number;
	minRange?: number;
	maxRange?: number;
	colorLevels?: number;
	minCubicRange?: number;
	maxCubicRange?: number;
}

interface Heatmap {
	mapDate?: Date;
	area?: UserArea;
	measurementType: string;
	selectedJob: number;
	setSelectedZone: (zone: Zone)=> void;
	selectedZone?: Zone;
	heatmapSettings?: HeatmapSettings;
}

interface PitchSize {
	x: number;
	y: number;
}

const useStyles = makeStyles(() =>
	createStyles({
		heatmapCard: { width: '100%', height: '300px', marginTop: '0px', marginLeft: '0px', paddingLeft: '0px', paddingTop: '0px', minHeight: '200px', alignItems: 'center', justifyContent: 'center',
			'& img': { height: '100%', position: 'absolute', top: '0', left: '0' }
		},
		imgOverlay: { maxWidth: '100%', maxHeight: '100%', width: '100%', height: '100%', position: 'relative',
			'& div': { display: 'inline-block', position: 'relative', minHeight: '100%' },
			'& a': { display: 'block', position: 'absolute', backgroundColor: 'rgba(100,100,100,0.3)', border: '1px #ffffff57 solid' },
			'& a:hover': { backgroundColor: 'rgba(100,100,100,0.5)', border: '1px #ffffff57 solid' },
			'& .selected': { boxShadow: 'inset 0px 0px 0px 2px #d3d3d3' }
		},
		hide: { display: 'none' }
	})
);

export const Heatmap: React.FC<Heatmap> = ({mapDate, area, measurementType, selectedJob, setSelectedZone, selectedZone, heatmapSettings}: Heatmap) => {
	const classes = useStyles();
	const heatmapRef = useRef();

	const [heatmapUrl, setHeatmapUrl] = React.useState<string>('');
	const [colorBarUrl, setColorBarUrl] = React.useState<string>('');
	const [loadingHeatmap, setLoadingHeatmap] = React.useState<boolean>(false);
	const [pitchSize, setPitchSize] = React.useState<PitchSize>({ x: 110, y: 80 });

	useEffect(() => {
		const tempDate = moment(mapDate, 'DD/MM/YY');
		if (tempDate.isValid() && area) {
			setHeatmapUrl('');
			setLoadingHeatmap(true);
			const formattedDate = tempDate.format('YYYY-MM-DD');
			let heatmapTempUrl = URL_BASE+'sensordata/heatmap?date=' + formattedDate + '&area=' + area.name + '&measurementType=' + measurementType + '&colorBar=none';
			let colorBarTempUrl = URL_BASE+'sensordata/colorbar?date=' + formattedDate + '&area=' + area.name + '&measurementType=' + measurementType + '&colorBar=none';
			if (selectedJob !== 0) {
				heatmapTempUrl += '&jobId=' + selectedJob;
				colorBarTempUrl += '&jobId=' + selectedJob;
			}
			if (heatmapSettings?.squareSize) {
				heatmapTempUrl += '&squareSize=' + heatmapSettings.squareSize;
				colorBarTempUrl += '&squareSize=' + heatmapSettings.squareSize;
			} else {
				heatmapTempUrl += '&squareSize=5';
				colorBarTempUrl += '&squareSize=5';
			}
			if (heatmapSettings?.colorLevels) {
				heatmapTempUrl += '&colorLevels=' + heatmapSettings.colorLevels;
				colorBarTempUrl += '&colorLevels=' + heatmapSettings.colorLevels;
			}
			if (heatmapSettings?.minRange && heatmapSettings?.maxRange) {
				heatmapTempUrl += '&colorRangeMin=' + heatmapSettings.minRange + '&colorRangeMax=' + heatmapSettings.maxRange;
				colorBarTempUrl += '&colorRangeMin=' + heatmapSettings.minRange + '&colorRangeMax=' + heatmapSettings.maxRange;
			}
			if (heatmapSettings?.minCubicRange && heatmapSettings?.maxCubicRange) {
				heatmapTempUrl += '&cubicRangeMin=' + heatmapSettings.minCubicRange + '&cubicRangeMax=' + heatmapSettings.maxCubicRange;
				colorBarTempUrl += '&cubicRangeMin=' + heatmapSettings.minCubicRange + '&cubicRangeMax=' + heatmapSettings.maxCubicRange;
			}
			setHeatmapUrl(heatmapTempUrl);
			setColorBarUrl(colorBarTempUrl);
			if (area.pitchSize) {
				setPitchSize({ x: area.pitchSize[0], y: area.pitchSize[1] });
			} else {
				setPitchSize({ x: 110, y: 80 });
			}
		}
	},[mapDate,area,measurementType,heatmapSettings,selectedJob]);

	// Football: X is the long side, Y is the short side
	async function zoneClickHandler (userArea: UserArea, zone: string) {
		if (userArea) {
			let point1, point2, point3, point4 = [0,0];
			switch (zone) {
			case 'zone1':
				point1 = [0,pitchSize.y * 0.75];
				point2 = [0,pitchSize.y * 0.75 + pitchSize.y * 0.25];
				point3 = [pitchSize.x * 0.25, pitchSize.y * 0.75 + pitchSize.y * 0.25];
				point4 = [pitchSize.x * 0.25, pitchSize.y * 0.75];
				await Promise.all([
					xytolatlnginarea(point1[0], point1[1], userArea),
					xytolatlnginarea(point2[0], point2[1], userArea),
					xytolatlnginarea(point3[0], point3[1], userArea),
					xytolatlnginarea(point4[0], point4[1], userArea)
				]).then( (value) => {
					setSelectedZone({
						points: value,
						geometry: {
							type: 'Polygon',
							coordinates: [[[value[0].lng, value[0].lat],[value[1].lng,value[1].lat],[value[2].lng,value[2].lat],[value[3].lng, value[3].lat],[value[0].lng,value[0].lat]]]
						},
						name: zone
					});
				});
				break;
			case 'zone2':
				point1 = [pitchSize.x * 0.25, pitchSize.y * 0.75];
				point2 = [pitchSize.x * 0.25, pitchSize.y * 0.75 + pitchSize.y * 0.25];
				point3 = [pitchSize.x * 0.50, pitchSize.y * 0.75 + pitchSize.y * 0.25];
				point4 = [pitchSize.x * 0.50, pitchSize.y * 0.75];
				await Promise.all([
					xytolatlnginarea(point1[0], point1[1], userArea),
					xytolatlnginarea(point2[0], point2[1], userArea),
					xytolatlnginarea(point3[0], point3[1], userArea),
					xytolatlnginarea(point4[0], point4[1], userArea)
				]).then( (value) => {
					setSelectedZone({
						points: value,
						geometry: {
							type: 'Polygon',
							coordinates: [[[value[0].lng, value[0].lat],[value[1].lng,value[1].lat],[value[2].lng,value[2].lat],[value[3].lng, value[3].lat],[value[0].lng,value[0].lat]]]
						},
						name: zone
					});
				});
				break;
			case 'zone3':
				point1 = [pitchSize.x * 0.50, pitchSize.y * 0.75];
				point2 = [pitchSize.x * 0.50, pitchSize.y * 0.75 + pitchSize.y * 0.25];
				point3 = [pitchSize.x * 0.75, pitchSize.y * 0.75 + pitchSize.y * 0.25];
				point4 = [pitchSize.x * 0.75, pitchSize.y * 0.75];
				await Promise.all([
					xytolatlnginarea(point1[0], point1[1], userArea),
					xytolatlnginarea(point2[0], point2[1], userArea),
					xytolatlnginarea(point3[0], point3[1], userArea),
					xytolatlnginarea(point4[0], point4[1], userArea)
				]).then( (value) => {
					setSelectedZone({
						points: value,
						geometry: {
							type: 'Polygon',
							coordinates: [[[value[0].lng, value[0].lat],[value[1].lng,value[1].lat],[value[2].lng,value[2].lat],[value[3].lng, value[3].lat],[value[0].lng,value[0].lat]]]
						},
						name: zone
					});
				});
				break;
			case 'zone4':
				point1 = [pitchSize.x * 0.75, pitchSize.y * 0.75];
				point2 = [pitchSize.x * 0.75, pitchSize.y * 0.75 + pitchSize.y * 0.25];
				point3 = [pitchSize.x * 1.00, pitchSize.y * 0.75 + pitchSize.y * 0.25];
				point4 = [pitchSize.x * 1.00, pitchSize.y * 0.75];
				await Promise.all([
					xytolatlnginarea(point1[0], point1[1], userArea),
					xytolatlnginarea(point2[0], point2[1], userArea),
					xytolatlnginarea(point3[0], point3[1], userArea),
					xytolatlnginarea(point4[0], point4[1], userArea)
				]).then( (value) => {
					setSelectedZone({
						points: value,
						geometry: {
							type: 'Polygon',
							coordinates: [[[value[0].lng, value[0].lat],[value[1].lng,value[1].lat],[value[2].lng,value[2].lat],[value[3].lng, value[3].lat],[value[0].lng,value[0].lat]]]
						},
						name: zone
					});
				});
				break;
			case 'zone5':
				point1 = [0, pitchSize.y * 0.5];
				point2 = [0, pitchSize.y * 0.5 + pitchSize.y * 0.25];
				point3 = [pitchSize.x * 0.25, pitchSize.y * 0.5 + pitchSize.y * 0.25];
				point4 = [pitchSize.x * 0.25, pitchSize.y * 0.5];
				await Promise.all([
					xytolatlnginarea(point1[0], point1[1], userArea),
					xytolatlnginarea(point2[0], point2[1], userArea),
					xytolatlnginarea(point3[0], point3[1], userArea),
					xytolatlnginarea(point4[0], point4[1], userArea)
				]).then( (value) => {
					setSelectedZone({
						points: value,
						geometry: {
							type: 'Polygon',
							coordinates: [[[value[0].lng, value[0].lat],[value[1].lng,value[1].lat],[value[2].lng,value[2].lat],[value[3].lng, value[3].lat],[value[0].lng,value[0].lat]]]
						},
						name: zone
					});
				});
				break;
			case 'zone6':
				point1 = [pitchSize.x * 0.25, pitchSize.y * 0.5];
				point2 = [pitchSize.x * 0.25, pitchSize.y * 0.5 + pitchSize.y * 0.25];
				point3 = [pitchSize.x * 0.50, pitchSize.y * 0.5 + pitchSize.y * 0.25];
				point4 = [pitchSize.x * 0.50, pitchSize.y * 0.5];
				await Promise.all([
					xytolatlnginarea(point1[0], point1[1], userArea),
					xytolatlnginarea(point2[0], point2[1], userArea),
					xytolatlnginarea(point3[0], point3[1], userArea),
					xytolatlnginarea(point4[0], point4[1], userArea)
				]).then( (value) => {
					setSelectedZone({
						points: value,
						geometry: {
							type: 'Polygon',
							coordinates: [[[value[0].lng, value[0].lat],[value[1].lng,value[1].lat],[value[2].lng,value[2].lat],[value[3].lng, value[3].lat],[value[0].lng,value[0].lat]]]
						},
						name: zone
					});
				});
				break;
			case 'zone7':
				point1 = [pitchSize.x * 0.50, pitchSize.y * 0.5];
				point2 = [pitchSize.x * 0.50, pitchSize.y * 0.5 + pitchSize.y * 0.25];
				point3 = [pitchSize.x * 0.75, pitchSize.y * 0.5 + pitchSize.y * 0.25];
				point4 = [pitchSize.x * 0.75, pitchSize.y * 0.5];
				await Promise.all([
					xytolatlnginarea(point1[0], point1[1], userArea),
					xytolatlnginarea(point2[0], point2[1], userArea),
					xytolatlnginarea(point3[0], point3[1], userArea),
					xytolatlnginarea(point4[0], point4[1], userArea)
				]).then( (value) => {
					setSelectedZone({
						points: value,
						geometry: {
							type: 'Polygon',
							coordinates: [[[value[0].lng, value[0].lat],[value[1].lng,value[1].lat],[value[2].lng,value[2].lat],[value[3].lng, value[3].lat],[value[0].lng,value[0].lat]]]
						},
						name: zone
					});
				});
				break;
			case 'zone8':
				point1 = [pitchSize.x * 0.75, pitchSize.y * 0.5];
				point2 = [pitchSize.x * 0.75, pitchSize.y * 0.5 + pitchSize.y * 0.25];
				point3 = [pitchSize.x * 1.00, pitchSize.y * 0.5 + pitchSize.y * 0.25];
				point4 = [pitchSize.x * 1.00, pitchSize.y * 0.5];
				await Promise.all([
					xytolatlnginarea(point1[0], point1[1], userArea),
					xytolatlnginarea(point2[0], point2[1], userArea),
					xytolatlnginarea(point3[0], point3[1], userArea),
					xytolatlnginarea(point4[0], point4[1], userArea)
				]).then( (value) => {
					setSelectedZone({
						points: value,
						geometry: {
							type: 'Polygon',
							coordinates: [[[value[0].lng, value[0].lat],[value[1].lng,value[1].lat],[value[2].lng,value[2].lat],[value[3].lng, value[3].lat],[value[0].lng,value[0].lat]]]
						},
						name: zone
					});
				});
				break;
			case 'zone9':
				point1 = [0,pitchSize.y * 0.25];
				point2 = [0, pitchSize.y * 0.25 + pitchSize.y * 0.25];
				point3 = [pitchSize.x * 0.25, pitchSize.y * 0.25 + pitchSize.y * 0.25];
				point4 = [pitchSize.x * 0.25, pitchSize.y * 0.25];
				await Promise.all([
					xytolatlnginarea(point1[0], point1[1], userArea),
					xytolatlnginarea(point2[0], point2[1], userArea),
					xytolatlnginarea(point3[0], point3[1], userArea),
					xytolatlnginarea(point4[0], point4[1], userArea)
				]).then( (value) => {
					setSelectedZone({
						points: value,
						geometry: {
							type: 'Polygon',
							coordinates: [[[value[0].lng, value[0].lat],[value[1].lng,value[1].lat],[value[2].lng,value[2].lat],[value[3].lng, value[3].lat],[value[0].lng,value[0].lat]]]
						},
						name: zone
					});
				});
				break;
			case 'zone10':
				point1 = [pitchSize.x * 0.25, pitchSize.y * 0.25];
				point2 = [pitchSize.x * 0.25, pitchSize.y * 0.25 + pitchSize.y * 0.25];
				point4 = [pitchSize.x * 0.50, pitchSize.y * 0.25 + pitchSize.y * 0.25];
				point3 = [pitchSize.x * 0.50, pitchSize.y * 0.25];
				await Promise.all([
					xytolatlnginarea(point1[0], point1[1], userArea),
					xytolatlnginarea(point2[0], point2[1], userArea),
					xytolatlnginarea(point3[0], point3[1], userArea),
					xytolatlnginarea(point4[0], point4[1], userArea)
				]).then( (value) => {
					setSelectedZone({
						points: value,
						geometry: {
							type: 'Polygon',
							coordinates: [[[value[0].lng, value[0].lat],[value[1].lng,value[1].lat],[value[2].lng,value[2].lat],[value[3].lng, value[3].lat],[value[0].lng,value[0].lat]]]
						},
						name: zone
					});
				});
				break;
			case 'zone11':
				point1 = [pitchSize.x * 0.50, pitchSize.y * 0.25];
				point2 = [pitchSize.x * 0.50, pitchSize.y * 0.25 + pitchSize.y * 0.25];
				point3 = [pitchSize.x * 0.75, pitchSize.y * 0.25 + pitchSize.y * 0.25];
				point4 = [pitchSize.x * 0.75, pitchSize.y * 0.25];
				await Promise.all([
					xytolatlnginarea(point1[0], point1[1], userArea),
					xytolatlnginarea(point2[0], point2[1], userArea),
					xytolatlnginarea(point3[0], point3[1], userArea),
					xytolatlnginarea(point4[0], point4[1], userArea)
				]).then( (value) => {
					setSelectedZone({
						points: value,
						geometry: {
							type: 'Polygon',
							coordinates: [[[value[0].lng, value[0].lat],[value[1].lng,value[1].lat],[value[2].lng,value[2].lat],[value[3].lng, value[3].lat],[value[0].lng,value[0].lat]]]
						},
						name: zone
					});
				});
				break;
			case 'zone12':
				point1 = [pitchSize.x * 0.75, pitchSize.y * 0.25];
				point2 = [pitchSize.x * 0.75, pitchSize.y * 0.25 + pitchSize.y * 0.25];
				point3 = [pitchSize.x * 1.00, pitchSize.y * 0.25 + pitchSize.y * 0.25];
				point4 = [pitchSize.x * 1.00, pitchSize.y * 0.25];
				await Promise.all([
					xytolatlnginarea(point1[0], point1[1], userArea),
					xytolatlnginarea(point2[0], point2[1], userArea),
					xytolatlnginarea(point3[0], point3[1], userArea),
					xytolatlnginarea(point4[0], point4[1], userArea)
				]).then( (value) => {
					setSelectedZone({
						points: value,
						geometry: {
							type: 'Polygon',
							coordinates: [[[value[0].lng, value[0].lat],[value[1].lng,value[1].lat],[value[2].lng,value[2].lat],[value[3].lng, value[3].lat],[value[0].lng,value[0].lat]]]
						},
						name: zone
					});
				});
				break;
			case 'zone13':

				point1 = [0,0];
				point2 = [0, pitchSize.y * 0.25];
				point3 = [pitchSize.x * 0.25, pitchSize.y * 0.25];
				point4 = [pitchSize.x * 0.25, 0];
				await Promise.all([
					xytolatlnginarea(point1[0], point1[1], userArea),
					xytolatlnginarea(point2[0], point2[1], userArea),
					xytolatlnginarea(point3[0], point3[1], userArea),
					xytolatlnginarea(point4[0], point4[1], userArea)
				]).then( (value) => {
					setSelectedZone({
						points: value,
						geometry: {
							type: 'Polygon',
							coordinates: [[[value[0].lng, value[0].lat],[value[1].lng,value[1].lat],[value[2].lng,value[2].lat],[value[3].lng, value[3].lat],[value[0].lng,value[0].lat]]]
						},
						name: zone
					});
				});
				break;
			case 'zone14':
				point1 = [pitchSize.x * 0.25, 0];
				point2 = [pitchSize.x * 0.25, pitchSize.y * 0.25];
				point3 = [pitchSize.x * 0.50, pitchSize.y * 0.25];
				point4 = [pitchSize.x * 0.50, 0];
				await Promise.all([
					xytolatlnginarea(point1[0], point1[1], userArea),
					xytolatlnginarea(point2[0], point2[1], userArea),
					xytolatlnginarea(point3[0], point3[1], userArea),
					xytolatlnginarea(point4[0], point4[1], userArea)
				]).then( (value) => {
					setSelectedZone({
						points: value,
						geometry: {
							type: 'Polygon',
							coordinates: [[[value[0].lng, value[0].lat],[value[1].lng,value[1].lat],[value[2].lng,value[2].lat],[value[3].lng, value[3].lat],[value[0].lng,value[0].lat]]]
						},
						name: zone
					});
				});
				break;
			case 'zone15':
				point1 = [pitchSize.x * 0.50, 0];
				point2 = [pitchSize.x * 0.50, pitchSize.y * 0.25];
				point3 = [pitchSize.x * 0.75, pitchSize.y * 0.25];
				point4 = [pitchSize.x * 0.75, 0];
				await Promise.all([
					xytolatlnginarea(point1[0], point1[1], userArea),
					xytolatlnginarea(point2[0], point2[1], userArea),
					xytolatlnginarea(point3[0], point3[1], userArea),
					xytolatlnginarea(point4[0], point4[1], userArea)
				]).then( (value) => {
					setSelectedZone({
						points: value,
						geometry: {
							type: 'Polygon',
							coordinates: [[[value[0].lng, value[0].lat],[value[1].lng,value[1].lat],[value[2].lng,value[2].lat],[value[3].lng, value[3].lat],[value[0].lng,value[0].lat]]]
						},
						name: zone
					});
				});
				break;
			case 'zone16':
				point1 = [pitchSize.x * 0.75, 0];
				point2 = [pitchSize.x * 0.75, pitchSize.y * 0.25];
				point3 = [pitchSize.x * 1.00, pitchSize.y * 0.25];
				point4 = [pitchSize.x * 1.00, 0];
				await Promise.all([
					xytolatlnginarea(point1[0], point1[1], userArea),
					xytolatlnginarea(point2[0], point2[1], userArea),
					xytolatlnginarea(point3[0], point3[1], userArea),
					xytolatlnginarea(point4[0], point4[1], userArea)
				]).then( (value) => {
					setSelectedZone({
						points: value,
						geometry: {
							type: 'Polygon',
							coordinates: [[[value[0].lng, value[0].lat],[value[1].lng,value[1].lat],[value[2].lng,value[2].lat],[value[3].lng, value[3].lat],[value[0].lng,value[0].lat]]]
						},
						name: zone
					});
				});
				break;
			}
		}
		return ;
	}

	return (
		<div>
			<Grid container spacing={1} className={classes.heatmapCard}>
				{ heatmapUrl !== 'error' ?
					<div className={ loadingHeatmap ? classes.hide : classes.imgOverlay }>
						<div style={{ minWidth: '90%' , minHeight: '100%' }}>
							<img style={{ maxWidth: '100%', width: '100%' }} src={heatmapUrl} ref={heatmapRef.current} onLoad={() => {
								if (heatmapUrl === '') {
									setLoadingHeatmap(true);
								} else {
									setLoadingHeatmap(false);
								}
							}} onError={(event) => {
								event.currentTarget.src = 'error';
								setHeatmapUrl('error');
								setLoadingHeatmap(false);
								event.currentTarget.alt = 'Heatmap unavailable';
							}}></img>
							{ area?.kind === 'football' ?
								<img style={{ maxWidth: '100%', width: '100%' }} src={pitchMarkings}></img>
								:
								<></>
							}
							<a style={{top: 0, left: 0, width: '25%', height: '25%'}} className={ selectedZone?.name === 'zone1' ? 'selected' : ''} onClick={ () => area ? zoneClickHandler(area, 'zone1') : 0 }></a>
							<a style={{top: 0, left: '25%', width: '25%', height: '25%'}} className={ selectedZone?.name === 'zone2' ? 'selected' : ''} onClick={ () => area ? zoneClickHandler(area, 'zone2') : 0 }></a>
							<a style={{top: 0, left: '50%', width: '25%', height: '25%'}} className={ selectedZone?.name === 'zone3' ? 'selected' : ''} onClick={ () => area ? zoneClickHandler(area, 'zone3') : 0 }></a>
							<a style={{top: 0, left: '75%', width: '25%', height: '25%'}} className={ selectedZone?.name === 'zone4' ? 'selected' : ''} onClick={ () => area ? zoneClickHandler(area, 'zone4') : 0 }></a>
							<a style={{top: '25%', left: 0, width: '25%', height: '25%'}} className={ selectedZone?.name === 'zone5' ? 'selected' : ''} onClick={ () => area ? zoneClickHandler(area, 'zone5') : 0 }></a>
							<a style={{top: '25%', left: '25%', width: '25%', height: '25%'}} className={ selectedZone?.name === 'zone6' ? 'selected' : ''} onClick={ () => area ? zoneClickHandler(area, 'zone6') : 0 }></a>
							<a style={{top: '25%', left: '50%', width: '25%', height: '25%'}} className={ selectedZone?.name === 'zone7' ? 'selected' : ''} onClick={ () => area ? zoneClickHandler(area, 'zone7') : 0 }></a>
							<a style={{top: '25%', left: '75%', width: '25%', height: '25%'}} className={ selectedZone?.name === 'zone8' ? 'selected' : ''} onClick={ () => area ? zoneClickHandler(area, 'zone8') : 0 }></a>
							<a style={{top: '50%', left: 0, width: '25%', height: '25%'}} className={ selectedZone?.name === 'zone9' ? 'selected' : ''} onClick={ () => area ? zoneClickHandler(area, 'zone9') : 0 }></a>
							<a style={{top: '50%', left: '25%', width: '25%', height: '25%'}} className={ selectedZone?.name === 'zone10' ? 'selected' : ''} onClick={ () => area ? zoneClickHandler(area, 'zone10') : 0 }></a>
							<a style={{top: '50%', left: '50%', width: '25%', height: '25%'}} className={ selectedZone?.name === 'zone11' ? 'selected' : ''} onClick={ () => area ? zoneClickHandler(area, 'zone11') : 0 }></a>
							<a style={{top: '50%', left: '75%', width: '25%', height: '25%'}} className={ selectedZone?.name === 'zone12' ? 'selected' : ''} onClick={ () => area ? zoneClickHandler(area, 'zone12') : 0 }></a>
							<a style={{top: '75%', left: 0, width: '25%', height: '25%'}} className={ selectedZone?.name === 'zone13' ? 'selected' : ''} onClick={ () => area ? zoneClickHandler(area, 'zone13') : 0 }></a>
							<a style={{top: '75%', left: '25%', width: '25%', height: '25%'}} className={ selectedZone?.name === 'zone14' ? 'selected' : ''} onClick={ () => area ? zoneClickHandler(area, 'zone14') : 0 }></a>
							<a style={{top: '75%', left: '50%', width: '25%', height: '25%'}} className={ selectedZone?.name === 'zone15' ? 'selected' : ''} onClick={ () => area ? zoneClickHandler(area, 'zone15') : 0 }></a>
							<a style={{top: '75%', left: '75%', width: '25%', height: '25%'}} className={ selectedZone?.name === 'zone16' ? 'selected' : ''} onClick={ () => area ? zoneClickHandler(area, 'zone16') : 0 }></a>
						</div>
						<div style={{ minWidth: '10%' }}>
							<img src={colorBarUrl}></img>
						</div>
					</div>
					:
					<div style={{ color: '#6f6f6f' }}>
						Heatmap unavailable
					</div>
				}
				{ loadingHeatmap ?
					<CircularProgress />
					:
					<></>
				}
			</Grid>
		</div>
	);
};