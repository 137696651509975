import * as React from 'react';
import { useEffect } from 'react';
import { Button, Divider, Paper, TextField, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import '../../style/App.css';
import UserContext from '../../context/UserContext';
import { isUserAdmin, isUserDefault, isUserLightbox } from '../../context/UserUtil';
import * as Auth from '../../helpers/auth/auth';
import { getUserByEmail } from '../../helpers/user/user';
import { User } from '../../helpers/user/user'; // Import the User interface

import logo from '../../resources/images/colour-logo.png';
import style from '../../style/styles';


export const Login: React.FC = () => {
	const classes = style();
	const history = useHistory();
	const User = React.useContext(UserContext);
	const [email, setEmail] = React.useState('');
	const [password, setPassword] = React.useState('');
	const [textColor, setTextColor] = React.useState('#ffffff');
	const [authError, setAuthError] = React.useState(false);
	const [formError, setFormError] = React.useState(false);


	useEffect(() => {
		// Redirect to the '/robots' route once the user is authenticated and has an ID
		if (User.isAuthenticated && User.id) {
			history.push('/robots');
		}
	}, [User.isAuthenticated, User.id, history]); // Depend on isAuthenticated, id, and history

	const buttonStyle = {
		color: textColor,
		transition: 'color 0.5s'
	};

	const determineUserType = (user: User) => {
		if (user.id && isUserAdmin(user.id.toString())) {
			return 'admin';
		} else if (user.id && isUserLightbox(user.id.toString())) {
			return 'lightbox';
		} else {
			return 'default';
		}
	};

	async function handleLogin () {
		if (validateForm()) {
			setFormError(false);
			const loginResponse = await Auth.login(email, password);
			if (loginResponse.isAuthorized && loginResponse.data) {
				const user = loginResponse.data;


				// Set the user context
				User.setId(user.userId);
				const userType = determineUserType(user);
				User.setUserType(userType);
				User.setAuth(true);

				// Save to localStorage
				localStorage.setItem('isAuthenticated', 'true');
				localStorage.setItem('userId', String(user.userId));
				localStorage.setItem('userType', userType);

			} else {
				User.setAuth(false);
				setAuthError(true);
			}
		} else {
			setFormError(true);
		}
	}



	const handleForgotPassword = () => {
		history.push('/forgotPassword');
	};

	const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
	};

	const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(e.target.value);
	};

	const validateForm = () => {
		const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return password.length > 0 && emailRegex.test(String(email).toLowerCase());
	};

	React.useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.keyCode === 13) {
				handleLogin();
			}
		};
		window.addEventListener('keydown', handleKeyDown);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [handleLogin]);

	return (
		<div className='Login'>
			<Paper elevation={1} className={classes.paperLogin}>
				<form>
					<figure>
						<img src={logo} alt='Sign In logo' />
					</figure>
					<Typography variant='subtitle1'>Log In</Typography>
					<Divider />
					<br />
					<TextField fullWidth variant='outlined' margin='dense' size='medium' label='Email' autoComplete='off' onChange={onChangeEmail} />
					<br />
					<TextField fullWidth variant='outlined' margin='dense' size='medium' label='Password' type='password' autoComplete='off' onChange={onChangePassword} />
					<div className={classes.errorMessageDiv}>
						{formError && <p className={classes.errorText}>Invalid email or password. For help, contact support.</p>}
						{authError && <p className={classes.errorText}>Login failed. Check email and password.</p>}
					</div>
					<Button variant='contained' fullWidth style={buttonStyle} onMouseEnter={() => setTextColor('#6f6f6f')} onMouseLeave={() => setTextColor('#ffffff')} className={classes.submitLogin} onClick={handleLogin}>Login</Button>
					<p className='forgotPassword' onClick={handleForgotPassword}>Forgot Password?</p>
				</form>
			</Paper>
		</div>
	);
};
