import * as React from 'react';
import { Card, Grid, Typography } from '@mui/material';
import { MqttClient } from 'mqtt';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { Bearing } from '../components/liveview/Bearing';
import { CompactionPlot } from '../components/liveview/CompactionPlot';
import { HardnessPlot } from '../components/liveview/HardnessPlot';
import { Map } from '../components/liveview/Map';
import { MoisturePlot } from '../components/liveview/MoisturePlot';
import { NDVIPlot } from '../components/liveview/NDVIPlot';
import { Sensors } from '../components/liveview/Sensors';
import { SpeedPlot } from '../components/liveview/SpeedPlot';
import { StatusBar } from '../components/liveview/StatusBar';
import { Tasks } from '../components/liveview/Tasks';
import { TemperaturePlot } from '../components/liveview/TemperaturePlot';
import { Video } from '../components/liveview/Video';
import { connectMQTT, subscribeMQTT } from '../helpers/connect/connect';
import { connectOptions, connectURL, createMessageConfig, createRTCConfig } from '../helpers/connect/connectConfig';
import * as robot from '../helpers/robot/robot';
import { useLiveviewStyles } from '../style/components/liveviewStyle';

let temp: MqttClient;
interface params {
	robot: robot.Robot;
}

interface paramsURL {
	serialId: string;
}

export const Liveview: React.FC = () => {
	const classes = useLiveviewStyles();
	const params = useLocation<params>();
	const paramsURL: paramsURL = useParams();
	const [message, setMessage] = React.useState({});
	//Next line should be dynamic.
	const [streamID] = React.useState(['1', '2', '3']);
	const [robotSerialId, setRobotSerialId] = React.useState('');
	const [client, setClient] = React.useState(temp);
	const [robotDisplayName, setRobotDisplayName] = React.useState('Select a robot');
	const [robotId, setRobotId] = React.useState<number|undefined>(undefined);
	const [liveviewRedirect, setLiveviewRedirect] = React.useState<boolean>(false);

	async function connectClient () {
		const messageTopics = createMessageConfig(robotSerialId);
		const propsMQTT = {
			url: connectURL,
			options: connectOptions(),
			topics: messageTopics,
			setMessage: setMessage
		};
		return connectMQTT(propsMQTT).then((clientBack) => {
			console.log('MQTT connected');
			return clientBack;
		});
	}

	async function subscribeRTC (
		client: MqttClient,
		streamID: string[],
		robotID: string
	) {
		streamID.map((streamMap) => {
			const props = {
				client: client,
				topics: createRTCConfig(robotID, streamMap)
			};
			subscribeMQTT(props);
			return null;
		});
		return client;
	}

	React.useEffect(() => {
		if (client) {
			client.end();
		}
		if (robotSerialId.length > 0) {
			connectClient().then((client) => {
				subscribeRTC(client, streamID, robotSerialId);
				setClient(client);
			});
		}
	}, [robotSerialId]);

	React.useEffect(() => {
		if (params.state) {
			setRobotSerialId(params.state.robot.serialId);
			setRobotDisplayName(params.state.robot.name);
		}
	}, [params]);

	React.useEffect(() => {
		if (paramsURL['serialId']) {
			let robotFound = false;
			robot.getRobots().then((robots) => {
				robots.forEach((robotItem) => {
					if (robotItem.serialId === paramsURL['serialId']) {
						setRobotSerialId(robotItem.serialId);
						setRobotDisplayName(robotItem.name);
						setRobotId(robotItem.id);
						robotFound = true;
					}
				});
				if (!robotFound) {
					setLiveviewRedirect(true);
				}
			});
		}
	}, [paramsURL]);

	return (
		<div className={classes.margin}>
			{liveviewRedirect || paramsURL.serialId === undefined ? <Redirect to='/robots' />: <></>}
			<Grid container spacing={2}>
				<Grid item xs={12} md={4} lg={4}>
					<div className={classes.title}>
						<Typography variant='h5'>{robotDisplayName}</Typography>
					</div>
				</Grid>
				<Grid item xs={12} md={4} lg={4}>
					<StatusBar message={message} client={client} robotSerialId={robotSerialId}/>
				</Grid>
				<Grid item xs={12} md={4} lg={4}>
				</Grid>
				<Grid item xs={12} md={4} lg={4}>
					<div className={classes.plot}>
						<Typography className={classes.title} variant='subtitle1'>Moisture</Typography>
						<Card className={classes.card}>
							<MoisturePlot message={message} />
						</Card>
					</div>
					<div className={classes.plot}>
						<Typography className={classes.title} variant='subtitle1'>Temperature</Typography>
						<Card className={classes.card}>
							<TemperaturePlot message={message} />
						</Card>
					</div>
					<div className={classes.plot}>
						<Typography className={classes.title} variant='subtitle1'>Compaction</Typography>
						<Card className={classes.card}>
							<CompactionPlot message={message} />
						</Card>
					</div>
					<div className={classes.plot}>
						<Typography className={classes.title} variant='subtitle1'>Hardness</Typography>
						<Card className={classes.card}>
							<HardnessPlot message={message} />
						</Card>
					</div>
					<div className={classes.plot}>
						<Typography className={classes.title} variant='subtitle1'>NDVI</Typography>
						<Card className={classes.card}>
							<NDVIPlot message={message} />
						</Card>
					</div>
				</Grid>
				<Grid item xs={12} md={8} lg={8}>
					<Grid container spacing={2}>
						<Grid item xs ={12}>
							<Card className={classes.videoCard}>
								<Map message={message} robotSerialId={robotSerialId} robotId={robotId}/>
							</Card>
						</Grid>
						<Grid item xs={12} md={6} lg={6}>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<Typography className={classes.title} variant='subtitle1'>Motor RPM</Typography>
									<Card className={classes.card}>
										<Sensors message={message} />
									</Card>
								</Grid>
								<Grid item xs={6}>
									<Typography className={classes.title} variant='subtitle1'>Bearing</Typography>
									<Card className={classes.card}>
										<Bearing message={message} />
									</Card>
								</Grid>
								<Grid item xs={12}>
									<Typography className={classes.title} variant='subtitle1'>Speed</Typography>
									<Card className={classes.card}>
										<SpeedPlot message={message} />
									</Card>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={6}>
							<div className={classes.plot}>
								<Tasks message={message}/>
							</div>
							<Video setMessage={setMessage} robotID={robotSerialId} streamID={streamID} client={client} setClient={setClient} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};
