import * as React from 'react';
import { Box, Card, Container } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import mqtt from 'mqtt';
import { useParams } from 'react-router-dom';
import { JoystickControl } from '../components/sandbox/JoystickControl';
import { sendMQTTCommand, subscribeMQTT } from '../helpers/mqtt';

interface paramsURL{
	serialId: string;
}

//MUI styling
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		dashboard: { width: '100%' },
		dashComponents: { display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignContent: 'flex-start', padding: '0.25rem' },
		connectCard: { display: 'none', flexDirection: 'column', margin: '0.25rem', height: '120px', width: '16rem' },
		controlCard: { display: 'flex', flexDirection: 'column', width: '46rem', height: '250px', margin: '0.25rem', alignItems: 'center' },
		joystick: { width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }
	})
);

//Establish MQTT connection
const connectURL = 'wss://mqtt.e-nano.io:443';
const connectOptions = {
	port: 443,
	clientId: `enano-client-${Date.now()}`,
	username: 'rayercat',
	password: 'blauWis5670101'
};
const client = mqtt.connect(connectURL, connectOptions);

export const ManualControl: React.FC = () => {
	const classes = useStyles();
	const paramsURL: paramsURL = useParams();

	//get user from local storage and user throughout app
	const [user, setUser] = React.useState(localStorage.user);
	const [robotSerialId, setRobotSerialId] = React.useState('');

	React.useEffect(() => {
		setUser(localStorage.user);
	}, []);

	React.useEffect(() => {
		if (paramsURL['serialId']) {
			console.log('paramsURL: ' + paramsURL['serialId']);
			setRobotSerialId(paramsURL['serialId']);
		}
	}, [paramsURL]);

	React.useEffect(() => {
		if (robotSerialId !== '') {
			handleConnect();
		}
	}, [robotSerialId]);

	//STATE
	const [online, setOnline] = React.useState(false);
	const hasFocus = false;
	const [messageQueue, setMessageQueue] = React.useState([
		{ }
	]);
	const [movementMessage, setMovementMessage] = React.useState({
		timestamp: new Date(Date.now()).toISOString(),
		payload: {
			USERID: user,
			SPEED: 0,
			ANGLE: 0,
			BRAKE: 0,
			SRC: 'WEB'
		}
	});
	const [toggleRemoteControl, setToggleRemoteControl] = React.useState(false);

	//HANDLE MQTT EVENTS
	//Handle connection and subscription

	const topicsObj = {
		[`/${robotSerialId}/command/movement`]: { qos: 0 }
	};

	function handleConnect () {
		// e.preventDefault();
		client.on('connected', function () {
			console.log('MQTT disconnected');
		});
		console.log('MQTT Connected');
		setOnline(true);
		//provide object in this form:

		subscribeMQTT(topicsObj, client, handleUpdateMQ);
	}

	//handle disconnect

	// function handleDisconnect (client: MqttClient) {
	// 	client.end();
	// 	setTimeout(function () {
	// 		if (client.disconnected) {
	// 			console.log('MQTT Disconnected');
	// 			setOnline(false);
	// 		}
	// 	}, 500);
	// }

	//HandleReconect function

	// function handleReconnect () {
	// 	handleDisconnect(client);
	// 	setTimeout(function () {
	// 		if (client.disconnected) {
	// 			console.log('MQTT Reconnecting');
	// 			client.reconnect();
	// 			setOnline(true);
	// 		}
	// 	}, 2000);
	// }

	//Handle message
	function handleUpdateMQ (message: string, topic: string) {
		let read = true;
		if (topic.includes('/movement') || topic.includes('/customcommand')) {
			read = false;
		}
		try {
			const messageJSON = JSON.parse(message);

			const tempMessage = {
				timestamp: messageJSON.timestamp,
				read,
				message: JSON.stringify(messageJSON.payload),
				topic: topic,
				full: false
			};
			messageQueue.unshift(tempMessage);
			if (messageQueue.length > 10) {
				messageQueue.pop();
			}
			const tempMQ = [...messageQueue];
			setMessageQueue(tempMQ);
		} catch (err) {
			console.log(err);
		}
	}

	//handle remote control toggle
	function handleToggleRemoteControl (e: any) {
		setToggleRemoteControl(!toggleRemoteControl);
		client.publish(`/${robotSerialId}/command/enabled`, `{"remoteControl":"${toggleRemoteControl}"}`);
	}

	React.useEffect(() => {
		sendMQTTCommand(movementMessage, client, `/${robotSerialId}/command/movement`);
		// eslint-disable-next-line
	}, [movementMessage]);

	return (
		<Container maxWidth='xl'>
			<Box className={classes.dashComponents}>
				<Card className={classes.controlCard}>
					<Box className={classes.joystick}>
						<JoystickControl
							movementMessage={movementMessage}
							setMovementMessage={setMovementMessage}
							online={online}	hasFocus={hasFocus}
							toggleRemoteControl={toggleRemoteControl}
							handleToggleRemoteControl={handleToggleRemoteControl}/>
					</Box>
				</Card>
			</Box>
		</Container>
	);
};
