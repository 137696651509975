import React from 'react';
import { Button, Card, Checkbox, Modal, TextField, Typography } from '@mui/material';
import generalStyles from '../../style/styles';

interface pointsOverLinesModal {
    pointsOverLinesModal: boolean;
    setPointsOverLinesModal: (modalState: boolean) => void;
	hasIncludeWalkthroughPoints: boolean;
	setHasIncludeWalkthroughPoints: (incluseWalkthroughPoints: boolean) => void;
    createPointsOverLines: (distance: number) => void;
}


export const PointsOverLinesModal: React.FC<pointsOverLinesModal> = ({ pointsOverLinesModal, setPointsOverLinesModal, hasIncludeWalkthroughPoints, setHasIncludeWalkthroughPoints, createPointsOverLines }) => {
	const importedclasses = generalStyles();

	const [distanceBetweenPoints, setDistanceBetweenPoints] = React.useState<number>(0);

	const handleConfirm = () => {
		createPointsOverLines(+distanceBetweenPoints);
		setPointsOverLinesModal(false);
	};

	return (
		<Modal open={pointsOverLinesModal} onClose={() => setPointsOverLinesModal(false)}>
			<Card className={importedclasses.modal}>
				<div>
					<Typography variant={'subtitle1'}>What distance do you want between points?</Typography>
					<div className={importedclasses.modalDetails}>
						<Typography variant='subtitle2'>Distance in meters:</Typography> <TextField value={distanceBetweenPoints} variant="standard" onChange={(e) => setDistanceBetweenPoints(+e.target.value)}/>
						<div>
							<Checkbox name="checkedC" checked={hasIncludeWalkthroughPoints} onChange={(e) => setHasIncludeWalkthroughPoints(e.target.checked)}/> Create also walkthrough points in path vertices
						</div>
					</div>
					<div>
						<Button variant={'outlined'} className={importedclasses.modalCancel} onClick={() => setPointsOverLinesModal(false)}>Cancel</Button>
						<Button variant={'outlined'} className={importedclasses.modalConfirm} color="primary" onClick={handleConfirm}>Create</Button>
					</div>
				</div>
			</Card>
		</Modal>
	);
};