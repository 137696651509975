// UserUtil.js
// Let's assume these are the IDs for admin users. This is just for the sake of demonstration.
// In reality, you should use a more secure method for categorizing users.
const adminUsers = ['1'];
const lightboxUsers = ['7', '27', '28'];
const defaultUsers = ['2', '3', '4', '5', '6', '8', '9', '10',
	'11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
	'21', '22', '23', '24', '25', '26', '29', '30',
	'31', '32', '33', '34', '35', '36', '37', '38', '39', '40',
	'41', '42', '43', '44', '45', '46', '47', '48', '49', '50'];

export const isUserAdmin = (id: number | string) => {
	return adminUsers.includes(id.toString());
};

export const isUserLightbox = (id: number | string) => {
	return lightboxUsers.includes(id.toString());
};

export const isUserDefault = (id: number | string) => {
	return defaultUsers.includes(id.toString());
};