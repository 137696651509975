import React, { useEffect } from 'react';
import AndroidIcon from '@mui/icons-material/Android';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BuildIcon from '@mui/icons-material/Build';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GradientIcon from '@mui/icons-material/Gradient';
import LanguageIcon from '@mui/icons-material/Language';
// import SettingsIcon from '@mui/icons-material/Settings';
import { Button } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Link, useHistory } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import { isUserAdmin, isUserDefault, isUserLightbox } from '../../context/UserUtil';
import { logout } from '../../helpers/auth/auth';

export const useSidebarStyles = makeStyles((theme: Theme) =>
	createStyles({
		sidebar: {
			minWidth: '70px',
			width: '70px',
			height: '100%',
			backgroundColor: '#212940',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between'
		},
		button: { color: '#ffffff' },
		icons: { marginTop: '10px', marginBottom: '10px' }
	})
);

const handleLogout = (history: any, User: any) => {
	logout(User.setAuth);
	history.push('/login');
};

export const Sidebar: React.FC = () => {
	const classes = useSidebarStyles();
	const history = useHistory();
	const User = React.useContext(UserContext);

	useEffect(() => {
		// Force the component to re-render when UserType changes
		if (User.id) {
			// Temporary Fix for User Type
			if (isUserAdmin(User.id.toString())) {
				User.setUserType('admin');
			}

			if (isUserLightbox(User.id.toString())) {
				User.setUserType('lightbox');
			}

			if (isUserDefault(User.id.toString())) {
				User.setUserType('default');
			}
		}
	  }, []);

	return (
		<div className={classes.sidebar}>
			<div className={classes.icons}>
				<Link to={'/robots'} key='robots'>
					<Button className={classes.button}>
						<AndroidIcon />
					</Button>
				</Link>
				{(User.userType === 'admin') && (
					<>
						<Link to={'/jobs'} key='jobs'>
							<Button className={classes.button}>
								<FormatListBulletedIcon />
							</Button>
						</Link><Link to={'/geo-analytics'} key='Geo-analytics'>
							<Button className={classes.button}>
								<LanguageIcon />
							</Button>
						</Link><Link to={'/area-reports'} key='area-reports'>
							<Button className={classes.button}>
								<AssessmentIcon />
							</Button>
						</Link>
						<Link to={'/image-analysis'} key='image-analysis'>
							<Button className={classes.button}>
								<GradientIcon />
							</Button>
						</Link></>
				)}
				{(User.userType === 'default') && (
					<>
						<Link to={'/jobs'} key='jobs'>
							<Button className={classes.button}>
								<FormatListBulletedIcon />
							</Button>
						</Link><Link to={'/geo-analytics'} key='Geo-analytics'>
							<Button className={classes.button}>
								<LanguageIcon />
							</Button>
						</Link><Link to={'/area-reports'} key='area-reports'>
							<Button className={classes.button}>
								<AssessmentIcon />
							</Button>
						</Link>
						<Link to={'/image-analysis'} key='image-analysis'>
							<Button className={classes.button}>
								<GradientIcon />
							</Button>
						</Link></>
				)}
				{(User.userType === 'lightbox') && (
					<>
						<Link to={'/image-analysis'} key='image-analysis'>
							<Button className={classes.button}>
								<GradientIcon />
							</Button>
						</Link></>
				)}
			</div>
			<div className={classes.icons}>
				{(User.userType === 'default' || User.userType === 'admin') && (
					<>
						<Link to={'/backoffice'} key='backoffice'>
							<Button className={classes.button}>
								<BuildIcon />
							</Button>
						</Link></>
				)}
				{/* <Link to={'/settings'} key='settings'>
					<Button className={classes.button}>
						<SettingsIcon />
					</Button>
				</Link> */}
				<Link to={'/login'} onClick={() => handleLogout(history, User)} key='logout'>
					<Button className={classes.button}>
						<ExitToAppIcon />
					</Button>
				</Link>
			</div>
		</div>
	);
};
