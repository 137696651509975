import { Polygon } from '@turf/turf';
import axios from 'axios';
import { Feature } from 'geojson';
import mapboxgl from 'mapbox-gl';
import { UserArea } from '../user/user';

export interface GeoInfo {
    id: string;
    type: string;
    properties: {
		type?: string;
		links?: {
			type: string;
			link: string;
			data?: { alive: string };
		}[];
	};
    geometry: {
		coordinates: [number, number];
		type: string;
	};
}

export interface Coordinates {
	lat: number;
	lng: number;
}

export function getCenter () {
	const location = localStorage.getItem('location');
	let centerObj = { lng: -0.1526251313284058, lat: 51.53186540294928 };
	try {
		if (location) {
			centerObj = JSON.parse(location);
		}
	} catch (e) {
		// console.log(e);
	}
	return centerObj;
}

export function getBearing () {
	const location = localStorage.getItem('location');
	if (location !== null) {
		try {
			const bearing = JSON.parse(location).bearing;
			if (bearing !== null && !isNaN(bearing)) {
				return parseFloat(bearing);
			}
		} catch (err) {
			console.log(err);
		}
	}
	return 0.0;
}

export function getZoomAt () {
	const location = localStorage.getItem('location');
	if (location !== null) {
		const zoomAt = JSON.parse(location).zoomAt;
		if (zoomAt !== null && !isNaN(zoomAt)) {
			return parseInt(zoomAt);
		}
	}
	return 15;
}


//https://docs.mapbox.com/api/search/geocoding/#geocoding-response-object
export async function getPlaces (place: string, token: string) {
	const URL = 'https://api.mapbox.com/geocoding/v5/mapbox.places/' + place + '.json?access_token=' + token;
	let places: Feature[] = [];
	try {
		await axios.get(URL)
			.then((response) => {
				if (response.status === 200) places = [...response.data.features];
			});
		return places;
	} catch {
		return places;
	}
}

export function initMap (setClickLocation?: (coord: Coordinates) => void) {
	let mapRenderer: mapboxgl.Map;
	mapboxgl.accessToken = getMapToken();

	// Default style is color satellite
	let styleURL = 'mapbox://styles/mapbox/satellite-v9';

	const search = window.location.search;
	const params = new URLSearchParams(search);
	const getStyle = params.get('mapstyle');

	if (getStyle) {
		styleURL = 'mapbox://styles/e-nano/' + getStyle;
	}	// eslint-disable-next-line prefer-const
	mapRenderer = new mapboxgl.Map({
		container: 'map',
		style: styleURL,
		center: getCenter(),
		bearing: getBearing(),
		zoom: 4, // Initialized in 4, and then animate to 17.
		maxZoom: 550,
		minZoom: 4
	});

	mapRenderer.zoomTo(getZoomAt(), { duration: 2000 });

	mapRenderer.addControl(
		new mapboxgl.GeolocateControl({
			positionOptions: {
				enableHighAccuracy: true
			},
			trackUserLocation: true
		})
	);

	if (setClickLocation) {
		mapRenderer.on('click', (e) => {
			setClickLocation({ lat: e.lngLat.lat, lng: e.lngLat.lng });
		});
	}

	return mapRenderer;
}

export function getMapToken () {
	const TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
	if (!TOKEN) {
		throw new Error('Can\'t find mapbox access token');
	} else {
		return TOKEN;
	}
}

export function addLabelToMapArea (mapRender: mapboxgl.Map, userArea: UserArea, labelCoordinates: number[] ) {
	try {
		mapRender.addSource(`${userArea.name}`, {
			type: 'geojson',
			data: {
				'type': 'FeatureCollection',
				'features': [{
					'type': 'Feature',
					'properties': {
						'description': `${userArea.name}`
					},
					'geometry': {
						'type': 'Point',
						'coordinates': labelCoordinates
					}
				}]
			}
		});
		mapRender.addLayer({
			'id': userArea.name,
			'type': 'symbol',
			'source': `${userArea.name}`,
			'layout': {
				'text-field': ['get', 'description']
			},
			'paint': {
				'text-color': 'rgba(79, 255, 252, 0.7)'
			}
		});
	} catch (ex) {
		//Do Nothing
	}
}

// TODO: this function can be merged with addLabelToMapArea.
// Or change addLabelToMapArea so that it doesn't get the coordinates and calculates the position based on the area.
export function addLabelToMap (mapRender: mapboxgl.Map, text: string, labelCoordinates: number[] ) {
	try {
		mapRender.addSource(labelCoordinates[0]+ '-' +labelCoordinates[1], {
			type: 'geojson',
			data: {
				'type': 'FeatureCollection',
				'features': [{
					'type': 'Feature',
					'properties': {
						'description': `${text}`
					},
					'geometry': {
						'type': 'Point',
						'coordinates': labelCoordinates
					}
				}]
			}
		});
		mapRender.addLayer({
			'id': labelCoordinates[0]+ '-' +labelCoordinates[1],
			'type': 'symbol',
			'source': `${labelCoordinates[0]+ '-' +labelCoordinates[1]}`,
			'layout': {
				'text-field': ['get', 'description']
			},
			'paint': {
				'text-color': 'rgba(79, 255, 252, 0.7)'
			}
		});
		return labelCoordinates[0]+ '-' +labelCoordinates[1];
	} catch (ex) {
		//Do Nothing
		return '';
	}
}

export function getMapCanvas (mapRenderer: mapboxgl.Map): Polygon {
	const canvas = mapRenderer.getCanvas();
	const w = canvas.width;
	const h = canvas.height;
	const cUL = mapRenderer.unproject([0,0]).toArray();
	const cUR = mapRenderer.unproject([w,0]).toArray();
	const cLR = mapRenderer.unproject([w,h]).toArray();
	const cLL = mapRenderer.unproject([0,h]).toArray();

	return {
		type: 'Polygon',
		coordinates: [[cUL,cUR,cLR,cLL,cUL]]
	};
}
