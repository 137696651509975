import * as React from 'react';
import '../../style/App.css';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import logo from '../../resources/images/colour-logo.png';

export const useSidebarStyles = makeStyles((theme: Theme) =>
	createStyles({
		header: { backgroundColor: '#ffffff', display: 'flex', justifyContent: 'flex-start',
			overflow: 'hidden', top: '0', height: '70px', width: '100%', zIndex: 1000 },
		img: { height: '50px', width: 'auto', padding: '10px' }
	})
);

export const Header: React.FC = () => {
	const classes = useSidebarStyles();
	return (
		<div className={classes.header}>
			<a href='https://www.e-nano.io/' target='_blank' rel="noopener noreferrer">
				<img src={logo} className={classes.img} alt='Enano logo'/>
			</a>
		</div>
	);
};
