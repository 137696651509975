import mqtt, { MqttClient } from 'mqtt';
import { getStream } from '../../helpers/video';

interface IConnectMQTT {
	url: string;
	options: {
		port: number;
		clientId: string;
		username: string;
		password: string;
	};
	topics: any;
	setMessage: any;
}

interface IConnectStream {
	config: any;
	existingClient: any;
	streams: any;
	setStreams: any;
	setVideoLoading: any;
}

interface ISubscribeMQTT {
	client: MqttClient;
	topics: any;
}

export async function connectMQTT (props: IConnectMQTT) {
	const { url, options, topics, setMessage } = props;
	const client = mqtt.connect(url, options);
	if (topics) {
		client.subscribe(topics, (err, granted) => {
			if (granted) {
				granted.map((topic) => {
					console.log(`Subscribed to topic: ${JSON.stringify(topic)}`);
					return null;
				});
			}
			client.on('message', (topic, message) => {
				try {
					if (message !== undefined) {
						setMessage({ topic: topic, message: JSON.parse(message.toString()) });
					}
				} catch (err) {
					console.log(err);
					if (message) {
						console.log('Message: ' + message.toString());
					}
				}
			});
		});
	}
	return client;
}

export async function subscribeMQTT (props: ISubscribeMQTT) {
	const { client, topics } = props;
	client.subscribe(topics, (err, granted) => {
		granted.map((topic) => {
			console.log(`Subscribed to topic: ${JSON.stringify(topic)}`);
			return null;
		});
	});
	return client;
}

export async function connectStream (props: IConnectStream, robotID: string, streamID: string) {
	console.log('connectStream');
	const { config, existingClient, streams, setStreams, setVideoLoading } = props;
	const connection = new RTCPeerConnection(config);
	getStream(connection, existingClient, streams, setStreams, setVideoLoading, robotID, streamID);
}
