import * as React from 'react';
import { Grid } from '@mui/material';
import { CurrentTaskCard } from './CurrentTaskCard';

interface ITasks {
    message: any;
}

export const Tasks: React.FC<ITasks> = ({ message }) => {

	const [, setCurrentFinished] = React.useState(false);
	return (
		<Grid container spacing={2}>
			<Grid item xs= {12}>
				<CurrentTaskCard message={message} setCurrentFinished={setCurrentFinished}/>
			</Grid>
			<Grid item xs= {12}>
				{/* <NextTaskCard message={message} currentFinished={currentFinished} /> */}
			</Grid>
		</Grid>
	);
};
