import * as React from 'react';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import RoomIcon from '@mui/icons-material/Room';
import { Checkbox, IconButton, InputLabel, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { DeckGL, GeoJsonLayer } from 'deck.gl';
import MapGL, { FlyToInterpolator, MapContext, Marker, Popup } from 'react-map-gl';
import * as Job from '../../helpers/job/job';
import { isLatitude, isLongitude } from '../../helpers/location/location-parsing';
import { GeoInfo, getBearing, getCenter, getMapToken, getZoomAt } from '../../helpers/map/map';
import logoAvailable from '../../resources/images/logo-available.png';
import logoUnavailable from '../../resources/images/logo-unavailable.png';
import generalStyles from '../../style/styles';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		mapContainer: { position: 'relative', width: '100%', height: '100%' },
		waypointIcon: { height: '20px', width: '20px', color: 'rgb(0,191,255)', fill: 'rgb(0,191,255)', transform: 'translate(-10px, -10px)', zIndex: 11 },
		popUp: { zIndex: 12, maxWidth: 'auto', padding: '0px', margin: '0px', textAlign: 'center' },
		popupitems: { margin: '0px', padding: '0px', fontSize: '0.6rem' },
		waypointMarker: { cursor: 'pointer', zIndex: 11 }
	})
);

interface IMap {
    message: any;
    robotSerialId: string;
    robotId: number|undefined;
}

interface JobByIdConfiguration {
	scheduledDate: string;
	geoInfo: any[];
	robots: any[];
	state: string;
	robotsIDs: any[];
}

interface SingleRobot {
    [robotId: string]: RobotsWaypointTasks;
}


export interface RobotsWaypointTasks {
    [waypointHash: string] : [];
}

export const Map: React.FC<IMap> = ({ message, robotId }) => {
	const classes = useStyles();
	const generalClasses = generalStyles();
	const [checkButton, setCheckButton] = React.useState(false);
	const [viewport, setViewport] = React.useState<any>({
		latitude: getCenter().lat,
		longitude: getCenter().lng,
		zoom: getZoomAt(),
		bearing: getBearing() });
	const [geoJson, setGeoJson] = React.useState({
		type: 'FeatureCollection',
		features: [{
			type: 'Feature',
			properties: {},
			geometry: {
				type: 'MultiLineString',
				coordinates: [
					[
						[0, 0]
					]
				]
			}
		}]
	});
	const [lastMessageTime, setLastMessageTime] = React.useState(Date.now());
	const [robotAvailable, setRobotAvailable] = React.useState(false);
	const [seconds, setSeconds] = React.useState(0);
	const [defaultHide, setDefaultHide] = React.useState(true);
	const [toggleButton, setToggleButton] = React.useState<boolean>(true);
	const [currentActiveJob, setCurrentActiveJob] = React.useState<number>(0);
	const [geoInfo, setGeoInfo] = React.useState<GeoInfo[]|undefined>(undefined);
	const [savedTasks, setSavedTasks] = React.useState<{[code: string] : string[]}|undefined>(undefined);
	const [popupData, setPopupdata] = React.useState<GeoInfo|null>(null);
	const [robotTask, setRobotTask] = React.useState<string[]>([]);

	function pushGeoJson () {
		if (message.topic) {
			if (message.topic.includes('GPS')) {
				setCurrentActiveJob(message.message.active_job_id);
				try {
					const tempGeoJson = { ...geoJson };
					const tempCoordinates = [parseFloat(message.message.payload.value.longitude), parseFloat(message.message.payload.value.latitude)];
					if (isLongitude(tempCoordinates[0]) && isLatitude(tempCoordinates[1])) {
						if (tempCoordinates[0] && tempCoordinates[1]) {
							tempGeoJson.features[0].geometry.coordinates[0].unshift(tempCoordinates);
							if (tempGeoJson.features[0].geometry.coordinates[0].length>100) {
								tempGeoJson.features[0].geometry.coordinates[0].pop();
							}
							if (defaultHide) {
								tempGeoJson.features[0].geometry.coordinates[0].pop();
							}
							setToggleButton(false);
							setGeoJson(tempGeoJson);
							setDefaultHide(false);
						}
					}
				} catch (e) {
					console.log(e);
				}
			}
		}
	}

	function CustomMarker (props:any) {
		const context = React.useContext(MapContext);
		const { longitude, latitude, robotAvailable } = props;
		const [x, y] = context.viewport?.project([longitude, latitude]) || [0, 0];

		const markerStyle = {
			position: 'absolute',
			border: 'none',
			left: x-15,
			top: y-15,
			zIndex: 10
		} as React.CSSProperties;

		const markerImage = {
			height: '30px',
			width: '35px'
		} as React.CSSProperties;

		return (
			<div style={markerStyle} >
				{robotAvailable?
					<img src={logoAvailable} style={markerImage} alt='robot-icon-available'/>:
					<img src={logoUnavailable} style={markerImage} alt='robot-icon-unavailable'/>
				}
			</div>
		);
	}

	const layer = new GeoJsonLayer({
		id: 'geojson-layer',
		data: JSON.parse(JSON.stringify(geoJson)),
		lineWidthMinPixels: 2,
		getLineColor: [255, 255, 0]
	});

	function checkAvailability () {
		const timeSinceLast = (Date.now() - lastMessageTime)/1000;
		if (Math.round(timeSinceLast) > 10) {
			setRobotAvailable(false);
		} else {
			setRobotAvailable(true);
		}
	}

	function centerViewport () {
		if (geoJson.features[0].geometry.coordinates[0].length > 1) {
			setViewport({
				latitude: geoJson.features[0].geometry.coordinates[0][0][1],
				longitude: geoJson.features[0].geometry.coordinates[0][0][0],
				zoom: 18,
				transitionDuration: 3000,
				transitionInterpolator: new FlyToInterpolator()
			});
		}
	}

	const waypointMarker = (geoInfo: GeoInfo, index: number) => {
		if (geoInfo.geometry.type === 'Point' && savedTasks) {
			return (
				<Marker key={index} offsetTop={-10} className={classes.waypointMarker} longitude={geoInfo.geometry.coordinates[0]} latitude={geoInfo.geometry.coordinates[1]} >
					<div>
						<IconButton
							className={classes.waypointIcon}
							onClick={() => {
								setRobotTask(savedTasks[geoInfo.id]); setPopupdata(geoInfo);
							}}
							size="large">
							<RoomIcon/>
						</IconButton>
					</div>
				</Marker>
			);
		}
	};

	React.useEffect(() => {
		pushGeoJson();
		// getJobWaypoints(message); -- to be used for active job functionality
		setLastMessageTime(Date.now());
		if (checkButton) {
			centerViewport();
		}
	}, [message]);

	React.useEffect(() => {
		const jobGeoDetails: GeoInfo[] = [];
		const robotTask: {[code: string] :[]} = {};
		if (currentActiveJob >= 0 && robotId !== undefined) {
			Job.getJobById(currentActiveJob).then((job: JobByIdConfiguration) => {
				job.robots.forEach((singleRobot: SingleRobot) => {
					if (parseInt(Object.keys(singleRobot)[0]) === robotId) {
						job.geoInfo.forEach((jobGeoInfo: GeoInfo) => {
							const jobGeoInfoId: string = jobGeoInfo.id;
							if (jobGeoInfo.id in singleRobot[robotId]) {
								jobGeoDetails.push(jobGeoInfo);
								robotTask[jobGeoInfo.id] = singleRobot[robotId][jobGeoInfoId];
							}
						});
					}
				});
				setGeoInfo(jobGeoDetails);
				setSavedTasks(robotTask);
			});
		}
	}, [currentActiveJob]);

	React.useEffect(() => {
		const timer = setTimeout(() => {
			setSeconds(seconds+1);
			checkAvailability();
		}, 1000);
		return () => clearTimeout(timer);
	}, [seconds]);

	return (
		<div className={classes.mapContainer}>
			<MapGL
				{...viewport}
				width="100%"
				height="100%"
				mapStyle="mapbox://styles/mapbox/satellite-v9"
				onViewportChange={setViewport}
				mapboxApiAccessToken={getMapToken()}
			>
				{geoInfo && geoInfo.map(waypointMarker)}
				{popupData && (
					<Popup anchor='top' className={classes.popUp} longitude={popupData.geometry.coordinates[0]} latitude={popupData.geometry.coordinates[1]} closeOnClick={true} tipSize={5} onClose={() => setPopupdata(null)}>
						<Typography style={{ fontSize: '0.7rem', fontWeight: 'bold' }} variant='subtitle1'>TASKS</Typography>
						{robotTask.map((task: string, index: number) => <Typography key={index} className={classes.popupitems} variant='body1'> {task} </Typography>)}
					</Popup>
				)}
				{geoJson.features[0].geometry.coordinates.length > 1?
					<></>:
					<CustomMarker longitude={geoJson.features[0].geometry.coordinates[0][0][0]} latitude={geoJson.features[0].geometry.coordinates[0][0][1]} robotAvailable={robotAvailable}/>
				}
				<DeckGL viewState={viewport} layers={[layer]}/>
			</MapGL>
			<div className={generalClasses.mapButtons}>
				<div className={generalClasses.mapButtonContainer} onClick={() => {
					setCheckButton(!checkButton);
				}}>
					<InputLabel className={generalClasses.mapButtonContainer}>Follow robot</InputLabel>
					<Checkbox className={generalClasses.mapCheckboxIcon} color='primary' size='small' checked={checkButton}/>
				</div>
				<div className={generalClasses.mapButtonContainer} onClick={centerViewport}>
					<InputLabel className={generalClasses.mapButtonContainer} disabled={Boolean(toggleButton)}>Center robot</InputLabel>
					<IconButton
						className={generalClasses.mapCenterButton}
						disableRipple={true}
						color='inherit'
						disabled={toggleButton}
						size="large"><MyLocationIcon/></IconButton>
				</div>
			</div>
		</div>
	);
};
