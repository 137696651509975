import * as React from 'react';
import { Button, Divider, Paper, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import * as Auth from '../../helpers/auth/auth';
import logo from '../../resources/images/colour-logo.png';
import style from '../../style/styles';

export const ForgotPassword: React.FC = () => {
	const classes = style();
	const history = useHistory();

	const [email, setEmail] = React.useState('');
	const [hasErrors, setHasErrors] = React.useState(false);
	const [textColor, setTextColor] = React.useState('#ffffff');
	const { enqueueSnackbar } = useSnackbar();

	const buttonStyle = {
		color: textColor,
		transition: 'color 0.5s'
	};

	const onChangeEmail = (e: any) => {
		const email = e.target.value;
		setEmail(email);
	};

	const sendEmail = (() => {
		if (validateForm()) {
			setHasErrors(false);
			try {
				Auth.forgotPassword(email, function () {
					history.push('/login');
					enqueueSnackbar('An email has been sent to your registered address. Please follow the link included in the email.');
				}, function () {
					enqueueSnackbar('Password reset failed. Please contact support.', { variant: 'error', autoHideDuration: 10000 });
				});
			} catch (e) {
				console.log(e);
			}
		} else {
			setHasErrors(true);
		}

	});

	const validateForm = () => {
		const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return emailRegex.test(String(email).toLowerCase());
	};

	React.useEffect(() => {
		const handleKeyDown = (event: any) => {
			if (event.keyCode === 13) {
				sendEmail();
			}
		};
		window.addEventListener('keydown', handleKeyDown);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	});


	return (
		<div className='Login'>
			<Paper elevation={1} className={classes.paperLogin}>
				<form>
					<figure>
						<img src={logo} alt='Sign In logo'/>
					</figure>
					<Typography variant='subtitle1'> {' '} Forgot Password{' '}</Typography>
					<Divider/>
					<br/>
					<TextField fullWidth variant='outlined' margin='dense' size='medium' label='Email' autoComplete='off' onChange={onChangeEmail} />
					<div className={classes.errorMessageDiv}>
						{(hasErrors)?<p className={classes.errorText}> Invalid email format.</p> : null}
					</div>
					<Button variant='contained' fullWidth style={buttonStyle} onMouseEnter={() => {
						return setTextColor('#6f6f6f');
					}} onMouseLeave={() => {
						return setTextColor('#ffffff');
					}} className={classes.submitLogin} onClick={sendEmail}> {' '}Send{' '} </Button>
				</form>
			</Paper>
		</div>
	);
};
