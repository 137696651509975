import React from 'react';
import { Button, Card, Checkbox, Modal, TextField, Typography } from '@mui/material';
import generalStyles from '../../style/styles';

interface pointsGridModal {
    pointsGridModal: boolean;
    setPointsGridModal: (modalState: boolean) => void;
    createPointsGrid: (pointsDistance: number, addRandomDeviation: boolean) => void;
}


export const PointsGridModal: React.FC<pointsGridModal> = ({ pointsGridModal, setPointsGridModal, createPointsGrid }) => {
	const importedclasses = generalStyles();

	const [pointsDistance, setPointsDistance] = React.useState<number>(5);
	const [addsRandomDeviation, setAddsRandomDeviation] = React.useState<boolean>(false);

	const handleConfirm = () => {
		createPointsGrid(+pointsDistance, addsRandomDeviation);
		setPointsGridModal(false);
		setAddsRandomDeviation(false);
	};

	return (
		<Modal open={pointsGridModal} onClose={() => setPointsGridModal(false)}>
			<Card className={importedclasses.modal}>
				<div>
					<Typography variant={'subtitle1'}>What distance do you want between points?</Typography>
					<div className={importedclasses.modalDetails}>
						<div>
							<Typography variant='subtitle2'>Distance between points:</Typography> <TextField variant="standard" value={pointsDistance} onChange={(e) => setPointsDistance(+e.target.value)}/>
						</div>
						<div>
							<Checkbox name="checkedC" value={addsRandomDeviation} onChange={(e) => setAddsRandomDeviation(e.target.checked)}/> Add random displacement
						</div>
					</div>
					<div>
						<Button variant={'outlined'} className={importedclasses.modalCancel} onClick={() => setPointsGridModal(false)}>Cancel</Button>
						<Button variant={'outlined'} className={importedclasses.modalConfirm} color="primary" onClick={handleConfirm}>Create</Button>
					</div>
				</div>
			</Card>
		</Modal>
	);
};
