import React from 'react';
import { Grid, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Sensor } from '../../helpers/sensors/sensors';

const useStyles = makeStyles(() =>
	createStyles({
		ZoneSummaryCard: { height: '300px', minHeight: '200px', width: '100%', marginTop: '0px', marginLeft: '0px', paddingLeft: '0px', paddingTop: '0px', alignItems: 'center', justifyContent: 'center' },
		itemTitle: { display: 'flex', paddingLeft: '15px',
			'& .MuiTypography-root': { fontSize: '0.8em', color: '#6c6c6c' } },
		itemValue: { display: 'flex',
			'& .MuiTypography-root': { color: '#000000', fontWeight: 500, textAlign: 'left' } }
	})
);

interface ZoneSummary {
    sensorData: Sensor[];
    zoneName: string;
}

export const ZoneSummary:React.FC<ZoneSummary> = ({sensorData, zoneName}: ZoneSummary) => {

	const classes = useStyles();

	const [moistureMin, setMoistureMin] = React.useState<number>(0.0);
	const [moistureAvg, setMoistureAvg] = React.useState<number>(0.0);
	const [moistureMax, setMoistureMax] = React.useState<number>(0.0);

	const [temperatureMin, setTemperatureMin] = React.useState<number>(0.0);
	const [temperatureAvg, setTemperatureAvg] = React.useState<number>(0.0);
	const [temperatureMax, setTemperatureMax] = React.useState<number>(0.0);

	const [compactionMin, setCompactionMin] = React.useState<number>(0.0);
	const [compactionAvg, setCompactionAvg] = React.useState<number>(0.0);
	const [compactionMax, setCompactionMax] = React.useState<number>(0.0);

	React.useEffect(() => {
		const filteredMoistureFlatData = sensorData.filter((sensorValue: Sensor) => {
			return sensorValue.type === 'moisture';
		}).map((sensorValue: Sensor) => {
			return parseFloat(JSON.parse(sensorValue.payload).value) * 100;
		});
		const moistureSum = filteredMoistureFlatData.reduce((prev, current) => {
			return prev + current;
		},0);
		setMoistureAvg(moistureSum / filteredMoistureFlatData.length);
		setMoistureMin(Math.min(...filteredMoistureFlatData));
		setMoistureMax(Math.max(...filteredMoistureFlatData));

		const filteredTemperatureFlatData = sensorData.filter((sensorValue: Sensor) => {
			return sensorValue.type === 'temperature';
		}).map((sensorValue: Sensor) => {
			return parseFloat(JSON.parse(sensorValue.payload).value);
		});
		const temperatureSum = filteredTemperatureFlatData.reduce((prev, current) => {
			return prev + current;
		},0);
		setTemperatureAvg(temperatureSum / filteredTemperatureFlatData.length);
		setTemperatureMin(Math.min(...filteredTemperatureFlatData));
		setTemperatureMax(Math.max(...filteredTemperatureFlatData));

		const filteredCompactionFlatData = sensorData.filter((sensorValue: Sensor) => {
			return sensorValue.type === 'load';
		}).map((sensorValue: Sensor) => {
			return parseFloat(JSON.parse(sensorValue.payload).value);
		});
		const compactionSum = filteredCompactionFlatData.reduce((prev, current) => {
			return prev + current;
		},0);
		setCompactionAvg(compactionSum / filteredCompactionFlatData.length);
		setCompactionMin(Math.min(...filteredCompactionFlatData));
		setCompactionMax(Math.max(...filteredCompactionFlatData));
	},[sensorData]);

	const numberOfPoints = () => {
		let numberOfPoints = 0;
		sensorData.forEach((measurement: Sensor) => {
			if (measurement.type !== 'payload') {
				numberOfPoints++;
			}
		});
		if (numberOfPoints === 1) {
			return '1 data point';
		}
		return `${numberOfPoints} data points`;
	};

	return (
		<div>
			<Grid container spacing={1} className={classes.ZoneSummaryCard}>
				{ zoneName !== '' ?
					<>
						<Grid item xs={5} className={classes.itemTitle}>
							<Typography variant='body1'>Moisture [min/avg/max]: </Typography>
						</Grid>
						<Grid item xs={7} className={classes.itemValue}>
							<Typography variant='subtitle2'>
								{ moistureMin && moistureAvg && moistureMax ?
									moistureMin.toFixed(2) + '% / ' + moistureAvg.toFixed(2) + '% / ' + moistureMax.toFixed(2) + '%'
									:
									'Not available'
								}
							</Typography>
						</Grid>
						<Grid item xs={5} className={classes.itemTitle}>
							<Typography variant='body1'>Soil temperature [min/avg/max]: </Typography>
						</Grid>
						<Grid item xs={7} className={classes.itemValue}>
							<Typography variant='subtitle2'>
								{ temperatureMin && temperatureAvg && temperatureMax ?
									temperatureMin.toFixed(2) + 'C / ' + temperatureAvg.toFixed(2) + 'C / ' + temperatureMax.toFixed(2) + 'C'
									:
									'Not available'
								}
							</Typography>
						</Grid>
						<Grid item xs={5} className={classes.itemTitle}>
							<Typography variant='body1'>Compaction [min/avg/max]: </Typography>
						</Grid>
						<Grid item xs={7} className={classes.itemValue}>
							<Typography variant='subtitle2'>
								{ compactionMin && compactionAvg && compactionMax ?
									compactionMin.toFixed(2) + ' / ' + compactionAvg.toFixed(2) + ' / ' + compactionMax.toFixed(2)
									:
									'Not available'
								}
							</Typography>
						</Grid>
						<Grid item xs={5} className={classes.itemTitle}>
							<Typography variant='body1'># of measurements: </Typography>
						</Grid>
						<Grid item xs={7} className={classes.itemValue}>
							<Typography variant='subtitle2'>
								{numberOfPoints()}
							</Typography>
						</Grid>
					</>
					:
					<div style={{ color: '#6f6f6f' }}>
						No zone selected on heatmap
					</div>
				}
			</Grid>
		</div>
	);
};