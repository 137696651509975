import React, { useEffect } from 'react';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import RoomIcon from '@mui/icons-material/Room';
import { Box, Button, Grid, IconButton, Link, Modal, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import * as Turf from '@turf/turf';
import { InteractiveMap, Layer, LayerProps, Marker, Popup, Source, ViewportProps } from 'react-map-gl';
import { buildFileUrl } from '../../helpers/main';
import { GeoInfo, getCenter, getMapToken } from '../../helpers/map/map';
import { Sensor } from '../../helpers/sensors/sensors';
import { UserArea } from '../../helpers/user/user';


const useStyles = makeStyles(() =>
	createStyles({
		areaMapCard: { width: '100%', height: '300px', marginTop: '0px', marginLeft: '0px', paddingLeft: '0px', paddingTop: '0px', minHeight: '200px', alignItems: 'center', justifyContent: 'center' },
		mapContainer: { position: 'relative', width: '100%', height: '100%' },
		waypointIcon: { height: '20px', width: '20px', color: 'rgb(0,191,255)', fill: 'rgb(0,191,255)', transform: 'translate(-10px, -10px)', zIndex: 11 },
		modalHeader: { height: '45px', width: '100%', backgroundColor: 'rgba(110,110,110,0.5)', borderRadius: '10px 10px 0 0' },
		modalHeaderContent: { display: 'inline-block', width: 'calc(100% - 130px)', paddingLeft: '65px', paddingTop: '15px', height: 'calc(100% - 15px)', verticalAlign: 'middle' },
		modalHeaderClose: { display: 'inline-block', width: '65px', height: '100%', verticalAlign: 'top', paddingTop: '5px' },
		popUp: { zIndex: 12, maxWidth: 'auto', padding: '0px', margin: '0px', textAlign: 'center' },
		popupitems: { margin: '0px', padding: '0px', fontSize: '0.6rem' },
		waypointMarker: { cursor: 'pointer', zIndex: 11 },
		modal: { position: 'absolute', height: '90%', width: '90%', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'rgba(219,219,219,0.9)', borderRadius: '10px', textAlign: 'center',
			'& img': { maxWidth: '100%', maxHeight: '100%', height: 'calc(100% - 50px)' }
		}
	})
);

interface UserAreaMap {
    userArea: UserArea | undefined;
	sensorData: Sensor[];
}

export const UserAreaMap: React.FC<UserAreaMap> = ({ userArea, sensorData }: UserAreaMap) => {
	const classes = useStyles();
	const [imgUrl, setImgUrl] = React.useState<string>('');
	const [viewport, setViewport] = React.useState<ViewportProps>({
		latitude: getCenter().lat,
		longitude: getCenter().lng,
		zoom: 15 });
	const [userAreaGeoJson, setUserAreaGeoJson] = React.useState<GeoJSON.FeatureCollection>();
	const [geoInfo, setGeoInfo] = React.useState<GeoInfo[]|undefined>(undefined);
	const [popupData, setPopupdata] = React.useState<GeoInfo|null>(null);
	const [popupLinks, setPopupLinks] = React.useState<{
			type: string;
			link: string;
			data?: { alive: string };
	}[]>([]);
	const [open, setOpen] = React.useState(false);
	const [alive, setAlive] = React.useState<string>('');
	const handleClose = () => setOpen(false);

	useEffect(() => {
		setImgUrl('');
		setPopupLinks([]);
		setPopupdata(null);
		setGeoInfo(undefined);
		if (userArea) {
			const areaCenter = Turf.centroid(userArea.area);
			setViewport({
				longitude: areaCenter.geometry.coordinates[0],
				latitude: areaCenter.geometry.coordinates[1],
				zoom: userArea.zoomAt ? userArea.zoomAt : viewport.zoom,
				bearing: userArea.bearing ? userArea.bearing : viewport.bearing
			});
			setUserAreaGeoJson({
				type: 'FeatureCollection',
				features: [{
					type: 'Feature',
					geometry: {
						type: 'Polygon',
						coordinates: userArea.area.coordinates
					},
					properties: {}
				}]
			});
		} else {
			setUserAreaGeoJson(undefined);
		}
	}, [userArea]);

	useEffect(() => {
		setPopupdata(null);
		if (sensorData) {
			const sensorGeoDetails: GeoInfo[] = [];
			sensorData.forEach((sensorValue: Sensor, index: number) => {
				if (sensorValue.type === 'photos') {
					const alive = ''+JSON.parse(sensorValue.payload)?.value?.alive;
					// const rbgLink = { type: 'rgb', link: ''+JSON.parse(sensorValue.payload)?.value?.rgb, data: { alive: alive } };
					const rbgCropLink = { type: 'Colour', link: ''+JSON.parse(sensorValue.payload)?.value?.rgb_crop, data: { alive: alive } };
					// const irLink = { type: 'ir', link: ''+JSON.parse(sensorValue.payload)?.value?.ir, data: { alive: alive } };
					const irCropLink = { type: 'Infrared', link: ''+JSON.parse(sensorValue.payload)?.value?.ir_crop, data: { alive: alive } };
					const mixLink = { type: 'Mixed', link: ''+JSON.parse(sensorValue.payload)?.value?.mix, data: { alive: alive } };
					const linksArray = [];
					// linksArray.push(rbgLink, rbgCropLink, irLink, irCropLink, mixLink);
					linksArray.push(rbgCropLink, irCropLink, mixLink);
					const tempGeoDetails: GeoInfo = {
						id: ''+index,
						type: 'point',
						properties: { type: 'photo', links: linksArray },
						geometry: {
							coordinates: [sensorValue.point.coordinates[0], sensorValue.point.coordinates[1]],
							type: 'point'
						}
					};
					sensorGeoDetails.push(tempGeoDetails);
				}
			});
			setGeoInfo(sensorGeoDetails);
		}
	}, [sensorData]);

	const waypointMarker = (geoInfo: GeoInfo, index: number) => {
		if (geoInfo.geometry.type === 'point') {
			return (
				<Marker key={index} offsetTop={-10} className={classes.waypointMarker} longitude={geoInfo.geometry.coordinates[0]} latitude={geoInfo.geometry.coordinates[1]} >
					<div>
						<IconButton
							className={classes.waypointIcon}
							onClick={() => {
								if (geoInfo.properties.links) {
									setPopupLinks(geoInfo.properties.links);
								}
								setPopupdata(geoInfo);
							}}
							size="large">
							<RoomIcon/>
						</IconButton>
					</div>
				</Marker>
			);
		}
	};

	const layerStyle: LayerProps = {
		id: 'polygon',
		type: 'fill',
		paint: {
			'fill-color': '#4E3FC8',
			'fill-outline-color': '#ffffff',
			'fill-opacity': 0.4
		}
	};

	const handleOpen = (imgUrl: string, alive: string) => {
		setOpen(true);
		setImgUrl(imgUrl);
		setAlive(alive+'%');
	};

	const buildLink = (fileName: string, label: string, data: { alive: string }) => {
		if (fileName !== undefined && fileName !== '') {
			return <Typography className={classes.popupitems} variant='body1'><Link href={buildFileUrl('getfileurl/'+fileName)} variant="body2" onClick={(e) => {
				e.preventDefault(); // avoids the page redirect allowing to show the link on hover (and copy).
				handleOpen(buildFileUrl('getfileurl/'+fileName), data.alive);
			}}>{label}</Link></Typography>;
		} else {
			return <>{label}: Invalid file</>;
		}
	};

	return (
		<Grid container className={classes.areaMapCard}>
			{ userArea ?
				<div className={classes.mapContainer}>
					<InteractiveMap
						{...viewport}
						width="100%"
						height="100%"
						mapStyle="mapbox://styles/mapbox/satellite-v9"
						onViewportChange={setViewport}
						mapboxApiAccessToken={getMapToken()}
					>
						{geoInfo && geoInfo.map(waypointMarker)}
						{popupData ?
							<Popup anchor='top' className={classes.popUp} longitude={popupData.geometry.coordinates[0]} latitude={popupData.geometry.coordinates[1]} closeOnClick={true} tipSize={5} onClose={() => setPopupdata(null)}>
								<Typography style={{ fontSize: '0.7rem', fontWeight: 'bold' }} variant='subtitle1'>Photos</Typography>
								{popupLinks.map((element: { type: string; link: string; data?: { alive: string } }, i: number) => {
									return <div key={i}> { buildLink(element.link, element.type, element.data ? element.data : { alive: '' }) }</div>;
								})}
							</Popup>
							:
							<></>
						}

						{userAreaGeoJson ?
							<Source id="my-data" type="geojson" data={userAreaGeoJson}>
								<Layer {...layerStyle} />
							</Source>
							:
							<></>
						}
					</InteractiveMap>
					<Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
						<Box className={classes.modal}>
							<div className={classes.modalHeader}>
								<div className={classes.modalHeaderContent}>
									<strong>Photo analysis / </strong> Coverage: {alive}
								</div>
								<div className={classes.modalHeaderClose}>
									<Button onClick={handleClose}><CancelOutlinedIcon/></Button>
								</div>
							</div>
							<img src={imgUrl} alt='Photo unavailable'/>
						</Box>
					</Modal>
				</div>
				:
				<div style={{ color: '#6f6f6f' }}>
					Area map unavailable
				</div>
			}
		</Grid>
	);
};
