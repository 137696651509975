import React from 'react';
import { Button, Card, Modal, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as Job from '../../helpers/job/job';
import importedStyles from '../../style/styles';

interface jobCancelModal {
    jobCancelModal: boolean;
    setJobCancelModal: (modalState:boolean)=> void;
    id: string;
    title: any;
}

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(1)
		}
	}
}));

export const JobCancelModal: React.FC<jobCancelModal> = ({ jobCancelModal, setJobCancelModal, id, title }) => {
	const importedclasses = importedStyles();
	const classes = useStyles();

	const handleCancel = () => {
		Job.updateJobState(parseInt(id), { state: 'cancelled' }).then((res) => {
			setJobCancelModal(false);
			window.location.reload();
		});
	};

	return (
		<Modal open={jobCancelModal} onClose={() => setJobCancelModal(false)}>
			<Card className={importedclasses.modal}>
				<div>
					<Typography variant={'subtitle1'}>Are you sure you want to cancel the job?</Typography>
					<div className={importedclasses.details}>
						<Typography variant='subtitle2'>Scheduled for: {title}</Typography>
						<Typography variant='subtitle2'>Job Id: {id}</Typography>
					</div>
					<div className={classes.root}>
						<Button style={{ border: '1px solid rgba(0, 0, 0, 0.23)' }} variant={'outlined'} onClick={() => setJobCancelModal(false)}>No, keep job</Button>
						<Button variant={'outlined'} color="error" onClick={handleCancel}>Yes, Cancel job</Button>
					</div>
				</div>
			</Card>
		</Modal>
	);
};