import React, { useEffect } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { Fab, Slider, Typography , Divider, Modal, Box, Link } from '@mui/material';
import Card from '@mui/material/Card';
// import { Mark } from '@mui/material/Slider';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import bbox from '@turf/bbox';
import { Units, lineString, Geometry }  from '@turf/helpers';
import turfgrid from '@turf/hex-grid';
import * as Turf from '@turf/turf';
import { Feature } from 'geojson';
import mapboxgl from 'mapbox-gl';
import Moment from 'moment';
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from 'recharts';
import { DrawingNotch } from '../components/newjob/DrawingNotch';
import { buildFileUrl } from '../helpers/main';
import { Coordinates, getMapCanvas, getMapToken, initMap } from '../helpers/map/map';
import * as sensors from '../helpers/sensors/sensors';
import moistureScaleImage from '../static/moisture-scale.png';
import generalStyles from '../style/styles';
// Ignoring mapbox-gl-draw typos until they expose @types oficially for typescript.
// @ts-ignore

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		box: { width: 'calc(100vw - 70px)' },
		slider: { width: 'calc(100vw - 70px)' },
		map: { height: '66vh', outline: 'none' },
		root: { width: '80%',  marginLeft: '10%'},
		button: { marginRight: theme.spacing(1) },
		canvasOutline: { outline: 'none' },
		margins: { width: '85vw', left: '15px', right: '15px', marginTop: '50px', justifyContent: 'center' },
		sensors: { width: '14%', position: 'absolute', top: '17px', right: '20px', opacity: '80%', zIndex: 2 },
		sizeSelector: { width: '77%', position: 'absolute', marginLeft: '10px', marginTop: '10px', padding: '20px 20px 10px 20px', opacity: '80%', background: 'white', borderRadius: '15px', zIndex: 2 },
		sensorButton: { width: '100%', background: '#4CAEF6', color: 'white', alignItems: 'center', margin: theme.spacing(1) },
		hexDetailsBox: { width: '20%', height: '400px', position: 'absolute', left: '20px', top: '25px', opacity: '80%', background: 'white', borderRadius: '15px', zIndex: 2 },
		hexDetailsBoxTitle: { minHeight: '20px', marginTop: '10px', fontSize: '1.2em', fontWeight: 800 },
		hexDetailsBoxSubtitle: { fontStyle: 'italic', marginBottom: '10px', fontSize: '0.9em' },
		referenceBox: { width: '70px', height: '400px', left: '22%', top: '25px', position: 'absolute', bottom: '10px',  borderRadius: '15px', minHeight: '40px', background: 'white' },
		colourScale: { background: 'linear-gradient(to bottom, rgba(252, 28, 3, 0) 0%, rgba(252, 28, 3, 1) 5%, rgba(252, 240, 3, 1) 40%, rgba(2, 179, 28, 1) 70%, rgba(66, 245, 215, 1) 90%, rgba(0, 102, 204, 1) 100%)', minHeight: '360px', width: '20px', marginLeft: '5px', marginTop: '20px' },
		scaleImage: { height: '97%', width: '90%', marginTop: '6px' },
		valuesContainer: { padding: '10px' },
		plotContainer: { marginLeft: '1%', textAlign: 'center', minHeight: '360px', minWidth: '440px', fontStyle: 'italic', fontWeight: 100 },
		notch: { borderRadius: '15px', justifyContent: 'space-around', color: '#363638', background: '#ff8a84', padding: '10px', width: '240px', alignContent: 'center', position: 'absolute', opacity: '80%', left: '43%', zIndex: 1, marginTop: '110px', fontSize: '0.8em', fontStyle: 'italic' },
		modal: { position: 'absolute', height: '90%', width: '90%', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'rgba(219,219,219,0.9)', borderRadius: '10px', textAlign: 'center',
			'& img': { maxWidth: '100%', maxHeight: '100%' }
		}
	})
);

let mapRenderer: mapboxgl.Map;

export const GeoAnalytics: React.FC = () => {
	const classes = useStyles();
	const generalClasses = generalStyles();
	const [place, setPlace] = React.useState<any>();
	const [typesPerDate, setTypesPerDate] = React.useState(new Map<Date, string[]>());
	const [sliderDate, setSliderDate] = React.useState<Date>();
	const [marks, setMarks] = React.useState<any>([]);
	const [valuesToDate, setValuesToDate] = React.useState<Map<any, Date>>();
	const [pointValues, setPointValues] = React.useState<any>();
	const [moisturePlotValues, setMoisturePlotValues] = React.useState<any[]>();
	const [compactionPlotValues, setCompactionPlotValues] = React.useState<any[]>();
	const [temperaturePlotValues, setTemperaturePlotValues] = React.useState<any[]>();
	const [compaction, setCompaction] = React.useState<boolean>(false);
	const [moisture, setMoisture] = React.useState<boolean>(false);
	const [temperature, setTemperature] = React.useState<boolean>(false);
	const [ndvi, setNdvi] = React.useState<boolean>(false);
	const [hardness, setHardness] = React.useState<boolean>(false);
	const [slider, setSlider] = React.useState<boolean>(true);
	const [sliderValue, setSliderValue] = React.useState<number>(90);
	const [selectedSensor, setSelectedSensor] = React.useState<'temperature' | 'moisture' | 'compaction' | 'ndvi' | 'hardness' | undefined>();
	const [selectedHexValue, setSelectedHexValue] = React.useState<string>('');
	const [zoomOverLimit, setZoomOverLimit] = React.useState<boolean>(false);
	const [hexSize, setHexSize] = React.useState<number>(4.0);
	const [open, setOpen] = React.useState(false);
	const handleClose = () => setOpen(false);
	const [imgUrl, setImgUrl] = React.useState<string>('');
	const [handlerAdded, setHandlerAdded] = React.useState(false);
	const [mapClickLocation, setMapClickLocation] = React.useState<Coordinates>({ lat: 0, lng: 0 });
	let hoveredHexId: number;

	//Steps constants
	const MAP_SELECTION = 0;
	const NONE = -1;
	const MAP_TOKEN: string = getMapToken();

	//Initial useEffect, only rendered once.
	useEffect(() => {

		mapRenderer = initMap(setMapClickLocation);

		//If the map changes the view then request for dates and sensors types
		mapRenderer.on('moveend', () => {
			sensors.getSensorsTypePerDate(getMapCanvas(mapRenderer)).then((ans: sensors.SensorTypes[]) => {
				const mapDateType = new Map<Date, string[]>();
				ans.forEach((each: sensors.SensorTypes) => {
					const knownTypes = each.types.filter((t) => t == 'moisture' || t == 'temperature' || t == 'load' || t == 'photos' || t == 'photo'
					|| t == 'ndvi' || t == 'multispectral' || t == 'thermal' || t == 'hardness');
					if ( knownTypes.length > 0 ) {
						mapDateType.set(each.date, knownTypes);
					}
				});
				setTypesPerDate(mapDateType);

				if (mapDateType.size === 0) {
					setSlider(true);
				} else {
					setSlider(false);
				}
			});
		});

		mapRenderer.on('zoomend', () => {
			if ( mapRenderer.getZoom() <= 16 ) {
				setZoomOverLimit(true);
			} else {
				setZoomOverLimit(false);
			}
		});

		//This is only for dev purposes, deleting watermars/trademarks/links from mapbox
		document.getElementsByClassName('mapboxgl-control-container')[0].remove();
	}, []);

	useEffect(() => {
		if (place) {
			if (['poi', 'address', 'postcode', 'neighborhood'].includes(place.place_type[0])) {
				mapRenderer.flyTo({center: place.center, zoom: 17});
			} else {
				mapRenderer.fitBounds(place.bbox);
			}
		}
	}, [place]);

	useEffect(() => {
		toMarks();
	}, [typesPerDate]);

	useEffect(() => {
		updateSensorButtons();
	}, [sliderDate]);

	useEffect(() => {
		if (selectedSensor !== undefined) {
			onSelectedSensor(selectedSensor);
		}
	}, [selectedSensor]);

	const updateSensorButtons = () => {
		const prevSelectedSensor = selectedSensor;
		clean();
		if (sliderDate) {
			const typesForSelectedDate = typesPerDate.get(sliderDate);
			typesForSelectedDate?.forEach((type: string) => {
				if (type === 'load') {
					setCompaction(true);
				} else if (type === 'temperature') {
					setTemperature(true);
				} else if (type === 'moisture') {
					setMoisture(true);
				} else if (type === 'multispectral') {
					setNdvi(true);
				} else if (type === 'hardness') {
					setHardness(true);
				}
			});
			if (typesForSelectedDate && typesForSelectedDate.length >= 1) {
				const filteredTypes = typesForSelectedDate.filter( (t) => t === 'load' || t === 'temperature' || t === 'moisture' || t === 'ndvi');
				if (filteredTypes.length === 1) {
					if (filteredTypes[0] === 'load') {
						setSelectedSensor('compaction');
						onSelectedSensor('compaction');
					} else if (filteredTypes[0] === 'temperature') {
						setSelectedSensor('temperature');
						onSelectedSensor('temperature');
					} else if (filteredTypes[0] === 'moisture') {
						setSelectedSensor('moisture');
						onSelectedSensor('moisture');
					} else if (filteredTypes[0] === 'multispectral') {
						setSelectedSensor('ndvi');
						onSelectedSensor('ndvi');
					}
				} else {
					if (!zoomOverLimit) {
						if (!hoveredHexId || !mapRenderer.getSource('grid') || mapRenderer.getFeatureState({ source: 'grid', id: hoveredHexId })['hover'] === 'false') {
							setPointValues(undefined);
						}
						const MMDDYYOnly = Moment(sliderDate, 'DD/MM/YY').format('MM-DD-YY');
						// We are using "compaction" for users, but we are storing values as load at the moment.
						sensors.getDataPerDate(MMDDYYOnly, getMapCanvas(mapRenderer)).then((ans: any) => {
							const measurements: any[]= [];
							ans.forEach((pt: any) => {
								// Parse json only once...
								const parsedPayload = JSON.parse(pt.payload);
								const value = parseFloat(parsedPayload.value);
								const unit = parsedPayload.unit;

								// Ensure value is positive if unit is 'load'
								const adjustedValue = unit === 'kg' ? Math.abs(value) : value;

								measurements.push({
									'type': 'Feature',
									'properties': { 'value': adjustedValue, 'unit': unit, 'date': MMDDYYOnly },
									'geometry': {
										'type': 'Point',
										'coordinates': pt.point.coordinates
									}
								});
							});
							if (!prevSelectedSensor || !mapRenderer.getLayer('grid')) {
								drawGrid(mapRenderer, measurements, '');
							} else if (prevSelectedSensor) {
								drawGrid(mapRenderer, measurements, prevSelectedSensor);
								if (prevSelectedSensor === 'moisture' && moisture) {
									setSelectedSensor('moisture');
								} else if (prevSelectedSensor === 'temperature' && temperature) {
									setSelectedSensor('temperature');
								} else if (prevSelectedSensor === 'compaction' && compaction) {
									setSelectedSensor('compaction');
								} else if (prevSelectedSensor === 'ndvi' && ndvi) {
									setSelectedSensor('ndvi');
								}
							}
						});
					}
				}
			}
		}
	};

	const clean = () => {
		setCompaction(false);
		setTemperature(false);
		setMoisture(false);
		setNdvi(false);
		setHardness(false);
		setSelectedSensor(undefined);
		setSelectedHexValue('');
	};

	const removeSourceAndLayer = () => {
		try {
			if (mapRenderer.getLayer('grid')) {
				mapRenderer.off('click', 'grid', onClickGrid);
				mapRenderer.removeLayer('grid');
				if (mapRenderer.getSource('grid')) {
					mapRenderer.removeSource('grid');
				}
			}
		} catch (ex) {
			//Do Nothing
		}
	};

	const toMarks = () => {
		const marks: any = [];
		let lastDate = new Date();
		const map: Map<number, Date> = new Map();

		let order = 90;
		typesPerDate.forEach((value: string[], key: Date) => {
			if (order !== 90) {
				if (new Date(lastDate).getMonth() > new Date(key).getMonth())
					order = order - 12; //8 Arbitrary separation between dates
			}
			marks.push({
				value: order,
				label: Moment(key,'DD/MM/YY').format('DD-MM-YY')
			});

			map.set(order, key);
			lastDate = new Date(key);
			order = order - 8; //8 Arbitrary separation between dates
		});

		setValuesToDate(map);

		let dateExistsAfterUpdate = false;
		if (sliderDate) {
			map.forEach((val, key) => {
				if (val === sliderDate) {
					setSliderDate(val);
					setSliderValue(key);
					dateExistsAfterUpdate = true;
				}
			});
		}
		if (!dateExistsAfterUpdate) {
			setSliderDate(map.get(90));
			setSliderValue(90);
		}
		updateSensorButtons();
		setMarks(marks);
	};

	const DateFormatter = (tick: any) => {
		return Moment(tick).format('DD/MM');
	};

	const onClickGrid = (e: any) => {
		if (e.features != null) {
			const feat: Feature =  e?.features[0];
			const geom: Geometry = e?.features[0].geometry;
			if (feat != null && feat.properties != null && feat.properties.data != null) {
				const gridType = feat.properties.type;
				if (gridType === 'moisture') {
					setSelectedHexValue((feat.properties.color*100).toFixed(2) + '%');
				} else if (gridType === 'temperature') {
					setSelectedHexValue((feat.properties.color).toFixed(2) + 'C');
				} else if (gridType === 'hardness') {
					setSelectedHexValue((feat.properties.color).toFixed(3) + ' gravs');
				} else if (gridType === 'none') {
					setSelectedHexValue('Select a type');
				} else {
					setSelectedHexValue((feat.properties.color).toFixed(4));
				}
				//update data to be displayed
				sensors.getDataPerDate(JSON.parse(feat.properties.data)[0].date, geom).then((ans: any) => {
					updateValuesListBox(ans);
				});
				// Change the clicked when we are clicking on another hex with data.
				// But first, turn false the previous.
				if (hoveredHexId !== undefined) {
					mapRenderer.setFeatureState({source: 'grid', id: hoveredHexId }, { hover: false });
				}
				// turn true the new hovered hex
				hoveredHexId = e.features[0].id;
				mapRenderer.setFeatureState({ source: 'grid', id: e.features[0].id }, { hover: true });

				// Get the 2 points that define the area where to look for points for the line plots and do the get for the 3 types.
				sensors.getDataForLocation('moisture', geom).then((ans: any) => {
					const moistureTemp = ans.map((res: any) => {
						return { 'timestamp': Moment(res.timestamp).valueOf(), 'val': JSON.parse(res.payload).value*100 };
					});
					if ( moistureTemp?.length > 0 ) {
						setMoisturePlotValues(moistureTemp);
					} else {
						setMoisturePlotValues(undefined);
					}
				});
				sensors.getDataForLocation('load', geom).then((ans: any) => {
					const compactionTemp = ans.map((res: any) => {
						const payloadValue = JSON.parse(res.payload).value;
						return { 'timestamp': Moment(res.timestamp).valueOf(), 'val': Math.abs(payloadValue) };
					});
					if ( compactionTemp?.length > 0 ) {
						setCompactionPlotValues(compactionTemp);
					} else {
						setCompactionPlotValues(undefined);
					}
				});
				sensors.getDataForLocation('temperature', geom).then((ans: any) => {
					const temperatureTemp = ans.map((res: any) => {
						return { 'timestamp': Moment(res.timestamp).valueOf(), 'val': JSON.parse(res.payload).value };
					});
					if ( temperatureTemp?.length > 0 ) {
						setTemperaturePlotValues(temperatureTemp);
					} else {
						setTemperaturePlotValues(undefined);
					}
				});
			}
		}
	};

	const drawGrid = (mapRenderer: mapboxgl.Map, measurements: any[], type: 'compaction' | 'moisture' | 'temperature' | 'ndvi' | 'hardness' | '') => {
		const line = lineString([[mapRenderer.getBounds().getNorthEast().lng, mapRenderer.getBounds().getNorthEast().lat], [
			mapRenderer.getBounds().getSouthWest().lng, mapRenderer.getBounds().getSouthWest().lat]]);
		const bbx = bbox(line);
		const cellSide = hexSize;
		const met: Units = 'meters';
		const options = { units: met };
		const grid = turfgrid(bbx, cellSide, options);
		let dty = 0.0;
		let values: any[] = [];
		let featId = 0;
		let hexMeasurements: Array<number> = [];
		let fillColor: any = [
			'interpolate',
			['linear'],
			['get', 'color'],
			-1, 'rgb(110, 110, 110)',
			0, 'rgba(252, 28, 3, 0)',
			0.1, 'rgba(252, 28, 3, 0)',
			0.18, 'rgba(252, 28, 3, 0.4)',
			0.20, 'rgba(252, 240, 3, 0.4)',
			0.30, 'rgba(2, 179, 28, 0.4)',
			0.32, 'rgba(66, 245, 215, 0.4)',
			0.5, 'rgba(0, 102, 204, 0.4)',
			1, 'rgba(0, 102, 204, 0.4)'
		];

		if (type === 'ndvi') {
			fillColor = [
				'interpolate',
				['linear'],
				['get', 'color'],
				-1, 'rgb(110, 110, 110)',
				0, 'rgba(252, 28, 3, 0)',
				0.1, 'rgb(215, 48, 39)',
				0.2, 'rgb(244, 109, 67)',
				0.3, 'rgb(253, 174, 97)',
				0.4, 'rgb(254, 224, 139)',
				0.5, 'rgb(255, 255, 191)',
				0.6, 'rgb(217, 239, 184)',
				0.7, 'rgb(217, 239, 184)',
				0.8, 'rgb(102, 189, 99)',
				0.9, 'rgb(26, 152, 80)',
				1, 'rgb(0, 104, 55)'
			];
		}

		if (type === 'temperature') {
			fillColor = [
				'interpolate',
				['linear'],
				['get', 'color'],
				-1, 'rgb(110, 110, 110)',
				0, 'rgba(252, 28, 3, 0)',
				4, 'rgb(26, 152, 80)',
				8, 'rgb(102, 189, 99)',
				12, 'rgb(217, 239, 184)',
				16, 'rgb(217, 239, 184)',
				20, 'rgb(255, 255, 191)',
				24, 'rgb(254, 224, 139)',
				28, 'rgb(253, 174, 97)',
				32, 'rgb(244, 109, 67)',
				36, 'rgb(215, 48, 39)'
			];
		}

		if  (type === 'hardness') {
			fillColor = [
				'interpolate',
				['linear'],
				['get', 'color'],
				-1, 'rgb(110, 110, 110)',
				0, 'rgba(0, 102, 204, 0)',
				50, 'rgba(0, 102, 204, 0.4)',
				70, 'rgba(66, 245, 215, 0.4)',
				80, 'rgba(2, 179, 100, 0.4)',
				90, 'rgba(0, 240, 3, 0.4)',
				110, 'rgba(252, 240, 3, 0.4)',
				130, 'rgba(252, 28, 3, 0.4)',
				150, 'rgba(252, 28, 3, 0.4)'
			];
		}

		if (type === 'compaction') 	{
			fillColor = [
				'interpolate',
				['linear'],
				['get', 'color'],
				-1, 'rgb(110, 110, 110)',
				0, 'rgba(252, 28, 3, 0)',
				10, 'rgba(252, 28, 3, 0)',
				20, 'rgba(252, 28, 3, 0.4)',
				25, 'rgba(252, 240, 3, 0.4)',
				30, 'rgba(2, 179, 28, 0.4)',
				35, 'rgba(66, 245, 215, 0.4)',
				40, 'rgba(0, 102, 204, 0.4)',
				50, 'rgba(0, 102, 204, 0.4)'
			];
		}

		grid.features.forEach((f) => {
			dty = 0.0;
			values = [];
			f.id = featId++;
			hexMeasurements = [];
			measurements.forEach((m) => {
				if ( Turf.booleanWithin(m.geometry,f) ) {
					hexMeasurements.push(m.properties.value);
					dty = 1.0;
					values.push({ value: m.properties.value, unit: m.properties.unit, date: m.properties.date });
				}
			});
			if (hexMeasurements.length > 0) {
				if ( type === 'moisture' || type === 'temperature' || type === 'compaction' || type === 'ndvi' || type === 'hardness') {
					const avgMeasurement = (hexMeasurements.reduce((a, b) => (a+b))) / hexMeasurements.length;
					f.properties = { density: dty, color: avgMeasurement, data: values, type: selectedSensor };
				} else {
					// Color: if there is at least a value, but none is moisture, temperature or compaction, we set -1
					f.properties = { density: dty, color: -1, data: values, type: 'none' };
				}
			} else {
				// Color: if there is no data, we set 0
				f.properties = { density: dty, color: 0 };
			}
		});
		removeSourceAndLayer();
		mapRenderer.addLayer({
			'id': 'grid',
			'type': 'fill',
			'source': {
				'type': 'geojson',
				'data': grid
			},
			'layout': {},
			'paint': {
				'fill-color': fillColor,
				'fill-opacity': [
					'case',
					['boolean', ['feature-state', 'hover'], false],
					0.5,
					1
				],
				'fill-outline-color': [
					'case',
					['boolean', ['feature-state', 'hover'], false],
					'black',
					'rgba(0, 0, 0, 0)'
				]
			}
		});
		if (!handlerAdded) {
			// The mapRenderer.off('click'...) apparently is not working, because we were getting multiple calls to onClickGrid
			mapRenderer.on('click', 'grid', onClickGrid);
			mapRenderer.on('touchend', 'grid', onClickGrid);
			setHandlerAdded(true);
		}
	};

	const onSelectedSensor = (type: 'compaction' | 'moisture' | 'temperature' | 'ndvi' | 'hardness') => {
		if (zoomOverLimit) {
			return ;
		}
		setPointValues(undefined);
		setSelectedHexValue('');
		const MMDDYYOnly = Moment(sliderDate, 'DD/MM/YY').format('MM-DD-YY');
		// We are using "compaction" for users, but we are storing values as load at the moment.
		// Calculating temporarily the NDVI from the multispectral camera!
		sensors.getDataPerTypeAndDate(MMDDYYOnly, type === 'compaction' ? 'load' : (type === 'ndvi' ? 'multispectral' : type), getMapCanvas(mapRenderer)).then((ans: any) => {
			const measurements: any[]= [];
			ans.forEach((pt: any) => {
				if (type === 'ndvi') {
					const value = JSON.parse(pt.payload).value;
					if (parseFloat(value.wl_dwn_645) !== 0.0 && parseFloat(value.wl_dwn_810) !== 0.0) {
						const red = parseFloat(value.wl_up_645) / parseFloat(value.wl_dwn_645) ;
						const nir = parseFloat(value.wl_up_810) / parseFloat(value.wl_dwn_810) ;
						const ndvi = (nir-red)/(nir+red);
						measurements.push({
							'type': 'Feature',
							'properties': { 'value': ndvi, 'unit': '', 'date': MMDDYYOnly },
							'geometry': {
								'type': 'Point',
								'coordinates': pt.point.coordinates
							}
						});
					}
				} else {
					const parsedPayload = JSON.parse(pt.payload);
					const value = parseFloat(parsedPayload.value);
					const unit = parsedPayload.unit;

					// Ensure value is positive if unit is 'load'
					const adjustedValue = unit === 'kg' ? Math.abs(value) : value;

					measurements.push({
						'type': 'Feature',
						'properties': { 'value': adjustedValue, 'unit': unit, 'date': MMDDYYOnly },
						'geometry': {
							'type': 'Point',
							'coordinates': pt.point.coordinates
						}
					});
				}
			});
			drawGrid(mapRenderer, measurements, type);
		});
	};
	// interface ColourDef {
	// 	limits: number[];
	// 	colours: string[];
	// }
	// interface ColourDictionary {
	// 	[kind: string]: ColourDef;
	// }

	/*
	const getColourScale = ( kind: string ) => {
		const colourScheme: ColourDictionary = {
			'moisture': {
				limits: [0.18, 0.2, 0.3, 0.32],
				colours: ['rgba(252, 28, 3, 0.4)', 'rgba(252, 240, 3, 0.4)', 'rgba(2, 179, 28, 0.4)', 'rgba(66, 245, 215, 0.4)', 'rgba(0, 102, 204, 0.4)']
			}, 'temperature': {
				limits: [1, 13, 22, 25, 30, 37],
				colours: ['rgba(0,84,255, 0.4)', 'rgba(0,255,244, 0.4)', 'rgba(62,255,0, 0.4)', 'rgba(176,255,0, 0.4)', 'rgba(255,230,0, 0.4)', 'rgba(255,160,0, 0.4)', 'rgba(255,50,0, 0.4)']
			}, 'compaction': {
				limits: [10, 20, 30, 40, 50, 60],
				colours: ['rgba(255,0,16 , 0.4)', 'rgba(255,90,0, 0.4)', 'rgba(255,240,0, 0.4)', 'rgba(62,255,0, 0.4)', 'rgba(255,240,0, 0.4)', 'rgba(255,90,0, 0.4)', 'rgba(255,0,16 , 0.4)']
			}, 'ndvi': {
				limits: [0.2, 0.4, 0.6, 0.8],
				colours: ['rgba(252, 28, 3, 0.4)', 'rgba(252, 240, 3, 0.4)', 'rgba(2, 179, 28, 0.4)', 'rgba(66, 245, 215, 0.4)', 'rgba(0, 102, 204, 0.4)']
			}
		};

		const colours = colourScheme[kind];
		let position = -1;
		let i = 0;
		while ( position === -1 ) {
			if ( colours.limits[i] >= value ) {
				position = i;
			} else {
				i++;
			}
			if ( i === colours.limits.length ) {
				position = i-1;
			}
		}
		return colours.colours[position];
		[
			'interpolate',
			['linear'],
			['get', 'color'],
			-1, 'rgb(110, 110, 110)',
			0, 'rgba(252, 255, 0, 0)',
			0.18, 'rgba(252, 28, 3, 0.4)',
			0.20, 'rgba(252, 240, 3, 0.4)',
			0.30, 'rgba(2, 179, 28, 0.4)',
			0.32, 'rgba(66, 245, 215, 0.4)',
			1, 'rgba(0, 102, 204, 0.4)'
		]
	};
	*/
	const updateValuesListBox = (data: any) => {
		const tempArr:any = [];
		data.forEach((e:any) => {
			tempArr.push({ timestamp: e.timestamp, type: e.type, value: JSON.parse(e.payload) });
		});
		setPointValues([...tempArr]);
	};

	const onSelectedDate = (e: any, date: number | number[]) => {
		setSelectedSensor(undefined);
		setMoisturePlotValues(undefined);
		setCompactionPlotValues(undefined);
		setTemperaturePlotValues(undefined);
		setSliderDate(valuesToDate!.get(date));
		if (typeof date === 'number') {
			setSliderValue(date);
		}
		removeSourceAndLayer();
		updateSensorButtons();
	};

	const onHexSizeSet = (e: any, size: number | number[]) => {
		if (typeof size === 'number') {
			setHexSize(size);
		}

		removeSourceAndLayer();
		updateSensorButtons();

		if (selectedSensor !== undefined) {
			onSelectedSensor(selectedSensor);
		}
	};

	const onHexSizeChanged = (e: any, size: number | number[]) => {
		if (typeof size === 'number') {
			setHexSize(size);
		}
	};

	const handleOpen = (imgUrl: string) => {
		setOpen(true);
		setImgUrl(imgUrl);
	};

	const buildLink = (fileName: string, label: string ) => {
		return <Link href={buildFileUrl('getfileurl/'+fileName)} variant="body2" onClick={(e) => {
			e.preventDefault(); // avoids the page redirect allowing to show the link on hover (and copy).
			handleOpen(buildFileUrl('getfileurl/'+fileName));
		}}>{label}</Link>;
	};

	// Correcting formatting errors for NaN on Alive/Dead/Soil values
	const formatPhotoValue = (value: any) => (isNaN(value) || value === undefined ? 'N/A' : value.toFixed(2));

	const getListElement = (point: any, i: number) => {
		let element: JSX.Element;
		switch (point.type) {
		case 'moisture':
			element = <>[{Moment(point.timestamp).format('HH:mm:ss')}] Moisture: {(point.value?.value*100).toFixed(2)}{' '}{point.value.unit}</>;
			break;
		case 'temperature':
			element = <>[{Moment(point.timestamp).format('HH:mm:ss')}] Temperature: {(point.value?.value).toFixed(2)}{' '}{point.value.unit}</>;
			break;
		case 'load':
			element = <>[{Moment(point.timestamp).format('HH:mm:ss')}] Compaction: {Math.abs(point.value?.value).toFixed(2)}{' '}{point.value.unit}</>;
			break;
		case 'photo':
			element = <>[{Moment(point.timestamp).format('HH:mm:ss')}] Photo:
				{buildLink(point.value?.value.file_name, 'Open')}
			</>;
			break;
		case 'photos':
			element = <>[{Moment(point.timestamp).format('HH:mm:ss')}] Photo analysis:&nbsp;
				{buildLink(point.value?.value.rgb, 'RGB')} &nbsp;
				{buildLink(point.value?.value.ir, 'IR')} &nbsp;
				{buildLink(point.value?.value.rgb_crop, 'RGB Crop')} &nbsp;
				{buildLink(point.value?.value.ir_crop, 'IR Crop')} &nbsp;
				{buildLink(point.value?.value.mix, 'Mix')} &nbsp;
				Alive={formatPhotoValue(point.value?.value?.alive)}% / Dead={formatPhotoValue(point.value?.value?.dead)}% / Soil={formatPhotoValue(point.value?.value?.soil)}%
			</>;
			break;
		case 'ndvi':
			element = <>[{Moment(point.timestamp).format('HH:mm:ss')}] SKYE: {(point.value?.value).toFixed(6)}</>;
			// element = <>[{Moment(point.timestamp).format('HH:mm:ss')}] NDVI: {(point.value?.value).toFixed(6)}</>;
			break;
		case 'thermal':
			// CHANGED AVG<>MAX TEMPORARLIY BECAUSE ROBOT IS REPORTING INCORRECTLY!
			element = <>[{Moment(point.timestamp).format('HH:mm:ss')}] Thermal: MIN={(point.value?.value?.min).toFixed(2)} / AVG={(point.value?.value?.max).toFixed(2)} / MAX={(point.value?.value?.avg).toFixed(2)}</>;
			break;
		case 'multispectral': {
			// console.log('[' + Moment(point.timestamp).format('DD/mm/yyyy HH:mm:ss') + '] Multispectral: ');
			// console.log(point.value?.value);
			const value = point.value.value;
			const red = parseFloat(value.wl_up_645) / parseFloat(value.wl_dwn_645);
			const nir = parseFloat(value.wl_up_810) / parseFloat(value.wl_dwn_810);
			const ndvi = (nir-red)/(nir+red);
			element = <>[{Moment(point.timestamp).format('HH:mm:ss')}] NDVI: {ndvi.toFixed(4)}</>;
			break;
		}
		case 'hardness':
			element = <>[{Moment(point.timestamp).format('HH:mm:ss')}] Hardness: {(point.value?.value).toFixed(2)}{' '}{point.value.unit}</>;
			break;
		case 'payload':
			// Ignore. "Payload" is the raw moisture senor input. We are separating it into moisture, load and temperature on POST.
			return ;
		default:
			return <li key={i}>Unexpected type: {point.type}</li>;
		}
		if (selectedSensor === 'ndvi') {
			if (point.type === 'multispectral') {
				return <li key={i}><strong>{element}</strong></li>;
			}
		} else if (point.type === selectedSensor || (point.type === 'load' && selectedSensor === 'compaction')) {
			return <li key={i}><strong>{element}</strong></li>;
		}
		return <li key={i}>{element}</li>;
	};

	return (
		<>
			<div className={classes.box}>
				<div style={{position: 'relative'}}>
					<div id='map' className={classes.map}></div>
					{ mapClickLocation && mapClickLocation.lat !== 0 && mapClickLocation.lng !== 0 ?
						<div style={{ backgroundColor: '#ffffff', opacity: '80%', position: 'absolute', left: '0%', bottom: '0%', padding: '3px', fontSize: '0.8em', fontWeight: '300' }}>
							Last click latitude, longitude: { mapClickLocation.lat },{ mapClickLocation.lng }
						</div>
						:
						<></>
					}
					<div style={{position: 'absolute', width: '100%', top: '0%'}}>
						<DrawingNotch currentStep={MAP_SELECTION} lastStep={MAP_SELECTION + 1} PATH_DEFINITION={NONE} WAYPOINTS_SELECTION={NONE}
							ROBOT_SELECTION={NONE} TASK_SELECTION={NONE} SCHEDULING={NONE} MAP_SELECTION={MAP_SELECTION}
							deleteAll={() => {}} setCurrentStep={MAP_SELECTION} selectAll={() => {}} deselectAll={() => {}} TOKEN={MAP_TOKEN} setCenter={setPlace} setIsDrawWaypointMode={(value: boolean) => {}}
							setMapCursor={(value: string) => {}} mapboxDraw={() => {}}/>
					</div>
					{ zoomOverLimit ?
						<Card className={classes.notch} >
							<span>Zoom too far, plot won&lsquo;t update.</span>
						</Card>
						:
						<></>
					}
					<div className={classes.sensors}>
						<Fab variant="extended" disabled={!moisture} className={classes.sensorButton} onClick={ (event) => {
							setSelectedSensor('moisture');
						}}>Moisture { selectedSensor === 'moisture' ? <CheckIcon style={{marginLeft: '10px'}}/> : <></>}</Fab>
						<Fab variant="extended" disabled={!temperature} className={classes.sensorButton} onClick={ (event) => {
							setSelectedSensor('temperature');
						}}>Temperature { selectedSensor === 'temperature' ? <CheckIcon style={{marginLeft: '10px'}}/> : <></>}</Fab>
						<Fab variant="extended" disabled={!compaction} className={classes.sensorButton} onClick={ (event) => {
							setSelectedSensor('compaction');
						}}>Compaction { selectedSensor === 'compaction' ? <CheckIcon style={{marginLeft: '10px'}}/> : <></>}</Fab>
						<Fab variant="extended" disabled={!hardness} className={classes.sensorButton} onClick={ (event) => {
							setSelectedSensor('hardness');
						}}>Hardness { selectedSensor === 'hardness' ? <CheckIcon style={{marginLeft: '10px'}}/> : <></>}</Fab>
						<Fab variant="extended" disabled={!ndvi} className={classes.sensorButton} onClick={ (event) => {
							setSelectedSensor('ndvi');
						}}>NDVI { selectedSensor === 'ndvi' ? <CheckIcon style={{marginLeft: '10px'}}/> : <></>}</Fab>
						<div className={classes.sizeSelector}>
							<div>Hexagon size (m)</div>
							<Slider
								defaultValue={5}
								step={0.2}
								min={1.0}
								max={15}
								valueLabelDisplay="auto"
								marks={[{value: 1.0, label: '1.0'} , {value: 3.0, label: '3'}, {value: 5.0, label: '5'}, {value: 10.0, label: '10'}, {value: 15.0, label: '15'}]}
								track={false}
								value={hexSize}
								onChangeCommitted={onHexSizeSet}
								onChange={onHexSizeChanged}
							/>
						</div>
					</div>

					<div className={classes.hexDetailsBox}>
						<div className={classes.hexDetailsBoxTitle}>{selectedHexValue}</div>
						<div className={classes.hexDetailsBoxSubtitle}>Average value</div>
						<Divider />
						<h5>Values inside area</h5>
						<div className={classes.valuesContainer}>
							{ !pointValues ?
								<span> Click on an hexagon to see values. </span>
								:
								<ul style={{textAlign: 'left', maxHeight: '255px', overflowY: 'scroll', fontSize: '0.85em', paddingLeft: '20px', marginTop: '0'}}>
									{pointValues.map((point: any, i: number) => getListElement(point, i))}
								</ul>
							}
						</div>
					</div>

					{ (selectedSensor === 'moisture') ?
						<div className={classes.referenceBox}>
							<img className={classes.scaleImage} src={moistureScaleImage}></img>
						</div>
						:
						<></>
					}
				</div>
			</div>
			<Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Box className={classes.modal}><img src={imgUrl}></img></Box>
			</Modal>
			{ (typesPerDate.size <= 0) ?
				<Typography style={{ left: 0, paddingTop: '20px'}}>No data available for this area.</Typography>
				:
				<>
					<div className={classes.slider}>
						<Slider className={classes.margins} value={sliderValue} disabled={slider} track={false} step={null} marks={marks} onChange={onSelectedDate} />
					</div>
					{  moisturePlotValues || temperaturePlotValues || compactionPlotValues ?
						<div style={{ display: 'flex', maxWidth: '100%', flexWrap: 'wrap' }}>
							<div className={classes.plotContainer}>
								<h3 className={generalClasses.sectionTitle}> Moisture through time</h3>
								{moisturePlotValues ?
									<LineChart width={440} height={300} data={moisturePlotValues}>
										<Line type="monotone" dataKey="val" stroke="#8884d8" />
										<CartesianGrid stroke="#ccc" />
										<XAxis type="number" scale="time" domain={['dataMin', 'dataMax']} dataKey="timestamp" tickFormatter={DateFormatter} />
										<YAxis />
									</LineChart>
									:
									<div>
											No moisture data
									</div>
								}
							</div>
							<div className={classes.plotContainer}>
								<h3 className={generalClasses.sectionTitle}> Compaction through time</h3>
								{ compactionPlotValues ?
									<LineChart width={440} height={300} data={compactionPlotValues}>
										<Line type="monotone" dataKey="val" stroke="#8884d8" />
										<CartesianGrid stroke="#ccc" />
										<XAxis type="number" scale="time" domain={['dataMin', 'dataMax']} dataKey="timestamp" tickFormatter={DateFormatter} />
										<YAxis />
									</LineChart>
									:
									<div>
											No compaction data
									</div>
								}
							</div>
							<div className={classes.plotContainer}>
								<h3 className={generalClasses.sectionTitle}> Temperature through time</h3>
								{ temperaturePlotValues ?
									<LineChart width={440} height={300} data={temperaturePlotValues}>
										<Line type="monotone" dataKey="val" stroke="#8884d8" />
										<CartesianGrid stroke="#ccc" />
										<XAxis type="number" scale="time" domain={['dataMin', 'dataMax']} dataKey="timestamp" tickFormatter={DateFormatter} />
										<YAxis />
									</LineChart>
									:
									<div>
											No temperature data
									</div>
								}
							</div>
						</div>
						:
						selectedSensor ?
							<span className={generalClasses.placeholder}>Click an area on the map to plot. </span>
							:
							<></>
					}
				</>
			}
		</>
	);
};