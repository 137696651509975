import * as React from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton, Typography } from '@mui/material';
import style from '../../style/styles';

interface IProps {
    online: boolean;
    handleConnect: any;
    handleReconnect: any;
}

export const Connect: React.FC<IProps> = ({ online, handleConnect, handleReconnect }) => {
	const classes = style();
	const [user, setUser] = React.useState('not logged in');

	React.useEffect(() => {
		const userStore = localStorage.getItem('user');
		if (userStore) setUser(userStore);
		handleConnect();
		// eslint-disable-next-line
    }, []);

	return (
		<>
			<Typography variant='subtitle2'> USER: {user}</Typography>
			<div className={classes.connect}>
				<div className='online-wrapper'>
					<div className={online ? 'online' : 'offline'} />
				</div>
				<IconButton color='primary' size='medium' onClick={handleReconnect} className={classes.reconnect + ' ' + classes.enanoBackground} >
					<RefreshIcon/>
				</IconButton>
			</div>
		</>
	);
};
