import * as React from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import RoomIcon from '@mui/icons-material/Room';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import { Checkbox, InputLabel } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ReactMapGL, { MapEvent, Marker, WebMercatorViewport } from 'react-map-gl';
import * as Map from '../../helpers/map/map';
import { Coordinates } from '../../helpers/map/map';
import generalStyles from '../../style/styles';


interface props {
    measurementsLocations : geoData[];
    jobWaypoints: waypointGeoData[];
}

interface geoData {
    type: string;
	coordinates: number[];
}

interface waypointGeoData {
    id: string;
    type: string;
    properties: {
		type: string;
		walkthrough?: boolean;
	};
    geometry: {coordinates: [number, number]; type: string};
}

interface coordinatesData {
	type: string;
	long: number;
	lat: number;
}

interface checkButtonState {
    jobMarkers: boolean;
    sensorMarkers: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		mapContainer: { height: '100%', width: '100%', position: 'relative', zIndex: 2, marginRight: '0', border: '1px solid #8b8b8b', paddingRight: '0px' },
		waypointMarker: { zIndex: 1 },
		measureWaypointIcon: { height: '15px', width: '15px', fill: 'rgb(210,236,72)', transform: 'translate(-7.5px, -7.5px)' },
		walkthroughWaypointIcon: { height: '15px', width: '15px', fill: 'rgb(210,236,72)', transform: 'translate(-7.5px, -7.5px)' },
		sensorIcon: { height: '15px', width: '15px', color: 'rgb(0,117,184)', transform: 'translate(-7.5px, -7.5px)' },
		checkbox: { color: '#000000' },
		disabled: { color: 'rgba(0, 0, 0, 0.38)' },
		buttonDiv: { display: 'flex', alignItems: 'center', '&:disabled': { color: 'blue' } },
		mapButtons: { display: 'flex', flexDirection: 'column', alignItems: 'flex-end', position: 'absolute', bottom: '2.1em',
			right: '1.15em', color: '#000000', fontWeight: 'bold', backgroundColor: 'rgba(255, 255, 255, 0.4)', paddingLeft: '12px', paddingRight: '0px' }
	})
);

export const JobMap: React.FC<props> = ({ measurementsLocations, jobWaypoints }) => {
	const classes = useStyles();
	const generalClasses = generalStyles();
	const [viewport, setViewport] = React.useState<any>();
	const [checkButtons, setCheckButtons] = React.useState<checkButtonState>({ jobMarkers: true, sensorMarkers: true });
	const [clickedMarkerLocation, setClickedMarkerLocation] = React.useState<Coordinates>({ lat: 0, lng: 0 });
	const [clickedMarkerType, setClickedMarkerType] = React.useState<string>('');

	React.useEffect(() => {
		const tempstore: coordinatesData[] = [];
		setClickedMarkerLocation({ lat: 0, lng: 0});
		setClickedMarkerType('');
		if (measurementsLocations.length > 0 || jobWaypoints.length > 0) {
			if (measurementsLocations.length > 0) {
				measurementsLocations.forEach((geo) => {
					tempstore.push({
						type: 'sensor',
						long: geo.coordinates[0],
						lat: geo.coordinates[1]
					});
				});
			}
			if (jobWaypoints.length> 0) {
				jobWaypoints.forEach((waypoint) => {
					if (waypoint.geometry.type === 'Point') {
						tempstore.push({
							type: 'jobwaypoint',
							long: waypoint.geometry.coordinates[0],
							lat: waypoint.geometry.coordinates[1]
						});
					}
				});
			}

			const minLong = Math.min(...tempstore.map((geo) => {
				return geo.long;
			}));
			const minLat = Math.min(...tempstore.map((geo) => {
				return geo.lat;
			}));
			const maxLong = Math.max(...tempstore.map((geo) => {
				return geo.long;
			}));
			const maxLat = Math.max(...tempstore.map((geo) => {
				return geo.lat;
			}));
			const southWest: [number, number] = [minLong, minLat - 0.002];
			const northEast: [number, number] = [maxLong, maxLat + 0.002];
			const latRange = maxLat - minLat;
			if (latRange.toFixed(3) === '0.000' || latRange === 0) {
				setViewport({
					longitude: tempstore[0].long,
					latitude: tempstore[0].lat,
					zoom: 17
				});
			} else {
				setViewport(new WebMercatorViewport({ width: 800, height: 500, zoom: 15 })
					.fitBounds([southWest, northEast], { padding: 20, offset: [0, -100], maxZoom: 24 })
				);
			}
		} else {
			setViewport({
				longitude: 0,
				latitude: 0,
				zoom: 17
			});
		}
	}, [measurementsLocations, jobWaypoints]);

	const jobWaypointMarkers = (waypoint: waypointGeoData, index: number) => {
		if (waypoint.geometry.type === 'Point') {
			return (
				<Marker key={index} className={classes.waypointMarker} longitude={waypoint.geometry.coordinates[0]} latitude={waypoint.geometry.coordinates[1]}
					onClick={(event: MapEvent) => {
						setClickedMarkerLocation({ lat: waypoint.geometry.coordinates[0], lng: waypoint.geometry.coordinates[1]});
						setClickedMarkerType('Waypoint');
					}}>
					{ waypoint.properties.walkthrough ?
						<RoomOutlinedIcon className={classes.walkthroughWaypointIcon}/>
						:
						<RoomIcon className={classes.measureWaypointIcon}/>
					}
				</Marker>
			);
		}
	};

	const sensorWaypointMarkers = (measurementLocations: geoData, index: number) => {
		return (
			<Marker key={index} className={classes.waypointMarker} longitude={measurementLocations.coordinates[0]} latitude={measurementLocations.coordinates[1]}
				onClick={(event: MapEvent) => {
					setClickedMarkerLocation({ lat: measurementLocations.coordinates[0], lng: measurementLocations.coordinates[1]});
					setClickedMarkerType('Measurement');
				}}>
				<FiberManualRecordIcon className={classes.sensorIcon}/>
			</Marker>
		);
	};

	return (
		<>
			<div className={classes.mapContainer}>
				<ReactMapGL
					{...viewport}
					width='100%'
					height='100%'
					onViewportChange={(nextViewport: any) => setViewport(nextViewport)}
					mapboxApiAccessToken={Map.getMapToken()}

					mapStyle="mapbox://styles/mapbox/satellite-v9"
				>
					{jobWaypoints && checkButtons.jobMarkers && jobWaypoints.map(jobWaypointMarkers)}
					{measurementsLocations && checkButtons.sensorMarkers && measurementsLocations.map(sensorWaypointMarkers)}
				</ReactMapGL>

				{ clickedMarkerLocation && clickedMarkerLocation.lat !== 0 && clickedMarkerLocation.lng !== 0 ?
					<div style={{ backgroundColor: '#ffffff', opacity: '80%', position: 'absolute', right: '0', top: '0', padding: '3px', fontSize: '0.8em', fontWeight: '300', zIndex: '3' }}>
						Clicked {clickedMarkerType} longitude, latitude: { clickedMarkerLocation.lng },{ clickedMarkerLocation.lat }
					</div>
					:
					<></>
				}
				<div className={generalClasses.mapButtons}>
					<div className={generalClasses.mapButtonContainer} onClick={() => {
						if (jobWaypoints.length > 0) setCheckButtons({ ...checkButtons, jobMarkers: !checkButtons.jobMarkers });
					}}>
						<InputLabel className={generalClasses.mapButtonContainer} disabled={jobWaypoints.length<1}>Configured waypoints</InputLabel>
						<Checkbox className={generalClasses.mapCheckboxIcon} checked={checkButtons.jobMarkers} disabled={jobWaypoints.length<1}/>
					</div>
					<div className={generalClasses.mapButtonContainer} onClick={() => {
						if (measurementsLocations.length>0) setCheckButtons({ ...checkButtons, sensorMarkers: !checkButtons.sensorMarkers });
					}}>
						<InputLabel className={generalClasses.mapButtonContainer} disabled={measurementsLocations.length<1}>Measurement locations</InputLabel>
						<Checkbox className={generalClasses.mapCheckboxIcon} checked={checkButtons.sensorMarkers} disabled={measurementsLocations.length<1}/>
					</div>
				</div>
			</div>
		</>
	);
};