import * as config from './API/config';

let URL_BASE = config.DEV;
if (process.env.REACT_APP_ENV === 'LOCAL') {
	URL_BASE = config.LOCALHOST;
}


export function getQueryVariable (variable: string): string {
	const query = window.location.search.substring(1);
	const vars = query.split('&');
	for (let i=0;i<vars.length;i++) {
		const pair = vars[i].split('=');
		if (pair[0] == variable) {
			return decodeURI(pair[1]);
		}
	}
	return '';
}

export function buildFileUrl (filename: string): string {
	return URL_BASE+filename;
}
