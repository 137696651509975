import * as React from 'react';
import { Box, Typography } from '@mui/material';
import Moment from 'moment';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import importedStyles from '../../style/styles';

interface INDVIPlot {
  message?: any;
}

export const NDVIPlot: React.FC<INDVIPlot> = ({message}) => {
	const importedClasses = importedStyles();
	const [data, setData] = React.useState([{}]);
	const [filteredData, setFilteredData] = React.useState< string | null >(null);

	function plotData (message: any) {
		try {
			if (message.topic.includes('sensor/multispectral_live/AS7265x')) {
				const ts: string = message.message.timestamp.toString();
				const timestamp = Moment.unix(Number(ts.substr(0,ts.indexOf('.'))));
				const value = message.message.payload.value;
				const red = parseFloat(value.wl_up_645) / parseFloat(value.wl_dwn_645);
				const nir = parseFloat(value.wl_up_810) / parseFloat(value.wl_dwn_810);
				const ndvi = (nir-red)/(nir+red);
				const temp = [...data,
					{
						'time': timestamp.format('HH:mm:ss'),
						'NDVI': ndvi.toFixed(2)
					}
				];
				if (data.length>100) {
					temp.shift();
				}
				setData(temp);
			}
		} catch (err) {
			console.log('triad err'+err);
		}
	}

	const filteredValue = (message: any) => {
		try {
			if (message.topic.includes('sensor/multispectral/AS7265x')) {
				const value = message.message.payload.value;
				const red = parseFloat(value.wl_up_645) / parseFloat(value.wl_dwn_645);
				const nir = parseFloat(value.wl_up_810) / parseFloat(value.wl_dwn_810);
				const ndvi = (nir-red)/(nir+red);
				setFilteredData(ndvi.toFixed(2));
			}
		} catch (err) {
			console.log(err);
		}
	};

	React.useEffect(() => {
		plotData(message);
		if (data.length > 0) {
			filteredValue(message);
		}
	}, [message]);

	return (
		<Box className={importedClasses.chart}>
			{(data.length <= 1)?
				'WAITING FOR NDVI DATA':
				<>
					{(filteredData !== null) ?
						<Typography variant='subtitle2' style={{ position: 'absolute', marginTop: '0px', right: '2em', top: '0px' }}>Last measured value: {filteredData}</Typography> : <></>}
					<div style={{ height: 'calc(100% - 15px)', marginTop: '15px', width: '100%'}}>
						<ResponsiveContainer width='100%' height='100%'>
							<LineChart data={data}  margin={{ top: 5, right: 30, left: 20, bottom: 5 }} >
								<XAxis dataKey="time" tick={{fontSize: '10'}} label={{ value: 'Timestamp', position: 'insideBottom', offset: 0}}/>
								<YAxis label={{ value: 'NDVI', angle: -90, position: 'insideBottomLeft' , offset: 10}} domain={[-1, 1]}/>
								<CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
								<Line type="monotone" dataKey="NDVI" stroke="darkblue" isAnimationActive={false}/>
								{/* <Legend verticalAlign="top" height={30} iconSize={0}/> */}
							</LineChart>
						</ResponsiveContainer>
					</div>
				</>}
		</Box>
	);
};