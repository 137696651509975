import { createStyles, makeStyles, DefaultTheme } from '@mui/styles';



const useStyles = makeStyles((theme: DefaultTheme) =>
	createStyles({

		//GLOBALS
		enanoBackground: { background: 'lightgrey' },
		sectionTitle: { textAlign: 'center', marginTop: '50px', marginBottom: '30px', textTransform: 'uppercase' }, // From Reports3
		placeholder: { fontStyle: 'italic', fontWeight: 100 }, // From Reports3
		modal: { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', justifyContent: 'center', padding: '2rem', borderRadius: '15px' }, // From waypoints RadomPointsModal
		modalDetails: { flexDirection: 'column', marginTop: '2rem', marginBottom: '2rem' },
		modalCancel: { margin: theme.spacing(1), border: '1px solid rgba(0, 0, 0, 0.23)' },
		modalConfirm: { margin: theme.spacing(1), color: '#3f51b5', border: '1px solid rgba(63, 81, 181, 0.5)', '&:hover': { backgroundColor: 'rgb(247,248,252)', border: '1px solid #3f51b5' } },
		select: { borderRadius: 12, marginTop: '20px', boxShadow: '0px 5px 8px -3px rgba(0,0,0,0.14)' },
		dateSelect: { borderRadius: 12, marginTop: '20px', boxShadow: '0px 5px 8px -3px rgba(0,0,0,0.14)'
			// '& .MuiInputBase-root': { borderRadius: 12 },
			// '& .MuiOutlinedInput-root': { '& fieldset': { borderRadius: 12 } }
		},

		//Liveview
		chart: { height: '95%', width: '95%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', position: 'relative' },

		//Job modal styles
		details: { flexDirection: 'column', marginTop: '2rem', marginBottom: '2rem' },
		row: { flexDirection: 'row' },
		center: { justifyContent: 'center' },

		//Map
		mapButtons: { display: 'flex', flexDirection: 'column', alignItems: 'flex-end', position: 'absolute', bottom: '22px',
			right: '2px', color: 'black', fontWeight: 'bold', backgroundColor: 'rgba(255, 255, 255, 0.4)', paddingLeft: '12px', paddingRight: '0px' },
		mapButtonContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold', '&:hover': { cursor: 'pointer' }, color: 'black' },
		mapCheckboxIcon: { color: 'black', alignItems: 'center', padding: '5px', width: '21px' },
		mapCenterButton: { color: 'black', alignItems: 'center', padding: '5px', marginLeft: '-3px' },

		//Connect
		menu: { textDecoration: 'none' },
		connect: { display: 'flex', flexDirection: 'row', justifyContent: 'center' },
		reconnect: { width: 51, height: 51, margin: '1rem', border: 1 },
		messages: { backgroundColor: 'black', color: 'green', height: '120px', overflow: 'auto', textAlign: 'justify', padding: '20px', margin: '1rem' },

		//ControlLog
		textControlLog: { color: 'green' },
		buttonControlLog: { backgroundColor: 'green', height: '5px', minWidth: '5px', '&:hover': { backgroundColor: 'green' } },

		//CustomCommand
		buttonCustomCommand: { width: 200, height: 50, marginTop: '1rem' },
		inputCustomCommand: { width: 200, height: 50 },

		//JoystickControl
		buttonJoystick: { width: 300, height: 50, marginTop: '0.5rem', backgroundColor: 'tomato', borderRadius: '25px' },
		directionButton: { width: 85, height: 85, margin: '0.5rem', borderRadius: '45px' },
		imageJoystick: { width: '2rem', height: '2rem' },
		directionJoystick: { width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' },
		arrowsJoystick: { display: 'flex', flexDirection: 'column' },
		joystickBase: { height: '200px', width: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '100px' },
		brakeSection: { display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '2rem' },

		//Sensors
		rowSensors: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' },
		columnSensors: { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', margin: '0.5rem', padding: '0.5rem', borderRadius: '5px', backgroundColor: 'lightgrey', width: '450px' },
		dataSensors: { width: '150px', display: 'flex', flexDirection: 'row', alignItems: 'flex-end', marginLeft: '0.5rem' },

		//Stream
		videoStream: { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' },
		rowStream: { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' },

		//VideoStats
		statsVideo: { width: '90%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' },
		statVideo: { width: '5rem', height: '5rem', margin: '0.25rem', padding: '0.25rem', borderRadius: '0.5rem' },

		//ForgotPassword & Login & ResetPassword
		paperLogin: { padding: theme.spacing(4), flexDirection: 'column', alignItems: 'center', borderRadius: '15px', width: '300px' },
		submitLogin: { marginTop: theme.spacing(2), marginBottom: theme.spacing(2), backgroundColor: '#1a95c5' },
		gridLogin: { justifyContent: 'center' },
		errorLogin: { backgroundColor: '#ff7272', width: '100%', padding: '1%', color: 'white', fontWeight: 'bold' },
		errorMessageDiv: { height: '40px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' },
		errorText: { color: 'red', display: 'flex', justifyContent: 'flex-start', fontSize: '10pt' },

		//custom button
		// Custom button style
		buttonArea: {display: 'flex', justifyContent: 'space-between', marginTop: theme.spacing(2)},
		customButton: {backgroundColor: 'blue', color: 'white', padding: '10px 20px', borderRadius: '5px', fontSize: '16px', fontWeight: 'bold', '&:hover': { backgroundColor: 'darkblue'}},
		separator: {height: theme.spacing(2)},
		separatorTitle: {height: theme.spacing(4)},
		// Custom Button for Table Header
		selectedRow: { backgroundColor: theme.palette.grey[300] },
		customHeaderCell: {boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)'}
	})
);

export default useStyles;