import axios from 'axios';
import * as config from '../API/config';

let URL_BASE = config.DEV;
if (process.env.REACT_APP_ENV === 'LOCAL') {
	URL_BASE = config.LOCALHOST;
}

export interface Robot {
	name: string;
	model: string;
	macAddress: string;
	id: number;
	tasks: string;
	serialId: string;
	imageURL: string;
}

export async function getRobots () {
	let robotList: Robot[] = [];
	try {
		await axios.get(URL_BASE + 'robots', { withCredentials: true })
			.then((response) => {
				if (response.status === 200) robotList = [...response.data];
			});
		return robotList;
	} catch {
		return robotList;
	}
}

export async function getRobotById (id: number) {
	let robot: Robot;
	try {
		await axios.get(URL_BASE + 'robot/' + id, { withCredentials: true })
			.then((response) => {
				if (response.status === 200) robot = response.data;
			});
		return robot!;
	} catch {
		return null;
	}
}
