import * as React from 'react';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Card, Grid, IconButton, MenuItem, Select, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { MqttClient } from 'mqtt';
import { connectStream } from '../../helpers/connect/connect';
import { config } from '../../helpers/connect/connectConfig';
import { Loading } from '../site/Loading';
import { Player } from './Player';

export const useVideoStyles = makeStyles((theme: Theme) =>
	createStyles({
		videoCard: {
			height: 'calc(1.25 * 20vw)', borderRadius: '20px', boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start'
		},
		playerCard: {
			height: '240px', position: 'relative'
		},
		controlBar: {
			height: '40px', width: '100%', background: '#232D4C', display: 'flex', zIndex: 10
		},
		loading: {
			display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
		},
		icon: {
			color: 'white', justifyContent: 'flex-end'
		},
		iconGrid: {
			display: 'flex', justifyContent: 'flex-start'
		},
		form: {
			color: 'white'
		},
		formGrid: {
			display: 'flex', justifyContent: 'center'
		},
		viewport: {
			height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'
		},
		center: {
			display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%'
		},
		centerConnected: {
			display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', background: '#232D4C'
		}

	})
);
interface IVideo {
    setMessage: any;
    robotID: string;
    streamID: string[];
    client: MqttClient;
    setClient: any;
}

export const Video: React.FC<IVideo> = ({ setMessage, robotID, streamID, client, setClient }) => {
	const classes = useVideoStyles();
	const [streams, setStreams] = React.useState([new MediaStream()]);
	const [videoLoading, setVideoLoading] = React.useState(true);
	const [showing, setShowing] = React.useState(0);
	const [paused, setPaused] = React.useState(false);
	const [refresh, setRefresh] = React.useState(false);
	const [verifying, setVerifying] = React.useState(false);


	function returnStreams (existingClient: MqttClient) {
		const propsStream = { config, existingClient, streams, setStreams, setVideoLoading };
		streamID.map((streamID) => {
			if (streams[parseInt(streamID)]===undefined) {
				existingClient.publish('/'+robotID+ '/' + streamID + '/webrtc', JSON.stringify({ start: 'start' }), { qos: 2 });
				if (refresh===false) {
					existingClient.on('message', function (topic: any, message: any) {
						if (topic.includes('webrtc')) {
							const messageJSON = JSON.parse(message.toString());
							if (messageJSON.streamID === streamID && messageJSON.type === 'preoffer') {
								connectStream(propsStream, robotID, streamID);
								setVerifying(true);
							}
						}
					});
				}
			}
			return null;
		});
		setRefresh(true);
		console.log('return streams');
	}

	function handleStreamUpdate (e: React.ChangeEvent<{value: unknown}>) {
		setShowing(e.target.value as number);
	}

	const handlePauseToggle = () => {
		const tracks = streams[showing].getTracks();
		setPaused(tracks[0].enabled);
		tracks[0].enabled = paused;
	};

	const handleRefreshConnections = (e: React.MouseEvent<{value: unknown}>) => {
		e.preventDefault();
		console.log('button press');
		returnStreams(client);
	};

	React.useEffect(() => {
		if (client) {
			returnStreams(client);
			setRefresh(false);
			setStreams([new MediaStream()]);
		}
		// eslint-disable-next-line
    }, [robotID]);

	return (
		<Card className={classes.videoCard}>
			<div className={classes.controlBar}>
				<Grid container>
					<Grid item xs={3} className={classes.iconGrid}>
						<IconButton size='medium' onClick={(e) => handleRefreshConnections(e)} className={classes.icon}><RefreshIcon/></IconButton>
					</Grid>
					{!videoLoading?
						<Grid item xs={6} className={classes.formGrid}>
							<Select value={showing} onChange={(e: any) => handleStreamUpdate(e)} label="Select Stream" className={classes.form} disableUnderline>
								<MenuItem value={0}>Select stream</MenuItem>
								{streams.map((stream, i) => {
									if (i>0) {
										if (stream!==undefined) {
											return <MenuItem value={i} key={i} disabled={stream.active?false:true}>Stream {i}</MenuItem>;
										} else {
											return <MenuItem value={i} key={i} disabled={true}>Stream {i}</MenuItem>;
										}
									}
									return null;
								})}
							</Select>
							{(showing>0)?<IconButton size='medium' onClick={handlePauseToggle} className={classes.icon}> {!paused?<PauseCircleFilledIcon/>:<PlayCircleFilledWhiteIcon/>} </IconButton>:null
							}
						</Grid>
						: null
					}
				</Grid>
			</div>

			<div className={showing>0?classes.centerConnected: classes.center}>
				{videoLoading?
					<div className={classes.loading}>
						<Loading/>
						<Typography variant='body2'> {!verifying ? 'VIDEO UNAVAILABLE...' : 'VERIFYING VIDEO STREAMS...'} </Typography>
					</div>
					:
					<div className={classes.viewport}>
						{showing===0?<Typography variant='body2' className={classes.loading}> SELECT A STREAM ABOVE</Typography>:null}
						{showing===1?<Player url={streams[1]}/>:null}
						{showing===2?<Player url={streams[2]}/>:null}
						{showing===3?<Player url={streams[3]}/>:null}
					</div>
				}
			</div>
		</Card>
	);
};
