import * as React from 'react';
import ReactPlayer from 'react-player';

interface IPlayer {
  url: MediaStream;
}

export const Player: React.FC<IPlayer> = ({ url }) => {
	React.useEffect(() => {
	}, [url]);
	return (
		<div>
			<ReactPlayer playing={true} controls={true} url={url} width='100%' height='100%'/>
		</div>
	);
};
