import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useLiveviewStyles = makeStyles((theme: Theme) =>
	createStyles({
		card: {
			background: '#E4E4E4',
			borderRadius: '20px',
			boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',
			height: '180px',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center'
		},
		videoCard: {
			height: 'calc(1.25 * 20vw)',
			borderRadius: '20px',
			boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center'
		},
		roboSelectCard: {
			height: '40px',
			borderRadius: '20px',
			boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'flex-start',
			paddingLeft: '10px'
		},
		title: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'flex-start'
		},
		margin: {
			margin: '1%'
		},
		myLocation: {
			cursor: 'pointer'
		},
		plot: { marginTop: '20px' }
	}
	));
