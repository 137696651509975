import React, { useLayoutEffect, useRef } from 'react';
// import * as am5 from '@amcharts/amcharts5';
// import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
// import * as am5xy from '@amcharts/amcharts5/xy';
import { Grid } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import * as d3 from 'd3';
import moment from 'moment';
import * as sensors from '../../helpers/sensors/sensors';

const useStyles = makeStyles(() =>
	createStyles({
		boxPlotCard: { width: '100%', height: '300px', marginTop: '0px', marginLeft: '0px', paddingLeft: '0px', paddingTop: '0px', minHeight: '200px', alignItems: 'center', justifyContent: 'center' },
		chartContainer: { position: 'relative'},
		grid: {		}
	})
);

interface AreaBoxPlot {
    data: sensors.Sensor[];
	sensorType: string;
	selectedDate?: Date;
	getValueFromPayload?: (inputJson: any) => number;
}

export interface AreaBoxPlotData {
	q1: number;
	min: number;
	q3: number;
	max: number;
	date: number;
}

export const AreaBoxPlot: React.FC<AreaBoxPlot> = ({ data, sensorType, selectedDate, getValueFromPayload }: AreaBoxPlot) => {
	const classes = useStyles();

	const [plotFormattedValues, setPlotFormattedValues] = React.useState<AreaBoxPlotData[]>([]);
	const svgRef = useRef(null);

	const [svgDimensions, setSvgDimensions] = React.useState({ width: 500, height: 300 });
	const containerRef = useRef(null); // Reference to the container div


	React.useEffect(() => {
		const resizeObserver = new ResizeObserver((entries) => {
		  for (const entry of entries) {
				if (entry.target === containerRef.current) {
			  const { width, height } = entry.contentRect;
			  setSvgDimensions({ width, height });
				}
		  }
		});

		if (containerRef.current) {
		  resizeObserver.observe(containerRef.current);
		}

		return () => {
		  resizeObserver.disconnect();
		};
	  }, []);

	React.useEffect(() => {
		if (selectedDate && data) {
			const measurementsPerDate = new Map<number, sensors.Sensor[]>();
			data.filter((sensorValue: sensors.Sensor) => {
				return sensorValue.type === sensorType;
			}).forEach((sensorValue: sensors.Sensor) => {
				const sensorRelativeDate = moment(selectedDate, 'DD/MM/YY').diff(moment(sensorValue.timestamp).hours(0).minutes(0).seconds(0).millisecond(0), 'days');
				if (measurementsPerDate.has(sensorRelativeDate)) {
					measurementsPerDate.get(sensorRelativeDate)?.push(sensorValue.payload);
				} else {
					measurementsPerDate.set(sensorRelativeDate, [sensorValue.payload]);
				}
			});
			for (let i = 0; i < 7; i++) {
				if (!measurementsPerDate.has(i)) {
					measurementsPerDate.set(i, [sensors.emptySensor().payload]);
				}
			}
			const plotValues: AreaBoxPlotData[] = [];
			measurementsPerDate.forEach((values: any, date: number) => {
				const out = { q1: 0,
					min: 0,
					q3: 0,
					max: 0,
					median: 0,
					date: 0
				};
				const flatValues: number[] = values.map((sensorRow: any): number => {
					if (sensorRow && Object.keys(sensorRow).length > 0 && Object.getPrototypeOf(sensorRow) !== Object.prototype) {
						if (getValueFromPayload) {
							return getValueFromPayload(sensorRow);
						} else {
							return parseFloat(JSON.parse(sensorRow)?.value);
						}
					} else {
						return 0.0;
					}
				});
				const asc = (arr: number[]) => arr.sort((a, b) => a - b);
				//const sum = (arr: number[]) => arr.reduce((a, b) => a + b, 0); // Needed for mean
				//const mean = (arr: number[]) => sum(arr) / arr.length; // We are not using it at the moment. Could be added to the plot.

				const quantile = (arr: number[], q: number) => {
					const sorted = asc(arr);
					const pos = (sorted.length - 1) * q;
					const base = Math.floor(pos);
					const rest = pos - base;
					if (sorted[base + 1] !== undefined) {
						return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
					} else {
						return sorted[base];
					}
				};

				out.date = moment(selectedDate, 'DD/MM/YY').subtract(date, 'days').valueOf();
				out.min = Math.min(...flatValues);
				out.max = Math.max(...flatValues);
				out.median = quantile(flatValues, .50);
				out.q1 = quantile(flatValues, .25);
				out.q3 = quantile(flatValues, .75);
				plotValues.push(out);
			});
			plotValues.sort((a,b) => a.date - b.date);
			setPlotFormattedValues(plotValues);
		}
	}, [data]);

	React.useEffect(() => {
		if (!plotFormattedValues || plotFormattedValues.length === 0) return;

		const svg = d3.select(svgRef.current);
		svg.selectAll('*').remove();

		const margin = { top: 10, right: 20, bottom: 25, left: 35},
		  plotWidth = svgDimensions.width - margin.left - margin.right,
		  plotHeight = svgDimensions.height - margin.top - margin.bottom;

		const x = d3.scaleBand()
		  .range([0, plotWidth])
		  .domain(plotFormattedValues.map((d) => moment(d.date).format('MMM D')))
		  .padding(0.5);

		svg.append('g')
		  .attr('transform', `translate(${margin.left},${svgDimensions.height - margin.bottom})`)
		  .call(d3.axisBottom(x));

		const xAxisGrid = d3.axisBottom(x)
			.tickSizeInner(-svgDimensions.width)
			.tickFormat(() => '')
			.tickSizeOuter(0);

		const vMax = d3.max(plotFormattedValues.map((d) => d.max).filter((max): max is number => max !== undefined)) || 0;

		const y = d3.scaleLinear()
		  .domain([0, vMax*1.1])
		  .range([plotHeight, 0]);

		const yAxisGrid = d3.axisLeft(y)
		  .tickSizeInner(-svgDimensions.width)
		  .tickFormat(() => '')
		  .tickSizeOuter(0);

		svg.append('g')
		  .attr('transform', `translate(${margin.left}, ${margin.top})`)
		  .call(d3.axisLeft(y));

		const boxWidth = 1.5*x.bandwidth();
		const boxTranslationH = x.bandwidth()/2;
		const boxTranslationY = 0;

		svg.selectAll('.box')
			.data(plotFormattedValues)
			.enter()
			.append('rect')
			.attr('class', 'box')
			.attr('x', (d) => {
				const dateStr = moment(d.date).format('MMM D');
				const xValue = x(dateStr);
				return xValue !== undefined ? xValue + margin.left - (boxWidth / 2) : 0;
			})
			.attr('y', (d) => y(Math.max(d.q3, 0)) + margin.top) // Use Math.max to avoid negative values
			.attr('height', (d) => {
				const q1 = y(Math.max(d.q1, 0));
				const q3 = y(Math.max(d.q3, 0));
				return q1 - q3;
			})
			.attr('transform', `translate(${boxTranslationH}, ${boxTranslationY})`)
			.attr('width', boxWidth)
			.attr('stroke', 'black')
			.style('fill', '#379634');

		svg.append('g')
			.attr('class', 'grid horizontal-grid') // Use specific classes
			.call(yAxisGrid as any)
			.attr('transform', `translate(${margin.left}, ${margin.top})`)
			.selectAll('line')
			.attr('stroke-dasharray', '5,5')
			.attr('stroke', 'lightgrey');

		svg.append('g')
			.attr('class', 'grid vertical-grid')
			.attr('transform', `translate(0,${svgDimensions.height})`)
			.call(xAxisGrid as any)
			.attr('transform', `translate(${margin.left},${svgDimensions.height - margin.bottom})`)
			.selectAll('line')
			.attr('stroke-dasharray', '5,5')
			.attr('stroke', 'lightgrey');

		svg.selectAll('.whiskers')
			.data(plotFormattedValues)
			.enter()
			.append('line')
			.attr('class', 'whisker')
			.attr('x1', (d) => {
				const dateStr = moment(d.date).format('MMM D');
				const xValue = x(dateStr);
				return xValue !== undefined ? xValue + x.bandwidth()/2 : 0;
			})
			.attr('x2', (d) => {
				const dateStr = moment(d.date).format('MMM D');
				const xValue = x(dateStr);
				return xValue !== undefined ? xValue + x.bandwidth()/2 : 0;
			})
			.attr('y1', (d) => y(d.q1))
			.attr('y2', (d) => y(d.min))
			.attr('stroke', '#262626')
			.attr('stroke-width', 2)
			.attr('transform', `translate(${x.bandwidth()}, ${margin.top})`);

		svg.selectAll('.whiskers')
			.data(plotFormattedValues)
			.enter()
			.append('line')
			.attr('class', 'whisker')
			.attr('x1', (d) => {
				const dateStr = moment(d.date).format('MMM D');
				const xValue = x(dateStr);
				return xValue !== undefined ? xValue + x.bandwidth()/2 : 0;
			})
			.attr('x2', (d) => {
				const dateStr = moment(d.date).format('MMM D');
				const xValue = x(dateStr);
				return xValue !== undefined ? xValue + x.bandwidth()/2 : 0;
			})
			.attr('y1', (d) => y(d.q3))
			.attr('y2', (d) => y(d.max))
			.attr('stroke', '#262626')
			.attr('stroke-width', 2)
			.attr('transform', `translate(${x.bandwidth()}, ${margin.top})`);

		const endLineLength = x.bandwidth() / 4;

		svg.selectAll('.whisker-end-bottom')
			.data(plotFormattedValues)
			.enter()
			.append('line')
			.attr('class', 'whisker-end-bottom')
			.attr('x1', (d) => {
				const dateStr = moment(d.date).format('MMM D');
				const xValue = x(dateStr);
				return xValue !== undefined ? xValue + boxTranslationH - endLineLength / 2 : 0;
			})
			.attr('x2', (d) => {
				const dateStr = moment(d.date).format('MMM D');
				const xValue = x(dateStr);
				return xValue !== undefined ? xValue + boxTranslationH + endLineLength / 2 : 0;
			})
			.attr('y1', (d) => y(d.min))
			.attr('y2', (d) => y(d.min))
			.attr('stroke', '#379634')
			.attr('stroke-width', 2)
			.attr('transform', `translate(${x.bandwidth()}, ${margin.top})`);

		svg.selectAll('.whisker-end-top')
			.data(plotFormattedValues)
			.enter()
			.append('line')
			.attr('class', 'whisker-end-top')
			.attr('x1', (d) => {
				const dateStr = moment(d.date).format('MMM D');
				const xValue = x(dateStr);
				return xValue !== undefined ? xValue + boxTranslationH - endLineLength / 2 : 0;
			})
			.attr('x2', (d) => {
				const dateStr = moment(d.date).format('MMM D');
				const xValue = x(dateStr);
				return xValue !== undefined ? xValue + boxTranslationH + endLineLength / 2 : 0;
			})
			.attr('y1', (d) => y(d.max))
			.attr('y2', (d) => y(d.max))
			.attr('stroke', '#379634')
			.attr('stroke-width', 2)
			.attr('transform', `translate(${x.bandwidth()}, ${margin.top})`);

	  }, [plotFormattedValues, svgDimensions.height, svgDimensions.width]);

	return (
		<div className={classes.chartContainer}>
			<Grid container spacing={1} className={classes.boxPlotCard}>
				<div ref={containerRef} style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
					{ data !== undefined && data.length > 0 ?
						<svg ref={svgRef} width={svgDimensions.width} height={svgDimensions.height}>
							<g className={classes.grid}></g>
						</svg>
						:
						<div style={{ color: '#6f6f6f' }}>
						Boxplot unavailable
						</div>
					}
				</div>
			</Grid>
		</div>
	);
};