import * as React from 'react';
import { Card, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import GetTimeAgo from '../../helpers/date/TimeAgo';

const useTaskCardStyles = makeStyles((theme: Theme) => {
	return createStyles({
		card: {
			background: '#232D4C',
			borderRadius: '20px',
			boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',
			color: 'white',
			padding: '20px'
		},
		title: { display: 'flex', justifyContent: 'flex-start' },
		alignText: { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' },
		height: { height: '200px' },
		offlineCard: { flexDirection: 'row', alignItems: 'center', justifyContent: 'center', background: '#E4E4E4', borderRadius: '20px', boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)', padding: '20px' },
		schedulerStatus: { fontSize: '0.9em' }
	});
}
);

interface IProps {
  message: any;
  setCurrentFinished: any;
}

interface currentTask {
	value: {
		STATUS: string;
		TASK: {
			type: string;
		};
	};
	timestamp: Date;
	distance: number;
}

const tempTask : currentTask = { value: { STATUS: 'OFFLINE', TASK: { type: '' } }, timestamp: new Date(Date.now()), distance: NaN };

export const CurrentTaskCard: React.FC<IProps> = ({ message }) => {
	const classes = useTaskCardStyles();

	const [currentTask, setCurrentTask] = React.useState(tempTask);

	const timeFormatter = (dateTime: Date) => {
		const day: Date = new Date(dateTime);
		return `${day.getHours()}:${day.getMinutes()}:${day.getSeconds()}`;
	};
	const [schedulerStatus, setSchedulerStatus] = React.useState('');

	React.useEffect(() => {
		try {
			if (message.topic.includes('manager')) {
				const { timestamp } = message.message;
				const { value } = message.message.payload;
				const newCurrentTask : currentTask= {
					value,
					timestamp: new Date(parseInt(timestamp)*1000),
					distance: value.POINT.distance
				};
				setCurrentTask(newCurrentTask);
				// if (action==='jobfinished'){
				// 	setCurrentFinished(true);
				// }
			} else if (message.topic.includes('job_scheduler/next')) {
				const { currentTime, jobID, startTime, timeLeft } = message.message.payload.value;
				if (jobID === 'None') {
					setSchedulerStatus('');
					console.log('job scheduled = None');
					console.log('Job id: ' + jobID
					+ ' - Current: ' + moment.unix(currentTime).format('YYYY/MM/DD - HH:mm:ss')
					+ ' - Start: ' + moment.unix(startTime).format('YYYY/MM/DD - HH:mm:ss')
					+ ' - Left: ' + timeLeft);
				} else {
					setSchedulerStatus('Job id: ' + jobID
					+ ' - Current: ' + moment.unix(currentTime).format('YYYY/MM/DD - HH:mm:ss')
					+ ' - Start: ' + moment.unix(startTime).format('YYYY/MM/DD - HH:mm:ss')
					+ ' - Left: ' + timeLeft);
				}
			}
		} catch (e) {
			console.log(e);
		}
	}, [message]);

	return (
		<div className={classes.height}>
			<Typography className={classes.title} variant='subtitle1'>Current activity</Typography> <span className={classes.schedulerStatus}>{schedulerStatus}</span>
			<Card className={`${currentTask.value.STATUS === 'OFFLINE' ? classes.offlineCard : classes.card}`}>
				{(currentTask.value.STATUS === 'OFFLINE') ?
					<div>
						WAITING FOR INFO
					</div>:null
				}
				{(currentTask.value.STATUS === 'IDLE')?
					<div className={classes.alignText}>
						<Typography variant='h6'>Idle - waiting for next job</Typography>
						<Typography>Last message: {timeFormatter(currentTask.timestamp)} ({<GetTimeAgo date={currentTask.timestamp}/>})</Typography>
					</div>:null
				}
				{(currentTask.value.STATUS === 'MOVE_TO_NEXT_POINT')?
					<div className={classes.alignText}>
						<Typography variant='h6'>Waiting for movement command</Typography>
						<Typography>Last message: {timeFormatter(currentTask.timestamp)} ({<GetTimeAgo date={currentTask.timestamp}/>})</Typography>
						<Typography>Distance from next waypoint: {currentTask.distance.toFixed(2)} m</Typography>
					</div>:null
				}
				{(currentTask.value.STATUS === 'MOVING_TO_NEXT_POINT')?
					<div className={classes.alignText}>
						<Typography variant='h6'>Moving to waypoint</Typography>
						<Typography>Last message: {timeFormatter(currentTask.timestamp)} ({<GetTimeAgo date={currentTask.timestamp}/>})</Typography>
						<Typography>Distance from next waypoint: {currentTask.distance.toFixed(2)} m</Typography>
					</div>:null
				}
				{(currentTask.value.STATUS === 'MEASURING')?
					<div className={classes.alignText}>
						<Typography variant='h6'>Completing measurement - type: {currentTask.value.TASK.type}</Typography>
						<Typography>Last message: {timeFormatter(currentTask.timestamp)} ({<GetTimeAgo date={currentTask.timestamp}/>})</Typography>
						<Typography>Distance from next waypoint: {currentTask.distance.toFixed(2)} m</Typography>
					</div>:null
				}
			</Card>
		</div>
	);
};
