import * as React from 'react';
import { Button, Divider, Paper, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router-dom';
import * as Auth from '../../helpers/auth/auth';
import logo from '../../resources/images/colour-logo.png';
import style from '../../style/styles';

interface Parameter {
	//TODO check this.
	token: any;
	userId: any;
}

export const ResetPassword: React.FC = () => {
	const classes = style();
	const history = useHistory();
	const params: Parameter = useParams();

	const [newPassword, setNewPassword] = React.useState('');
	const [confirmPassword, setconfirmPassword] = React.useState('');
	const { enqueueSnackbar } = useSnackbar();

	const onChangeNewPassword = ((e: any) => {
		setNewPassword(e.target.value);
	});

	const onChangeConfirmPassword = ((e: any) => {
		setconfirmPassword(e.target.value);
	});

	const changePassword = (() => {
		if (confirmPassword === newPassword) {
			Auth.changePassword(params['token'], params['userId'], newPassword, () => {
				history.push('/login');
				enqueueSnackbar('Password changed. Please login with your new password.', { variant: 'success', autoHideDuration: 5000 });
			}, () => {
				enqueueSnackbar('Error changing password. Please contact support.', { variant: 'error', autoHideDuration: null });
			});
		} else {
			enqueueSnackbar('Passwords do not match, please amend and try again.', { variant: 'error', autoHideDuration: 10000 });
		}
	});

	return (
		<div className='Login'>
			<Paper elevation={1} className={classes.paperLogin}>
				<form>
					<figure> <img src={logo} alt='Sign In logo'/></figure>
					<Typography variant='subtitle1'> {' '}Reset Password{' '} </Typography>
					<Divider/>
					<br/>
					<TextField variant='outlined' margin='dense' size='medium' label='New Password' type='password' autoComplete='off' onChange={onChangeNewPassword} />
					<br/>
					<TextField variant='outlined' margin='dense' size='medium' label='Confirm New Password' type='password' autoComplete='off' onChange={onChangeConfirmPassword} />
					<br/>
					<Button variant='contained' className={classes.submitLogin} fullWidth onClick={changePassword}> {' '}Reset{' '} </Button>
				</form>
			</Paper>
		</div>
	);
};
