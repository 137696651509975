import * as React from 'react';
import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStatusBarStyles = makeStyles((theme: Theme) =>
	createStyles({
		online: { height: '15px', width: '15px', borderRadius: '10px', backgroundColor: 'green' },
		offline: { height: '15px', width: '15px', borderRadius: '10px', backgroundColor: 'red' }
	})
);

interface IOnline {
   online: boolean;
}

export const Online: React.FC<IOnline>= ({ online }) => {
	const classes = useStatusBarStyles();

	return (
		<>
			{online?<div className={classes.online}/>: <div className={classes.offline}/>}
		</>
	);
};