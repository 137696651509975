import React from 'react';
import { Button } from '@mui/material';
import { Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { createStyles, makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import generalStyles from '../../style/styles';

interface CoordinatesInsertCard {
    showOnStep: number;
	currentStep: number;
	positioning: string;
	showCard: boolean;
    setShowCard: (value: boolean) => void;
    addPoint: (lat: number, lng: number) => boolean;
    // TODO: refactor so that main view watches features list and updates values automatically on change.
    updateValues: () => void;
}

export const insertCardStyles = makeStyles((theme: Theme) =>
	createStyles({
		div: { background: 'white', borderRadius: '15px', overflow: 'auto', marginTop: '3%' }
	})
);

export const CoordinatesInsertCard: React.FC<CoordinatesInsertCard> = ({ showOnStep, currentStep, positioning, showCard, setShowCard, addPoint, updateValues }) => {
	const classes = insertCardStyles();
	const importedclasses = generalStyles();
	const { enqueueSnackbar } = useSnackbar();

	const [latlng, setLatlng] = React.useState<string>('');

	const insertPoint = () => {
		if (latlng.indexOf(',') > -1 && latlng.indexOf(',') === latlng.lastIndexOf(',')) {
			const lat = parseFloat(latlng.split(',')[0]);
			const lng = parseFloat(latlng.split(',')[1]);
			if (addPoint(lat, lng)) {
				updateValues();
				setLatlng('');
				enqueueSnackbar('Point added to map.', { variant: 'info', autoHideDuration: 5000, anchorOrigin: { horizontal: 'left', vertical: 'bottom' } });
			} else {
				enqueueSnackbar('The point is outside the created areas. Try with other coordinates.', { variant: 'error', autoHideDuration: 5000 } );
			}
		}
	};

	return (
		<>
			{   currentStep === showOnStep && showCard ?
				<div className={classes.div + ' ' + positioning}>
					<TextField
						label='Point coordinates'
						variant='standard'
						placeholder='latitude,longitude'
						margin='normal'
						InputLabelProps={{ shrink: true }}
						InputProps={{ autoComplete: 'off' }}
						value={latlng || ''}
						onChange={(event) => {
							setLatlng(event.target.value);
						}}
						sx={{ width: '240px' }}
					/>
					<div>
						<Button variant='outlined' className={importedclasses.modalConfirm} onClick={insertPoint}>Add</Button>
					</div>
				</div>
				:
				<></>
			}
		</>
	);
};
