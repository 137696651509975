import * as React from 'react';
import { Button, Card, Typography } from '@mui/material';
import style from '../../style/styles';

interface IMessage {
    timestamp: number;
    read: boolean;
    message: string;
    topic: string;
    full: boolean;
}

interface IProps {
    messageQueue: IMessage[];
    setMessageQueue: (messageQueue: IMessage[]) => void;
}

export const ControlLog: React.FC<IProps> = ({ messageQueue, setMessageQueue }) => {
	const classes = style();

	function handleExpand (message: IMessage, i: number) {
		const newFlag = !message.full;
		const newMessage = { ...message, full: newFlag };
		const newMessageQueue = [...messageQueue];
		newMessageQueue.splice(i, 1, newMessage);
		setMessageQueue(newMessageQueue);
	}

	return (
		<Card className={classes.messages}>
			{messageQueue.map((message, i) => {
				let readWrite = 'READ';
				if (!message.read) readWrite = 'WRITE';
				const messageFull = `[${message.timestamp}][${readWrite}][${message.topic}][${message.message}]`;
				const messageShort = messageFull.slice(0, 90);

				return (
					<div key={i}>
						<Typography variant='body2' className={classes.textControlLog} id='text' >
							<Button variant='text' disableFocusRipple={true} className={classes.buttonControlLog} onClick={() => handleExpand(message, i)} >
								{message.full ? '-' : '+'}
							</Button>
							{message.full ? messageFull : messageShort}
						</Typography>
					</div>
				);
			})}
		</Card>
	);
};
