import React, { useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import Switch from '@mui/material/Switch';
import { Joystick } from 'react-joystick-component';
import down from '../../resources/images/down.png';
import up from '../../resources/images/up.png';
import style from '../../style/styles';

type JoystickDirection = 'FORWARD' | 'RIGHT' | 'LEFT' | 'BACKWARD';

export interface IJoystickUpdateEvent {
    type: 'move' | 'stop' | 'start';
    x: number | null;
    y: number | null;
    direction: JoystickDirection | null;
}
interface IControlMessage {
    timestamp: string;
    payload: {
        USERID: string;
        SPEED: number;
        ANGLE: number;
        BRAKE: number;
        SRC: string;
    };
}
interface IProps {
    movementMessage: IControlMessage;
    setMovementMessage: (controlMessage: IControlMessage) => void;
    online: boolean;
    hasFocus: boolean;
    toggleRemoteControl: boolean;
    handleToggleRemoteControl: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const JoystickControl: React.FC<IProps> = ({ movementMessage, setMovementMessage, online, hasFocus, toggleRemoteControl, handleToggleRemoteControl }) => {
	const classes = style();

	//state
	const [speed, setSpeed] = React.useState(0);
	const [delay, setDelay] = React.useState(true);

	function handleSteer (event: IJoystickUpdateEvent) {
		if ((event.x && event.y) !== null) {
			const newAngle: any = event.x;
			const newCommand = {
				timestamp: new Date(Date.now()).toISOString(),
				payload: {
					USERID: movementMessage.payload.USERID,
					SPEED: movementMessage.payload.SPEED,
					ANGLE: newAngle,
					BRAKE: 0,
					SRC: movementMessage.payload.SRC
				}

			};
			setMovementMessage(newCommand);
		}
	}

	function handleCenter () {
		const newCommand = {
			timestamp: new Date(Date.now()).toISOString(),
			payload: {
				USERID: movementMessage.payload.USERID,
				SPEED: movementMessage.payload.SPEED,
				ANGLE: 0,
				BRAKE: 0,
				SRC: movementMessage.payload.SRC
			}
		};
		setMovementMessage(newCommand);
	}

	function handleStop (e: React.SyntheticEvent) {
		e.preventDefault();
		const newCommand = {
			timestamp: new Date(Date.now()).toISOString(),
			payload: {
				USERID: movementMessage.payload.USERID,
				SPEED: 0,
				ANGLE: 0,
				BRAKE: 1,
				SRC: movementMessage.payload.SRC
			}
		};
		setMovementMessage(newCommand);
		setSpeed(0);
	}

	function handleSpeed (speed: number, forward: boolean) {
		let newSpeed = speed;
		if (forward && newSpeed <= 90) {
			newSpeed += 10;
		} else if (!forward && newSpeed >= -90) {
			newSpeed -= 10;
		}
		const newCommand = {
			timestamp: new Date(Date.now()).toISOString(),
			payload: {
				USERID: movementMessage.payload.USERID,
				SPEED: newSpeed,
				ANGLE: 0,
				BRAKE: 0,
				SRC: movementMessage.payload.SRC
			}
		};
		setSpeed(newSpeed);
		setMovementMessage(newCommand);
	}

	useEffect(() => {
		const handleKeyDown = (event: any) => {
			if (!hasFocus) {
				if (!event.repeat) {
					if (event.keyCode === 87) handleSpeed(speed, true);
					if (event.keyCode === 83) handleSpeed(speed, false);
					if (event.keyCode === 32) handleStop(event);
				} else {
					if (event.keyCode === 87) {
						if (delay) {
							setDelay(false);
							handleSpeed(speed, true);
							setTimeout(() => setDelay(true), 1000);
						}
					}
					if (event.keyCode === 83) {
						if (delay) {
							setDelay(false);
							handleSpeed(speed, false);
							setTimeout(() => setDelay(true), 1000);
						}
					}
				}
			}
		};
		window.addEventListener('keydown', handleKeyDown);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	});

	return (
		<>
			<div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
				<Typography variant='subtitle2'> {toggleRemoteControl? 'Enable': 'Disable '} Remote Control</Typography>
				<Switch checked={!toggleRemoteControl} onChange={handleToggleRemoteControl} color='primary'/>
			</div>
			<div className={classes.directionJoystick}>
				<div className={classes.arrowsJoystick}>
					<Button disabled={!online || toggleRemoteControl} variant='outlined' className={classes.directionButton + ' ' + classes.enanoBackground} onClick={() => handleSpeed(speed, true)} >
						<img src={up} alt='arrow' className={classes.imageJoystick} />
					</Button>
					<Button disabled={!online || toggleRemoteControl} variant='outlined' className={classes.directionButton + ' ' + classes.enanoBackground} onClick={() => handleSpeed(speed, false)} >
						<img src={down} alt='arrow' className={classes.imageJoystick} />
					</Button>
				</div>
				<div className={classes.brakeSection}>
					<Typography variant='body2'> press/hold W for forward <br /> press/hold S for backward <br /> press &lsquo;SPACE&rsquo; for brake </Typography>
					<Button disabled={!online || toggleRemoteControl} variant='outlined' className={classes.buttonJoystick} onClick={(e) => handleStop(e)} > BRAKE </Button>
				</div>
				<div className={classes.joystickBase + ' ' + classes.enanoBackground}>
					<Joystick size={100} throttle={200} baseColor='lightgrey' stickColor='green' move={handleSteer} stop={handleCenter} disabled={!online ||toggleRemoteControl}/>
				</div>
			</div>
		</>
	);
};
