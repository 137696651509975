export const isLatitude = (lat: any) => {
	if (isNumber(lat) && lat <= parseFloat('90.0') && lat >= parseFloat('-90.0')) {
		return true;
	}
	return false;
};

export const isLongitude = (lon: any) => {
	if (isNumber(lon) && lon <= parseFloat('180.0') && lon >= parseFloat('-180.0')) {
		return true;
	}
	return false;
};

function isNumber (n: any) {
	return !isNaN(parseFloat(n)) && !isNaN(n - 0);
}