import { connectMQTT } from '../connect/connect';
import { connectOptions, connectURL, createHeartbeatConfig } from '../connect/connectConfig';
import * as robots from '../robot/robot';

export interface heartbeat {
    serialId: string;
    timer: number;
    isOnline: boolean;
    location?: {
        lat: number;
        lng: number;
    };
}

export function getHeartbeats (robotList: robots.Robot[] | undefined) {
	const heartbeatsArr : heartbeat[] = [];
	if (robotList) {
		robotList.map((robot) => {
			const heartbeatTemp = {
				serialId: robot.serialId,
				timer: 0,
				isOnline: false,
				location: {
					lat: 0,
					lng: 0
				}
			};
			heartbeatsArr.push(heartbeatTemp);
			return null;
		});
	}
	return heartbeatsArr;
}

export async function connectClient (heartbeats: any, setMessage: any) {
	const messageTopics = createHeartbeatConfig(heartbeats);
	const propsMQTT = {
		url: connectURL,
		options: connectOptions(),
		topics: messageTopics,
		setMessage: setMessage
	};
	return connectMQTT(propsMQTT).then((clientBack: any) => {
		console.log('MQTT connected');
		return clientBack;
	});
}

export function updateHeartbeatMessage (heartbeats: heartbeat[], message: any, setHeartbeats: any) {
	let serialID = '';
	let geoLocation: any = { lat: 0, lon: 0 };
	if (message.topic) {
		serialID = message.topic.split('/')[1];
		geoLocation = message.message.geo_position;
	}
	const heartbeatsTemp = [...heartbeats];
	heartbeatsTemp.forEach((heartbeat) => {
		if (heartbeat.serialId === serialID) {
			heartbeat.isOnline = true;
			heartbeat.timer = 10;
			heartbeat.location = {
				lat: geoLocation.lat,
				lng: geoLocation.lon
			};
		}
	});
	setHeartbeats(heartbeatsTemp);
	return null;
}

export function updateHeartbeatTime (heartbeats: heartbeat [], message: any, setHeartbeats: any) {
	if (message.topic) {
		heartbeats.forEach((heartbeat) => {
			heartbeat.timer = heartbeat.timer - 1;
			if (heartbeat.timer <= 0) {
				heartbeat.isOnline = false;
				heartbeat.timer = 0;
			}
		});
		setHeartbeats(heartbeats);
	}
	return null;
}