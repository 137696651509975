import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import UserContext from '../../context/UserContext';

// eslint-disable-next-line
const LoginRoute = ({ component, ...rest }: any) => {

	const User = React.useContext(UserContext);

	const routeComponent = (props: any) =>
		!User.isAuthenticated ? (
			React.createElement(component, props)
		) : (
			<Redirect to="/robots" />
		);
	return <Route {...rest} render={routeComponent} />;
};

export default LoginRoute;
