import { Geometry, geometry } from '@turf/turf';
import axios from 'axios';
import * as config from '../API/config';

let URL_BASE = config.DEV;
if (process.env.REACT_APP_ENV === 'LOCAL') {
	URL_BASE = config.LOCALHOST;
}

export interface geoData {
    type: string;
	coordinates: number[];
}

export interface waypointGeoData {
    id: string;
    type: string;
    properties: {type: string};
    geometry: { coordinates: [number, number]; type: string };
}

export interface Sensor {
	id: number;
    timestamp: Date;
    payload: any;
    point: geoData;
    type: string;
    robotId: number;
    job: number;
}

export function emptySensor (): Sensor {
	return {
		id: 0,
		timestamp: new Date,
		payload: { },
		point: { type: 'point', coordinates: [0,0] },
		type: '',
		robotId: 0,
		job: 0
	};
}

export interface SensorTypes {
	date: Date;
	types: string[];
}

export async function getSensors (serialId: string, dateFrom?: string, dateTo?: string) {
	let ans: Sensor[] = [];
	let additionalQueries = '';
	if (dateFrom && dateTo) {
		additionalQueries = `&dateFrom=${dateFrom}&dateTo=${dateTo}`;
	}

	await axios.get(URL_BASE + `sensordata?serialId=${serialId}` + additionalQueries, { withCredentials: true }).then((response) => {
		if (response.status === 200) {
			ans = [...response.data];
		}
	});
	return ans;
}

export async function getSensorsTypePerDate (geometry: Geometry) {
	let ans: SensorTypes[] = [];
	await axios.post(URL_BASE + 'sensordata/types-per-date?', {
		'geometry': geometry
	}, {
		withCredentials: true
	}).then((response) => {
		if (response.status === 200) {
			ans = [...response.data];
		}
	});

	return ans;
}

export async function getSensorsTypePerDateForArea (area: string) {
	let ans: SensorTypes[] = [];
	await axios.get(URL_BASE + 'sensordata/types-per-date-user-area?', {
		withCredentials: true,
		params: {
			area: area
		}
	}).then((response) => {
		if (response.status === 200) {
			ans = [...response.data];
		}
	});

	return ans;
}

export async function getDataPerTypeAndDate (date: string, type: string, geometry: Geometry) {
	let ans: Sensor[] = [];
	await axios.post(URL_BASE + 'sensordata/data-per-date?', {
		'date': date,
		'type': type,
		'geometry': geometry
	}, {
		withCredentials: true
	}).then((response) => {
		if (response.status === 200) {
			ans = [...response.data];
		}
	});

	return ans;
}

export async function getDataPerDate (date: string, geometry: Geometry) {
	//Ans type any is not proper. Doing this way because data receivid will be modified with the nexts tries.
	let ans: Sensor[] = [];
	await axios.post(URL_BASE + 'sensordata/data-per-date',  {
		'date': date,
		'geometry': geometry
	}, {
		withCredentials: true
	}).then((response) => {
		if (response.status === 200) {
			ans = [...response.data];
		}
	});
	return ans;
}

export async function getDataBetweenDates (dateFrom: string, dateTo: string, device: string): Promise<Sensor[]> {
	//Ans type any is not proper. Doing this way because data receivid will be modified with the nexts tries.
	let allData: Sensor[] = [];

	await axios.post(URL_BASE + 'sensordata/data-between-dates',  {
		'dateFrom': dateFrom,
		'dateTo': dateTo,
		'deviceId': device
	}, {
		withCredentials: true
	}).then((response) => {
		if (response.status === 200) {
			allData = [...response.data];
			console.log('Adding Data Collected');
		}
	});
	return allData;
}

export async function getDataForLocation (type: string, geometry: Geometry) {
	let ans: Sensor[] = [];
	await axios.post(URL_BASE + 'sensordata/data-for-location?', {
		'type': type,
		'geometry': geometry
	},{
		withCredentials: true
	}).then((response) => {
		if (response.status === 200) {
			ans = [...response.data];
		}
	});

	return ans;
}

export async function getGeoDataByJobId (jobId: string) {
	let sensorData: Sensor[] = [];
	await axios.get(URL_BASE + 'sensordata/jobroute', {
		withCredentials: true,
		params: {
			jobId
		}
	}).then((response) => {
		if (response.status === 200) {
			sensorData = [...response.data];
		}
	});

	return sensorData;
}

export async function getDataForIntervalInUserArea (areaName: string, startDate: string, endDate: string) {
	let dataResponse: Sensor[] = [];
	await axios.get(URL_BASE + 'sensordata/data-for-interval-user-area', {
		withCredentials: true,
		params: {
			from: startDate,
			to: endDate,
			area: areaName
		}
	}).then((response) => {
		if (response.status === 200) {
			dataResponse =[...response.data];
		}
	});
	return dataResponse;
}

export async function getDataForDateInUserArea (areaName: string, forDate: string) {
	return getDataForIntervalInUserArea(areaName, forDate, forDate);
}
